<div class="mxe__thank-you-page--wrapper">
    <button type="button" class="btn btn-x"  *ngIf="isMainScreen" data-linktracking="cta:summary:close" (click)="closeThankYouPage()">
        <img src="../../../assets/icons/mxe__ico-exit-menu.svg" alt="X" />
    </button>
    <div class="mxe__thank-you-page--container">
        <div class="thank-you-page-content">
            <div class="thank-you-page-title">
                {{getLabel('SO_STF_THANKYOU_TEXT')}}
            </div>
            <div class="thank-you-page-qr-code">
                <ngx-qrcode-styling [config]="qrCodeConfig"></ngx-qrcode-styling>
            </div>
            <div class="thank-you-page-message gradient-line">
                Scan this QR Code to bring the configuration with you.
            </div>
            <div class="thank-you-page-config-code">
                Your configuration code:
            </div>
            <div class="thank-you-page-config-code-container">
                {{configId}}
                <button type="button" class="btn copy" *ngIf="isMainScreen" (click)="copyMessage(configId)" value="click to copy">
                    <img src="../../../assets/icons/mxe__ico-copy.svg" />
                </button>
            </div>
        </div>
        <button class="btn btn-bordered btn-transparent btn-save-configuration" *ngIf="isMainScreen" (click)="printSend()">
            {{getLabel('WHEELNORM_DOWNLOAD_LABEL')}}
        </button>
    </div>
</div>