import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { MENU_LOADED } from '../actions/menu-actions';

//TODO distinguish car model before storing information in memory db
@Injectable()
export class MenuEffects {

    constructor(private actions$: Actions) { }

    MenuState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(MENU_LOADED),
                tap(actions => localStorage.setItem('menuItems', JSON.stringify(actions.menuItems)))
            ),
        { dispatch: false }
    );
}