import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Pack } from 'src/app/core/models/car-grid.model';
import { IConflictsData } from 'src/app/core/models/common.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';

@Component({
  selector: 'mxe-changed-configuration-modal',
  templateUrl: './changed-configuration-modal.component.html',
  styleUrls: ['./changed-configuration-modal.component.scss']
})
export class ChangedConfigurationModalComponent {
  @Input() conflictsData: IConflictsData
  @Input() currency: string
  @Input() priceMask: string
  @Input() showPrices:boolean;
  @Input() modelName: string
  @Input() modelCode: string
  @Output() confirmEmitter = new EventEmitter<boolean>()
  packages: Pack[];
  packages$: any;

  constructor(
    public uiCommonService: UiCommonService,
    private store: Store<MxeReducers>
    ) { 
      this.packages$ = this.store.select(s => s.modelsState.packages).subscribe(
        packages => {
          this.packages = packages
        }
      )
    }

    getPrice(optionId: string, defaultPrice: string): number {
      const pack = this.packages.find(p => p.packageId === optionId)
      if(pack) {
        return parseFloat(pack.price)
      } 
      else {
        const selectedPacks = this.packages.filter(p => p.packageObj.status.selected === true)
        for(let p of selectedPacks){
          if(p.implicits.find(o => o.id === optionId)){
            return 0
          } else if(p.standAlone.options.find(o => o.id === optionId)){
            return 0
          } else {
            for(let set of p.standAlone.sets){
              for(let l of set.list){
                if(l.id === optionId)
                  return 0
              }
            }
            return parseFloat(defaultPrice)
          }
        }
        return parseFloat(defaultPrice)
      } 
    }

  /**
   * Calculates prices delta
   * @returns 
   */
   getDeltaPrice() {
    let addedTotalPrice = 0
    this.conflictsData.toAdd.forEach(item => {
      addedTotalPrice += this.getPrice(item.id, item.price)
    })
    

    let removedTotalPrice = 0
    this.conflictsData.toRemove.forEach(item => {
      removedTotalPrice += this.getPrice(item.id, item.price)
    })
    const priceDelta = (addedTotalPrice - removedTotalPrice).toFixed(4)
    return this.uiCommonService.formatPrice(priceDelta, this.currency, this.priceMask) 
  }

  /**
   * Confirm the configuration's change
   */
  onConfirmClick() {
    this.confirmEmitter.emit(true)
  }

  /**
   * Revert the configuration's change
   */
  onRevertClick() {
    this.confirmEmitter.emit(false)
  }

  /**
   * Get translations
   * @param id the option id
   * @param type the translation type
   * @param color the colorcode
   * @returns 
   */
  getLabel(id: string, type = '', color = ''){
    return this.uiCommonService.getLabel(id, type, color, this.modelCode);
  }

  formatPrice(price: string) {
    if(price == '0')
      return ''
    return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
  }

  ngOnDestroy() {
    this.packages$.unsubscribe()
  }
}
