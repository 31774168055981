import { combineLatest, interval, map, Observable, Subject, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { PAIRING_STEP_ENUM } from 'src/app/core/models/common.model';
import { CarModel, Family } from 'src/app/core/models/get-models-service.model';
import { CarImage, Layer, Menu } from 'src/app/core/models/interface-service.model';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { ICTA } from '../../core/models/country-prop-service.model';
import { Optional } from '../../core/models/interface-service.model';
import { Options } from 'ngx-qrcode-styling';
import { FullscreenLoaderComponent } from '../fullscreen-loader/fullscreen-loader.component';
import { ErrorDialogService } from '../../core/services/error-dialog.service';
import { ConfigItem } from 'src/app/core/store/initials/temporary-configs-initial-state';
import { environment } from 'src/environments/environment';
import { MxeReducers } from 'src/app/core/store/reducers';
import { Store, on } from '@ngrx/store';
import { Topics } from 'src/app/core/enums/topics';
import anime from 'src/app/anime.min.js'


@Component({
  selector: 'app-tv-screen-view',
  templateUrl: './tv-screen-view.component.html',
  styleUrls: ['./tv-screen-view.component.scss'],
})
export class TvScreenViewComponent implements OnInit {

  @ViewChild('stream') streamElement: ElementRef<HTMLVideoElement>;
  @ViewChild('sidebarEl') sidebarEl: ElementRef<HTMLVideoElement>;
  @ViewChild(FullscreenLoaderComponent) progress: FullscreenLoaderComponent;
  public protocol: string;
  public host: string
  public hostName: string

  //#region hearbeat
  private pairingReconnectAttempt: number = 0;
  private _heartbeat: any = null;
  TIMEOUT_DELAY = 2000;
  MAX_RECONNECTS_ATTEMPTS = environment.autobahn_configuration.heartbeat.heartbeat_max_retries;
  KEEP_ALIVE_TIME = environment.autobahn_configuration.heartbeat.heartbeat_retry_after; 
  
  set heartbeat(value: any) {
    if(!value) {
      clearTimeout(this._heartbeat);
      this._heartbeat = null;
    } else {
      this._heartbeat = value;
    }
  }

  get heartbeat() {
    return this._heartbeat;
  }
  //#endregion hearbeat


  selectedCountry: {
    countryCode: number,
    languageId: string,
    ctaLanguageId: string
  } = {
      countryCode: 104,
      languageId: 'en',
      ctaLanguageId: 'it'
    }

  configuratorLoaded: boolean = true
  scrollPositionEvent: string
  summaryScrollPositionEvent: string
  summaryAccordionToggled: { accordionToggled: string, value: boolean }
  toggleAccordionEvent: EventEmitter<string> = new EventEmitter()
  modelOptions: UIOptItem[]
  packages: Pack[]

  searchToggleValue: boolean
  searchValue: string

  ephemeralDeviceID: string;
  expirationTime: number;

  ctas: ICTA[]
  desiredUnrealConfig: string[]
  hiddenOptions: UIOptItem[]
  optionalsBinding: Optional[];
  imageView: string;
  imageLayers: Layer[];
  customerName: string;
  customerSurname: string;
  showCustomerName: boolean;
  totalPrice: string;
  unformattedBasePrice: any;
  unformattedTaxes: number;
  unformattedEquipments: any;
  carModel: CarModel
  menuItems: Menu[]
  priceStatus: boolean
  isSceneReady = false
  showSidebar = false
  isWindowFullScreenActive = false
  isLoading = true
  packageDetailsToggleValue: any;
  carTotalPrice: string;
  closedAccordions: string[] = [];
  standAloneSet = {}
  standAloneSetInt = {}
  standAloneSetHighlightPacks = {}
  accordionOpen : boolean = false;
  pairingStep: string;
  activeIndex: number = 0;
  pairingStepEnum = PAIRING_STEP_ENUM
  accordionExp: number
  labels: any
  families: Family[] = []
  defaultOptions: UIOptItem[];
  swiperViews: CarImage[] = []
  activeView: CarImage;
  loadingCompleted: boolean;
  fallbackActiveImageOffset: string;
  sendPdfBasePath: string;
  packagesFormatSelectionDetail : string;
  optFormatSelectionDetail : string;
  accFormatSelectionDetail : string;
  showMoreLivree : boolean;
  isWrapper : boolean;
  showCinematicLogo : boolean = false;
  showCinematicText: boolean = true;
  textAnimationActive: boolean = false;
  bAudio: boolean = false;
  splittedCommercialName : string[] = []
  displayLoadingServicesPage: boolean;

  private refreshCounterInterval: any;
  private isStreamingReadyEvt: EventEmitter<boolean> = new EventEmitter()
  private isSidebarReadyEvt: EventEmitter<boolean> = new EventEmitter()
  private subs$: Subscription = new Subscription()
  private sceneReady_: Subscription;
  private autobahnRemote$: Subscription;
  private autobahnLocal$: Subscription;
  private executionInformations$: Subscription;
  private expirationTimeCountdown$: Subscription;
  private remoteInitialized: boolean =  false;
  private localInitialized: boolean =  false;
  private ephemeralDeviceIdReady: Subject<boolean> = new Subject<boolean>()
  private remoteSubscripionsReady: Subject<boolean> = new Subject<boolean>();
  private localSubscriptionReady: Subject<boolean> = new Subject<boolean>()

//KEEPING TRACK OF THE PROGRESS BAR INCREMENT
private progressTimeout: any;

  showSummaryModal: boolean = false;
  showThankYouPage = false;
  country: number;
  language: string;
  optionsForAnalytics: string;
  configId: string;
  dealerId: string;
  activeSlideImageIndex: any;
  activeSlideIndex: number;
  selectedFamily: Family;
  currentTrimCode: string;
  selectedLanguage: string;
  trimSelectorSwiperScrollDelta: any
  modelSelectorSwiperScrollDelta: any
  restoreConfig : boolean = false;
  loadConfig : boolean = true;
  temporaryItemsFromStore: ConfigItem[] = [];
  temporaryItemsFromStoreLength : number;
  imagePath: string;
  selectedElementRestoreConfig : any
  accordionOpenClose : boolean = true;
  currentStateHighPacksDetail : string[] = ["closed","closed","closed","closed","closed","closed","closed","closed","closed"]
  currentStateHigh : string[] = ["open"];
  currentStateExt : string[] = ["open","open","open","open","open","open"];
  currentStateInt : string[] = ["open","open","open","open","open","open"];
  currentStateOpt : string[] = ["open","open","open","open","open","open"];
  currentStateAccessories : string[] = ["open","open","open","open","open","open","open","open"];
  fallbackImageCurrentPosition: number;
  packagesSwiperSlideChange: {imageIndex: number, packageIndex: number}
  showCinematicEventFromSummary : boolean;
  commercialName : string;
  showCinematic : boolean;
  
  expirationTimeCountdown: string;

  public qrCodeConfig: Options = {
    width: 256,
    height: 256,
    data: "",
    image: "/assets/icons/logo-maserati-icon-black.svg",
    margin: 40,
    dotsOptions: {
      color: "#000",
      type: "square"
    },
    backgroundOptions: {
      color: "rgba(255,255,255,0.8)"
    },
    imageOptions: {
      hideBackgroundDots: true,
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  streamingAvailable = true;

  private _initSubscriptions() {
    this.subs$.add(
      this.monkeyWay.streamControlSubs$
        .subscribe((connect) => connect ? this._onConnect() : this._onDisconnect()));
  }

  private sceneObserver() {
    const stream$: Observable<boolean> = this.isStreamingReadyEvt.asObservable()
    const sidebar$: Observable<boolean> = this.isSidebarReadyEvt.asObservable()
    this.sceneReady_ = combineLatest([sidebar$, stream$]).subscribe(() => {
      this.streamElement.nativeElement.style.display = 'block'
      this.isSceneReady = true
      this.isLoading = false
    })
  }
  
  constructor(
    private autobahn: AutobahnClient,
    private monkeyWay: MonkeyWayService,
    private uiCommonService: UiCommonService,
    private chg: ChangeDetectorRef,
    private errorDialogService: ErrorDialogService,
    private store: Store<MxeReducers>
  ) {
    this.errorDialogService.mainScreen = false;
  }

  ngOnInit(): void {
      this.executionInformations$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.appState.hostName)
    ]).subscribe(([protocol, host, hostName]) => {
      this.protocol = protocol
      this.host = host;
      this.hostName = hostName
      if(!hostName.includes('local') || host == 'localhost:8080') {
        //ONLY THE REMOTE AUTOBAHN SHOULD BE USED
        this.remoteSubscripionsReady.subscribe((ready: boolean) => {
          if(ready) {
            console.log('%c[AUTOBAHN CLIENT] Connected and Subscribed', 'color: green;');
            this.isLoading = false;
          }
        })
        this.autobahnRemote$ = this.autobahn.onAutobahnRemoteOpen.asObservable().subscribe((connected: boolean) => {
          if(connected) {
             console.log(`[AUTOBAHN CONNECTION ESTABLISHED (REMOTE)]`)
             if(this.remoteInitialized) {
              console.log(`${new Date().toLocaleString()} Reconnecting to autobahn (REMOTE) topics...`)
              this.remoteInitialization()
             } else {
              //FIRST INITIALIZATION
              this.autobahn.getSimpleEphemeralDeviceId()
              .then((ephemeralDevice: any) => {
                if (ephemeralDevice.deviceID && ephemeralDevice.deviceID.pairing_id) {
                  this.ephemeralDeviceID = ephemeralDevice.deviceID.pairing_id;
                  this.expirationTime = ephemeralDevice.deviceID.expiration_time;                  
                  this.setExpirationTimeCountDown();
                } else {
                  this.ephemeralDeviceID = ephemeralDevice.deviceID;
                }
                this.qrCodeConfig.data = this.ephemeralDeviceID;
                console.debug(`EphemeralDeviceID: ${this.ephemeralDeviceID}`);
                console.debug(`Expires on ${new Date(this.expirationTime)}`)
                
                this.remoteInitialized = true;
                this._initSubscriptions();
                this.sceneObserver();
                this.remoteInitialization();
              })
              .catch((reason: any) => {
                if(!environment.production){
                  console.error(`[AUTOBAHN_CLIENT] Error occurred while retrieving ephemeralDeviceId`, reason);
                } else {
                  console.error(`[AUTOBAHN_CLIENT] Error occurred while retrieving ephemeralDeviceId`);
                }
                
              });
             }
          }
        })

      } else {
        //WE MUST USE BOTH LOCAL AND REMOTE CONNECTION
        combineLatest([this.remoteSubscripionsReady, this.localSubscriptionReady]).subscribe(
          ([remoteReady, localReady]) => {
            if(remoteReady && localReady){
              console.log('%c[AUTOBAHN CLIENT] Connected and Subscribed', 'color: green;');
              this.isLoading = false;
            }
          }
        )
        this.autobahnRemote$ = this.autobahn.onAutobahnRemoteOpen.asObservable().subscribe((connected: boolean) => {
          if(connected) {
            console.log(`[AUTOBAHN CONNECTION ESTABLISHED (REMOTE)]`)
            if(this.remoteInitialized) { 
              console.log(`${new Date().toLocaleString()} Reconnecting to autobahn (REMOTE) topics...`)
              //SECURE TOPICS RESUBSCRIBE
              this.remoteInitialization()
            } else {
              //FIRST INITIALIZATION
              this.autobahn.getSimpleEphemeralDeviceId()
              .then((ephemeralDevice: any) => {
                if (ephemeralDevice.deviceID && ephemeralDevice.deviceID.pairing_id) {
                  this.ephemeralDeviceID = ephemeralDevice.deviceID.pairing_id;
                  this.expirationTime = ephemeralDevice.deviceID.expiration_time;
                  this.setExpirationTimeCountDown();
                } else {
                  this.ephemeralDeviceID = ephemeralDevice.deviceID;
                }
                this.qrCodeConfig.data = this.ephemeralDeviceID;
                console.debug(`EphemeralDeviceID: ${this.ephemeralDeviceID}`);
                
                this.remoteInitialized = true;
                this.ephemeralDeviceIdReady.next(true);
                this._initSubscriptions();
                this.sceneObserver();
                this.remoteInitialization();
              })
              .catch((reason: any) => {
                if(!environment.production){
                  console.error(`[AUTOBAHN_CLIENT] Error occurred while retrieving ephemeralDeviceId `, reason);
                } else {
                  console.error(`[AUTOBAHN_CLIENT] Error occurred while retrieving ephemeralDeviceId `);
                }
               
              });
            }
          }
        });

        this.autobahnLocal$ = this.autobahn.onAutobahnLocalOpen.asObservable().subscribe((connected: boolean) => {
          if(connected) {
            console.log(`[AUTOBAHN CONNECTION ESTABLISHED (LOCAL)]`)
            if(this.localInitialized) {
              console.log(`${new Date().toLocaleString()} Reconnecting to autobahn (LOCAL) topics...`)
              //LOCAL TOPICS RESUBSCRIBE
              this.localInitialization();
            } else {
              //FIRST INITIALIZATION (SUBSCRIBE TO ALL TOPICS LOCAL AND REMOTE)
              if(this.ephemeralDeviceID) {
                this.localInitialization();
                this.localInitialized = true;
              } else {
                this.ephemeralDeviceIdReady.subscribe((ready: boolean) => {
                  if(ready) {
                    this.localInitialization();
                    this.localInitialized = true;
                  }
                })
              }
            }
          }
        }) 
      }
    })
  }
  setExpirationTimeCountDown() {
    const millisecondsToTime = (ms: number): string  =>  {
      let seconds = Math.floor((ms/1000)%60).toString().padStart(2, '0')
      let minutes = Math.floor((ms/(1000* 60) %  60)).toString().padStart(2, '0')
      let hours = Math.floor((ms / (1000 * 3600)%24)).toString().padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    }
    const expirationTimeCountdown = interval(1000).pipe(
      map(()=> {
        const now = Date.now()
        const diff = this.expirationTime - now
        if(diff <= 0) {
          this.expirationTimeCountdown$.unsubscribe();
          console.log('EphemeralID expired...diconnecting pairing')
          location.reload()
          return '00:00:00'
        }
        return millisecondsToTime(diff)
      })
    )
    this.expirationTimeCountdown$ = expirationTimeCountdown.subscribe((value) => {
      this.expirationTimeCountdown = value
    })
  }

  remoteInitialization() {
    const secureTopicSubscriptionsPromise = this._secureTopicSubscriptions()
    .then(() => {
      console.log(`[AUTOBAHN CLIENT] secure topics subscriptions successfully created`)
    })
    .catch((error: any) => {
      if(!environment.production){
        console.error(`${error.message}: ${error.innerMessage}`)
      } else {
        console.error(`[AUTOBAHN CLIENT] secure topics subscriptions NOT created`)
      }
    });
    const pairingTopicSubscriptionsPromise = this._topicSubscriptions(Topics.Remote)
    .then(() => {
      console.log(`[AUTOBAHN CLIENT] pairing topics subscriptions succesfully created (REMOTE)`)
    })
    .catch((error: any) => {
      if(!environment.production){
        console.error(`${error.message}: ${error.innerMessage}`)
      } else {
        console.error(`[AUTOBAHN CLIENT] pairing topics subscriptions NOT created (REMOTE)`)
      }
    })
    Promise.all([secureTopicSubscriptionsPromise, pairingTopicSubscriptionsPromise]).finally(() => this.remoteSubscripionsReady.next(true))
  }

  localInitialization() {
    this._topicSubscriptions(Topics.Local)
    .then(() => {
      console.log(`[AUTOBAHN CLIENT] pairing topics subscriptions succesfully created (LOCAL)`)
    })
    .catch((error: any) => {
      if(!environment.production){
        console.error(`${error.message}: ${error.innerMessage}`)
      } else {
        console.error(`[AUTOBAHN CLIENT] pairing topics subscriptions NOT created (LOCAL)`)
      }
    })
    .finally(() => this.localSubscriptionReady.next(true) )
  }

  

  ngOnDestroy() {
    this.stopStreamingSession()
    this.sceneReady_.unsubscribe()
    this.autobahnRemote$.unsubscribe()
    this.autobahnLocal$.unsubscribe()
  }

  ngAfterViewInit() {
    //todo: disable all clicks and scroll
    if (window.innerWidth >= 3600) {
      this.qrCodeConfig.height = 512
      this.qrCodeConfig.width = 512
    }
  }

  private async _secureTopicSubscriptions() {
    const joinSessionFn = async (args: any, kwargs: any, details: any): Promise<string> => {
      console.log(`[MONKEYWAY: Joining session]:`, kwargs)
      this.pairingStep = PAIRING_STEP_ENUM.CAR_CONFIGURATOR;
      this.refreshCountdown();
      this.chg.detectChanges();
      this.monkeyWay.setStreamElement(this.streamElement);
      if (window.innerWidth >= 3600) {
        this.qrCodeConfig.height = 512;
        this.qrCodeConfig.width = 512;
      }
      const connectionKey = kwargs["connectionKey"];
      const baseUrl = kwargs["baseUrl"];
      const appEnvId = kwargs["appEnvId"]
      this.monkeyWay.startSession(connectionKey, baseUrl, appEnvId);
      return this.ephemeralDeviceID;
    };
    const leaveSessionFn = () => {
      this.monkeyWay.stopPresenterScreenSession();
      this.heartbeat = null;
      return 'stop';
    };
    const leaveMwSessionFn = () => {
      console.log(`[MONKEYWAY: Leaving session]`)
      this.monkeyWay.stopMonkeyWaySession()
      this.heartbeat = null;
    };
    const setDealerIdFn = async (args: any, kwargs: any, details: any) => {
      this.dealerId = kwargs['dealerId']
    }

    try {
      console.log('[AUOTBAHN CLIENT]: Initializing SECURE topics subscriptions...')
      await this.autobahn.registerMonkeyWayTopics(
        this.ephemeralDeviceID,
        joinSessionFn,
        leaveSessionFn,
        leaveMwSessionFn
      );
      await this.autobahn.getDealerId(this.ephemeralDeviceID, setDealerIdFn);
    } catch(error: any) {
      const customError: any = new Error('[AUTOBAHN CLIENT] an error occurred while subscribing on secure topics'); 
      customError.innerMessage = error.message;
      throw customError;
    }
  }

  private async _topicSubscriptions(topic) {
    console.log(`[AUTOBAHN CLIENT] Initializing ${topic == Topics.Remote ? 'REMOTE': 'LOCAL'} topics subscriptions...`)

    const heartbeatFn = (args: any, kwargs: any, details: any) => {
      this.heartbeat = null;
      const heartbeatTimer = kwargs.heartbeat
      updateTimer(heartbeatTimer)
    }
    const heartbeatTimerFn = (heartbeatTimer: any) => {
      if (this.pairingReconnectAttempt < this.MAX_RECONNECTS_ATTEMPTS) {
        console.log(`[Pairing Session expired] on ${new Date().toLocaleString()}, keep-alive attempt ${this.pairingReconnectAttempt + 1} at ${new Date().toLocaleString()}...`)
        this.pairingReconnectAttempt++;
        this.heartbeat = setTimeout(() => heartbeatTimerFn(this.KEEP_ALIVE_TIME), heartbeatTimer + 1000)
      } else {
        console.error(`[KEEP_ALIVE_FAILED]: disconnecting ${new Date().toLocaleString()}`)
        this._onDisconnect()
      }
    }
    const updateTimer = (heartbeatTimer) => {
      this.pairingReconnectAttempt = 0;
      this.heartbeat = setTimeout(() => heartbeatTimerFn(heartbeatTimer), heartbeatTimer + this.TIMEOUT_DELAY)
    }
    const clearTimeoutFn = () => {
      this.heartbeat = null;
    }
    const onActivatePairingFromSimplePageFn = async (args: any, kwargs: any, details: any) => {
      this.pairingStep = kwargs.pairingStep
      console.debug(`Activating pairing from ${this.pairingStep}`)
      if(this.pairingStep != PAIRING_STEP_ENUM.CAR_CONFIGURATOR){
        //this.carModel = kwargs.carModel;
        this.menuItems = [];
        this.closedAccordions = [];
        //this.priceStatus = kwargs.priceStatus;
        this.modelOptions = []
        this.packages = []
        //this.uiCommonService.initializeLabels(kwargs.labels)
        this.accordionExp = 0
      }
      this.isSceneReady = true
    }
    const onClosePairingFromSimplePageFn = async (args: any, kwargs: any, details: any) => {
      this.pairingStep = ''
      console.debug(`Close pairing`)
      this.isSceneReady = false
      location.reload()
    }
    const onThrowErrorFn = (args: any, kwargs: any, details: any) => {
      const errorMessage = kwargs['error']
      throw new Error(errorMessage)
    }
    const onInitializeLoadConfiguratorFn = async(args:any, kwargs: any, details: any) => {
      this.restoreConfig = kwargs.restoreConfig
      this.loadConfig = kwargs.loadConfig
      this.configId = kwargs.configurationID
    }

    const switchCountryFn = async (args: any, kwargs: any, details: any) => {
      this.selectedCountry.countryCode = kwargs['countryCode']
      this.selectedCountry.languageId = kwargs['languageId']
      this.selectedCountry.ctaLanguageId = kwargs['ctaLanguageId']
      this.priceStatus = false;
    }

    const onCustomerNameFn = async (args: any, kwargs: any, details: any) => {
      this.customerName = kwargs.customerName
    }
    const onCustomerSurnameFn = async (args: any, kwargs: any, details: any) => {
      this.customerSurname = kwargs.customerSurname
    }

    const switchLanguageFn = async (args: any, kwargs: any, details: any) => {
      this.selectedCountry.languageId = kwargs['selectedLanguage']
    }

    const getLabelsFn = async (args: any, kwargs: any, details: any) => {
      this.labels = kwargs
      this.uiCommonService.initializeLabels(this.labels)
    }
    const getActiveImageIndexFn = (ags: any, kwargs: any, details: any) => {
      this.activeSlideImageIndex = {
        model:kwargs.activeModelName,
        index: kwargs.activeImageIndex
      }
    }
    const getActiveSlideIndexFn = (ags: any, kwargs: any, details: any) => {
      this.activeSlideIndex = kwargs['activeSlideIndex']
    }
    const getCarTrimFamilyCode = async (args: any, kwargs: any, details: any) => {
      this.selectedFamily = kwargs.selectedFamily
      this.pairingStep = this.pairingStepEnum.TRIM_SELECTOR
    }
    const getCurrentTrimCodeFn = async (args: any, kwargs: any, details: any) => {
      this.currentTrimCode = kwargs['currentTrimCode']
    }
    const getFamiliesFn = async (args: any, kwargs: any, details: any) => {
      this.families = kwargs.families
      console.log(this.families)
    }
    const setFallback2dFn = async (args: any, kwargs: any, details: any) => {
      this.menuItems = kwargs['menuItems']
      this.defaultOptions = kwargs['defaultOptions']
      this.optionalsBinding = kwargs['optionalsBinding']
      this.hiddenOptions = kwargs['hiddenOptions']
      this.modelOptions = kwargs['options']
      this.carModel = kwargs['carModel']
      this.swiperViews = kwargs['swiperViews']
      this.activeView = kwargs['activeView']
      this.loadingCompleted = kwargs['loadingCompleted']
      this.isLoading = false;
      this.isSceneReady = true;
    }
    const getStreamingStatusFn = async (args: any, kwargs: any, details: any) => {
      this.streamingAvailable = kwargs['streamingAvailable']
      if (!this.streamingAvailable) {
        this.isLoading = false;
        this.isSceneReady = true;
      }
    }
    const getFallbackActiveImageFn = async (args: any, kwargs: any, details: any) => {
      if (this.swiperViews && this.swiperViews.length > 0) {
        this.activeView = this.swiperViews[kwargs['activeImageIndex']];
      }
    }
    const getFallbackActiveImageOffsetFn = async (args: any, kwargs: any, details: any) => {
      this.fallbackActiveImageOffset = kwargs['leftOffset'];
    }
    const onFallbackSetImageInitialPositionFn = async (args: any, kwargs: any, details: any) => {
      this.fallbackImageCurrentPosition = kwargs['setCurrentPosition'];
    }
    const setScrollPositionFn = (args: any, kwargs: any, details: any) => {
      this.scrollPositionEvent = kwargs['scrollPosition']
    }
    const sidebarOptChangeFn = (args: any, kwargs: any, details: any) => {
      this.modelOptions = kwargs.sidebarOpt
    }
    const sidebarPacksChangeFn = (args: any, kwargs: any, details: any) => {
      this.packages = kwargs.packages
    }
    const setToggleAccordion = (args: any, kwargs: any, details: any) => {
      this.toggleAccordionEvent.emit(kwargs['accordionClass'])
    }
    const setSearchBoxToggleFn = (args: any, kwargs: any, details: any) => {
      this.searchToggleValue = kwargs['searchToggleValue']
    }
    const setPackageDetailsToggleFn = (args: any, kwargs: any, details: any) => {
      this.packageDetailsToggleValue = kwargs['packageDetailsToggleValue']
    }
    const defineSidebarDataFn = async (args: any, kwargs: any, details: any) => {
      this.carModel = kwargs.carModel;
      this.menuItems = kwargs.menuItems;
      this.closedAccordions = kwargs.closedAccordions;
      this.priceStatus = kwargs.priceStatus;
      this.modelOptions = kwargs.sidebarOpt
      this.packages = kwargs.sidebarPacks
      this.uiCommonService.initializeLabels(kwargs.labels)
      this.accordionExp = kwargs.accordionExp
      console.log(kwargs);
      this.pairingStep = PAIRING_STEP_ENUM.CAR_CONFIGURATOR
      console.debug(`Pairing step: ${this.pairingStep}`)
      this.isSidebarReadyEvt.emit(true)
      return kwargs
    }
    const setToggleFullScreenFn = (args: any, kwargs: any, details: any) => {
      this.showSidebar = kwargs['toggleFullScreen']
    }
    const setThankYouPageFn = async (args: any, kwargs: any, details: any) => {
      this.showThankYouPage = kwargs['showThankYouPage']
      this.configId = kwargs['configId']
      this.carModel = kwargs['carModel']
      this.country = kwargs['country']
      this.language = kwargs['language']
      this.sendPdfBasePath = kwargs['sendPdfBasePath']
      this.ctas = kwargs['ctas']
    }
    const setSummaryScrollPositionFn = (args: any, kwargs: any, details: any) => {
      this.summaryScrollPositionEvent = kwargs['scrollPosition']
    }

    const setBAudioFn = (args: any, kwargs: any, details: any) => {
      console.warn('[CINEMATIC] Audio set to', kwargs.bAudio)
      this.streamElement.nativeElement.muted = !kwargs.bAudio
    }

    const getSearchValueFn = async (args: any, kwargs: any, details: any) => {
      this.searchValue = kwargs['searchValue']
    }
    const getCarTotalPriceFn = async (args: any, kwargs: any, details: any) => {
      this.carTotalPrice = kwargs['carTotalPrice']
    }
    const getPriceStatusFn = async (args: any, kwargs: any, details: any) => {
      this.priceStatus = kwargs['priceStatus']
    }
    const getStandAloneSetEditingFn = async (args: any, kwargs: any, details: any) => {
      this.standAloneSet = kwargs['standAloneSet']
    }
    const setSummaryFn = async (args: any, kwargs: any, details: any) => {
      const datas = kwargs
      this.defaultOptions = datas.defaultOptions
      this.customerName = datas.customerName
      this.customerSurname = datas.customerSurname
      this.showCustomerName = datas.showCustomerName
      this.unformattedBasePrice = datas.basePrice
      this.unformattedTaxes = datas.taxes
      this.unformattedEquipments = datas.equipments
      this.ctas = datas.ctas
      this.desiredUnrealConfig = datas.config
      this.hiddenOptions = datas.hiddenOptions
      this.optionalsBinding = datas.optionalsBinding
      this.imageView = datas.imageView
      this.imageLayers = datas.imageLayers
      this.showSummaryModal = datas.showSummary
      this.country = datas.country
      this.language = datas.language
      this.optionsForAnalytics = datas.optionsForAnalytics
      this.configId = datas.configId
      this.dealerId = datas.dealerId
      this.streamingAvailable = datas.streamingAvailable
      this.commercialName = datas.commercialName
      this.showCinematicEventFromSummary = datas.showCinematicEventFromSummary
    }
    const toggleAccordionFn = async (args: any, kwargs: any, details: any) => {
      this.summaryAccordionToggled = kwargs;
    }
    const setDisplayLoadingServicesPageFn = async (args: any, kwargs: any, details: any) => {
      this.displayLoadingServicesPage = kwargs.displayLoadingServices;
    }
    const setCarConfiguratorData = (args: any, kwargs: any, details: any) => {
      const datas = kwargs
      this.priceStatus = datas.priceStatus
      this.packages = datas.packages
      this.carModel = datas.carModel
      this.menuItems = datas.menuItems
      this.modelOptions = datas.modelOptions
      this.closedAccordions = datas.closedAccordions
    }

    const onCarConfigurationLoading = (args: any, kwargs: any, details: any) => {
      this.progress?.progressBarIncrement(kwargs['progress']);
      this.chg.detectChanges();

      if (this.progressTimeout) {
        clearTimeout(this.progressTimeout);
      }
      this.progressTimeout = setTimeout(() => {
        console.log('Progress Bar self completing')
        this.progress?.progressBarIncrement(100);
        this.chg.detectChanges();
        this.configuratorLoaded = true;
      }, 4000);

      if (this.progress?.progress >= 100) {
        this.configuratorLoaded = true;
        clearTimeout(this.progressTimeout);
      }
    }

    const onCarConfiguratorNotLoaded = (args: any, kwargs: any, details: any) => {
      this.isLoading = true;
      // Da Rivedere
      this.configuratorLoaded = true;
      this.carModel = kwargs['carModel']
      this.pairingStep = PAIRING_STEP_ENUM.CAR_CONFIGURATOR
    }
    const onSelectorSwiperScrollFn = (args: any, kwargs: any, details: any) => {
      if (kwargs['component'] == 'trim') {
        this.trimSelectorSwiperScrollDelta = { scrollDelta: kwargs['scrollDelta'], swiperPaginationWidth: kwargs['swiperPaginationWidth'] }
      } else {
        this.modelSelectorSwiperScrollDelta = { scrollDelta: kwargs['scrollDelta'], swiperPaginationWidth: kwargs['swiperPaginationWidth'] }
      }
    }
    const  onGetCarConfiguratorPackageSlideIndexFn = (args:any, kwargs:any, details: any) => {
      this.packagesSwiperSlideChange = kwargs;
    }
    const setTemporaryItemsFromStoreFn = async(args:any, kwargs: any, details:any) => {
      this.temporaryItemsFromStore = kwargs['temporaryItemsFromStore'];
    }
    const setImgPathFn = (args: any, kwargs: any, details: any) => {
      this.imagePath = kwargs['imagePath']
    }
    const setSelectedElementRestoreConfigFn = (args: any, kwargs: any, details: any) => {
      this.selectedElementRestoreConfig = kwargs['selectedElementRestoreConfig']
    }
    const setPackagesFormatSelectionDetailFn = async(args:any, kwargs: any, details:any) => {
      this.packagesFormatSelectionDetail = kwargs['formatSelection'];
    }
    const setOptFormatSelectionDetailFn = async(args:any, kwargs: any, details:any) => {
      this.optFormatSelectionDetail = kwargs['formatSelection'];
    }
    const setAccFormatSelectionDetailFn = async(args:any, kwargs: any, details:any) => {
      this.accFormatSelectionDetail = kwargs['formatSelection'];
    }
    const setAccOpenCloseFn = async(args:any, kwargs: any, details:any) => {
      this.accordionOpenClose = kwargs['accordionOpenClose'];
    }
    const setShowMoreLivreeFn = async(args:any, kwargs: any, details:any) =>{
      this.showMoreLivree = kwargs['showMoreLivree'];
    }
    const setCurrentStateHighPacksDetailStateFn = async(args:any, kwargs: any, details:any) => {
      this.currentStateHighPacksDetail = kwargs['currentStateHighPacksDetail']
    }
    const setAccordionStateFn = async(args:any, kwargs: any, details:any) => {
      switch( kwargs['componentName']){
        case 'high':this.currentStateHigh = kwargs['currentState']
        break;
        case 'ext': this.currentStateExt = kwargs['currentState']
        break;
        case 'int': this.currentStateInt = kwargs['currentState']
        break;
        case 'opt': this.currentStateOpt = kwargs['currentState']
        break;
        case 'acc': this.currentStateAccessories= kwargs['currentState']
        break;
      }
    }
    const getStandAloneSetEditingInteriorFn = async (args: any, kwargs: any, details: any) => {
      this.standAloneSetInt = kwargs['standAloneSetInt']
    }
    const getStandAloneSetEditingHighlightFn = async (args: any, kwargs: any, details: any) => {
      this.standAloneSetHighlightPacks = kwargs['standAloneSetHighlightPacks']
    }
    const getStandAlonePacksHighlightFn = async (args: any, kwargs: any, details: any) => {
      this.accordionOpen = kwargs['accordionOpen']
    }
    const setIsWrapperFn = async (args: any, kwargs: any, details: any) => {
      this.isWrapper = kwargs['isWrapper']
    }
    
    const setShowCinematicLogoFn = async (args: any, kwargs: any, details: any) => {
      this.showCinematicLogo = kwargs['showCinematicLogo']
    }
    const setShowCinematicTextFn = async (args: any, kwargs: any, details: any) => {
      this.showCinematicText = kwargs['showCinematicText']
    }

    const setSplittedCommercialNameFn = async (args: any, kwargs: any, details: any) => {
      this.splittedCommercialName = kwargs['splittedCommercialName']
    }
    
    const onFromConfiguratorToModelSelectionFn = async (args: any, kwargs: any, details: any) => {
      this.labels = kwargs.labels
      this.families = kwargs.families
      this.priceStatus = kwargs.priceStatus
    }
    const onFromConfiguratorToTrimSelectionFn = async (args: any, kwargs: any, details: any) => {
      this.labels = kwargs.labels
      this.selectedFamily = kwargs.currentFamily
      this.priceStatus = kwargs.priceStatus
    }

    const onSetLastConfigurationsFromStoreFn = async(args: any, kwargs: any, details: any) =>{
      this.hiddenOptions = kwargs.lastHiddenOptions
      this.defaultOptions = kwargs.lastDefaultOptions
      this.packages = kwargs.lastPackages
      this.modelOptions = kwargs.lastOptions
      this.optionsForAnalytics = kwargs.lastOptionsForAnalytics
      this.carModel = kwargs.lastCarModel
      this.imageLayers = kwargs.imageLayers,
      this.imageView = kwargs.imageView
    }

    const setShowCinematicFn = async (args: any, kwargs: any, details: any) => {
      this.showCinematic = kwargs['showCinematic']
      this.showCustomerName = kwargs['showCustomerName']
      this.commercialName = kwargs['commercialName']
      this.customerName = kwargs['customerName']
      this.customerSurname = kwargs['customerSurname']
      this.showCinematicText = kwargs['showCinematicText']
      this.textAnimationActive = kwargs['textAnimationActive']
      this.showCinematicLogo = kwargs['showCinematicLogo']
      setTimeout(() => {
        textAnimation();
      }, 1000);
      
    }
    const textAnimation = () => {
      setTimeout(() =>{
        this.textAnimationActive = true;
        this.chg.detectChanges();
      },500)
      let textWrapper = document.querySelector(".ml12");
      if(textWrapper){
        textWrapper.innerHTML = textWrapper.textContent!.replace(/\S/g, "<span class='letter'>$&</span>");
        this.chg.detectChanges();
        //easeOutExpo is the initial animation 
        anime.timeline({loop: false})
          .add({
            targets: '.ml12 .letter',
            translateX: [40,0],
            translateZ: 0,
            opacity: [0,1],
            easing: "easeOutExpo",
            duration: 2000,
            delay: (el, i) => 800 + 50 * i
          }).add({
                targets: '.ml12 .letter',
                translateX: [0,-30],
                opacity: [1,0],
                easing: "easeInExpo",
                duration: 1200,
                delay: (el, i) => 100 + 30 * i
           });
          this.textAnimationActive = false;
          this.chg.detectChanges();
      }
    }
    //#region autobahn subscriptions
    try {
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getHeartbeat, this.ephemeralDeviceID, heartbeatFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.activatePairingFromSimplePage, this.ephemeralDeviceID, onActivatePairingFromSimplePageFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.closePairingFromSimplePage, this.ephemeralDeviceID, onClosePairingFromSimplePageFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onThrowError, this.ephemeralDeviceID, onThrowErrorFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onInitializeLoadConfiguration, this.ephemeralDeviceID, onInitializeLoadConfiguratorFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getToggleFullScreen, this.ephemeralDeviceID, setToggleFullScreenFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getThankYouPage, this.ephemeralDeviceID, setThankYouPageFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getModelSelectorActiveIndex, this.ephemeralDeviceID, getActiveSlideIndexFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getModelSelectorImageUpdate, this.ephemeralDeviceID, getActiveImageIndexFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCustomerName , this.ephemeralDeviceID, onCustomerNameFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCustomerSurname,this.ephemeralDeviceID, onCustomerSurnameFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCarTrimFamilyCode , this.ephemeralDeviceID,getCarTrimFamilyCode)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCurrentTrimCode , this.ephemeralDeviceID,getCurrentTrimCodeFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onSwitchCountry , this.ephemeralDeviceID,switchCountryFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getFallback2dData, this.ephemeralDeviceID, setFallback2dFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onSwitchLanguage, this.ephemeralDeviceID,switchLanguageFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getLabels, this.ephemeralDeviceID, getLabelsFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getFamilies, this.ephemeralDeviceID,getFamiliesFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getStreamingStatus, this.ephemeralDeviceID,getStreamingStatusFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getFallbackActiveImage, this.ephemeralDeviceID,getFallbackActiveImageFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getFallbackActiveImageOffset, this.ephemeralDeviceID,getFallbackActiveImageOffsetFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onsetFallbackImageInitialPosition, this.ephemeralDeviceID,onFallbackSetImageInitialPositionFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.registerSideBarScrollEvent, this.ephemeralDeviceID, setScrollPositionFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getPresenterScreenData, this.ephemeralDeviceID,defineSidebarDataFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSidebarOptChange, this.ephemeralDeviceID, sidebarOptChangeFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSidebarPacksChange, this.ephemeralDeviceID,sidebarPacksChangeFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getToggleAccordion, this.ephemeralDeviceID, setToggleAccordion)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSearchValue, this.ephemeralDeviceID,getSearchValueFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSearchToggleValue, this.ephemeralDeviceID, setSearchBoxToggleFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getPackageDetailsToggleValue, this.ephemeralDeviceID, setPackageDetailsToggleFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onClearTimeout, this.ephemeralDeviceID,clearTimeoutFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCarTotalPrice, this.ephemeralDeviceID, getCarTotalPriceFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getPriceStatus, this.ephemeralDeviceID, getPriceStatusFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getStandAloneSet, this.ephemeralDeviceID, getStandAloneSetEditingFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCarConfiguratorData, this.ephemeralDeviceID,setCarConfiguratorData )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onLoadConfigurator, this.ephemeralDeviceID,onCarConfigurationLoading )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getConfiguratorNotLoaded, this.ephemeralDeviceID,onCarConfiguratorNotLoaded )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSelectorSwiperScroll, this.ephemeralDeviceID, onSelectorSwiperScrollFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getCarConfiguratorPackageSlideIndex, this.ephemeralDeviceID,onGetCarConfiguratorPackageSlideIndexFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getTemporaryItemsFromStore, this.ephemeralDeviceID, setTemporaryItemsFromStoreFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getImgStringPathStore, this.ephemeralDeviceID,setImgPathFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSelectedElementRestoreconfig, this.ephemeralDeviceID,setSelectedElementRestoreConfigFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getPackagesFormatSelectionDetail, this.ephemeralDeviceID,setPackagesFormatSelectionDetailFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getOptFormatSelectionDetail, this.ephemeralDeviceID, setOptFormatSelectionDetailFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getAccFormatSelectionDetail, this.ephemeralDeviceID,setAccFormatSelectionDetailFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getAccOpenClose, this.ephemeralDeviceID,setAccOpenCloseFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getAccordionState, this.ephemeralDeviceID, setAccordionStateFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getStandAloneSetEditingInterior, this.ephemeralDeviceID,getStandAloneSetEditingInteriorFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getAccordionHighlightPacksDetailState, this.ephemeralDeviceID,setCurrentStateHighPacksDetailStateFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getStandAlonePacksHighlightAccOpen, this.ephemeralDeviceID,getStandAlonePacksHighlightFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getStandAloneSetHighlightPacks, this.ephemeralDeviceID, getStandAloneSetEditingHighlightFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getshowMoreLivree, this.ephemeralDeviceID,setShowMoreLivreeFn ) 
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getIsWrapperFromLoader, this.ephemeralDeviceID,setIsWrapperFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.registerSummaryToggleAccordion, this.ephemeralDeviceID,toggleAccordionFn ) 
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.registerSummarySet, this.ephemeralDeviceID,setSummaryFn ) 
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.registerSummaryScrollEvent, this.ephemeralDeviceID,setSummaryScrollPositionFn )  
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onFromConfiguratorToModelSelection, this.ephemeralDeviceID,onFromConfiguratorToModelSelectionFn )  
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.onSetLastConfigurationsFromStore, this.ephemeralDeviceID,onSetLastConfigurationsFromStoreFn )
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getShowCinematic, this.ephemeralDeviceID,setShowCinematicFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getshowCinematicLogo, this.ephemeralDeviceID,setShowCinematicLogoFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getSplittedCommercialName, this.ephemeralDeviceID,setSplittedCommercialNameFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getshowCinematicText, this.ephemeralDeviceID,setShowCinematicTextFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getBAudioCinematic, this.ephemeralDeviceID,setBAudioFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.getDisplayLoadingServicesPage, this.ephemeralDeviceID,setDisplayLoadingServicesPageFn)
      await this.autobahn.pairingSubscriptionsWrapper(topic, this.autobahn.initializePairing, this.ephemeralDeviceID, () => console.log('Pairing Connecting...') )
    } catch (error: any) {
      const customError: any = new Error('[AUTOBAHN CLIENT] an error occurred while subscribing on pairing topics'); 
      customError.innerMessage = error.message;
      throw customError;
    } finally {
      this.isLoading = false;
    }
    //#endregion autobahn subscriptions
  }



  public triggerFullscreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        this.isWindowFullScreenActive = true
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        this.isWindowFullScreenActive = false
      });
    }
  }

  private stopStreamingSession() {
    this.subs$.unsubscribe();
    this.monkeyWay.stopSession();
    this.monkeyWay.destroyStreamElement();
    this.isSceneReady = false;
  }


  private _onDisconnect(): void {
    this.ephemeralDeviceID = '';
    this.pairingStep = ''
    try {
      this.subs$.unsubscribe();
      this.monkeyWay.stopSession();
      this.monkeyWay.destroyStreamElement();
      if (this.streamElement) {
        this.streamElement.nativeElement.pause();
        this.streamElement.nativeElement.srcObject = null;
        this.streamElement.nativeElement.style.display = 'none';
      }
      location.reload();
    } catch (error: any) {
      console.error('An error occurred while disconnecting pairing...')
      location.reload();
    }

  }

  private _onConnect(): void {
    try {
      this.streamElement.nativeElement.srcObject = this.monkeyWay.srcObject;
    } catch (error) {
      this.streamElement.nativeElement.src = URL.createObjectURL(this.monkeyWay.srcObject);
    }


    this.streamElement.nativeElement.muted = true;

    this.streamElement.nativeElement.addEventListener('canplay', () => {
      this.streamElement.nativeElement.play()
        .then(() => console.debug('Streaming playing'))
        .catch((e: any) => {
          setTimeout(() => {
            this.streamElement.nativeElement.play()
              .then(() => console.debug('Streaming playing'))
            console.log("Error during start playback of media resource", e);
          }, 1000);
        });
    });
    this.streamElement.nativeElement.addEventListener('error', (error: Event) => {
      console.log("Error loading media resource", error);
    });

    // this.streamElement.nativeElement.style.display = 'block';
    this.isStreamingReadyEvt.emit(true)
    this.isLoading = true
    clearInterval(this.refreshCounterInterval)
  }



  private refreshCountdown() {
    const reloadPage = () => {
      location.reload()
    }
    this.refreshCounterInterval = setInterval(reloadPage, 60000 * 5)
  }

  public refreshPage() {
    location.reload();
  }


}