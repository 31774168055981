import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { SwiperModule } from 'swiper/angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationComponent } from './components/authorization-component/authorization.component';
import { AfkModalComponent, FormatTimePipe } from './components/car-configurator-wrapper/afk-modal/afk-modal.component';
import { CameraCarouselComponent } from './components/car-configurator-wrapper/camera-carousel/camera-carousel.component';
import { CarConfiguratorWrapperComponent } from './components/car-configurator-wrapper/car-configurator-wrapper.component';
import { CarModelDataComponent } from './components/car-configurator-wrapper/car-model-data/car-model-data.component';
import { InteractiveBottomBarComponent } from './components/car-configurator-wrapper/interactive-bottom-bar/interactive-bottom-bar.component';
import { MonkeyWayComponent } from './components/car-configurator-wrapper/monkey-way/monkey-way.component';
import { NewCarUpperMenuComponent } from './components/car-configurator-wrapper/new-car-upper-menu/new-car-upper-menu.component';
import { AccessoriesConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/accessories-configuration/accessories-configuration.component';
import { ExteriorConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/exterior-configuration/exterior-configuration.component';
import { InteriorConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/interior-configuration/interior-configuration.component';
import { OptionalConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/optional-configuration/optional-configuration.component';
import { ModalComponent } from './components/car-configurator-wrapper/sidebar-configurator/packages-configuration/modal/modal.component';
import { PackagesConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/packages-configuration/packages-configuration.component';
import { SidebarConfiguratorComponent } from './components/car-configurator-wrapper/sidebar-configurator/sidebar-configurator.component';
import { StopScreenCastModalComponent } from './components/car-configurator-wrapper/stop-screen-cast-modal/stop-screen-cast-modal.component';
import { TridentModalComponent } from './components/car-configurator-wrapper/trident-modal/trident-modal.component';
import { VideoModalWithDescriptionsComponent } from './components/car-configurator-wrapper/video-modal-with-descriptions/video-modal-with-descriptions.component';
import { VideoModalComponent } from './components/car-configurator-wrapper/video-modal/video-modal.component';
import { CarTrimSelectorComponent } from './components/car-trim-selector/car-trim-selector.component';
import { CarModelSelectorComponent } from './components/car-models-selector/car-models-selector.component';
import { DisclaimerModalComponent } from './components/car-models-selector/disclaimer-modal/disclaimer-modal.component';
import { CarUpperMenuComponent } from './components/car-upper-menu/car-upper-menu.component';
import { ChangedConfigurationBarComponent } from './components/changed-configuration-bar/changed-configuration-bar.component';
import { ChangedConfigurationModalComponent } from './components/changed-configuration-modal/changed-configuration-modal.component';
import { ClientNameComponent } from './components/client-name/client-name.component';
import { ConfigurationLoaderComponent } from './components/configuration-loader/configuration-loader.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { EnvironmentsCarouselComponent } from './components/environments-carousel/environments-carousel.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { HomeComponent } from './components/home/home.component';
import { PreDefinedModelSelectorComponent } from './components/pre-defined-model-selector/pre-defined-model-selector.component';
import { PresenterScreenModalComponent } from './components/presenter-screen-modal/presenter-screen-modal.component';
import { SaveConfigurationModalComponent } from './components/summary-configuration/save-configuration-modal/save-configuration-modal.component';
import { SendPdfModalComponent } from './components/summary-configuration/send-pdf-modal/send-pdf-modal.component';
import { SummaryConfigurationComponent } from './components/summary-configuration/summary-configuration.component';
import { TvScreenViewComponent } from './components/tv-screen-view/tv-screen-view.component';
import { AutobahnClient } from './core/clients/autobahn.client';
import { AuthService } from './core/services/authentication.service';
import { CarConfigurationService } from './core/services/car-configuration.service';
import { ErrorDialogService } from './core/services/error-dialog.service';
import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
import { MaseratiService } from './core/services/maserati.service';
import { UiCommonService } from './core/services/ui-common.service';
import { AppSettingEffects } from './core/store/effects/app-setting-effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CameraEffects } from './core/store/effects/camera-effects';
import { CarGridEffects } from './core/store/effects/car-grid-effects';
import { ConfigurationEffects } from './core/store/effects/configuration-effects';
import { CountryPropEffects } from './core/store/effects/country-prop-effects';
import { EngineEffects } from './core/store/effects/engine-effects';
import { EnvironmentsEffects } from './core/store/effects/environments-effects';
import { InterfaceEffects } from './core/store/effects/interface-effects';
import { MenuEffects } from './core/store/effects/menu-effects';
import { ModelEffects } from './core/store/effects/models-effects';
import { UserEffects } from './core/store/effects/user-effects';
import { MxeReducers, initialState, reducers } from './core/store/reducers';
import { MxeAnalyticsService } from './core/services/mxe-analytics.service';
import { PairingToggleComponent } from './components/pairing-toggle-component/pairing-toggle-component';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { HeartbeatService } from './core/services/heartbeat.service';
import { Fallback2DComponent } from './components/fallback2D/fallback2D.component';
import { FallbackInteractiveBottomBarComponent } from './components/fallback2D/fallback-interactive-bottom-bar/fallback-interactive-bottom-bar.component';
import { FallbackCameraCarouselComponent } from './components/fallback2D/fallback-camera-carousel/fallback-camera-carousel.component';
import { FallbackToasterComponent } from './components/fallback2D/fallback-toaster/fallback-toaster.component';
import { ThankYouPageComponent } from './components/thank-you-page/thank-you-page.component';
import { RestartConfigurationModalComponent } from './components/summary-configuration/restart-configuration-modal/restart-configuration-modal.component';
import { ConfigEffects } from './core/store/effects/temporary-configs-effects';
import { HighlightConfigurationComponent } from './components/car-configurator-wrapper/sidebar-configurator/highlight-configuration/highlight-configuration.component';
import { APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS } from './core/store/actions/app-settings/app-settings-actions';
import { RedirectService } from './core/services/redirect.service';
import { ExperienceChoiceComponent } from './components/experience-choice/experience-choice.component';
import { SaveFormComponent } from './components/save-form/save-form.component';
import { LottieModule } from 'ngx-lottie';
import player from "lottie-web";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MonkeyWaySessionsEffects } from './core/store/effects/monkeyway-sessions-effects';
import { UserManualPageComponent } from './components/user-manual-page/user-manual-page.component';
import { LoadingServicesPageComponent } from './components/loading-services-page/loading-services-page.component';
import { LoadingServicesPageTvComponent } from './components/loading-services-page-tv/loading-services-page-tv.component';
import { Router } from "@angular/router";
import * as Sentry from '@sentry/angular-ivy'
import { SentryHttpInterceptor } from './core/services/sentry.http.interceptor';


export function getAppExecutionInformations(store: Store<MxeReducers>) {
  return () => {
    const protocol = window.location.protocol;
    const host =  window.location.host;
    const hostName = window.location.hostname;
    store.dispatch(APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS({ protocol: protocol, host: host, hostName: hostName }))
  }
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarModelSelectorComponent,
    CarTrimSelectorComponent,
    InteriorConfigurationComponent,
    CarUpperMenuComponent,
    ExteriorConfigurationComponent,
    SummaryConfigurationComponent,
    SaveConfigurationModalComponent,
    SendPdfModalComponent,
    PackagesConfigurationComponent,
    ModalComponent,
    OptionalConfigurationComponent,
    AccessoriesConfigurationComponent,
    MonkeyWayComponent,
    AuthorizationComponent,
    ConfigurationLoaderComponent,
    ClientNameComponent,
    HomeMenuComponent,
    PreDefinedModelSelectorComponent,
    CameraCarouselComponent,
    ChangedConfigurationBarComponent,
    ChangedConfigurationModalComponent,
    NewCarUpperMenuComponent,
    FullscreenLoaderComponent,
    VideoModalComponent,
    EnvironmentsCarouselComponent,
    CountrySelectorComponent,
    DisclaimerModalComponent,
    TvScreenViewComponent,
    CountrySelectorComponent,
    AfkModalComponent,
    FormatTimePipe,
    StopScreenCastModalComponent,
    CarConfiguratorWrapperComponent,
    InteractiveBottomBarComponent,
    CarModelDataComponent,
    SidebarConfiguratorComponent,
    PresenterScreenModalComponent,
    TridentModalComponent,
    VideoModalWithDescriptionsComponent,
    ErrorDialogComponent,
    PairingToggleComponent,
    Fallback2DComponent,
    FallbackInteractiveBottomBarComponent,
    FallbackCameraCarouselComponent,
    FallbackToasterComponent,
    ThankYouPageComponent,
    RestartConfigurationModalComponent,
    HighlightConfigurationComponent,
    ExperienceChoiceComponent,
    SaveFormComponent,
    UserManualPageComponent,
    LoadingServicesPageComponent,
    LoadingServicesPageTvComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SwiperModule,
    ReactiveFormsModule,
    NgxScannerQrcodeModule,
    QRCodeModule,
    NgxQrcodeStylingModule,
    StoreModule.forRoot(reducers, {
      initialState: initialState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppSettingEffects, CarGridEffects, ConfigurationEffects, MenuEffects, CountryPropEffects, InterfaceEffects, EngineEffects, ModelEffects, UserEffects, CameraEffects, EnvironmentsEffects, ConfigEffects, MonkeyWaySessionsEffects]),
    FormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxIntlTelInputModule
  ],
  providers: [AutobahnClient, MaseratiService, AuthService, CarConfigurationService, UiCommonService, MxeAnalyticsService, ErrorDialogService, HeartbeatService, RedirectService, 
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService,
  }, 
  {
    provide: APP_INITIALIZER,
    multi:true,
    deps: [Store],
    useFactory: getAppExecutionInformations
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SentryHttpInterceptor,
    multi: true,
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
  
],
  bootstrap: [AppComponent]
})
export class AppModule { }
