import { Injectable } from '@angular/core';
import { IUserState } from '../store/initials/user-initial-state';
import { AutobahnClient } from '../clients/autobahn.client';
import { environment } from 'src/environments/environment';

@Injectable()
export class RedirectService {
  constructor(
    private autobahn: AutobahnClient
  ) {
  }

  public convertToQueryString(data: any, parentKey = ""): string {
    return Object.keys(data)
      .map((key) => {
        const currentKey = parentKey ? `${parentKey}[${key}]` : key;
        const value = data[key];
        if (value && typeof value === "object" && !Array.isArray(value)) {
          return this.convertToQueryString(value, currentKey);
        }
        if (Array.isArray(value)) {
          return value.map((item, index) =>
            this.convertToQueryString(item, `${currentKey}[${index}]`)
          );
        }
        return `${encodeURIComponent(currentKey)}=${encodeURIComponent(value)}`;
      })
      .flat()
      .join("&");
  }

  public convertToUserState(queryString: string): any {
    const params = new URLSearchParams(queryString.slice(2).slice(0,-2));
    const result: Record<string, any> = {};
  
    params.forEach((value, key) => {
      const keys = key.split('[').map((k) => k.replace(']', ''));
      let currentObject = result;
  
      keys.forEach((keyPart, index) => {
        const isArray = index < keys.length - 1 && !isNaN(parseInt(keys[index + 1], 10));
        const valueToSet = isArray ? [] : {};
        if (index === keys.length - 1) {
          if (keyPart === 'localIps') {
            currentObject[keyPart] = currentObject[keyPart] || [];
            currentObject[keyPart].push(value);
            currentObject[keyPart] = currentObject[keyPart].map(Number).join('.');
          } else if (isArray) {
            currentObject[keyPart].push(value);
          } else {
            currentObject[keyPart] = value;
          }
        } else {
          currentObject[keyPart] = currentObject[keyPart] || valueToSet;
          currentObject = currentObject[keyPart];
        }
      });
    });
    result['machinesInfo'] =  result['machinesInfo']?.map((item: any) => {
      const localIpsArray = item.localIps.map((subArray:any) => subArray.join(''))
      return {serialNumber: item.serialNumber, localIps:localIpsArray};
    })
    return result;
  }

  public async redirectSolution(userState: IUserState, ip: string) {
    
    let hashingLocalIp = await this.hashIp(ip) 
    //CAMBIARE ENDPOINT
    let url = `https://${hashingLocalIp}.${environment.environment}.local.workers.mxe-backend.com`
    const queryString = this.convertToQueryString(userState) 
    const encodedQueryString = encodeURIComponent(queryString);
    const redirect_url = `${url}/mxe/oauth/redirecturi?${encodedQueryString}`
    window.location.href = redirect_url;

  }
  
  async hashIp(localIp: string): Promise<string> {
    try {
      const hexDigest = await this.sha1HexDigest(localIp);
      const hashedIp = this.extractSubstring(hexDigest);
      console.log(hashedIp);
      return hashedIp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private async sha1HexDigest(data: string): Promise<string> {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);

    // Generate digest SHA-1 using Web Crypto API
    const hashBuffer = await crypto.subtle.digest('SHA-1', dataBuffer);

    return Array.from(new Uint8Array(hashBuffer))
                .map(byte => byte.toString(16).padStart(2, '0'))
                .join('');
  }

  private extractSubstring(hexDigest: string): string {
    let result = '';
    for (let i = hexDigest.length - 1; i >= 0 && result.length < 8; i -= 2) {
      result = result + hexDigest[i]; // Prepend characters to invert order
    }
    return result;
  }

  
}
