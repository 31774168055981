import { Action, createReducer, on } from '@ngrx/store';
import { CONFIGURATION_LOADED, RESET_CONFIGURATION_LOADED } from '../actions/configuration-actions';
import { configurationInitialState } from '../initials/configuration-initial-state';


const _configurationReducer = createReducer(configurationInitialState,
    on(CONFIGURATION_LOADED, (state, payload) => ({
        ...state,
        loaded: true,
        loadedConfig: payload.config
    })),
    on(RESET_CONFIGURATION_LOADED, (state, payload) => ({
        ...state,
        loaded: false,
        loadedConfig:  {
            modelCode: 0,
            country: 0,
            language: '',
            config: '',
            frozen: false,
            videoRequested: false,
            modelName: '',
            modelDescription: ''
        }
    }))
);

export function configurationReducer(state = configurationInitialState, action: Action) {
    return _configurationReducer(state, action);
}
