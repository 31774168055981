
import { createAction, props } from '@ngrx/store';
import { Camera } from 'src/app/core/models/unreal-available-models.model';

export const UPDATE_SELECTED_CAMERA = createAction(
    '[CameraCarouselComponent] UPDATE_SELECTED_CAMERA',
    props<{
        activeCamera: Camera
    }>()
)

export const UPLOAD_CAMERAS = createAction(
    '[AutobahnClient] UPLOAD_CAMERAS',
    props<{
        cameras: Camera[]
    }>()
)