import { Optional } from "src/app/core/models/interface-service.model";
import { UIOptItem } from "../../models/car-grid.model";
import { Layer } from "../../models/interface-service.model";

export interface ConfigState {
  configItems: ConfigItem[];
}

export interface ConfigItem {
    carName: string;
    savedOn: number;
    options: UIOptItem[];
    hiddenOptions: UIOptItem[];
    defaultOptions: UIOptItem[];
    optionals: Optional[];
    imageLayers: Layer[];
    configId: string;
    modelCode: string;
    pdfLink: string;
    imageView: string;
    modelYear: string;
  }
  
  
  export const temporaryConfigInitialState: ConfigState = { configItems: []}