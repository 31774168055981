<div class="maserati__car-configurator-bottom-panel">
    <div class="maserati__car-configurator--view-controls">
        <div>
            <button type="button" (click)="fullScreenToggle()" [ngClass]="{'button-active': fullScreenIconActive}"
                class="maserati__car-configurator--icon maserati__car-configurator--action-fullscreen-toggle">
            </button>
            <button type="button" (click)="showCameraCarousel = !showCameraCarousel;"
                [ngClass]="{'button-active': showCameraCarousel}"
                class="maserati__car-configurator--icon maserati__car-configurator--action-play-video">
            </button>
            <button [ngClass]="{'button-disabled': cameraIndex <= 0}" type="button" class="maserati__car-configurator--icon maserati__car-configurator--action-prev-camera" (click) = prevCamera()>
            </button>
            <button [ngClass]="{'button-disabled': cameraIndex + 1 >= swiperImages.length}" type="button" class="maserati__car-configurator--icon maserati__car-configurator--action-next-camera" (click) = nextCamera()>
            </button>
        </div>
    </div>
    <!-- <div class="streaming-connection-button">
        <button type="button" class="streaming-btn" (click)="onStreamingConnection()">
        </button>
    </div> -->
    <app-fallback-camera-carousel *ngIf="showCameraCarousel" 
    [swiperImages]="swiperImages" 
    [fullScreen]="fullScreenIconActive"
    (onSelectCamera)="onCameraSelected($event)" 
    [currentIndex]="cameraIndex">
    </app-fallback-camera-carousel>
</div>