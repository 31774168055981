import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { OPTION_TYPE_ENUM } from 'src/app/core/models/common.model';
import { CarModel } from 'src/app/core/models/get-models-service.model';
import { CarImage, Optional } from 'src/app/core/models/interface-service.model';
import { FallbackToasterComponent } from './fallback-toaster/fallback-toaster.component';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';
import { APP_SETTINGS_SET_STREAMING_STATUS } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { Subscription } from 'rxjs';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { HandledError } from 'src/app/core/models/handled-error';
import { SentryMessaging } from 'src/app/core/services/sentry-messaging.service';


@Component({
  selector: 'app-fallback2D',
  templateUrl: './fallback2D.component.html',
  styleUrls: ['./fallback2D.component.scss']
})
export class Fallback2DComponent {
  @ViewChild('activeImageElement') activeImageEl: ElementRef<HTMLImageElement>
  @ViewChild(FallbackToasterComponent) fallBackComponent

  public swiperImages: any[] = [];
  public activeView: CarImage;
  public showToasterAlertBar: boolean;
  public screenCastActive: boolean;
  public ephemeralDeviceID: string;
  
  private _selectedOptions: UIOptItem[] = [];
  private _swiperViews : CarImage[];
  private _activeImageUrl: string;
  private _pairingInitialOffset: number = 0;
  private screenCastActive$: Subscription;
  private ephemeralDeviceID$: Subscription;
  @Input() isTrimAvailableStreaming : boolean;
 
  @Input() set loadingCompleted(completed: boolean){
    if(completed && this.fallBackComponent && this.isMainScreen){
      this.showToasterAlertBar = true;      
    }
  }

  @Input() set activeCamera(activeCamera: CarImage) {
    if(activeCamera && this._swiperViews) {
        let imageIndex = this._swiperViews.map(m => m.view).indexOf(activeCamera.view);
        if(imageIndex >= 0) {
          this.onSelectCamera(imageIndex)
        }
      }
    }

  @Input() set options(options: UIOptItem[]) {
    this.getSelectedOptions(options);
    if(this._swiperViews) this.getSwiperImages();
    if(this.activeView) {
      let imageIndex = this._swiperViews.indexOf(this.activeView)
      if(this._activeImageUrl != this.swiperImages[imageIndex]) {
        this.imageLoaded = false;
      }
      this._activeImageUrl = this.swiperImages[imageIndex];
  }
  }
  @Input() set swiperViews(swiperViews: CarImage[]) {
    this._swiperViews = swiperViews;
    this.activeView = swiperViews.filter(c => c.viewType == 'EXT')[0];
    this.getSwiperImages();
    if(this.activeView) {
      let imageIndex = this._swiperViews.indexOf(this.activeView)
      if(this._activeImageUrl != this.swiperImages[imageIndex]) {
        this.imageLoaded = false;
      }
      this._activeImageUrl = this.swiperImages[imageIndex];
    } else {
      if(this._activeImageUrl != this.swiperImages[0]) {
        this.imageLoaded = false;
      }
      this._activeImageUrl = this.swiperImages[0];
    }
  }

  private offsetX = 0;

  @Input() set pairedActiveImageOffset(offset: string) {
    if(offset && this.activeImageEl) {
      this.offsetX = this._pairingInitialOffset + <number><unknown>offset;
      let maxOffsetX  =this.activeImageEl.nativeElement.clientWidth - document.getElementById('container')?.clientWidth!
      let minOffsetX = 0;
        if(this.offsetX <= minOffsetX) {
          if(Math.abs(this.offsetX) <= maxOffsetX) {
            this.activeImageEl.nativeElement.style.left = this.offsetX + 'px';
          } else {
            this.activeImageEl.nativeElement.style.left = -maxOffsetX + 'px';
          }
        } else {
          this.activeImageEl.nativeElement.style.left = 0 + 'px';
        }
    }

  }

  @Input() set fallbackImageCurrentPosition(fallbackImageCurrentPosition: number) {
    if(this.activeImageEl) {
      if(fallbackImageCurrentPosition != 0) {
        this._pairingInitialOffset = this.activeImageEl.nativeElement.offsetLeft;
      }
      else {
        this._pairingInitialOffset = 0;
        this.activeImageEl.nativeElement.style.left = 0 + 'px';
      }
    }

  }

  @Input() hiddenOptions: UIOptItem[] = [];
  @Input() optionalsBinding: Optional[] = [];
  @Input() defaultOptions: UIOptItem[]
  @Input() isMainScreen: boolean
  @Input() carModel: CarModel;
  @Input() viewPresenterScreenSidebar = true;
  @Input() set packages (_packs : Pack[]) {
    if(_packs){
      this.packs = _packs
    }
  }

  @Output() onFullScreenToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onActiveImageChange: EventEmitter<CarImage> = new EventEmitter<CarImage>();
  @Output() viewPresenterScreenSidebarEvent: EventEmitter<boolean> = new EventEmitter();

  get selectedOptions() {
    return this._selectedOptions;
  }
  get activeImageUrl() {
    return this._activeImageUrl;
  }
  get swiperViews() {
    return this._swiperViews;
  }

  imageLoaded: boolean;
  packs : Pack[];

  constructor(
    private monkeyWay: MonkeyWayService,
    private autobahnClient: AutobahnClient,
    private store: Store<MxeReducers>,
    private uiCommonService: UiCommonService,
    private sentryMessaging: SentryMessaging
    ) { }

  ngOnInit() {
    if (this.isMainScreen) {
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive)

      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
  }

  ngAfterViewInit() {
    if(!this.imageLoaded) {
      this.activeImageEl.nativeElement.hidden = true;
    }
  }

  ngOnDestroy() {
    if(this.screenCastActive$) {
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$) {
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }
  private getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  async onStreamingConnection(){
    this.autobahnClient.checkAvailableTrim(this.carModel.modelCode).then(async(availableTrim: boolean) => {
      if(availableTrim) {
        this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: true}))
        //posso fare qui lo stop dello streamingService? 
        if(this.ephemeralDeviceID && this.screenCastActive) {
           await this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID)
          }
        return this.autobahnClient.getStreaming().then((res) => {
            console.warn('Trim available in Unreal, connecting')
            this.monkeyWay.startSession(res.connection.connectionKey, res.baseUrl, res.appEnvId);
            this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: true}))
        })
        .catch((error: any) => {
          if(!environment.production){
            console.error(error);
          } else {
            console.error()
          }     
          let errorMessage = null;
          try {
              errorMessage = JSON.parse(error.message).error
          } catch(err:any) {
            console.log('Error message is not in JSON format')
          }
            if(errorMessage && errorMessage == 'MXE_SSO_EXPIRED') {
              throw new HandledError(`${this.getLabelWithDefaultValue('MXE_SSO_EXPIRED_ERROR','Your authentication has expired, click Continue to return to the homepage.')}`)        
            }
          console.warn('No stream available. Activating fallback on 2D configurator')
          this.sentryMessaging.logEvent('mxe-error.fallback_activation','error',{reason: `allocation/starting streaming process failed`, error: error})
          this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: false}))
        })
      } else {
        console.warn(`MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal. Activating fallback on 2D configurator`)
        this.sentryMessaging.logEvent('mxe-warning.fallback_activation','warning',{reason: `MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal`})
        this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: false}))
        return;
      }
    })

  }

  async isTrimStreamingAvailable(){
    let isAvailable = await this.autobahnClient.checkAvailableTrim(this.carModel.modelCode);
    console.debug("ISAVAILABLE",isAvailable)
    return isAvailable;
  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  getSwiperImages() {
    this.swiperImages = [];
    const configItems = this.selectedOptions
      .concat(this.hiddenOptions)
      .concat(this.defaultOptions)
    const uniqueConfigItems = [...new Set(configItems)]
    this.swiperViews.forEach(i => {
      this.swiperImages.push(new Image().src = `${environment.photocopier_endpoint}/${this.carModel.modelCode}/1920/c1080/gfx${i?.view}.png?config=background;${this.uiCommonService.buildConfigForPhotocopier(i.layer, uniqueConfigItems, this.optionalsBinding, this.packs)}`);
    })
  }

  onSelectCamera(event: number) {
    this.activeView = this.swiperViews[event];
    this.reflowAnimations();
    this._activeImageUrl = this.swiperImages[event]
    if(this.screenCastActive) {
      this.autobahnClient.setFallbackActiveImage(this.ephemeralDeviceID, event)
    }
    this.onActiveImageChange.emit(this.activeView)
  }

  private reflowAnimations() {
    let ids = ['drag-hand-container', 'drag-arrow', 'drag-hand']
    ids.forEach(id => {
      let element = document.getElementById(id);
      if(element) {
        element.style.animation = 'none';
        element.offsetHeight;
        element.style.animation = '';
      }
    });
  }

  private getSelectedOptions(options: UIOptItem[]) {
    this._selectedOptions = []
    if(options){
      options.filter(o =>
        (o.status.selected === true
          || o.status.selectedInPack)
          && (o.type === OPTION_TYPE_ENUM.OPTION || o.type === OPTION_TYPE_ENUM.CHECKBOX)
      )
      .forEach(opt => {
        if(opt.colorList && opt.colorList.length > 0) {
          let color = opt.colorList.find(c => c.selected === true)
          if(color){
            if(color.fuoriserie === opt.fuoriserie) {
              let filteredOpt: UIOptItem = {
                id: opt.id,
                colorList: [color],
                fuoriserie: opt.fuoriserie,
                highlight: opt.highlight,
                group: opt.group,
                layerGroup: opt.layerGroup,
                onlyImplicit: opt.onlyImplicit,
                price: opt.price,
                quantity: opt.quantity,
                section: opt.section,
                status: opt.status,
                type: opt.type,
                must: opt.must
              }
              this._selectedOptions.push(filteredOpt)
            }
          }
        } else {
            let filteredOpt: UIOptItem = {
              id: opt.id,
              colorList: [],
              fuoriserie: opt.fuoriserie,
              highlight: opt.highlight,
              group: opt.group,
              layerGroup: opt.layerGroup,
              onlyImplicit: opt.onlyImplicit,
              price: opt.price,
              quantity: opt.quantity,
              section: opt.section,
              status: opt.status,
              type: opt.type,
              must: opt.must
            }
            this._selectedOptions.push(filteredOpt)
          }
        })
    }
  
  }

  onTouchMove(event: any) {
    event.preventDefault();
    var screenCastActive = this.screenCastActive;
    var ephemeralDeviceID = this.ephemeralDeviceID;
    var autobahn = this.autobahnClient;
    let touch: Touch = event.touches[0];
    var touchedImage = event.target;
    var initialOffsetX = touchedImage.offsetLeft;
    var minOffsetX = 0;
    var maxOffsetX = touchedImage.clientWidth - document.getElementById('container')?.clientWidth!
    var touchstartX = touch.pageX;
    if(this.screenCastActive) {
      autobahn.setFallbackImageInitialPosition(ephemeralDeviceID, initialOffsetX)
    }

    var ontouchMove = function(e) {
      e.preventDefault();
      let offsetX;
      let touchactualX = e.touches[0].pageX;
      offsetX = touchactualX - touchstartX;
      let leftOffset = initialOffsetX + offsetX;
      if(screenCastActive) {
        autobahn.setFallbackImageOffset(ephemeralDeviceID, offsetX)
      }
      if(leftOffset <= minOffsetX) {
        if(Math.abs(leftOffset) <= maxOffsetX) {
          touchedImage.style.left = leftOffset + 'px';
        } else {
          touchedImage.style.left = -maxOffsetX + 'px';
        }
      } else {
        touchedImage.style.left = 0 + 'px';
      }
    }

    touchedImage.addEventListener('touchmove', ontouchMove, false);

    touchedImage.addEventListener('touchend', function(e) {
      e.preventDefault();
      touchedImage.removeEventListener('touchmove', ontouchMove, false)
    },false);
  }

  fullScreenToggleHandler(event: boolean) {
      if(event) {
        if(this.activeView.viewType === 'INT') {
          this.activeImageEl.nativeElement.style.left = 0 + 'px'
        } else {
        const maxOffset  = this.activeImageEl.nativeElement.clientWidth - window.innerWidth;
        this.activeImageEl.nativeElement.style.left = -maxOffset/2 + 'px'
        }
        if(this.screenCastActive) {
          this.autobahnClient.setFallbackImageInitialPosition(this.ephemeralDeviceID, 0).then(() => {
            this.autobahnClient.setFallbackImageOffset(this.ephemeralDeviceID, '0')
          });
        }
      }
    this.onFullScreenToggle.emit(event);
  }

  public togglePresenterScreenSidebar() {
    this.viewPresenterScreenSidebar = !this.viewPresenterScreenSidebar
    if(!this.viewPresenterScreenSidebar) {
      this.autobahnClient.setFallbackImageInitialPosition(this.ephemeralDeviceID, 0).then(() => {
        this.autobahnClient.setFallbackImageOffset(this.ephemeralDeviceID, '0').then(() => {
          this.viewPresenterScreenSidebarEvent.emit(this.viewPresenterScreenSidebar)
        }) 
      })
    } else {
      this.activeImageEl.nativeElement.style.left = `0px`
      this.viewPresenterScreenSidebarEvent.emit(this.viewPresenterScreenSidebar)
    }
  }

  onImageLoad(){
    this.imageLoaded = true;
    if(this.activeImageEl && this.activeImageEl.nativeElement.hidden) {
      this.activeImageEl.nativeElement.hidden = false;
    }
    console.debug('Fallback active image fully loaded')
  }

  onImageLoadFailed() {
    this.imageLoaded = true;
    console.error('Image Load failed')
    if(this.activeImageEl) {
      this.activeImageEl.nativeElement.hidden = true;
    }
  }
}
