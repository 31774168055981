
<div class="mxe__changed-configuration-modal">
    <div class="padd">
        <h2>Modification details</h2>
        <div class="mxe__ccm-wrapper">
            <div class="mxe__ccm-scrollable">
                <div class="added-grid" *ngIf="conflictsData.toAdd.length > 0">
                    <h3>{{getLabel('MXE_CHANGED_CONFIG_ADDED_BUTTON')}}</h3>
                    <div class="elements-list">
                        <div class="el-row" *ngFor="let item of conflictsData.toAdd">
                            <span class="name-element">{{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME)}}</span>
                            <span class="price-element"  *ngIf="showPrices">{{formatPrice(getPrice(item.id, item.price).toString())}}</span>
                        </div>
                    </div>
                </div>
                <div class="removed-grid" *ngIf="conflictsData.toRemove.length > 0">
                    <h3>{{getLabel('MXE_CHANGED_CONFIG_REMOVED_BUTTON')}}</h3>
                    <div class="elements-list">
                        <div class="el-row" *ngFor="let item of conflictsData.toRemove">
                            <span class="name-element">{{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME)}}</span>
                            <span class="price-element" *ngIf="showPrices">{{formatPrice(getPrice(item.id, item.price).toString())}}</span>
                        </div>
                    </div>
                </div>
                <div class="total" *ngIf="showPrices">
                    <h3>Total</h3>
                    <span class="total-price">
                        {{getDeltaPrice()}}
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-control-buttons">
            <button class="btn btn-transparent btn-bordered btn-dark"
            data-linktracking="cta:view-details:revert"  
            (click)="onRevertClick()">{{getLabel('MXE_CHANGED_CONFIG_REVERT_BUTTON')}}</button>
            <button class="btn btn-accent"
            data-linktracking="cta:view-details:confirm"  
            (click)="onConfirmClick()">{{getLabel('BTN_SELECT')}}</button>
        </div>
    </div>
</div>
<div class="mxe__modal-backdrop">
</div>