import {Action, createReducer, on } from "@ngrx/store";
import { MonkeyWaySessionActions } from "../actions/monkeyway-sessions/monkeyway-sessions-exported-actions";
import { monkeyWaySessionsInitialState } from "../initials/monkeyway-sessions-initial-state";


const _monkeyWaySessionsReducer = createReducer(monkeyWaySessionsInitialState,
    on(MonkeyWaySessionActions.MW_SESSION_ADD, (state, payload) => ({
        sessions: [...state.sessions, payload.session]
    })),
    on(MonkeyWaySessionActions.MW_SESSION_DELETE, (state, payload) => ({
        sessions: state.sessions.filter(s => s.sessionId !== payload.session.sessionId)
    })),
    on(MonkeyWaySessionActions.MW_SESSION_INIT, (state, payload) => ({
        sessions: payload.sessions
    })),
);

export function monkeyWaySessionsReducer(state = monkeyWaySessionsInitialState, action: Action) {
    return _monkeyWaySessionsReducer(state, action)
}

 