import { Component } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { AutobahnClient } from "src/app/core/clients/autobahn.client";
import { APP_SETTINGS_SET_COUNTRY } from "src/app/core/store/actions/app-settings/app-settings-actions";
import { RESET_MODELS_STATE } from "src/app/core/store/actions/models/models-actions";
import { SET_ACCESS_TOKEN, SET_AUTHORIZATION_CODE, SET_DEALERS_INFO, SET_DEALER_ID } from "src/app/core/store/actions/users/user-actions";
import { MxeReducers } from "src/app/core/store/reducers";
import countries from '../../../assets/countries.json'
import { environment } from "src/environments/environment";
import { IDealer } from "src/app/core/models/authorization/dealer.model";
import { SET_MACHINES_INFO } from '../../core/store/actions/users/user-actions';
import { Observable, Subscription, combineLatest, forkJoin, map } from "rxjs";
import { IUserState } from "src/app/core/store/initials/user-initial-state";
import { HttpClient } from "@angular/common/http";
import { RedirectService } from "src/app/core/services/redirect.service";
import { IMachineInfo } from '../../core/models/authorization/machine.model';
import * as Sentry from '@sentry/angular-ivy';
import { NavigationStateService } from "src/app/core/services/navigation-state.service";

@Component({
  selector: 'authorization-component',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent {
  
private executionInformations$: Subscription
private userState$: Subscription
private appExecutionInfos: { protocol: string, host: string,  hostName: string } = { protocol: 'https:', host: '', hostName: '' }
private userState: IUserState;
public endpoints: string[] = [];
private endpointsToTest: number = 0;
dealerInfo = {}
  constructor(
    private store: Store<MxeReducers>,
    private navigation: NavigationStateService,
    private autobahnClient: AutobahnClient,
    private http: HttpClient,
    private route: ActivatedRoute,
    private redirectService: RedirectService
  ) { }

  ngOnInit() {
    this.executionInformations$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.appState.hostName)
    ])
    .subscribe(
      ([protocol, host, hostName]) => {
        this.appExecutionInfos.protocol = protocol
        this.appExecutionInfos.host = host
        this.appExecutionInfos.hostName = hostName
        if(host !== 'localhost:8080' && hostName.includes('local')) {
          this.route.queryParams.subscribe(params => {
            let userState = this.redirectService.convertToUserState(JSON.stringify(params))
            console.log('[QUERYSTRING] retrieving user state: ', userState)
            this.setUserState(userState, hostName)
            })
        } else {
          this.getAuthCodeFromUri()
        }
      }
    );
    this.userState$ = this.store.select(s => s.userState).subscribe(
      (userState) => {
        this.userState = userState
      }
    )
  }

  ngOnDestroy() {
    this.userState$?.unsubscribe();
    this.executionInformations$?.unsubscribe();
  }

  private getAuthCodeFromUri(){
    const toMatch = window.location.hash
    let exp = /#code=(\S+)&id_token=(\S+)/
    const groups = exp.exec(toMatch)
    if(groups && groups?.length === 3) {
        const id_token = groups[2]

        const base64Url = id_token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const decodeToken = JSON.parse(jsonPayload);
        const jwt_tokenExpiration = decodeToken['exp'];

        const authorization_code = groups[0].replace('#code=', '')
        this.getDealerId(id_token, authorization_code, jwt_tokenExpiration, authorization_code)
    }
  }

  private getDealerId(idToken: string, accessToken: string, jwt_tokenExpiration: number, authorization_code: string) {
    console.debug('[Autobahn] Requesting dealer info')
    const payloadObj = {
      code: accessToken,
      code_type: 'authorization_code',
      redirect_uri: environment.authentication.redirect_uri,
      client_id: environment.authentication.client_id
    }

    const dealerInfoPromise = this.autobahnClient.getDealerInfo(idToken, payloadObj)

    dealerInfoPromise.then(
      (dealer) => {
        console.debug(dealer)
        if(dealer){
          //Store the token
          const accessToken = dealer['access_token']
          this.store.dispatch(SET_AUTHORIZATION_CODE({ jwt_token: idToken, jwt_token_expiration_time: jwt_tokenExpiration, authorization_code: authorization_code }))
          this.store.dispatch(SET_ACCESS_TOKEN({access_token: accessToken['token'], access_token_expiration_time: accessToken['expires']}))
          //Store dealer_info
          this.store.dispatch(SET_DEALERS_INFO({ dealersInfo: dealer['dealer_info']}))
          const dealerInfo = dealer['dealer_info'][0]
          this.dealerInfo = dealerInfo;
          this.store.dispatch(SET_DEALER_ID({ dealerId: dealerInfo['dealer_code'] }))
          // Sentry.setUser({
          //   id: dealerInfo['dealer_code'],
          //   session_id: 'session-' + Math.random().toString(36).substring(2, 9)
          // })
          // Sentry.startSession();
          const country = countries.countryList.find(c => c.countryCode === dealerInfo['country'])
          if(country){
            const languageId = country.availableLanguages.length > 0 ? country.availableLanguages[0] : 'en'
            this.store.dispatch(APP_SETTINGS_SET_COUNTRY({ countryCode: country.countryCode, languageId: languageId, ctaLanguageId: country.ctaLanguageId }))
            this.store.dispatch(RESET_MODELS_STATE())
          } else {
            console.error(`Country with code: ${dealerInfo['country']} not found in countries' file`)
          }
          this.getAvailableMachines(dealer)
          .then(
            (machinesAvailable: IMachineInfo[]) => {
              let testMachines : IMachineInfo[] =  machinesAvailable?.flat()
              console.log('[AUTOBAHN_CLIENT] Machines Available: ', machinesAvailable?.flat())
              let lTest : IMachineInfo = {
                localIps: ["192.168.1.10"],
                serialNumber: "WINAPy6uWC3fiZE",
                dealerID: dealerInfo['dealer_code']
              }
              testMachines.push(lTest)
              this.store.dispatch(SET_MACHINES_INFO({machinesInfo: testMachines}))
            }
          )
          .catch((error: any) => {
            throw error; 
          })
          .finally(() =>{
            //to redirect to authorization page
            if (!dealer['dealer_info'].some(d => d['onprem_enabled'])) {
              this.navigation.navigate(['/mxe']);
            }
            else{
              this.navigation.navigate(['/']);
            }
          })
  
        }
      }
    )
  }

  private async getAvailableMachines(dealer): Promise<IMachineInfo[]> {
    let machinesAvailable: IMachineInfo[] = []
    if(dealer) {
      const promises = dealer['dealer_info']?.map(async (dealerInfo) => {
        const availableMachines = await this.autobahnClient.getAvailableMachines(dealerInfo['dealer_code']);
        if(availableMachines && availableMachines.systems && availableMachines.systems.length > 0) {
          availableMachines.systems.forEach(machine => {
            machinesAvailable.push({dealerID: dealerInfo['dealer_code'], localIps: machine.localIps, serialNumber: machine.serialNumber});
          })
          
        }
      });
      await Promise.all(promises)
      return machinesAvailable
    }
    return [];
  }

  


  private async setUserState(userState: any, hostName: string) {
    this.store.dispatch(SET_AUTHORIZATION_CODE({ jwt_token: userState['jwt_token'], jwt_token_expiration_time: userState['jwt_token_expiration_time'], authorization_code: userState['authorization_code'] }))
    this.store.dispatch(SET_ACCESS_TOKEN({access_token: userState['access_token'], access_token_expiration_time: userState['access_token_expiration_time']}))
    this.store.dispatch(SET_DEALERS_INFO({ dealersInfo: userState['dealersInfo']}))
    this.store.dispatch(SET_DEALER_ID({ dealerId: userState['dealerId'] }))
    this.store.dispatch(SET_MACHINES_INFO({machinesInfo: userState['machinesInfo']}))

    let machineHostName: string| null = null
    let hashedIps : string[] = [];
    if (userState.machinesInfo) {
      for (const machine of userState.machinesInfo) {
        const promises = machine.localIps?.map(async (ip) => {
          let hashedIp = await this.redirectService.hashIp(ip);
          hashedIps.push(hashedIp);
        }) ?? [];
    
        await Promise.all(promises);
    
        let hashPartUrl = hostName.split(".")[0];
        if (hashedIps.includes(hashPartUrl)) {
          machineHostName = machine.serialNumber;
          break;
        }
      }
    }
    machineHostName? localStorage.setItem('machine_hostname', machineHostName): localStorage.setItem('machine_hostname', ''); 


    const country = countries.countryList.find(c => c.countryCode == userState.dealersInfo.find(d => d.dealer_code === userState['dealerId'])?.country)
    if(country){
      const languageId = country.availableLanguages.length > 0 ? country.availableLanguages[0] : 'en'
      this.store.dispatch(APP_SETTINGS_SET_COUNTRY({ countryCode: country.countryCode, languageId: languageId, ctaLanguageId: country.ctaLanguageId }))
      this.store.dispatch(RESET_MODELS_STATE())
    } else {
      console.error(`Country with code: ${userState['dealersInfo'][0]['country']} not found in countries' file`)
    }
    this.navigation.navigate(['/mxe'])
  }
}
