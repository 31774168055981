import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fallback-camera-carousel',
  templateUrl: './fallback-camera-carousel.component.html',
  styleUrls: ['./fallback-camera-carousel.component.scss']
})
export class FallbackCameraCarouselComponent implements OnInit {
  @ViewChild('carouselRef') carouselRef: ElementRef<HTMLDivElement>;
  private _swiperImages: any[] = [];
  private _currentIndex: number = 0;

  loadedImages: boolean[] = [];

  get swiperImages() {return this._swiperImages}
  get currentIndex() {return this._currentIndex}

  @Input() set swiperImages (swiperImages: any []) {
    this._swiperImages = swiperImages;
    this.loadedImages = [];
    this._swiperImages.forEach(img => {
      this.loadedImages.push(false);
    })
  }

  @Input() set currentIndex(currentIndex: number) {
    if(currentIndex >= 0) {
      this._currentIndex = currentIndex;
    }
  }
  @Input() fullScreen: boolean;
  @Output() onSelectCamera: EventEmitter<number> = new EventEmitter<number>();
  
  constructor() { }

  ngOnInit(): void {
  }

  getCameraImage(i:number){
    return this.swiperImages[i];
  }

  selectCamera(index: number){
    this.currentIndex = index;
    this.onSelectCamera.emit(index);
  }

  onLoad(index: number) {
    this.loadedImages[index] = true;
  }

}
