<div class="mxe__camera-carousel-border" [ngClass]="{'expanded': fullScreen}">
    <div #carouselRef class="mxe__camera-carousel-container">
        <div class="mxe__camera-carousel-images">
            <button type="button" class="camera-thumb" *ngFor="let camera of swiperImages; let i=index"
                [ngClass]="{'active': currentIndex === i}" (click)="selectCamera(i)">
                <div class="camera-background">
                    <div style="display:flex; width: 100%; height: 100%; text-align: center; justify-content: center; align-items: center; background-color: black;">
                        <div *ngIf="!loadedImages[i]" class="spinner"></div>
                        <img  [hidden]="!loadedImages[i]" (load)="onLoad(i)" class="camera-image" [src]="getCameraImage(i)">
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>