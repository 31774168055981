<div class="mxe__loader-poster">
    <div class="mxe__loader-background" >
        <div class="title-container">
                <div class="row-container">
                    <div class="italic-word">Loading</div>&nbsp;<div>your</div>
                </div>
                <div class="row-container">
                    <div>MXE</div>&nbsp;<div class="italic-word">experience</div>
                </div>
        </div>
    </div>
</div>
    