import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-afk-modal',
  templateUrl: './afk-modal.component.html',
  styleUrls: ['./afk-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AfkModalComponent implements OnInit, OnDestroy {

  // TODO set this true when modal is open
  isModalOpen = true;

  public countDown$:Subscription | null;
  public counter = 120;
  public tick = 1000;
  @Input() streamingAvailable : boolean;
  @Input() envName : string
  @Input() isCinematicActiveForSelectedTrim : boolean
  @Input() isCinematicStarted : boolean
  @Output() continueBrowsingEvent = new EventEmitter()
  @Output() confirmExpirationEvent = new EventEmitter()
  @Output() isCinematicStartedEvent = new EventEmitter()


  constructor(
    private chg: ChangeDetectorRef,
    private uiCommonService: UiCommonService
  ) { }

  ngOnInit() {
    if(this.envName === 'cloud'){
      this.countDown$ = timer(0, this.tick).subscribe((() => {
        --this.counter
        this.chg.detectChanges();
        if(this.counter == -1){
          this.confirmExpirationEvent.emit()
        }
      }));
    }
  }

  
  ngOnDestroy(){
    this.countDown$?.unsubscribe()
    this.countDown$ = null;
  }

  closeModal(){
    if(!this.isCinematicStarted && this.isCinematicActiveForSelectedTrim) return
    this.isCinematicStartedEvent.emit(false)
    this.continueBrowsingEvent.emit()
    this.isModalOpen = false;
  }
  
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}