import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NavigationStateService } from './navigation-state.service';
import { SentryMessaging } from './sentry-messaging.service';
import { AppComponent } from 'src/app/app.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreventBackNavigationGuard implements CanDeactivate<unknown> {
  constructor(private navigationStateService: NavigationStateService, private sentry: SentryMessaging) {}

  canDeactivate(): Observable<boolean> {
    if (!this.navigationStateService.isNavigationAllowedSync()) {
      this.sentry.logEvent('Inappropriate use of application navigation', 'warning', {reason: 'back_browser_button_clicked'});
      this.navigationStateService.showToast('For a better MXE experience, use the internal navigation buttons');
      return of(false);
    }
    return of(true);
  }
}
