<div *ngIf="showRestartConfirmationModal" class="mxe__restart-configuration-wrapper">
    <div class="modal-backdrop"></div>
    <div class="mxe__restart-configuration-content">
        <div class="modal-title">
            <h2>{{getLabel('MXE_YOU_SURE', 'Are you sure?')}}</h2>
            <div class="modal-subtitle">
                {{getLabel('MXE_ACTUAL_CONFIG_DISCARDED', 'Your actual configuration will be discarded')}}
            </div>
            <div class="modal-buttons">
                <button class="btn btn-accent"
                (click)="closeModal('confirm')">
                {{getLabel('LINE_CONFIRM_BTN', 'Confirm')}}
                </button>
                <button class="btn btn-dark"
                (click)="closeModal('dismiss')">
                {{getLabel('LINE_CANCEL_BTN','Cancel')}}
                </button>
            </div>
        </div>
    </div>
</div>