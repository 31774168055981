

<div class="mxe__disclaimer-wrapper">
    <span class="modal-backdrop" (click)="closeModal()"></span>
    <div class="mxe__disclaimer-content"  >
        <div class="title-details">
            <!-- <span class="title">
                Lorem Ipsum
               </span>
               <span class="subtitle">
                   Lorem Ipsum
               </span> -->
        </div> 
        <div class="disclaimer">
            {{getLabel('DISCLAIMER_DESCR')}}
        </div>
    </div>
</div>
