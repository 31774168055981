<div class="mxe__afk-modal-wrapper simple-modal">
    <div class="modal-backdrop"></div>
    <div class="mxe__afk-modal-content">
        <div class="modal-padd">
            <h2> {{getLabel('MXE_SELECT_COUNTRY_WARNING')}} </h2>
            <!-- if insert description put into modal-disclaimer div class -->
            <div class="modal-buttons">
                <button
                class="btn btn-dark btn-transparent btn-bordered"
                (click)="onCancel()"
                >
                {{getLabel('LINE_CANCEL_BTN')}}
                </button>
                <button class="btn btn-accent btn-dark"
                (click)="onConfirm()"
                >
                {{getLabel('LINE_CONFIRM_BTN')}}
                </button>
            </div>
        </div>
    </div>
</div>