import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { ColorItem, UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IDomElementData, IMAGE_TYPE_ENUM, MENU_ENUM, OPTION_TYPE_ENUM } from 'src/app/core/models/common.model';
import { Menu } from 'src/app/core/models/interface-service.model';
import { Camera } from 'src/app/core/models/unreal-available-models.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';



@Component({
  selector: 'app-interior-configuration',
  templateUrl: './interior-configuration.component.html',
  styleUrls: ['./interior-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ trigger('openCloseAcc', [
    state('open', style({ 
        'height': '*'
      })),
    state('closed', style({ 
        'height': '0px'
      })),
    transition('open <=> closed', [
    animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
    ])]
 
})
export class InteriorConfigurationComponent implements OnInit {
  @Input() menu: Menu[]
  @Input() modelCode: string;
  @Input() currency: string
  @Input() priceMask: string;
  @Input() showPrices: boolean;
  @Input() options: UIOptItem[];
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != '') this.inputText = inputText.toLowerCase()
    else this.inputText = text
    this.buildVisibleSections()
  } 
  
  @Input() configId: string;
  @Input() optionsForAnalytics: string;
  @Input() dealerId: string;
  @Input() modelName: string;
  @Input() commercialName: string;
  @Input() standAloneSetInt = {}
  @Input() isMainScreen: boolean;
  @Input() currentState : string[]; 

  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()
  @Output() optionChangeEvent: EventEmitter<UIOptItem[]> = new EventEmitter()
  @Output() changeCameraEvt = new EventEmitter<Camera>()
  @Output() standAloneSetEditingIntEmitter: EventEmitter<{}> = new EventEmitter<{}>()

  standAloneSetEditingInt: {}
  inputText: string
  optcode: any;
  layerGroup: any;
  modelDetails: any;
  carDetails: any;
  colorList: any;
  intColors: any;
  family: string;
  colorActive: any = '';
  intProps: any;
  menuInterface: any;
  menuItems: Menu | undefined;
  interiorRadioActive: boolean = false;
  menuLabel: string = '';
  showVariantList = false;
  visibleSections: string[] = []
  accordionOpened = true;
  firstColorSelection = true;
  heightArray : any[] = [];
  setHeightDiv : number;

  
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;

  private frontCamera: Camera = {
    name: "MVI_Int_FrontSeat_Cam",
    location: "int"
  }
  private rearCamera: Camera = {
    name: "MVI_Int_BackDashboard_Cam",
    location: "int"
  }
  private domOptionElements: IDomElementData[] = [];

  constructor(
    private carConfigurationService: CarConfigurationService,
    private chg: ChangeDetectorRef,
    private el: ElementRef,
    public uiCommonService: UiCommonService,
    private mxeAnalyticsService: MxeAnalyticsService,
    private autobahn : AutobahnClient,
    private store : Store<MxeReducers>
  ) { }

  ngOnInit(): void {
    this.menuItems = this.menu.find(x => x.id === MENU_ENUM.INT)
    if (this.menuItems?.id) {
      this.menuLabel = this.menuItems?.id;
    }
    else {
      this.menuLabel = 'Interior';
    }
    this.buildVisibleSections()
    this.standAloneSetEditingInt = this.standAloneSetInt["standAloneSetEditingInt"] ?? {}
    this.currentState = ["open","open","open","open","open","open"];
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
  }


 

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes["standAloneSetInt"] && changes["standAloneSetInt"].currentValue != changes["standAloneSetInt"].previousValue && !this.isMainScreen){
      const newValue = changes["standAloneSetInt"].currentValue
      this.standAloneSetEditingInt = newValue["standAloneSetEditingInt"]
      
    }
   }

   ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }
  


  toggleAccordion(dataaccordion: string,i, e): void {
    e.stopPropagation && e.stopPropagation()
    this.accordionOpened = !this.accordionOpened

    this.currentState[i] = this.currentState[i]  === 'open' ? 'closed' : 'open';
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionState(this.ephemeralDeviceID,this.currentState,'int')
    }
    const boxes = [`#${dataaccordion}_header`, `#${dataaccordion}_content` , `#${dataaccordion}_wrapper`]

    boxes.forEach(box => {
      let myTag = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(myTag) {
        if(!myTag.classList.contains('closed')) {
          myTag.classList.add('closed');
        } else {
          myTag.classList.remove('closed');
        }
      }
    })
 }

  /**
   * Returns if the section is visible or not
   * @param sectionId 
   * @returns 
   */
  sectionIsVisible(sectionId: string): boolean {
    return this.visibleSections.includes(sectionId)
  }

  /**
   * Returns if the tab is visible or not
   * @param tabId 
   * @returns 
   */
  tabIsVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }

  /**
   * Call the configuration service in order to have a path of the option image with the given color
   * @param group - Option's group
   * @param optionId - Option ID
   * @param color - Color Code (Optional)
   * @returns 
   */
  getImagePath(group: string, optionId: string, color = ''): string {
    return this.carConfigurationService.getImagePath(this.modelCode, group, optionId, IMAGE_TYPE_ENUM.MENU_ICON, color)
  }

  /**
   * Translates labels
   * @param id 
   * @param type 
   * @param color 
   * @returns 
   */
  getLabel(id: string, type = '', color = '') {
    return this.uiCommonService.getLabel(id, type, color, this.modelCode);
  }

  changeConfiguration(id: string, sectionId: string, color: string, price = '', isChecked = false) {
    if (!color) {
      const newStatus = isChecked ? '0' : '1'
      const res = this.carConfigurationService.checkConflicts(id, newStatus)
      if (res.changes.toRemove.length > 0 || res.changes.toAdd.length > 0) {
        this.changeConfigurationEmitter.emit(res.changes)
      }
    } else {
      this.showVariantList = true;
      this.carConfigurationService.updateColor(id, color)
      if(this.firstColorSelection) {
        this.changeSelectedVariant(sectionId);
        this.firstColorSelection = false;
      }
    }
    this.chg.detectChanges();
  }

  /**
   * Returns all options for a given section
   * @param sectionId 
   * @returns 
   */
  getOptionsInSection(sectionId: string, filter_fuoriserie: string): UIOptItem[] {
    if(filter_fuoriserie != null) {
      if (filter_fuoriserie === '1') {
        const fuoriserie_options = this.options.filter(o =>
          o.type === OPTION_TYPE_ENUM.OPTION
          && o.onlyImplicit === false
          && o.section === sectionId
          && o.fuoriserie === true
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  
        const options_with_fuoriserie_colors = this.options.filter(o =>
          o.type === OPTION_TYPE_ENUM.OPTION
          && o.onlyImplicit === false
          && o.section === sectionId
          && o.fuoriserie === false
          && o.colorList.length > 0
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  
        return fuoriserie_options.concat(options_with_fuoriserie_colors)
      }
      return this.options.filter(o =>
        o.type === OPTION_TYPE_ENUM.OPTION
        && o.onlyImplicit === false
        && o.section === sectionId
        && o.fuoriserie === false
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
    }
    return  this.options.filter(o => o.type === OPTION_TYPE_ENUM.OPTION && o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  }

  isTabVisible(tabId: string): boolean{
    return this.options.some(x => x.group === tabId && x.status.visible) 
  }

  /**
   * Returns all radios for a given section
   * @param sectionId 
   * @returns 
   */
  getRadiosInSection(sectionId: string, filter_fuoriserie: string, selectable: boolean = true ): UIOptItem[] {
    if (filter_fuoriserie === '1') {
      const fuoriserie_options = this.options.filter(o =>
        o.type === OPTION_TYPE_ENUM.RADIO
        && o.onlyImplicit === false
        && o.section === sectionId
        && o.fuoriserie === true
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))

      const options_with_fuoriserie_colors = this.options.filter(o =>
        o.type === OPTION_TYPE_ENUM.RADIO
        && o.onlyImplicit === false
        && o.section === sectionId
        && o.fuoriserie === false
        && o.colorList.length > 0
        && o.colorList.filter(c => c.fuoriserie === true).length > 0
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))

      return fuoriserie_options.concat(options_with_fuoriserie_colors).filter(o => o.status.selectable == selectable && !o.status.selected)
    }

    var ret = this.options.filter(o =>
      o.type === OPTION_TYPE_ENUM.RADIO
      && o.onlyImplicit === false
      && o.section === sectionId
      && o.fuoriserie === false
      && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)
      && o.status.selectable == selectable
      && !o.status.selected
    )
    return ret;
  }

  getSelectedVariant(sectionId: string, filter_fuoriserie: string) {
    const variant = this.options.find(o =>
      o.type === OPTION_TYPE_ENUM.RADIO
      && o.onlyImplicit === false
      && o.section === sectionId
      && o.fuoriserie === (filter_fuoriserie === '1')
      && o.status.selected === true
      && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))

    return variant ? this.getLabel(variant?.id, 'opt_name') : ''
  }

  sectionHasVariants(sectionId: string, filter_fuoriserie: string) {
    return this.options.filter(o =>
      o.type === OPTION_TYPE_ENUM.RADIO
      && o.onlyImplicit === false
      && o.section === sectionId
      && o.fuoriserie === (filter_fuoriserie === '1')
      && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }

  changeSelectedVariant(sectionId) {
    this.showVariantList = !this.showVariantList;
    this.standAloneSetEditingInt[`${sectionId}`] = !this.standAloneSetEditingInt[`${sectionId}`]
    this.standAloneSetEditingIntEmitter.emit({ standAloneSetEditingInt: this.standAloneSetEditingInt})
  }

  getCheckboxInSection(sectionId: string, filter_fuoriserie: string): UIOptItem[] {
    if (filter_fuoriserie === '1') {
      const fuoriserie_options = this.options.filter(o =>
        o.type === OPTION_TYPE_ENUM.CHECKBOX
        && o.onlyImplicit === false
        && o.section === sectionId
        && o.fuoriserie === true
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))

      const options_with_fuoriserie_colors = this.options.filter(o =>
        o.type === OPTION_TYPE_ENUM.CHECKBOX
        && o.onlyImplicit === false
        && o.section === sectionId
        && o.fuoriserie === false
        && o.colorList.length > 0
        && o.colorList.filter(c => c.fuoriserie === true).length > 0
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))

      return fuoriserie_options.concat(options_with_fuoriserie_colors)
    }
    return this.options.filter(o =>
      o.type === OPTION_TYPE_ENUM.CHECKBOX
      && o.onlyImplicit === false
      && o.section === sectionId
      && o.fuoriserie === false
      && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)
    )
  }

  activateOption() {
    if (this.interiorRadioActive != false) {
      this.interiorRadioActive = false
    }
    else {
      this.interiorRadioActive = true
    }
  }

  formatPrice(price: string) {
    return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
  }

  /**
   * Gets colors taking care of the 'fuoriserie' property
   * @param colors 
   * @param sectionId 
   * @returns 
   */
  getColors(optionId: string, colors: ColorItem[], filter_fuoriserie: string): ColorItem[] {
    return colors.filter(c => c.fuoriserie === (filter_fuoriserie === '1') && this.uiCommonService.applySearchFilterOnColor(optionId, c.colIdent, this.inputText, this.modelCode))
  }

  /**
   * Gets user's selections for every visible section
   * @param tabId the option's group, userd to filter selection in every group
   * @returns 
   */
  getSelectedOptionsDetails(tabId: string): string[] {
    let result: string[] = []
    this.options.filter(o => o.status.selected === true).forEach(option => {
      if (this.visibleSections.includes(option.section) && option.group === tabId) {
        if (option.colorList.length > 0) {
          let selectedColor = option.colorList.find(c => c.selected)
          if (selectedColor) {
            let formattedPrice = ''
            if (+selectedColor.price > 0) {
              formattedPrice = ' - ' + this.formatPrice(selectedColor.price)
            } 
            const detail = this.showPrices
              ? `<span class="selected-price-details">${this.getLabel(option.section)}:</span> ${this.getLabel(option.id, 'opt_color', selectedColor.colIdent)}<span class="selected-price-details">${formattedPrice}</span>`
              : `<span class="selected-price-details">${this.getLabel(option.section)}:</span> ${this.getLabel(option.id, 'opt_color', selectedColor.colIdent)}`
            result.push(detail)
          }
        } else {
          let formattedPrice = ''
          if (+option.price > 0) {
            formattedPrice = ' - ' + this.formatPrice(option.price)
          } 

          const detail = this.showPrices
            ? `<span class="selected-price-details">${this.getLabel(option.section)}:<span> ${this.getLabel(option.id, 'opt_name')}<span class="selected-price-details">${formattedPrice}</span>`
            : `<span class="selected-price-details">${this.getLabel(option.section)}:<span> ${this.getLabel(option.id, 'opt_name')}`
          if(!result.includes(detail))
            result.push(detail)
        }
      }
    })
    return result
  }

  public changeCamera(e, id: string | null = null) {
    e.preventDefault() && e.preventDefault
    e.stopPropagation() && e.stopPropagation
    const camera = id == 'TRIM' ? this.frontCamera : this.rearCamera
    this.changeCameraEvt.emit(camera)
  }

  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string = '', action: string = ''): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }

  private buildVisibleSections() {
    this.menuItems?.tabs.forEach(tab => {
      tab.sections.forEach(section => {
        if(section.filter_fuoriserie != null) {
          if (section.filter_fuoriserie === '1') {
            const fuoriserie_options = this.options.filter(o => o.section === section.id && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
            const options_with_fuoriserie_colors = this.options.filter(o =>
              o.section === section.id
              && o.onlyImplicit === false
              && o.fuoriserie === false
              && o.colorList.length > 0
              && o.colorList.filter(c => c.fuoriserie === true).length > 0
              && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
            if (fuoriserie_options.concat(options_with_fuoriserie_colors).length > 0) {
              if (this.visibleSections.includes(section.id) === false)
                this.visibleSections.push(section.id)
            }
          } else {
            if (this.options.filter(o => o.section === section.id && o.fuoriserie === false && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
              if (this.visibleSections.includes(section.id) === false)
                this.visibleSections.push(section.id)
            }
          }
        } else {
          if (this.options.filter(o => o.section === section.id && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
            if (this.visibleSections.includes(section.id) === false)
              this.visibleSections.push(section.id)
          }
        }
      })
    })
  }

  private toElementData(o: UIOptItem): any {
    const domElem = document.getElementById(o.id)
    if(domElem){
      this.domOptionElements.push({
        elementIndex: -1,
        id: o.id,
        offTop: domElem.offsetTop,
        offHeight: domElem.offsetHeight
      })
    }
  }
}