import { Action, createReducer, on } from '@ngrx/store';
import { MENU_LOADED } from '../actions/menu-actions';
import { menuInitialState } from '../initials/menu-initial-state';

const _menuReducer = createReducer(menuInitialState,
    on(MENU_LOADED, (state, payload) => ({
        ...state,
        menuItems: payload.menuItems
    }))
);

export function menuReducer( state = menuInitialState, action: Action){
    return _menuReducer(state, action)
}