import { Menu } from '../../models/interface-service.model'

export const menuInitialState: MenuState = {
    menuItems: []
}

export interface MenuState {
    menuItems: Menu[]
}

