import { createAction, props } from '@ngrx/store';
import { LoadConfigurationResult } from '../../models/load-conf-service.model';

export const CONFIGURATION_LOADED = createAction(
    'CONFIGURATION_LOADED',
    props<{ config: LoadConfigurationResult }>()
)
export const RESET_CONFIGURATION_LOADED = createAction(
    'RESET_CONFIGURATION_LOADED'
)
