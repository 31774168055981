import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mxe-loading-services-page-tv',
  templateUrl: './loading-services-page-tv.component.html',
  styleUrls: ['./loading-services-page-tv.component.scss']
})
export class LoadingServicesPageTvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
