import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss']
})
export class DisclaimerModalComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  
  isModalDisclaimerOpen: boolean = false;
  modalStatus: boolean;
  constructor(
    private uiCommonService: UiCommonService
    ) { }

  ngOnInit(): void {
  }
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

  closeModal() {
    this.modalStatus = false;
    this.valueChange.emit(this.modalStatus);
  }


}
