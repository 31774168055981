function formatTime(date) {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
}

export function logSubscription(topic: string, payload: any) {
  if (localStorage.getItem('pairing_debugging')) {
  const formattedTime = formatTime(new Date());
  console.log(`%cSubscription: ${topic}, Time: ${formattedTime}, Payload:`, 'color: cyan;', payload);}
}

export function logPublication(topic: string, payload: any) {
  if (localStorage.getItem('pairing_debugging')) {
    const formattedTime = formatTime(new Date());
    console.log(`%cPublication: ${topic}, Time: ${formattedTime}, Payload:`, 'color:cyan', payload);
  }
}
