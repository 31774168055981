<div class="mxe__video-modal">
    <div class="heading">
        <h2>Options</h2>
        <button class="mxe__close-action" (click)="closeModal()"></button>
    </div>
    <div class="overscroll">
        <div class="scrollable-div">
            <div class="mxe__iframe-box">
                <iframe [src]="getVideoUrl()" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="title-and-description">
                <h3>
                    {{videoTitle}}
                </h3>
                <div class="descr">
                    {{videoDescription}}
                </div>
            </div>
        </div>
    </div>

</div>