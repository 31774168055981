import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class SentryHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.debug('intercepting ', req.method, req.url)
    
    const startTime = performance.now();
    const transaction = Sentry.startTransaction({ name: `HTTP ${req.method} ${req.url}` });

    return next.handle(req).pipe(
      catchError(error => {
        transaction.setStatus('error');
        transaction.setTag('error', 'true');
        transaction.setTag('http.error', error.message || 'Unknown error');
        throw error;}),
      finalize(() => {
        const duration = performance.now() - startTime;
        transaction.setTag('domain', new URL(req.url).hostname);
        transaction.setMeasurement('duration', duration, 'milliseconds');
        transaction.finish();
      })
    );
  }
}
