import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-changed-configuration-bar',
  templateUrl: './changed-configuration-bar.component.html',
  styleUrls: ['./changed-configuration-bar.component.scss']
})
export class ChangedConfigurationBarComponent {
  @Output() changedConfigurationEmitter = new EventEmitter()
  constructor(private uiCommonService: UiCommonService) { }

  viewDetails() {
    this.changedConfigurationEmitter.emit()
  }

  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }
}
