import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { CONFIGURATION_LOADED, RESET_CONFIGURATION_LOADED } from '../actions/configuration-actions';

@Injectable()
export class ConfigurationEffects {

    constructor(private actions$: Actions) { }

    ConfigurationState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(CONFIGURATION_LOADED),
                tap(actions => localStorage.setItem('config', JSON.stringify(actions.config))),   
            ),
        { dispatch: false }
    );

    ResetConfigurationState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(RESET_CONFIGURATION_LOADED),
                tap(actions => localStorage.removeItem('config')),   
            ),
        { dispatch: false }
    );
}