<div class="mxe__fallback2D-toaster">
    <img class="warning-icon" src="../../../assets/icons/mxe_ico-warning.svg" alt="warning">
    <div class="whatever">
        <div class="ccb-config-title">3D VIEWER IS NOT AVAILABLE</div>
        <div class="ccb-toggle-config-modal"
        data-linktracking="cta:view-details">YOU CAN CONTINUE CONFIGURING YOUR CAR IN 2D</div> 
    </div>
    <button type="button" class="btn btn-x" (click)="closeToaster()" >
        <img class="close-icon" src="../../../assets/icons/mxe_ico-close.svg" alt="close" >
    </button>
</div>