import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MxeReducers } from "../store/reducers";
import { Observable, Subject, Subscription, distinctUntilChanged, filter, interval, map, switchMap } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})

export class JwtService {
    private jwtExpirationObservable: Observable<any>;
    private routeObservable: Observable<any>;
    private jwtExpirationSubscription$: Subscription;
    private checkExpirationInterval$: Subscription;
    private isInitialized: boolean = false;


    public jwtTokenExpired: Subject<boolean> = new Subject<boolean>()

    constructor(private store: Store<MxeReducers>, private router: Router) {
        this.routeObservable = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        this.jwtExpirationObservable = this.store.select(s => s.userState.jwt_token_expiration_time)
        .pipe(
            filter((jwt_expiration: number) => jwt_expiration > 0), //Filter first initialization
            distinctUntilChanged() //fires only when the value of jwt expiration change
        );

        this.jwtExpirationSubscription$ = this.jwtExpirationObservable.pipe(
            switchMap(jwt_expiration => this.routeObservable.pipe(
                filter(route => !route.url.includes('presenter-screen')),
                map(route => [route, jwt_expiration])
            ))
        ).subscribe(([route, jwt_expiration]) => {
            this.initializeService(jwt_expiration)
        })
    }

    private initializeService(jwt_expiration: number) {
        if(this.isInitialized) return;
        this.jwtTokenExpired.next(false);
        this.checkExpirationInterval$ = interval(10000).subscribe(() => {
            if (new Date().getTime() > jwt_expiration * 1000) {
                console.debug("[JWT_SERVICE]: Authentication expired...")
                this.jwtTokenExpired.next(true)
                this.checkExpirationInterval$.unsubscribe()
            } else {
                console.debug("[JWT SERVICE]: Authentication token is still valid...")
                this.jwtTokenExpired.next(false);
            }
        })
        this.isInitialized = true;
        console.log('[JWT_SERVICE]: Initialized')
    }


}