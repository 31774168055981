<div class="mxe__home-menu-wrapper">
  <button type="button" class="btn btn-x" data-linktracking ="side-nav:preferences:close" (click)="homeMenuChangeStatusEvent()">
    <img src="../../../assets/icons/mxe__ico-exit-menu.svg" alt="X" />
  </button>
  <div *ngIf="showStreamingModeInfo()" class="streaming-mode-info">
    <img [src]="getStreamingModeIcon()" />
    {{ getLabel('MXE_STREAMING_MODE_INFO','You are using') }}&nbsp;<strong>{{getStreamingModeInfo()}}</strong>
  </div>
  <div style="display: flex; flex-direction: column; position: absolute; color: white; opacity: 0.5; top: 90px; gap: 10px;" *ngIf="monkeyWaySessions">
    <div *ngFor="let session of monkeyWaySessions">
      {{session.ipAddress}}
    </div>
  </div>
  <div *ngIf="arePricesAvailable" class="switch-button-home" [ngClass]="{'pricesEnabled': priceStatus}" >
    <label class="switch">
      <input type="checkbox" />
      <span class="slider round" (click)="togglePrices()" [attr.data-linktracking]="!priceStatus ? 'side-nav:preferences:prices:yes' : 'side-nav:preferences:prices:no'"></span>
    </label>
    Prices
  </div>
  <!--<img class="logo-maserati" data-linktracking="side-nav:preferences:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />-->
  
  <div class="mxe__menu-items-container gradient-line-container">
    <div class="mxe__custom--accordion gradient-line" >
      <div class="mxe__custom--accordion-heading" (click)="toggleAccordion('accordion-dealers', $event,0)"
      [ngClass]="{'is-expanded' : accordionOpened['accordion-dealers']}">
        <div class="mxe__home-elements dealers-accordion">
          <div class="mxe__home-elements-item">
            <img class="icon-menu" src="../../../assets/icons/mxe__ico-user_grey.svg" alt="open-dealers-accordion" />
            <span class="mxe__custom--accordion-heading--title">
              Dealer ID: <span class="dealer-code">{{selectedDealer.dealer_code}}</span>
            </span>
          </div>
          <div class="mxe__home-elements-item">
            <span class="mxe__custom--accordion-heading--dealer-info">
              <span [ngClass]="{'active' : selectedDealer.cloud_enabled }">cloud</span>&nbsp;<span class="circle" [ngClass]="{'active' : selectedDealer.cloud_quota > 0 }">{{selectedDealer.cloud_quota}}</span> 
              <span>|</span>
              <span [ngClass]="{'active' : selectedDealer.onprem_enabled }">on premise</span>&nbsp;<span class="circle"  [ngClass]="{'active' : selectedDealer.onprem_quota > 0 }">{{selectedDealer.onprem_quota}}</span>
            </span>
            <span [ngClass]="{'mxe__dropdown-arrow' : selectableDealers.length > 0}" ></span>
          </div>
        </div>
      </div>
      <div class="mxe__custom--accordion-content" data-accordion="accordion-dealers"
        [ngClass]="{'active' : accordionOpened['accordion-dealers']}"
        [@openCloseAcc]="currentState[0]">
        <div class="mxe__lang-list">
          <div class="available-languages" *ngFor="let dealer of selectableDealers">
            <button type="button" class="btn btn-transparent dealer-item" 
              (click)="onSelectDealer(dealer.dealer_code,0)">
              <!-- {{dealer.dealer_code}} -->
              <div class="mxe__home-elements-item">
                <span class="mxe__custom--accordion-heading--title">
                  {{dealer.dealer_code}}
                </span>
              </div>
              <div class="mxe__home-elements-item">
                <span class="mxe__custom--accordion-heading--dealer-info">
                  <span [ngClass]="{'active' : dealer.cloud_enabled }">cloud</span>&nbsp;<span class="circle" [ngClass]="{'active' : dealer.cloud_quota > 0 }">{{dealer.cloud_quota}}</span> 
                  <span>|</span>
                  <span [ngClass]="{'active' : dealer.onprem_enabled }">on premise</span>&nbsp;<span class="circle"  [ngClass]="{'active' : dealer.onprem_quota > 0 }">{{dealer.onprem_quota}}</span>
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
  </div>
     <div class="mxe__custom--accordion gradient-line" >
        <div class="mxe__custom--accordion-heading" (click)="toggleAccordion('accordion-languages', $event,1)"
        [ngClass]="{'is-expanded' : accordionOpened['accordion-languages']}">
        <div class="mxe__home-elements">
          <img class="icon-menu" src="../../../assets/icons/mxe__ico-language.svg" alt="open-languages-accordion" />
          <span class="mxe__custom--accordion-heading--title">
            {{getLabel(selectedLanguageId)}}
          </span>
          <span [ngClass]="{'mxe__dropdown-arrow' : languagesList.length > 0}" ></span>
        </div>
        </div>
        <div class="mxe__custom--accordion-content" data-accordion="accordion-languages"
          [ngClass]="{'active' : accordionOpened['accordion-languages']}"
          [@openCloseAcc]="currentState[1]"
        >
          <div class="mxe__lang-list">
            <div class="available-languages" *ngFor="let languageId of languagesList">
              <button type="button" class="btn btn-transparent lang" 
                [attr.data-linktracking]="'side-nav:preferences:languages:'+ normalizeForAnalytics(languageId)"
                [ngClass]="{'lang-active' : languageId === selectedLanguageId}" (click)="onSelectLanguage(languageId,1)">
                {{getLabel(languageId)}}
              </button>
            </div>
          </div>
        </div>
    </div>
    <div class="btn btn-transparent gradient-line" [attr.data-linktracking]="'side-nav:preferences:guidlines'" (click)="goToUserGuidlinesPage()" >
     <img class="icon-menu" style="opacity: 0.5" src="../../../assets/icons/mxe__ico-openExternal.svg"/>
     {{getLabelWithDefaultValue('USER_GUIDLINES','GUIDELINES')}}
    </div>
    <button
      type="button"
      class="btn btn-transparent"
      (click)="logout()"
      data-linktracking ="side-nav:preferences:logout">
      <img class="icon-menu" src="../../../assets/icons/mxe__undo.svg" alt="logout" />
      {{getLabelWithDefaultValue('RESET','RESET')}}
    </button>
  </div>
  <div class="country">
    <button
    type="button"
    [disabled]="!countrySelectorEnabled"
    class="btn btn-transparent"
    (click)="modalCountryOpen()">
    <img class="icon-country" src="../../../assets/icons/mxe__ico-change-country.svg" alt="country" />
    <span data-linktracking ="side-nav:preferences:countries:text">{{selectedCountry.description}}</span>
    <!--<div class="country-active {{selectedCountry.isoCode2.toLowerCase()}}" data-linktracking ="side-nav:preferences:countries:icon"></div>-->
    </button>
  </div>
</div>

<div class="switch-country-prod">
  <button
    type="button"
    class="btn btn-transparent"
    (click)="modalCountryOpen()">
    <img class="icon-country" src="../../../assets/icons/mxe__ico-change-country.svg" alt="country" />
    <span data-linktracking ="side-nav:preferences:countries:text">{{selectedCountry.description}}</span>
  </button>
</div>


<mxe-country-selector 
  (modalCountryStatus)="modalCountryStatusChange($event)"
  [isModalCountryOpen]="isModalCountryOpen"
  [countries]="countries"
  [selectedCountry]="selectedCountry">
</mxe-country-selector>

<div class="modal-content-confirmation" *ngIf="showConfirmSwitchLanguage">
  <div class="padd">
      <h1>{{getLabel('MXE_CONFIRM_MODAL_TITLE', 'Are you sure?')}}</h1>
      <div class="text-diclaim">
        {{getLabel('MXE_CONFIRM_LANGUAGE_MODAL_DESCRIPTION', 'If you change the language your configuration will be restarted')}}
      </div>
      <div class="mxe__selection-buttons">
          <button type="button" class="btn btn-transparent btn-dark btn-bordered" (click)="onCancelSwitchLanguage();">
              {{getLabel('LINE_CANCEL_BTN')}}
          </button>
          <button type="button" class="btn btn-accent" (click)="onConfirmSwitchLanguage();" >
              {{getLabel('LINE_CONFIRM_BTN')}}
          </button>
      </div>
  </div>
</div>
