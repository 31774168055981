
import { createAction, props } from '@ngrx/store';
import { UnrealState } from 'src/app/core/models/get-scene-state';

export const UPDATE_UNREAL_DESIRED_SCENE_STATE = createAction(
    '[AutobahnClient] UPDATE_UNREAL_DESIRED_SCENE_STATE',
    props<{
        desired_unreal_state: UnrealState
    }>()
)

export const UPDATE_CONFIG_FOR_SCENE = createAction(
    '[CarConfigurationService] UPDATE_CONFIG_FOR_SCENE',
    props<{
        config: string[]
    }>()
)

export const RESET_CONFIG_FOR_SCENE = createAction(
    '[HomeComponent] RESET_CONFIG_FOR_SCENE',
)

export const UPDATE_MODEL_SELECTED = createAction(
    'UPDATE_MODEL_SELECTED',
    props<{
        modelCode: string
    }>()
)
export const UPDATE_DAY_TIME = createAction(
    'UPDATE_DAY_TIME',
    props<{
        daytime: string
    }>()
)
export const UPDATE_ENVIRONMENT = createAction(
    'UPDATE_ENVIRONMENT',
    props<{
        environment: string
    }>()
)
export const UPDATE_CAMERA = createAction(
    '[CameraEffects] UPDATE_CAMERA',
    props<{
        camera: string
    }>()
)
export const UPDATE_COUNTRY = createAction(
    '[SIDE_EFFECT_SWITCH_COUNTRY] UPDATE_COUNTRY',
    props<{
        country: string
    }>()
)
export const UPDATE_LANGUAGE = createAction(
    '[SIDE_EFFECT_APP_INIT] UPDATE_LANGUAGE',
    props<{
        language: string
    }>()
)
export const UPDATE_ANIMATION_STATE = createAction(
    'UPDATE_ANIMATION_STATE',
    props<{
        animation_states: {}
    }>()
)
export const UPDATE_CURRENT_STATE = createAction(
    'UPDATE_CURRENT_STATE',
    props<{
        current_state: string
    }>()
)
export const UPDATE_PRESENTER_SCREEN_STATE = createAction(
    'UPDATE_PRESENTER_SCREEN_STATE',
    props<{
        presenterScreen : boolean
    }>()
)
export const UPDATE_FULL_SCREEN_STATE = createAction(
    'UPDATE_FULL_SCREEN_STATE',
    props<{
        fullscreen : boolean
    }>()
)
export const RESET_SCENE_STATE = createAction(
    '[DESTROY CAR CONF WRAPPER] RESET_SCENE_STATE',
)
export const UPDATE_HOTSPOTS_STATE = createAction(
    'UPDATE_HOTSPOTS_STATE',
    props<{
        hotspots: boolean
    }>()
)
export const UPDATE_VIEW_UI_STATE = createAction(
    'UPDATE_VIEW_UI_STATE',
    props<{
        viewUi: boolean
    }>()
)
export const UPDATE_XRAY_STATE = createAction(
    'UPDATE_XRAY_STATE',
    props<{
        xrayEnabled: boolean
    }>()
)
