import { Action, createReducer, on } from '@ngrx/store';
import { CTAS_LOADED, LABELS_LOADED } from '../actions/country-prop-actions';
import { countryPropInitialState } from '../initials/country-prop-initial-state';

const _countryPropReducer = createReducer(countryPropInitialState,
    on(CTAS_LOADED, (state, payload) => ({
        ...state,
        ctas: payload.ctas
    })),
    on(LABELS_LOADED, (state, payload) => ({
        ...state,
        labels: payload.labels
    }))
);

export function countryPropReducer( state = countryPropInitialState, action: Action){
    return _countryPropReducer(state, action)
} 