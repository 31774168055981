import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FallbackCameraCarouselComponent } from '../fallback-camera-carousel/fallback-camera-carousel.component';
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';

@Component({
  selector: 'app-fallback-interactive-bottom-bar',
  templateUrl: './fallback-interactive-bottom-bar.component.html',
  styleUrls: ['./fallback-interactive-bottom-bar.component.scss']
})
export class FallbackInteractiveBottomBarComponent implements OnInit {
  @ViewChild(FallbackCameraCarouselComponent) cameraCarousel: FallbackCameraCarouselComponent

  @Input() swiperImages: any[];
  @Output() onSelectCamera: EventEmitter<number> = new EventEmitter<number>();
  @Output() onFullScreenToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onStreamingConnect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cameraIndex: number = 0;

  fullScreenIconActive = false;
  showCameraCarousel = false;

  constructor(private store: Store<MxeReducers>) { }

  ngOnInit(): void {
  }

  onCameraSelected(index: number) {
    this.cameraIndex = index;
    this.onSelectCamera.emit(index);
  }

  nextCamera(){
    if(this.cameraIndex + 1 < this.swiperImages.length) {
      this.cameraIndex++;
      this.showCameraCarousel = false;
      this.onSelectCamera.emit(this.cameraIndex)
    }
  }

  prevCamera(){
    if(this.cameraIndex - 1 >= 0) {
      this.cameraIndex--;
      this.showCameraCarousel = false;
      this.onSelectCamera.emit(this.cameraIndex)
    }
  }

  fullScreenToggle() {
    this.fullScreenIconActive = !this.fullScreenIconActive;
    this.onFullScreenToggle.emit(this.fullScreenIconActive)
    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_FULL_SCREEN_STATE({
      fullscreen: this.fullScreenIconActive
    }))
  }

  onStreamingConnection(){
    this.onStreamingConnect.emit()
    console.log('connecting to stream')
  }
}
