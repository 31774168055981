import { Action, createReducer, on } from '@ngrx/store';
import { SET_AUTHORIZATION_CODE, RESET_AUTHORIZATION_TOKEN, SET_DEALER_ID, RESET_DEALER_ID, SET_ACCESS_TOKEN, SET_DEALERS_INFO, SET_MACHINES_INFO } from '../actions/users/user-actions';
import { userInitialState } from '../initials/user-initial-state';

const _userReducer = createReducer(userInitialState,
  on(SET_AUTHORIZATION_CODE, (state, payload) => ({
    ...state,
    jwt_token: payload.jwt_token,
    jwt_token_expiration_time: payload.jwt_token_expiration_time,
    authorization_code: payload.authorization_code
  })),
  on(RESET_AUTHORIZATION_TOKEN, (state, payload) => ({
    ...state,
    jwt_token: '',
    jwt_token_expiration_time: 0,
    access_token: '',
    access_token_expiration_time: 0
  })),
  on(SET_DEALER_ID, (state, payload) => ({
    ...state,
    dealerId: payload.dealerId
  })),
  on(RESET_DEALER_ID, (state, payload) => ({
    ...state,
    dealerId: ''
  })),
  on(SET_ACCESS_TOKEN, (state, payload) => ({
    ...state,
    access_token: payload.access_token,
    access_token_expiration_time: payload.access_token_expiration_time,
    authorization_token: ''
  })),
  on(SET_DEALERS_INFO, (state, payload) => ({
    ...state,
    dealersInfo: payload.dealersInfo
  })),
  on(SET_MACHINES_INFO, (state, payload) => ({
    ...state,
    machinesInfo: payload.machinesInfo
  }))
);

export function userReducer(state = userInitialState, action: Action) {
  return _userReducer(state, action);
}
