import * as pako from 'pako'

function base64ToUint8Array(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export function decompressObject(base64): any | any[] {
    const compressed = base64ToUint8Array(base64);
    const decompressed = pako.inflate(compressed, { to: 'string' });
    return JSON.parse(decompressed);
}


export function compressObject(obj) {
    const jsonString = JSON.stringify(obj);
    const compressed = pako.deflate(jsonString);
    return uint8ArrayToBase64(compressed);
}

function uint8ArrayToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

