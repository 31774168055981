//IF it's needed to change endpoint on dev services,
//the correct variable that should to be change are below with this value:
// maserati_services_endpoint: 'https://configurator-dev.maserati.com'
// image_repo_url: 'https://configurator-dev.maserati.com/ccbe/public/api/imageMapper/repo'

export const environment = {
  environment: 'develop',
  develop: true,
  production: false,
  maserati_services_endpoint: 'https://www-test.configurator.maserati.com',
  maserati_instock_endpoint: 'https://www-rc.maserati.com',
  autobahn_configuration: {
    broker_endpoint: 'wss://mxe-dev.maserati.com/ws',
    realm: 'realm1',
    max_retries: 5,
    heartbeat: {
      heartBeatTimer: 5000,
      heartbeat_max_retries: 4,
      heartbeat_retry_after: 5000
    },
    pairing_streaming_session_retry_after: 5000,
    pairing_not_compressed_message_max_size: 1000
  },
  authentication: {
    maserati_authentication_endpoint: 'https://sts-dev.fiat.com/adfs/oauth2',
    client_id: 'a6a6ca8f-1a25-4d04-bb2f-cf1e695eade9',
    resource: 'MXE',
    redirect_uri: 'https://mxe-dev.maserati.com/mxe/oauth/redirecturi',
  },
  monkeyWay: {
    idleTime: 300, //seconds
    localStreamingOptions: {
      baseUrl: 'https://general.gorillastreaming.com',
      appEnvId: '406f3367-4ac0-4195-b273-60ebc2938003',
      apiKey: "" 
    }
  },
  homepage_url: 'https://mxe-dev.maserati.com',
  image_repo_url: 'https://configurator-test.maserati.com/ccbe/public/api/imageMapper/repo',
  enable_monkey_way_streaming: true,
  photocopier_endpoint: 'https://ph-test.cloud.maserati.com',
  country_selector_enabled: true,
  maseratiWsEndpoint: 'https://ws-test.maserati.com',
  endpointsaveWTLjson : 'https://wtl-cdntest.maserati.com/wtl/saveWTLjson.jsp',
  endpointModelsOfInterest: 'https://wtl-cdntest.maserati.com/wtl//jsonGenericLov.jsp',
  sentry_configuration: {
    dsn: "https://dee2c471d677d4afc5eb5826ef0f3d4e@o4508046702477312.ingest.de.sentry.io/4508160752681041",
    tracePropagationTargets: [/^https:\/\/mxe-dev.maserati.com(\/.*)?$/, /^https:\/\/\w{8}\.develop\.local\.workers\.mxe-backend\.com(\/.*)?$/],
    replaysSessionSampleRate: 0.1,
    environment: 'development',
    debug: false
  }

};