<div id="{{menuLabel}}" class="mxe__exterior-config-panel accordion-{{menuLabel}} active">
  <!--PRIMO HEADER-->
  <div id="{{menuLabel}}_header" class="mxe__optionals--section-header">
    <div class="header-title" (click)="toggleAccordion(menuLabel,0,null, $event)"
    [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + menuLabel.toLocaleLowerCase()+ [accordionOpened ? ':close' : ':open']">
      <span class="header-text">
        {{getLabel(menuLabel).toLocaleLowerCase()}}
      </span>
      <div class="header-icons">
        <span class="toggle-arrow"></span>
      </div>
    </div>
    <span class="selected-options-infos" *ngIf="totalOptSelected > 0" [innerHtml]="formatSelectionDetails()"></span>
  </div>
  <!--/PRIMO HEADER-->
  <!-- PRIMO CONTENT -->
  <div id="{{menuLabel}}_content" class="mxe__optionals--section-content" [@openCloseAcc]="currentState[0]">
    <div class="mxe__optional-config-wrapper">
      <ng-container *ngFor="let tab of visibleItems; let k = index">
        <div class="mxe__opt-wrapper">
          <!-- SECONDO HEADER -->
          <div class="mxe__exterior--accordion accordion-{{tab.id}} inner-header-cont active">
            <div id="{{tab.id}}_header" class="mxe__optionals--section-header sub-header">
              <div class="header-title" (click)="toggleAccordion(tab.id,null, k+1, $event)"
              [attr.data-linktracking]="'cta:configurator:accordion:'+ menuLabel.toLocaleLowerCase() + ':' + tab.id.toLocaleLowerCase() + [accordionOpened ? ':close' : ':open']">
                <span class="header-text">
                  {{getLabel(tab.id)}}
                </span>
                <div class="header-icons">
                  <span class="toggle-arrow minor"></span>
                </div>
              </div>
              <span class="selected-options-infos" [innerHtml]="getSelectedOptionDetails(tab.id)"></span>
            </div>
         
          <!-- /SECONDO HEADER -->
          <!-- SECONDO CONTENT -->
          <div id="{{tab.id}}_content" class="mxe__optionals--section-content sub-content" [@openCloseAcc]="currentState[k+1]">
            <div class="mxe__optional-config-panel" *ngFor="let section of tab.sections">
              <ng-container *ngIf="sectionIsVisible(section.id, section.filter_fuoriserie)">
                <span id="{{section.id}}" class="opt-details"
                  *ngIf="getLabel(section.id) != undefined || getLabel(section.id).length > 0 ">{{getLabel(section.id)}}</span>
                <div class="optional"
                  *ngFor="let acc of getOptionsBySection(section.id, section.filter_fuoriserie)"
                  id="{{acc.section+'_'+acc.id}}">
                  <div class="mxe__optional-image">
                    <button class="btn-opt-selection" [class.active]="acc.quantity && acc.quantity > 0"
                      [disabled]="!acc.status.selectable"
                      [ngStyle]="{'opacity' : !acc.status.selectable ? '0.5' : '1'}">
                      <span class="mxe__image-wrapper">
                        <img [src]="getImagePath(acc.group, acc.id)" />
                        <div class="accessories-counter">
                          {{acc.quantity}}
                        </div>
                      </span>
                    </button>
                  </div>
                  <div class="mxe__optional-details">
                    <div class="opt_title" [ngStyle]="{'opacity' : !acc.status.selectable ? '0.5' : '1'}">{{ getLabel(acc.id, uiCommonService.LABEL_TYPE_ENUM.NAME) }}</div>
                    <div class="opt_price" *ngIf="showPrices" [ngStyle]="{'opacity' : !acc.status.selectable ? '0.5' : '1'}">{{ this.formatPrice(acc.price) }}</div>
                    <div class="mxe__btn-add-remove" *ngIf="acc.status.labelKey != 'ACC_INCLUDED_AS_MUST'">
                      <button class="mxe__btn-remove"
                        [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, acc.id, '', 'remove')"
                        (click)="removeAccessories(acc.id, acc.quantity)"
                        [disabled]="!acc.quantity || acc.quantity == 0"></button>
                      <button class="mxe__btn-add"
                        [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, acc.id, '', 'add')"
                        (click)="addAccessories(acc.id, acc.quantity)" [disabled]="!acc.status.selectable"></button>
                    </div>
                    <div class="btn-actions" *ngIf="acc.status.labelKey === 'ACC_INCLUDED_AS_MUST'">
                      <span class="included" [ngStyle]="{'opacity' : !acc.status.selectable ? '0.5' : '1'}">{{getLabel('ACC_INCLUDED_AS_MUST')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        </div>
      </ng-container>
      <div class="section-disclaimer">
        {{ getLabel('ACCESSORIES_DISCLAIMER_DESCR') }}
      </div>
    </div>
  </div>
</div>