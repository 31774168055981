<div class="mxe__afk-modal-wrapper" 
     *ngIf="!streamingAvailable && envName === 'cloud' || 
     (streamingAvailable && envName === 'cloud' && !isCinematicActiveForSelectedTrim)">
    <div class="modal-backdrop"></div>
    <div class="mxe__afk-modal-content">
        <div class="modal-padd">
            <h2>{{getLabel('MXE_IDLE_BOX_TITLE')}} </h2>
            <div class="modal-disclaim">
                {{getLabel('MXE_IDLE_MESSAGE')}}
            </div>
            <div class="modal-buttons">
                <button
                disabled
                class="btn btn-dark btn-transparent btn-bordered disabled"
                >
                reset {{counter | formatTime}}
                </button>
                <button class="btn btn-accent btn-dark"
                (click)="closeModal()"
                >
                {{getLabel('BTN_CONTINUE')}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="mxe__afk-modal-wrapper" 
     *ngIf="streamingAvailable && envName === 'cloud' && isCinematicActiveForSelectedTrim">
    <div class="content-container" [ngClass]="{'active': isCinematicStarted}">
           <div class="padd-content">
                <div class="text-container">
                    <h2 class="idle-box-title">{{getLabel('MXE_IDLE_BOX_TITLE')}} </h2>
                    <div class="idle-box-message">
                        {{getLabel('MXE_IDLE_MESSAGE')}}
                    </div>
                </div>
                <div class="buttons-container">
                    <div class="idleTimer">
                        <span>{{getLabelWithDefaultValue('MXE_TIME_TO_RESET',"Time to reset")}}</span>
                        <span class="counter">{{counter | formatTime}}</span>
                    </div>
                    <button class="btn btn-accent"
                    (click)="closeModal()"
                    >
                     {{getLabel('BTN_CONTINUE')}}
                    </button>
                </div>
            </div>
        </div>
</div>
<div class="mxe__afk-modal-wrapper" 
     *ngIf="streamingAvailable && envName === 'on_premise' && isCinematicActiveForSelectedTrim"
     (click)="closeModal()">
    <div class="content-container" [ngClass]="{'active': isCinematicStarted}">
           <div class="padd-content">
                <div class="text-container">
                    <h2 class="idle-box-title">{{getLabel('MXE_IDLE_BOX_TITLE')}} </h2>
                    <div class="idle-box-message">
                        {{getLabelWithDefaultValue('MXE_IDLE_MESSAGE_PREMISE',"You have been inactive for a while, touch everywhere on the screen to continue configuration.")}}
                    </div>
                </div>
            </div>
    </div>
</div>