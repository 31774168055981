import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { ColorItem, UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IMAGE_TYPE_ENUM, MENU_ENUM } from 'src/app/core/models/common.model';
import { Menu } from 'src/app/core/models/interface-service.model';
import { Camera } from 'src/app/core/models/unreal-available-models.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';

@Component({
  selector: 'app-exterior-configuration',
  templateUrl: './exterior-configuration.component.html',
  styleUrls: ['./exterior-configuration.component.scss'],
  animations: [ 
    trigger('openCloseAcc', [
      state('open', style({ 
          'height': '*'
        })),
      state('closed', style({ 
          'height': '0px'
        })),
      transition('open <=> closed', [
      animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
      ])
 ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExteriorConfigurationComponent {
  @Input() menu: Menu[]
  @Input() modelCode: string
  @Input() currency: string
  @Input() priceMask: string
  @Input() showPrices: boolean;
  @Input() isMainScreen: boolean;
  @Input() options: UIOptItem[];
  @Input() configId: string;
  @Input() optionsForAnalytics: string;
  @Input() dealerId: string;
  @Input() modelName: string;
  @Input() commercialName: string;
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != '') this.inputText = inputText.toLowerCase()
    else this.inputText = text
    this.buildVisibleSections()
  }
   //NUMBER OF ACCORDION IN EXTERIOR
  @Input() currentState : string[] ;

  //To remove once all exterior images have been generated
  @Input() familyCommercialCode: string
  @Input() showMoreLivree : boolean

  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()
  @Output() changeCameraEvt = new EventEmitter<Camera>()

  public inputText: string

  private exteriorCamera: Camera = {
    name: "MVI_Ext_Orbit_Cam",
    location: "ext"
  }

  private rimsCamera = {
    name: "MVI_Rim_FrontRight_Cam",
    location: "ext"
  }

  setHeightDiv : number;
  accordionActive: string = '';
  menuItems: Menu | undefined;
  menuLabel: string = '';
  visibleSections: string[] = [];
  heightArray : any[] = [];
  public accordionOpened = true  
 
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;
   

  constructor(
    private chg: ChangeDetectorRef,
    private carConfigurationService: CarConfigurationService,
    private uiCommonService: UiCommonService,
    private el: ElementRef,
    private mxeAnalyticsService: MxeAnalyticsService,
    private autobahn : AutobahnClient,
    private store : Store<MxeReducers>
  ) { }

  ngOnInit(): void {
    this.menuItems = this.menu.find(x => x.id === MENU_ENUM.EXT)
    if (this.menuItems?.id) {
      this.menuLabel = this.menuItems?.id;
    }
    else {
      this.menuLabel = 'Exterior';
    }
    this.buildVisibleSections()
    this.showMoreLivree = false;
    this.currentState = ["open","open","open","open","open","open"]
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
  }

  ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }

  
  /**
   * Get all options for a given section
   * @param sectionId 
   */
  getOptionsBySection(sectionId: string, filter_fuoriserie: string): UIOptItem[] {
    if(filter_fuoriserie != null) {
      if (filter_fuoriserie === '1') {
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        const options_with_fuoriserie_colors = this.options.filter(o =>
          o.section === sectionId
          && o.onlyImplicit === false
          && o.fuoriserie === false
          && o.colorList.length > 0
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  
        return fuoriserie_options.concat(options_with_fuoriserie_colors)
      }
      return this.options.filter(o => 
        o.section === sectionId 
        && o.fuoriserie === false 
        && o.onlyImplicit === false 
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
    }
    return  this.options.filter(o => o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  }

  /**
   * Returns if the section is visible or not
   * @param sectionId 
   * @returns 
   */
  sectionIsVisible(sectionId: string): boolean {
    return this.visibleSections.includes(sectionId)
  }


  /**
   * Returns if the tab is visible or not
   * @param tabId 
   * @returns 
   */
  tabIsVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }

  /**
   * Toggles accordion's state
   */
  toggleAccordion(dataaccordion: string,i, e): void {
    e.stopPropagation && e.stopPropagation()
    this.accordionOpened = !this.accordionOpened

    if(!this.accordionOpened){
      this.showMoreLivree = false;
    }
    this.currentState[i] = this.currentState[i]  === 'open' ? 'closed' : 'open';    
    
    const boxes = [`#${dataaccordion}_header`, `#${dataaccordion}_content`,`#${dataaccordion}_wrapper` ]

    boxes.forEach(box => {
      let myTag = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(myTag) {
        if(!myTag.classList.contains('closed')) {
          myTag.classList.add('closed');
        } else {
          myTag.classList.remove('closed');
        }
      }
    })
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionState(this.ephemeralDeviceID,this.currentState,'ext')
      this.autobahn.setshowMoreLivree(this.ephemeralDeviceID,this.showMoreLivree)
    }
    this.chg.detectChanges();
  }

  /**
   * Call the configuration service in order to have a path of the option image with the given color
   * @param group - Option's group
   * @param optionId - Option ID
   * @param color - Color Code (Optional)
   * @returns 
   */
  getImagePath(group: string, optionId: string, color = ''): string {
    return this.carConfigurationService.getImagePath(this.modelCode, group, optionId, IMAGE_TYPE_ENUM.MENU_ICON, color, this.familyCommercialCode)
  }

  /**
   * Get translations
   * @param id the option id
   * @param type the translation type
   * @param color the colorcode
   * @returns 
   */
  getLabel(id: string, type = '', color = '') {
    return this.uiCommonService.getLabel(id, type, color, this.modelCode);
  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  showMore(){
    this.showMoreLivree = !this.showMoreLivree
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setshowMoreLivree(this.ephemeralDeviceID,this.showMoreLivree)
    }
  }

  /**
   * Sends the configuration's change to Rule Engine and update the label in the UI
   * @param id 
   * @param color 
   * @param sectionId 
   * @param price 
   */
  changeConfiguration(id: string, color: string, price: string, sectionId: string) {
    if (!color) {
      const res = this.carConfigurationService.checkConflicts(id, '1')
      if (res.changes.toRemove.length > 0 || res.changes.toAdd.length > 0) {
        this.changeConfigurationEmitter.emit(res.changes)
      }
    } else {
      this.carConfigurationService.updateColor(id, color)
    }
    this.chg.detectChanges();
  }

  /**
   * Gets colors taking care of the 'fuoriserie' property
   * @param colors 
   * @param sectionId 
   * @returns 
   */
  getColors(optionId: string, colors: ColorItem[], filter_fuoriserie: string): ColorItem[] {
    return colors.filter(c => c.fuoriserie === (filter_fuoriserie === '1') && this.uiCommonService.applySearchFilterOnColor(optionId, c.colIdent, this.inputText, this.modelCode))
  }

  /**
   * Gets user's selections for every visible section
   * @param tabId the option's group, userd to filter selection in every group
   * @returns 
   */
  getSelectedOptionsDetails(tabId: string): string[] {
    let result: string[] = []
    this.options.filter(o => o.status.selected === true).forEach(option => {
      if (this.visibleSections.includes(option.section) && option.group === tabId) {
        if (option.colorList.length > 0) {
          let selectedColor = option.colorList.find(c => c.selected)
          if (selectedColor) {
            let formattedPrice = ''
            if (+selectedColor.price > 0) {
              formattedPrice = ' - ' + this.formatPrice(selectedColor.price)
           } 
            const detail = this.showPrices
              ? `${this.getLabel(option.id, 'opt_color', selectedColor.colIdent)}<span>${formattedPrice}</span>`
              : `${this.getLabel(option.id, 'opt_color', selectedColor.colIdent)}`
            result.push(detail)
          }
        } else {
          let formattedPrice = ''
          if (+option.price > 0) {
            formattedPrice = ' - ' + this.formatPrice(option.price)
          } 
          const detail = this.showPrices
            ? `${this.getLabel(option.id, 'opt_name')}<span>${formattedPrice}</span>`
            : `${this.getLabel(option.id, 'opt_name')}`
          let livree = this.getLabel('DESLIV', 'opt_name')
          if(!result.includes(detail) || detail == livree )
            result.push(detail)
        }
      }
    })
    return result
  }

  isTabVisible(tabId: string): boolean{
    return this.options.some(x => x.group === tabId) 
  }

  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string = '', action: string = ''): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }

  public changeCamera(e, id: string | null = null) {
    e.preventDefault() && e.preventDefault
    e.stopPropagation() && e.stopPropagation
    const camera = id == 'RIMS' || id == 'CAL' ? this.rimsCamera : this.exteriorCamera
    this.changeCameraEvt.emit(camera)
  }

  private formatPrice(price: string) {
    return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
  }

  private buildVisibleSections() {
    this.menuItems?.tabs.forEach(tab => {
      tab.sections.forEach(section => {
        if(section.filter_fuoriserie != null) {
          if (section.filter_fuoriserie === '1') {
            const fuoriserie_options = this.options.filter(o => o.section === section.id && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
            const options_with_fuoriserie_colors = this.options.filter(o =>
              o.section === section.id
              && o.onlyImplicit === false
              && o.fuoriserie === false
              && o.colorList.length > 0
              && o.colorList.filter(c => c.fuoriserie === true).length > 0
              && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
            if (fuoriserie_options.concat(options_with_fuoriserie_colors).length > 0) {
              if (this.visibleSections.includes(section.id) === false)
                this.visibleSections.push(section.id)
            }
          } else {
            if (this.options.filter(o => o.section === section.id && o.fuoriserie === false && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
              if (this.visibleSections.includes(section.id) === false)
                this.visibleSections.push(section.id)
            }
          }
        } else {
          if (this.options.filter(o => o.section === section.id && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
            if (this.visibleSections.includes(section.id) === false)
              this.visibleSections.push(section.id)
          }
        }
      })
    })
  }
}