import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, NgZone, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, filter, finalize, forkJoin, map, merge, Observable, of, skipWhile, Subject, Subscription, takeUntil, tap } from 'rxjs';
import smoothscroll from 'smoothscroll-polyfill';
import IdleTimer from 'src/app/common/idleTimer';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { CarGridRequest, CarGridResponse, Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { CTA_KEYS_ENUM, IConflictsData} from 'src/app/core/models/common.model';
import { GetCountryPropRequest, ICTA } from 'src/app/core/models/country-prop-service.model';
import { CarModel, Family } from 'src/app/core/models/get-models-service.model';
import { UnrealState, GetSceneState } from 'src/app/core/models/get-scene-state';
import { CarImage, GetInterfaceRequest, Layer, Menu } from 'src/app/core/models/interface-service.model';
import { GetSaveConfigurationRequest } from 'src/app/core/models/save-conf-service.model';
import { Camera } from 'src/app/core/models/unreal-available-models.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { AppSettingsActions } from 'src/app/core/store/actions/app-settings/app-settings-exported-actions';
import { CAR_GRID_LOADED } from 'src/app/core/store/actions/car-grid-actions';
import { INTERFACE_LOADED } from 'src/app/core/store/actions/interface-actions';
import { MENU_LOADED } from 'src/app/core/store/actions/menu-actions';
import { CamerasActions } from 'src/app/core/store/actions/unreal-cameras/cameras-exported-ections';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { EnvironmentsActions } from 'src/app/core/store/actions/unreal-environments/environments-exported-actions';
import { CamerasState } from 'src/app/core/store/initials/cameras.initial-state';
import { EnvironmentsState } from 'src/app/core/store/initials/environments-initial-state';
import { MxeReducers } from 'src/app/core/store/reducers';
import { Optional } from '../../core/models/interface-service.model';
import { PAIRING_STEP_ENUM, STEP_ENUM } from '../../core/models/common.model';
import { APP_SETTINGS_SET_APPLICATION_STEP, APP_SETTINGS_SET_SCREENCAST_ACTIVE, APP_SETTINGS_SET_STREAMING_STATUS } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { UPDATE_VIEW_UI_STATE } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-actions';
import { CTAS_LOADED, LABELS_LOADED } from 'src/app/core/store/actions/country-prop-actions';
import { ConfigItem } from 'src/app/core/store/initials/temporary-configs-initial-state';
import { TEMP_CONFIG_ADD } from 'src/app/core/store/actions/temporary-configs/temporary-configs-actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RenderPipelineService } from 'src/app/core/services/render-pipeline.service';
import { InteractiveBottomBarComponent } from './interactive-bottom-bar/interactive-bottom-bar.component';
import anime from 'src/app/anime.min.js'
import { RESET_SELECTED_MODEL, SET_LAST_MODEL } from 'src/app/core/store/actions/models/models-actions';
import { RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import {ServiceStatus} from 'src/app/core/models/maserati-service-status'
import { SentryMessaging } from 'src/app/core/services/sentry-messaging.service';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';


@Component({
  selector: 'app-car-configurator-wrapper',
  templateUrl: './car-configurator-wrapper.component.html',
  styleUrls: ['./car-configurator-wrapper.component.scss', './rtl-car-configurator-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarConfiguratorWrapperComponent {

 @ViewChild(InteractiveBottomBarComponent) interactiveBottomBar: InteractiveBottomBarComponent;

  fullScreenEvent: EventEmitter<boolean> = new EventEmitter();
  loadingCompleted: boolean;
  fullScreen = false;
  dayNight: string;
  environments: string[];
  activeEnvironment: string;
  screenCastActive: boolean;
  showScreenCastModal = false;
  ephemeralDeviceID: string; //understand how to use it
  showScreenCastAlert = false;
  cameras: Camera[];
  activeCamera: Camera;
  carModel: CarModel;
  priceStatus: boolean;
  menuItems: Menu[];
  showContentData = false;
  showChangeConfigurationWindow = false;
  showChangeConfigurationAlertBar = false;
  conflictsData: IConflictsData;
  showSaveModal = false;
  showSummaryModal = false;
  showPopUp = false;
  showThankYouPage = false;
  isSearchNewInventoryCtaEnabled = false;
  searchNewInventoryEndpoint: SafeResourceUrl;
  searchNewInventoryLink : string;
  isTridentModalOpen = false;
  modelOptions: UIOptItem[];
  modelPacks: Pack[];
  labels: any;
  searchToggleValue: boolean;
  homeMenuIsOpen = false;
  searchValue: string;
  optionsForAnalytics: string;
  configId: string;
  dealerId: string;
  saveCtaEnabled: boolean = false;
  country: number;
  language: string;
  idleTimeIsExpired = false
  standAloneSet = {}
  standAloneSetInt = {}
  activeFamilyCommercialCode = ''
  allCtas = {}
  ctas: ICTA[];
  desiredUnrealConfig: string[];
  hiddenOptions: UIOptItem[];
  optionalsBinding: Optional[];
  imageView: string;
  swiperViews: CarImage[] = []
  activeView: CarImage;
  imageLayers: Layer[];
  customerName: string;
  customerSurname: string;
  showCustomerName: boolean;
  unformattedBasePrice: number;
  unformattedTaxes: number;
  unformattedEquipments: number;
  PairingStep = PAIRING_STEP_ENUM;
  Step = STEP_ENUM;
  isMainScreen: boolean = true;
  mirroringStoppedByUser = false;
  hotspots: boolean;
  saveFrameLinkWithParams: string;
  autobahnScrollPosition: string;
  currentFamily: Family;
  viewUi: boolean;
  envName: string;
  accordionExp: number
  streamingAvailable: boolean;
  isInteractiveBottomBarVisible: boolean;
  defaultOptions$: Subscription;
  defaultOptions: UIOptItem[];
  labels$: Subscription;
  trimIsImplemented: boolean = true
  showSni : boolean;
  isCongifIdCreated : boolean;
  sendPdfBasePath: string;
  canDisplayLoadingPage: boolean;
  customerNameValue: string;
  customerSurNameValue : string;
  emailValue: string;
  modelOfInterest: string;
  mobileInputValue : any
  showCinematic : boolean
  showCinematicEventFromSummary : boolean
  isCinematicActiveForSelectedTrim: boolean
  isCinematicActive: boolean
  rimsCustomConfiguration : string;
  extColorCustomConfiguration : string;
  intColorCustomConfiguration : string;
  canSkipCinematic: boolean = false;
  hostName: string;
  servicesCompleted : boolean = false;
  saveClicked: boolean = false;

  carGridStatus: ServiceStatus;
  interfaceStatus: ServiceStatus;
  streamingStatus: ServiceStatus;

  commercialName : string
  
  set scrollPosition(position: string) {
    this.autobahnScrollPosition = position
    if(this.screenCastActive)
      this.autobahnClient.setScrollPosition(this.ephemeralDeviceID, this.autobahnScrollPosition)
  }

  set accordionToggle(elementClass: string) {
    if(this.screenCastActive)
      this.autobahnClient.setAccordionToggle(this.ephemeralDeviceID, elementClass)
  }
  set summaryScrollPosition(position: string) {
    if(this.screenCastActive)
      this.autobahnClient.setSummaryScrollPosition(this.ephemeralDeviceID, position)
  }
  
  countryCode: string;
  languageId: string;
  
  animationsState_: Observable<{}>;
  updateAnalyticsPageNameEvt: EventEmitter<any> = new EventEmitter();
  closedAccordions: string[] = [];
  showCustomSaveModal : boolean = false;
  protocol: string;
  host: string;
  isOnPremise: boolean;
  isOnCloud: boolean
  isAudioOn: boolean
  bAudio: boolean
  renderPipelineImgUrl: SafeResourceUrl
  unsafeRenderPipelineImgUrl: string
  isPipelineLoading : boolean = false
  renderPipelineImgUrlArray: SafeResourceUrl[] = []
  unsafeRenderPipelineImgUrlArray: string[] = []
  currentTrimFamily: string;
  showRefreshLabel : boolean = false;

  private ctaLanguageId: string;
  private saveFrameLink: string;
  private desiredState = <any>{};
  private animationState = {};
  private carDetails: CarGridResponse | any;
  private showChangeConfigurationAlertBarTimeout: any;
  private timer: IdleTimer;
  private ctaLanguageId$: Subscription;
  private getAvailableModelsSub$: Subscription;
  private getSceneStateChangeSub$: Subscription;
  private sceneStateSub$: Subscription;
  private desireUnrealStateSub$: Subscription;
  private environmentSub$: Subscription;
  private screenCastActive$: Subscription;
  private cameraSub$: Subscription;
  private modelStateOptSub$: Subscription;
  private modelStatePackSub$: Subscription;
  private modelStateOptForAnalytics$: Subscription;
  private showPrices$: Subscription;
  private maseratiServiceSub$: Subscription;
  private configIdAnalytics$: Subscription;
  private dealerIdState$: Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private ctasSub$: Subscription;
  private countryCode$: Subscription;
  private languageId$: Subscription;
  private hiddenOptionsSub$: Subscription;
  private specialGraphicOptionals$ : Subscription
  private appState$: Subscription
  private ephemeralDeviceID$: Subscription;
  private envName$: Subscription;
  private fallback2dData$: Subscription;
  private streamingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private appExecutionInfos$ : Subscription;
  private routerSubscription$ : Subscription;
  private renderPipelineServicePollImages$ : Subscription;
  private onEndCinematicSub$ : Subscription;
  private cinematicStartedStatusSub$ : Subscription;
  private modelStateSub$ : Subscription

  private maseratiServicesCompleted: boolean = false;
  public maseratiServicesCompleted$: Subject<boolean> = new Subject<boolean>()
  public monkeyWayStreamingReady: boolean = false;
  public safeUrlWithParams: SafeResourceUrl | null = null;
  public xrayEnabled: boolean = true
  isTrimAvailableStreaming : boolean
  carsCounter: string
  modelName: string;
  public cinematicNamesList : any[] = [];
  audioButtonVisible : boolean = false;
  showCinematicLogo : boolean = false;
  showCinematicText: boolean = true;
  textAnimationActive: boolean = false;
  isCinematicStarted: boolean = false;
  index:number = 0
  

  unrealSelectedModelName : string


  constructor(
    private store: Store<MxeReducers>,
    private autobahnClient: AutobahnClient,
    private carConfigurationService: CarConfigurationService,
    private maseratiService: MaseratiService,
    private uiCommonService: UiCommonService,
    private chg: ChangeDetectorRef,
    private router: Router,
    private errorDialogService: ErrorDialogService,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private httpClient: HttpClient,
    private renderPipelineService : RenderPipelineService,
    private ngZone: NgZone,
    private monkeyWay: MonkeyWayService,
    private sentryMessaging: SentryMessaging,
    private navigation: NavigationStateService
  ) {
    const isTrimSelected = sessionStorage.getItem('trim_selected')
    if(!isTrimSelected) {
      this.sentryMessaging.logEvent('Inappropriate use of application navigation', 'warning', {reason: 'mxe_opened_inside_3d_configuration'});
      this.navigation.navigate('/mxe');
    }

    this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.CAR_CONFIGURATOR}))
    this.routerSubscription$ = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event) => {
      if((event as NavigationStart)?.navigationTrigger == 'popstate'){
        document.querySelector('#legal-notes')?.remove()
        // this.autobahnClient.deallocateSessionsAndDispatchDeletes().catch((e:any) => {
        //   if(!environment.production)
        //     {console.error(e)} 
        // });
      }
    })
  }

  ngOnInit(): void {
    this.interfaceStatus = ServiceStatus.pending;
    this.carGridStatus = ServiceStatus.pending;
    this.streamingStatus = ServiceStatus.pending;
    this.appExecutionInfos$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.appState.hostName)
    ]).subscribe(([protocol,host,hostName]) => {
          this.protocol = protocol;
          this.host = host;
          this.hostName = hostName
    })
    this.streamingStatus$.pipe(
      filter(value => value !== null),
      distinctUntilChanged()
    ).subscribe(value => {
      this.streamingAvailable = value
      if (this.screenCastActive && this.ephemeralDeviceID) {
        this.autobahnClient.setStreamingStatus(this.ephemeralDeviceID, value)
      }
    })
    this.envName$ = this.store.select(s => s.appState.envName).subscribe(
      envName => {this.envName = envName
        console.debug("[Env] -",this.envName)},
    )
    this.showPopUp = false;
    this.desireUnrealStateSub$ = this.store.select(s => s.desired_unreal_state).pipe(
      tap(unrealSetting => {
        this.viewUi = unrealSetting.viewUi
        this.desiredUnrealConfig = unrealSetting.config
        this.country = +unrealSetting.country
        this.language = unrealSetting.language
        this.dayNight = unrealSetting.daytime;
        this.fullScreen = unrealSetting.fullscreen
        this.animationState = unrealSetting.animation_states
        this.hotspots = unrealSetting.hotspots
      }),
      debounceTime(100),
      tap(unrealState => this.desiredState.desired_unreal_state = unrealState),
      
      filter(desiredState => desiredState.config.length > 1),
      
      distinctUntilChanged(),
    ).subscribe({
      next: () => {
        if (this.desiredState && this.desiredState.desired_unreal_state && this.desiredState.desired_unreal_state.environment != '') {
          if (this.streamingAvailable) {
            console.log('Updating SCENE', this.desiredState)
            this.updateScene(this.desiredState)
          }
        }
      }
    })
    this.getConnectionModetype();
    this.environmentSub$ = this.store.select(s => s.environmentsState).pipe(
      tap((environmentsState: EnvironmentsState) => {
        console.log('ENVIRONMENTSTATE', environmentsState.activeEnvironment)
        this.environments = environmentsState.environments,
          this.activeEnvironment = environmentsState.activeEnvironment
      }),
      // takeUntil(this.stopEnvironmentSub$)
    ).subscribe()

    this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).pipe(skipWhile(s => !s)).subscribe({
      next: (res: boolean) => {
        this.screenCastActive = res
        this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_PRESENTER_SCREEN_STATE({
          presenterScreen: res
        }))}
    })

    this.cameraSub$ = this.store.select(s => s.camerasState).pipe(
      tap((camerasState: CamerasState) => {
        this.cameras = camerasState.cameras;
        this.activeCamera = camerasState.activeCamera
      }),
    ).subscribe()

    this.modelStateOptForAnalytics$ = this.store.select(m => m.modelsState.optionsForAnalytics).subscribe(
      (optionsForAnalytics: string) => this.optionsForAnalytics = optionsForAnalytics
    )

    this.configIdAnalytics$ = this.store.select(c => c.appState.configId).subscribe(
      (configId: string) => this.configId = configId
    )

    this.ctaLanguageId$ = this.store.select(c => c.appState.ctaLanguageId).subscribe(
      (ctaLanguageId: string) => this.ctaLanguageId = ctaLanguageId
    )

    this.dealerIdState$ = this.store.select(d => d.userState.dealerId).subscribe(
      (dealerId: string) => {
        this.dealerId = dealerId; 
        if(this.screenCastActive) {
          this.autobahnClient.setDealerId(this.ephemeralDeviceID, dealerId)
        }
      }
    )

    this.modelStateSub$ = this.store.select(s => s.modelsState).subscribe(modelState =>{
      this.currentFamily = modelState.families.find(f => f.familyCommercialCode === modelState.currentCarModel?.familyCommercialCode!)!
      this.carModel = modelState.currentCarModel!
      this.activeFamilyCommercialCode = modelState.currentCarModel?.familyCommercialCode!
      if(this.currentFamily){
          this.currentTrimFamily = this.currentFamily.familyName
      }
    })

    this.modelStateOptSub$ = this.store.select(s => s.modelsState.options).subscribe(
      (options: UIOptItem[]) => {
        this.modelOptions = options;
        if (this.screenCastActive && this.streamingAvailable) this.autobahnClient.setOptionsItem(this.ephemeralDeviceID, this.modelOptions)
      }
    )
    this.modelStatePackSub$ = this.store.select(s => s.modelsState.packages).subscribe(
      (packs: Pack[]) => {
        this.modelPacks = packs;
        if (this.screenCastActive) this.autobahnClient.setPacksItem(this.ephemeralDeviceID, this.modelPacks)
      }
    )

    this.hiddenOptionsSub$ = this.store.select(s => s.modelsState.hiddenOptions).subscribe(
      (options: UIOptItem[]) => {
        this.hiddenOptions = options;
      }
    )

    this.defaultOptions$ = this.store.select(s => s.modelsState.defaultOptions).subscribe(
      opts => {
        this.defaultOptions = opts
      }
    )

    this.specialGraphicOptionals$ = this.store.select(s => s.interfaceState.interface).subscribe(
      (getInterface) => {
        this.optionalsBinding = getInterface.specialGraphicOptionals.optional
        this.swiperViews = getInterface.carImages
        const carImageLayer = getInterface.carImages.filter(c => c.viewType == 'EXT')[0]
        if(carImageLayer){
          this.imageView = carImageLayer.view
          this.imageLayers = carImageLayer.layer!
        }
      }
    )
    
    this.appState$ = this.store.select(s => s.appState).subscribe(appState => {
      if (appState.clientName && appState.clientSurname) {
        this.customerName = appState.clientName
        this.customerSurname = appState.clientSurname
        this.showCustomerName = true
      }
      if(this.streamingAvailable !== appState.streamingAvailable) {
        this.streamingAvailable = appState.streamingAvailable
        this.chg.detectChanges();
      }  
      this.isInteractiveBottomBarVisible = this.streamingAvailable ? true : false;
            
    })

    this.showPrices$ = this.store.select(s => s.appState.showPrices).subscribe(showPrices => {
      this.priceStatus = showPrices;
    })

    this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
      countryCode => this.countryCode = ''+countryCode
    )

    this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
      lang => this.languageId = lang
    )

    this.labels$ = this.store.select(s => s.countryPropState.labels).subscribe(
      (labels) => {
        if(labels) {
          this.labels = labels
          this.uiCommonService.initializeLabels(this.labels)
          if(this.screenCastActive && this.ephemeralDeviceID) {
            this.autobahnClient.setLabels(this.ephemeralDeviceID, this.labels)
          }
        } else {
          // Something went wrong
          console.error('[Car configurator] - Cache labels is empty.')
        }
      }  
    )

    this.ctasSub$ = this.store.select(s => s.countryPropState.ctas).subscribe(
      (ctas) => {
        this.allCtas = ctas
        if(this.carModel && this.carModel.modelCode && Object.keys(ctas).includes(this.carModel.modelCode)){
          this.ctas = ctas[this.carModel.modelCode];
          this.checkSaveCtaAvailability()
        }
      }
    )       

    if(!Object.keys(this.labels).includes(this.carModel.modelCode)) {
      const payload: GetCountryPropRequest = {
        country: +this.countryCode,
        language: this.languageId,
        modelCode: this.carModel.modelCode,
        modelName: this.carModel.modelName
      }
      this.maseratiService.getCountryProp(payload).subscribe(
        (result) => {
          this.labels[this.carModel.modelCode] = this.uiCommonService.updateLanguageIdsTranslation(result.labels, result.languages)
          this.store.dispatch(LABELS_LOADED({labels: this.labels}))
          this.uiCommonService.initializeLabels(this.labels)
          if(this.ctaLanguageId === this.languageId) {
            this.allCtas[this.carModel.modelCode] = 
            this.ctas = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
            this.store.dispatch(CTAS_LOADED({ ctas: this.allCtas }))
            this.checkSaveCtaAvailability()
            this.chg.detectChanges()
          } 
        }
      )

      if(this.ctaLanguageId !== this.languageId) {
        const payload: GetCountryPropRequest = {
          country: +this.countryCode,
          language: this.ctaLanguageId,
          modelCode: this.carModel.modelCode,
          modelName: this.carModel.modelName
        }
        this.maseratiService.getCountryProp(payload).subscribe(
          (result) => {
              this.allCtas[this.carModel.modelCode] = 
              this.ctas = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
              this.store.dispatch(CTAS_LOADED({ ctas: this.allCtas }))
              this.checkSaveCtaAvailability()
              this.chg.detectChanges()
          }
        )
      }
    }

    if(this.carModel.modelName != '' && +this.country >= 0 && this.languageId != '') {
      this.getCarModelDetails()
    }

    
    this.getSceneStateChangeSub$ = this.autobahnClient.getSceneStateChangeEvt.asObservable().subscribe((res) => {
      if (res.payload.desired_state.daytime) this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_DAY_TIME({ daytime: res.payload.desired_state.daytime }))
      if (res.payload.desired_state.environment != "") this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_ENVIRONMENT({ environment: 'NotSoDaringBlue' }))
      if (res.payload.desired_state.currentState != "") this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_CURRENT_STATE({ current_state: res.payload.desired_state.currentState }))
      if (res.payload.desired_state.animation_states != null) this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_ANIMATION_STATE({ animation_states: res.payload.desired_state.animation_states }))
      this.autobahnClient.onStopCinematicMode()  
    })

    this.cinematicStartedStatusSub$ = this.autobahnClient.cinematicStartedStatusEvt.asObservable().subscribe((res) => {
      if(res){
        try{
          const parsedCode = JSON.parse(res as any)
          if(parsedCode && parsedCode.code && parsedCode.code.includes('started') && !this.idleTimeIsExpired){
            this.canSkipCinematic = true;
            this.audioButtonVisible = true;
            this.chg.detectChanges();
          }
          else if(parsedCode && parsedCode.code && parsedCode.code.includes('started') && this.idleTimeIsExpired){
            this.isCinematicStarted = true;
            this.chg.detectChanges();
          }
        }catch(error){
          this.showCinematicEvent(false,false)
        }
      }
    })

    this.onEndCinematicSub$ = this.autobahnClient.onEndCinematicEvt.asObservable().subscribe((res) =>{
      if(res){
        const parsedCode = JSON.parse(res as any)
        if(parsedCode && parsedCode.code && parsedCode.code.includes('finished')){
          this.isCinematicStarted = false;
          this.showCinematicEvent(false,false,true)
          this.openSummary();
          this.chg.detectChanges();
        }
      }
    })
    

    this.sceneStateSub$ = this.autobahnClient.sceneStateChangeEvt.asObservable().subscribe(res => {

      const sceneState: GetSceneState = JSON.parse(res as any)
      const currentState = JSON.stringify(sceneState.payload.current_state.animation_states);
      const desiredState = JSON.stringify(sceneState.payload.desired_state.animation_states);
      const activeAnimationState = JSON.stringify(this.animationState)

      if (desiredState != activeAnimationState) {
        this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_ANIMATION_STATE({ animation_states: sceneState.payload.desired_state.animation_states }))
      }
    })

    this.getAvailableModelsSub$ = this.autobahnClient.getAvailableModelsEvt.asObservable().subscribe( availableModels => {
      console.log('Available Models:',availableModels)
      const modelsWithoutXrayList: string[] = availableModels.availableModels.flatMap(model => model.modelsWithoutXray);
      console.log('models without X-Ray: ', modelsWithoutXrayList);
      if(modelsWithoutXrayList.includes(this.carModel.modelCode)) {
        this.xrayEnabled = false;
      }
      if(availableModels && availableModels.availableModels){
        availableModels.availableModels.forEach( model =>{
          if(model.modelNumbers.includes(this.carModel.modelCode)){
            this.cinematicNamesList = model.cinematics;
            this.unrealSelectedModelName = model.name
          }
        })
      }
      this.isCinematicActiveForSelectedTrim = this.cinematicNamesList && this.cinematicNamesList.length > 0 && this.cinematicNamesList[0].length > 0 ? true : false
      //STOP CINEMATIC IN ANYCASE
      if(this.streamingAvailable && this.isCinematicActiveForSelectedTrim){
        this.autobahnClient.stopCinematicMode(true);
      }
      const unrealModelCodes = availableModels.availableModels.map(a => a.modelNumbers).reduce((r, a) => a.concat(r))
      if (unrealModelCodes && unrealModelCodes.includes(this.carModel.modelCode)) {
        if (availableModels.availableModels[0].cameras.length > 0) {
          this.store.dispatch(CamerasActions.UPLOAD_CAMERAS({ cameras: availableModels.availableModels[0].cameras }))
          this.store.dispatch(CamerasActions.UPDATE_SELECTED_CAMERA({ activeCamera: availableModels.availableModels[0].cameras[0] }))
        }
        if (availableModels.availableModels[0].environments) {
          this.store.dispatch(EnvironmentsActions.UPLOAD_ENVIRONMENTS({ environments: availableModels.availableModels[0].environments }))
          this.store.dispatch(EnvironmentsActions.UPDATE_SELECTED_ENVIRONMENT({ activeEnvironment: 'NotSoDaringBlue' }))
        }
      } else {
        console.warn(`MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal`)
        this.sentryMessaging.logEvent('mxe.fallback_activation','warning',{reason: `MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal`})

        this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: false}))
        this.streamingAvailable = false
        this.isInteractiveBottomBarVisible = false
        this.trimIsImplemented = false
      }
    })

    this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
      ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
    )

    this.fallback2dData$ = combineLatest([
      this.maseratiServicesCompleted$.asObservable(),
      this.store.select(s => s.appState.isScreenCastActive),
      this.store.select(s => s.appState.streamingAvailable),
      this.store.select(s => s.modelsState.defaultOptions),
      this.store.select(s => s.modelsState.hiddenOptions),
      this.store.select(s => s.modelsState.currentCarModel),
      this.store.select(s => s.modelsState.options),
      this.store.select(s => s.interfaceState.interface.carImages),
      this.store.select(s => s.interfaceState.interface.specialGraphicOptionals.optional)
    ]).subscribe(([maseratiServicesCompleted, screenCastActive, streamingAvailable, defaultOptions, hiddenOptions, currentCarModel, options, carImages, optionasBindings]) => {
      this.streamingAvailable = streamingAvailable
      this.isInteractiveBottomBarVisible = this.streamingAvailable ? true : false;
      if(this.screenCastActive && maseratiServicesCompleted) {
        if(!this.streamingAvailable) {
          const parameters = {
            menuItems: this.menuItems,
            defaultOptions: defaultOptions,
            optionalsBinding: optionasBindings,
            hiddenOptions: hiddenOptions,
            options: options,
            carModel: currentCarModel,
            swiperViews: carImages,
            activeView: this.activeView ? this.activeView : carImages.find(v => v.viewType === 'EXT'),
            loadingCompleted: this.loadingCompleted
          }
          console.debug(parameters)
          this.autobahnClient.setFallback2dData(this.ephemeralDeviceID, parameters)
        }
        this.streamingStatus$.next(this.streamingAvailable)  
      }
    })

    this.continueBrowsing();
    smoothscroll.polyfill();

    this.addSaveMessageListener()

    if(this.ctas && this.ctas.length > 0){
      const searchNewInventoryCta = this.ctas.find(c => c.name === CTA_KEYS_ENUM.SEARCH_NEW_INVENTORY);
      if(searchNewInventoryCta && searchNewInventoryCta.enabled && searchNewInventoryCta.link != ""){
        this.isSearchNewInventoryCtaEnabled = true;
        this.searchNewInventoryLink = searchNewInventoryCta.link;
        this.getSearchNewInventoryCount();
      }
      else{
        this.isSearchNewInventoryCtaEnabled = false;
      }
    }
    this.showSni = false;
    this.isCongifIdCreated = false;
    this.showCinematic = false;
    this.commercialName = this.carModel.commercialName

    this.canDisplayLoadingPage = true;
    if(this.isMainScreen && this.screenCastActive){
      this.autobahnClient.setIsWrapperFromLoader(this.ephemeralDeviceID,true);
    }
    this.modelName = this.carModel.modelName
    this.bAudio = false;
    this.canSkipCinematic = false;
    this.audioButtonVisible = false;
    this.textAnimationActive = false;
    this.showCinematicText = true;
  }
  

  
  ngOnDestroy() {
    this.autobahnClient.deallocateSessionsAndDispatchDeletes().catch((e: any) => {if(!environment.production)
      {console.error(e)} }).finally(() => {
      this.autobahnClient.resetMachineIdAndSessionId();
      if(this.unsafeRenderPipelineImgUrlArray && this.unsafeRenderPipelineImgUrlArray.length > 0){
        this.unsafeRenderPipelineImgUrlArray.forEach( unsafeRenderPipelineImgUrl => {
          URL.revokeObjectURL(unsafeRenderPipelineImgUrl)
        })
      }
      this.unsubscribeAllSubscriptions();
    })
  }

  getConnectionModetype(){
    if(this.host !== 'localhost:8080' && this.hostName.includes('local')){
      this.isOnPremise =  true;
    }
    else {
      this.isOnCloud = true;
    }
  }
  
  onMonkeyWayReady(ready: boolean) {
    this.monkeyWayStreamingReady = ready;
    this.joinSessionOnScreenCast();
  }



  async joinSessionOnScreenCast() {
    if (this.monkeyWayStreamingReady && this.maseratiServicesCompleted) {
      if (this.screenCastActive) {
        this.autobahnClient.onJoinSession(this.ephemeralDeviceID, true).then(
          async response => {
            if (response['sessionToJoin']) {
              await this.autobahnClient.setPresenterScreenSidebarData(this.ephemeralDeviceID, this.carModel, this.priceStatus, this.menuItems, this.modelOptions, this.modelPacks, this.labels, this.closedAccordions, this.accordionExp)
            }
          }
        )
      }
    }
  }

  getSearchNewInventoryCount(){
    const searchInventoryCount = this.ctas.find(c => c.name === CTA_KEYS_ENUM.SEARCH_NEW_INVENTORY_COUNT)
    const extColor = this.carConfigurationService.getExteriorColor()
    if (searchInventoryCount && searchInventoryCount.link != '' && extColor) {
      const params = `&dealerCodeList=${this.dealerId}&configExtColor=${extColor}`
      this.maseratiService.preflightNoHeaders(searchInventoryCount.link, params).subscribe({
          next: response => {
            if (response && response.result) {
              this.carsCounter = response.result.total
            } else {
              console.error(response.errorMessage)
            }
          },
          error: (error) => {
            if(!environment.production){
              console.error(error)
            }},
          complete: () => {console.info('SearchInventoryCount completed')
            this.chg.detectChanges();}
        }
      )
    }
  }

  openSniTab(){
    window.open(this.searchNewInventoryEndpoint['changingThisBreaksApplicationSecurity'], '_blank')
  }

  async showPopUpSni(){
    this.getSearchNewInventoryCount();
    this.showSni = true;
    this.showPopUp = true;
    let configId = await this.callSaveService();
    if(this.isSearchNewInventoryCtaEnabled && configId && configId.length > 0){
      this.isCongifIdCreated = true;
      this.searchNewInventoryEndpoint = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.searchNewInventoryLink}?configId=${this.configId}&dealerCodeList=${this.dealerId}&iframe=true`)
    }
    else{
      this.isCongifIdCreated = false;
    }
    this.chg.detectChanges()
   
  }

  
  addSaveMessageListener() {
    window.addEventListener("message", (event) => {
      if(event.data == "wtl-submit:success" || event.data === "SAVE_CTA_WTE:0"){
        console.debug(event)
        this.closeSaveModal(true)
      }
    }, false )
  }

  private checkSaveCtaAvailability() {
    const saveCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.MXE_SAVE_CTA_WTL)
    if (saveCTA && saveCTA.link != '') {
      this.saveCtaEnabled = true
      this.saveFrameLink = saveCTA.link
    }
  }

  private unsubscribeAllSubscriptions() {
    this.ephemeralDeviceID$?.unsubscribe();
    this.desireUnrealStateSub$?.unsubscribe();
    this.environmentSub$?.unsubscribe();
    this.screenCastActive$?.unsubscribe();
    this.cameraSub$?.unsubscribe();
    this.showPrices$?.unsubscribe()
    this.maseratiServiceSub$?.unsubscribe()
    if (this.screenCastActive) {
      this.modelStateOptSub$?.unsubscribe()
      this.modelStatePackSub$?.unsubscribe()
    }
    this.modelStateOptForAnalytics$?.unsubscribe()
    this.dealerIdState$?.unsubscribe()
    this.configIdAnalytics$?.unsubscribe()
    this.ctasSub$?.unsubscribe()
    this.sceneStateSub$?.unsubscribe()
    this.getAvailableModelsSub$?.unsubscribe()
    this.getSceneStateChangeSub$?.unsubscribe()
    this.ctaLanguageId$?.unsubscribe()
    this.countryCode$?.unsubscribe()
    this.languageId$?.unsubscribe()
    this.hiddenOptionsSub$?.unsubscribe()
    this.specialGraphicOptionals$?.unsubscribe()
    this.appState$?.unsubscribe()
    this.timer?.cleanUp()
    //this.autobahnClient.close()
    this.emptyStore()
    this.envName$?.unsubscribe()
    this.defaultOptions$?.unsubscribe()
    this.labels$?.unsubscribe()
    this.fallback2dData$?.unsubscribe()
    this.appExecutionInfos$?.unsubscribe();
    this.routerSubscription$?.unsubscribe();
    this.cinematicStartedStatusSub$?.unsubscribe();
    this.onEndCinematicSub$?.unsubscribe();
    this.renderPipelineServicePollImages$?.unsubscribe();
    this.modelStateSub$?.unsubscribe();
    sessionStorage.removeItem('trim_selected')
  }

 
  public changeCamera(value: Camera) {
    this.activeCamera = value
    this.store.dispatch(CamerasActions.UPDATE_SELECTED_CAMERA({
      activeCamera: this.activeCamera
    }))
  }

  public confirmExpiration() {
    this.idleTimeIsExpired = false
    document.querySelector('#legal-notes')?.remove()
    if(!this.idleTimeIsExpired && this.streamingAvailable && this.envName === 'cloud'){
      this.showCinematicEvent(this.idleTimeIsExpired,true)
    }
    this.resetLoadedConfiguration()
    this.navigation.navigate('/mxe')
    if(this.screenCastActive) {
      this.monkeyWay.stopSession().then(() => {
        this.zone.run(() => {
          this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: false}))
        })
      })
    }
    this.chg.detectChanges()
     
  }

  resetLoadedConfiguration() {
    this.store.dispatch(SET_LAST_MODEL(
      {lastCarModel: this.carModel, 
       lastHiddenOptions: this.hiddenOptions, 
       lastOptions: this.modelOptions,
       lastDefaultOptions: this.defaultOptions,
       lastPackages: this.modelPacks,
       lastOptionsForAnalytics: this.optionsForAnalytics
      }))
    this.store.dispatch(RESET_SELECTED_MODEL())
    this.store.dispatch(RESET_CONFIGURATION_LOADED())
  }

  public continueBrowsing() {
    this.idleTimeIsExpired = false;
    //Valido sia per onPremise che Cloud
    if(!this.idleTimeIsExpired && this.streamingAvailable && this.isCinematicActive){      
      this.showCinematicEvent(this.idleTimeIsExpired,true)
    }
    this.timer = new IdleTimer({
      onTimeout: () => {
        this.idleTimeIsExpired = true
        //Attivare la cinematics dopo inattività - Valido sia per onPremise che Cloud
        //quindi bScreenSaver è true
        if(this.idleTimeIsExpired && this.streamingAvailable){
           this.showCinematicEvent(this.idleTimeIsExpired,true)
        }
        this.chg.detectChanges()
      }
    });
  }


  set onFullScreenToggle(event: boolean) {
    this.fullScreen = event
    this.hotspots = event
    }

  public screenCastModalToggle(value: boolean | null = null) {
    if (!value) {
      this.showScreenCastModal = !this.showScreenCastModal
    } else {
      this.showScreenCastModal = value
    }
  }

  public onConfirmEmitter(isConfirmed: boolean) {
    this.showChangeConfigurationWindow = false;
    if (!isConfirmed) {
      if (this.conflictsData.toAdd) {
        this.conflictsData.toAdd.forEach(item => {
          this.carConfigurationService.updateConfiguration(item.id, '0')
        })
      }
      if (this.conflictsData.toRemove) {
        this.conflictsData.toRemove.forEach(item => {
          this.carConfigurationService.updateConfiguration(item.id, '1')
        })
      }
    }
  }

  public onShowChangeConfigurationAlertBar(eventData: IConflictsData) {
    this.showChangeConfigurationAlertBar = true
    this.conflictsData = eventData

    // If the user doesn't click on "View Details" closes the bar after 6 secs
    this.showChangeConfigurationAlertBarTimeout = setTimeout(() => {
      this.showChangeConfigurationAlertBar = false;
      this.chg.detectChanges()
    }, 6000);
  }

  public showChangeConfigurationAlertWindow() {
    this.showChangeConfigurationWindow = true
    this.showChangeConfigurationAlertBar = false
    clearTimeout(this.showChangeConfigurationAlertBarTimeout)
  }

  openTridentModal( ){
    this.isTridentModalOpen = true
  }

  public onStopMirroring() {
    if(this.screenCastActive) {
      console.log('ON STOP MIRRORING...')
      this.mirroringStoppedByUser = true
      this.zone.run(() => {
        this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: false}))
      })
      this.autobahnClient.onLeaveScreenCast(this.ephemeralDeviceID)
    }
  }

  public togglePresenterScreenSidebar(e: boolean) {
    this.store.dispatch(UPDATE_VIEW_UI_STATE({viewUi: e}))
    this.autobahnClient.setToggleFullScreen(this.ephemeralDeviceID, e)
  }

  public getModelPrice() {
    if(!this.carModel) return '';
    let carTotalPrice = this.carConfigurationService.getTotalPrice() ?? this.carDetails.price;
    if(this.uiCommonService.isPriceValid(carTotalPrice)){
      if (this.carModel.price && this.carModel.priceMask) {
        carTotalPrice = this.uiCommonService.formatPrice(carTotalPrice, this.carModel.currency, this.carModel.priceMask)
      }
      if(this.screenCastActive && this.ephemeralDeviceID) 
        this.autobahnClient.setCarTotalPrice(this.ephemeralDeviceID, carTotalPrice)
      return ` - ${carTotalPrice}`
    } 
    if(this.screenCastActive && this.ephemeralDeviceID) 
      this.autobahnClient.setCarTotalPrice(this.ephemeralDeviceID, '')
    return ''
  }

  public tridentModalStatusController(modalStatus) {
    this.isTridentModalOpen = modalStatus;
    if(!modalStatus)this.updateAnalyticsPageNameEvt.emit()
  }

  public onPropagateSearchBoxEvent(value: string) {
    if(this.screenCastActive)
      this.autobahnClient.onSearch(this.ephemeralDeviceID, value)
    this.searchValue = value
  }

  public onPropagateSearchBoxToggleEvent(value: boolean) {
    if(this.screenCastActive)
      this.autobahnClient.onSearchToggle(this.ephemeralDeviceID, value)
    this.searchToggleValue = value
  }

  public onPackageDetailsEmitter(value: number) {
    if(this.screenCastActive)
      this.autobahnClient.onPackageDetailsToggle(this.ephemeralDeviceID, value)
  }

  public onClosedAccordionsEmit(value: string) {
    this.closedAccordions.push(value)
  }

  public onOpenedAccordionsEmit(value: string){
    if(this.closedAccordions.includes(value))
      this.closedAccordions = this.closedAccordions.filter(a => a != value)
  }
  
  getCustomConfiguration(customOptToFind : string) : any{
    var carConfigurationSerialized = this.carConfigurationService.serializeCarConfiguration()
    var parsedConfiguration = this.carConfigurationService.parseCarConfigurationFromString(carConfigurationSerialized);
    var objFound = parsedConfiguration.find(x => x.group == customOptToFind)
    return objFound
  }

  

  async save() {
    this.saveClicked = true;

    //if (this.host !== 'localhost:8080' && !this.hostName.includes('local')) {
      // let modelDesc = this.carModel.commercialName;
      // const iFrameParam = this.saveFrameLink.indexOf('?') >= 0 ? '&iframe=true' : '?iframe=true'
      // let intSelectedColor = this.getCustomConfiguration("INT")._selectedColor;
      // let extSelectedColor = this.getCustomConfiguration("EXT")._selectedColor;
      // let selectedRims = this.getCustomConfiguration("RIMS").id;
      // let custom_int = this.getLabelForOpt('INT', 'opt_color', intSelectedColor)
      // let custom_ext = this.getLabelForOpt('EXT', 'opt_color', extSelectedColor);
      // let custom_rims = this.getLabelForOpt(selectedRims, 'opt_name');
      // //If configId is null or empty calls BE service to obtain custom_config_id
      // if (this.configId == null || this.configId == '') {
      //   this.callSaveService().then(value => {
      //     this.saveFrameLinkWithParams = this.saveFrameLink +
      //       iFrameParam +
      //       '&sl_context=MXE' +
      //       '&country=' + this.countryCode +
      //       '&dealercode=' + this.dealerId +
      //       '&modelName=' + this.carModel.modelName +
      //       '&modelcode=' + this.carModel.modelCode +
      //       '&custom_configId=' + value +
      //       '&custom_modelDesc=' + modelDesc +
      //       '&custom_ext=' + custom_ext +
      //       '&custom_int=' + custom_int +
      //       '&custom_rims=' + custom_rims
      //     this.safeUrlWithParams = this.sanitizer.bypassSecurityTrustResourceUrl(this.saveFrameLinkWithParams)
      //     this.showSaveModal = true;
      //     this.configId = value;
      //     this.chg.detectChanges()
      //     this.saveTemporaryConfiguration();
      //     this.adobeTrackSave()
      //   })
      // } else {
      //   this.saveFrameLinkWithParams = this.saveFrameLink +
      //     iFrameParam +
      //     '&sl_context=MXE' +
      //     '&country=' + this.countryCode +
      //     '&dealercode=' + this.dealerId +
      //     '&modelName=' + this.carModel.modelName +
      //     '&modelcode=' + this.carModel.modelCode +
      //     '&custom_configId=' + this.configId +
      //     '&custom_modelDesc=' + modelDesc +
      //     '&custom_ext=' + custom_ext +
      //     '&custom_int=' + custom_int +
      //     '&custom_rims=' + custom_rims
      //   this.safeUrlWithParams = this.sanitizer.bypassSecurityTrustResourceUrl(this.saveFrameLinkWithParams)
      //   this.showSaveModal = true;
      //   this.chg.detectChanges()
      //   this.adobeTrackSave()
      // }

    // } else {  
    if(this.configId == null || this.configId == '') {
      this.callSaveService().then(value => {
        this.configId = value;
        this.saveTemporaryConfiguration();
        this.showCustomSaveModal = true;
        this.saveClicked = false;
        this.adobeTrackSave()

        this.chg.detectChanges()
      }) 
    } else {
      this.showCustomSaveModal = true
      this.saveClicked = false
      this.adobeTrackSave()
      this.chg.detectChanges()
    }    
     
    // }
  }  

  public isSaveButtonAvailable: boolean = true;
  getLabelForOpt(id: string, type = '', color = '') {
    return this.uiCommonService.getLabel(id, type, color, this.carModel.modelCode);
  }

  async saveFromCustomFormSave(){
    this.isSaveButtonAvailable = false;
    let modelDesc = this.carModel.commercialName;
    let payload;
    let intSelectedColor = this.getCustomConfiguration("INT")._selectedColor;
    let extSelectedColor = this.getCustomConfiguration("EXT")._selectedColor;
    let selectedRims = this.getCustomConfiguration("RIMS").id;
    let custom_int = this.getLabelForOpt('INT', 'opt_color', intSelectedColor)
    let custom_ext = this.getLabelForOpt('EXT', 'opt_color', extSelectedColor);
    let custom_rims = this.getLabelForOpt(selectedRims, 'opt_name');
    let country = this.countryCode
    let payloadObj ={
      country: country,
      language: this.languageId,
      wtl_language: this.languageId.toUpperCase(),
      wtl_source: "",
      wtlglobal: "",
      notes: "",
      sourceCampaign: "",
      first_name: this.customerNameValue,
      last_name: this.customerSurNameValue,
      mobileInput: this.mobileInputValue && this.mobileInputValue.number ? this.mobileInputValue.number.replace(/\s+/g,"") : "",
      mobile: this.mobileInputValue && this.mobileInputValue.number ? (this.mobileInputValue?.dialCode+this.mobileInputValue?.number)?.replace(/\s+/g,"") : "",
      email: this.emailValue,
      type_of_request: "More Info",
      model_of_interest: this.modelOfInterest,
      preferred_dealer: this.dealerId,
      custom_saveonly: "true",
      custom_configId: this.configId.toString(),
      custom_modelDesc: modelDesc,
      custom_int: custom_int.toString(),
      custom_ext: custom_ext.toString(),
      custom_rims: custom_rims.toString(),
      modelName: this.carModel.modelName,
    }
    //For USA
    if(this.countryCode == '1'){
      payloadObj = {
        ...payloadObj,
        privacy: "1",
        newsletterCollection: "1",
        newsletter: "1"
      } as any;
      payload = new URLSearchParams(payloadObj).toString();
    } else {
      payloadObj = {
        ...payloadObj,
        sl_context: 'MXE',
        forcenosalesforce: "",
        privacy_direct_marketing: this.getPrivacyAndProfileDirectMarketingChoices('privacy_direct_marketing--yes'),
        privacy_dm_channel_mail: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_mail'),
        privacy_dm_channel_phone: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_phone'),
        privacy_dm_channel_social: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_social'),
        privacy_dm_channel_mobile: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_mobile'),
        privacy_dm_channel_fax: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_fax'),
        privacy_dm_channel_email: this.getPrivacyAndProfileDirectMarketingChoices('privacy_dm_channel_email'),
        privacy_profiling: this.getPrivacyAndProfileDirectMarketingChoices('privacy_profiling--yes')
      } as any;
      payload = new URLSearchParams(payloadObj).toString();
    }
    let endpointsaveWTLjson = environment.endpointsaveWTLjson;
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    .set('Accept', '*/*');
    this.httpClient.post(endpointsaveWTLjson,payload,{ headers: headers }).subscribe((result : any) =>{
      if(result && result.digitalData_component_form){
        this.adobeFormCompletion(result.digitalData_component_form.oid)
      }
      this.closeSaveModal(true);
    })
  }

  adobeFormCompletion(oid){
    window.adobeDataLayer.push({
      "event": "CC_saveConf",
      "data": {
        "component": {
          "form": {
            "oid": oid,
            "stepName": "thank_you",
            "stepNumber": "2"
          },
          "configurator": {
            "configurationID": this.configId
          }
        },
      },
    });
  }

  getPrivacyAndProfileDirectMarketingChoices(value: string){
   var labelForValue = document.querySelector(`label[for=${value}]`);
    var ariaCheckedValue = labelForValue?.getAttribute("aria-checked");
    ariaCheckedValue = ariaCheckedValue == 'true' ? 'Yes' : 'No'
    return ariaCheckedValue;
  }

  private async callSaveService(): Promise<string> {
    return new Promise((resolve, reject) => {
      const payload: GetSaveConfigurationRequest = {
        country: +this.countryCode,
        modelCode: +this.carModel.modelCode,
        config: this.carConfigurationService.serializeCarConfiguration(),
        toFreeze: true,
        emailAddress: "",
        sendEmail: "sendToMe",
        firstName: ""
        
      };
      this.maseratiService.saveCarConfig(payload).subscribe({
        next: (value) => {
            if (value.errorCode > 0) {
              console.error(value.errorMessage);
              throw value
            }
            else {
              this.ngZone.run(
                () => {
                  this.store.dispatch(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED({configId: value.result.configId}))
                }
              )
              resolve(value.result.configId)
            }
        },
        complete: () => 
        {
          console.info('[MASERATI SERVICE] saveCarConfig call completed')
        },
        error: (e) => 
        {
          if(!environment.production){
            console.error(e);
          }
          reject(e)
        }
      });
    })
  }

  private adobeTrackSave() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "car-configurator:configuration-save",
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }

  private saveTemporaryConfiguration() {
    const sendPdfCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.PDF)
    if (sendPdfCTA && sendPdfCTA.link != '') {
      this.sendPdfBasePath = sendPdfCTA.link
      console.debug(sendPdfCTA.link)
    }
    let temporaryConfiguration: ConfigItem = {
      carName: this.carModel.commercialName,
      modelYear: this.carModel.yearModel,
      savedOn: new Date().getTime(),
      options: this.modelOptions,
      defaultOptions: this.defaultOptions,
      hiddenOptions: this.hiddenOptions,
      imageLayers: this.imageLayers,
      optionals: this.optionalsBinding,
      configId: this.configId,
      modelCode: this.carModel.modelCode,
      pdfLink: this.sendPdfBasePath,
      imageView: this.imageView
    }
    this.ngZone.run(
      () => {
        this.store.dispatch(TEMP_CONFIG_ADD({configItem: temporaryConfiguration}))
      }
    )
    console.log(`Configuration ${this.configId} saved as temporary configuration`)
  }

  closeSaveModal(showThankYou = false){ 
    this.showSaveModal = false
    this.showCustomSaveModal = false;
    this.isSaveButtonAvailable = true;
    this.updatePageInfoAfterPopupClosing()
    if(showThankYou)
      this.toggleThankYouPage()
    document.querySelector('#legal-notes')?.remove()
    this.chg.detectChanges()
  }

  toggleThankYouPage(){
    this.showThankYouPage = !this.showThankYouPage;
    if(this.screenCastActive){
      const thankYouPageParams = 
      {
        showThankYouPage: this.showThankYouPage,
        configId: this.configId,
        carModel: this.carModel,
        country: this.countryCode,
        language: this.languageId,
        sendPdfBasePath: this.sendPdfBasePath,
        ctas: this.ctas
      }
      this.autobahnClient.setThankYouPage(this.ephemeralDeviceID, thankYouPageParams)
    }
   }
  
  closeHomeMenu() {
    this.homeMenuIsOpen = false
    this.updatePageInfoAfterPopupClosing()
  }

  onStandSetAloneEditingEmitter(standAloneSet) {
    this.standAloneSet = standAloneSet
    if(this.screenCastActive)
      this.autobahnClient.setStandAloneSetEditing(this.ephemeralDeviceID, standAloneSet)
  }

  onStandSetAloneEditingIntEmitter(standAloneSetInt) {
    this.standAloneSetInt = standAloneSetInt
    if(this.screenCastActive)
      this.autobahnClient.setStandAloneSetEditingInterior(this.ephemeralDeviceID, standAloneSetInt)
  }

  onHotspotsToggleEvent(event) {
    this.hotspots = event
  }

  audioOnOff(){
    // boolean for button animation
    this.isAudioOn = true
    setTimeout(() =>{
      this.isAudioOn = false
      this.chg.detectChanges();
    },2000)
    
    this.bAudio = !this.bAudio;
    
    this.autobahnClient.activeCinematicAudio(this.bAudio)
    if(this.screenCastActive){
      this.autobahnClient.setBAudioCinematic(this.ephemeralDeviceID,this.bAudio)
    }
  }

  showCinematicEvent(showCinematic : boolean, bScreensaver: boolean, triggeredFromEndSub: boolean = false){
    this.isCinematicActive = showCinematic
    if(showCinematic){
      if(this.cinematicNamesList && this.cinematicNamesList.length > 0 && this.cinematicNamesList[0].length > 0){
        this.autobahnClient.activeCinematicMode(this.cinematicNamesList[0], bScreensaver)
        this.onFullScreenToggle = true;
        this.showCinematic = true;
        this.showCinematicLogo = true;
        this.showCinematicText = true;
        this.textAnimationActive = false;        
        setTimeout(() => {
          this.textAnimation();
        }, 1000);
        if(this.screenCastActive){
          this.autobahnClient.setToggleFullScreen(this.ephemeralDeviceID, false)
        }
        setTimeout(() => {
          this.showCinematicLogo = false;
          this.showCinematicText = false;
          this.textAnimationActive = false;
          if(this.screenCastActive && !bScreensaver){
            this.autobahnClient.setShowCinematicLogo(this.ephemeralDeviceID,this.showCinematicLogo)
            this.autobahnClient.setShowCinematicText(this.ephemeralDeviceID,this.showCinematicText)
          }
          this.chg.detectChanges();
        }, 7000);
        this.chg.detectChanges();
      }
    }
    else{
      //sto spegnendo la cinematica, muto a prescindere audio
      if(!triggeredFromEndSub) {
      this.autobahnClient.activeCinematicAudio(false);
      this.autobahnClient.stopCinematicMode();
      }
      this.onFullScreenToggle = false;
      this.showCinematic = false;
      //Stop music
      this.bAudio = false;
      if(this.showCinematicEventFromSummary){
        this.showCinematicEventFromSummary = !this.showCinematicEventFromSummary
        this.openSummary();
      }
      //Stop Cinematic when opened like screenSaver
      if(this.idleTimeIsExpired){
         this.continueBrowsing();
      }
      this.canSkipCinematic = false;
      this.audioButtonVisible = false;
      if(this.screenCastActive){
        this.autobahnClient.setToggleFullScreen(this.ephemeralDeviceID, this.viewUi)
      }
      this.chg.detectChanges();
    }
    if(this.screenCastActive){
      if(!bScreensaver){
        this.autobahnClient.setShowCinematic(this.ephemeralDeviceID, 
          this.showCinematic, this.showCustomerName, this.commercialName, this.customerName, this.customerSurname,this.showCinematicLogo,this.showCinematicText,
      this.textAnimationActive)
      }
    }
  }

  textAnimation(){
    setTimeout(() =>{
      this.textAnimationActive = true;
      this.chg.detectChanges();
    },500)
    let textWrapper = document.querySelector(".ml12");
    if(textWrapper){
      textWrapper.innerHTML = textWrapper.textContent!.replace(/\S/g, "<span class='letter'>$&</span>");
      this.chg.detectChanges();
      //easeOutExpo PARTE A INIZIO 
      anime.timeline({loop: false})
        .add({
          targets: '.ml12 .letter',
          translateX: [40,0],
          translateZ: 0,
          opacity: [0,1],
          easing: "easeOutExpo",
          duration: 2000,
          delay: (el, i) => 800 + 50 * i
        }).add({
              targets: '.ml12 .letter',
              translateX: [0,-30],
              opacity: [1,0],
              easing: "easeInExpo",
              duration: 1200,
              delay: (el, i) => 100 + 30 * i
         });
        this.textAnimationActive = false;
        this.chg.detectChanges();
    }
  }

  showCinematicFromSummaryEvent(showCinematicEventFromSummary : boolean, bScreenSaver: boolean){
    this.showCinematicEventFromSummary = showCinematicEventFromSummary;
    if(this.showCinematicEventFromSummary){
      this.showCinematicEvent(true,bScreenSaver);
      this.closeSummary();
      this.chg.detectChanges();
    }
  }

  skipCinematic(){
    this.showCinematicEvent(false,false);
    // this.autobahnClient.activeCinematicAudio(false);
    // this.autobahnClient.stopCinematicMode();
    // this.openSummary();
  }

  private updatePageInfoAfterPopupClosing() {
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
        "pageInfo":
          { "pageName": "car-configurator" }
        }
      })
  }

  private emptyStore() {
    this.zone.run(() => {
      this.store.dispatch(UnrealDesiredSceneStateActions.RESET_SCENE_STATE())
    })
  }

  private updateScene(payload: UnrealState) {
    this.autobahnClient.onSetSceneState(payload)
  }

 private getCarModelDetails() {
    this.carGridStatus = ServiceStatus.pending;
    this.interfaceStatus = ServiceStatus.pending
    const payloadI: GetInterfaceRequest = {
      country: +this.country,
      modelName: this.carModel.modelName,
      modelCode: this.carModel.modelCode,
      mobile: 'false'
    };
    const payloadC: CarGridRequest = {
      country: +this.country,
      modelName: this.carModel.modelName,
      modelCode: this.carModel.modelCode
    };
    this.maseratiServiceSub$ = merge(
      this.maseratiService.getInterface(payloadI).
        pipe(map((interfaceResponse => (
          { service: 'Interface', interfaceResponse })
        )),
          catchError(error => of({ service: 'Interface', error }))
        ),
      this.maseratiService.getCarGrid(payloadC).pipe(map((carGridResponse => (
        { service: 'CarGrid', carGridResponse })
      )),
        catchError(error => of({ service: 'CarGrid', error }))
      )
    )
    .subscribe(
      {
        next: (response) => {
          if(response.service == 'Interface'){
            this.interfaceStatus = ServiceStatus.completed
            if(response['interfaceResponse']?.ERROR || (response as any).error){
              this.interfaceStatus = ServiceStatus.failed
              this.chg.detectChanges()
              let error = response['interfaceResponse']?.ERROR ?? (response as any)?.error
              this.sentryMessaging.logEvent('Error calling ICT services','error',{reason: error})
            } else{
              console.log('[Interface Response]: ', response['interfaceResponse'])
              this.store.dispatch(INTERFACE_LOADED({ interface: response['interfaceResponse'] }))
              this.store.dispatch(MENU_LOADED({ menuItems: response['interfaceResponse'].menu }));
              this.menuItems = response['interfaceResponse'].menu
              this.chg.detectChanges()
            }
          }
          if(response.service == 'CarGrid'){
            this.carGridStatus = ServiceStatus.completed
            if(response['carGridResponse']?.ERROR || (response as any).error){
              this.carGridStatus = ServiceStatus.failed
              this.chg.detectChanges()
              let error = response['carGridResponse']?.ERROR ?? (response as any)?.error
              this.sentryMessaging.logEvent('Error calling ICT services','error',{reason: error})
            }
            if(response['carGridResponse']){
              console.log('[CarGrid Response]: ', response['carGridResponse'])
              this.store.dispatch(CAR_GRID_LOADED({ carGrid: response['carGridResponse'] }))
              this.carDetails = response['carGridResponse'];
            }
            this.chg.detectChanges()
          }
        },
        error: (error: any) => { 
          if(!environment.production){
          console.error(error);
          } 
          
          this.destroy$.unsubscribe();
          this.chg.detectChanges();
          throw new Error('Error calling ICT services')
        },
        complete: () => {
          if((this.carGridStatus == ServiceStatus.failed || this.interfaceStatus == ServiceStatus.failed) && this.index == 0){
            this.index += 1
            setTimeout( () => {
              this.getCarModelDetails()
            },6000)
          }
          else if(this.index == 1){
            this.showRefreshLabel = true;
            this.chg.detectChanges();
          }
          else{
            console.debug('Model details retrieved successfully');
            this.carConfigurationService.initEngine(this.carDetails)
            this.maseratiServicesCompleted = true;
            this.maseratiServicesCompleted$.next(true);
            this.chg.detectChanges();
            this.showContentData = true
            this.chg.detectChanges();
            if(this.screenCastActive) {
              const parameters = {
                priceStatus: this.priceStatus,
                packages: this.modelPacks,
                carModel: this.carModel,
                menuItems: this.menuItems,
                modelOptions: this.modelOptions,
                closedAccordions: this.closedAccordions
              }
              this.joinSessionOnScreenCast();
            }
          }
        }
      }
    )
    
  }

  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }  

  refreshPage(){
      this.autobahnClient.resetMachineIdAndSessionId();
      if(this.screenCastActive) {
        this.onStopMirroring();
        this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID).then()
        .catch((reason:any) => {
          if(!environment.production){
            console.error('Stop Monkeyway Session Went Wrong', reason)
          } else{
            console.error('Stop Monkeyway Session Went Wrong')
          } 
         })
        .finally(() => {
          this.unsubscribeAllSubscriptions();
        })
      } else {
        this.unsubscribeAllSubscriptions();
      }
    setTimeout(() => {
      window.location.reload();
    },300)
  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }
  
  closeSummary() {
    this.showSummaryModal = false
    if(this.screenCastActive){
      this.setSummaryOnScreenCast();
    }
    this.updatePageInfoAfterPopupClosing()
  }


  public onSummaryNavigation: boolean;

  openSummary() {
    this.onSummaryNavigation = true;
    this.chg.detectChanges();
    setTimeout(() => {
      try{
        this.unformattedBasePrice = this.carConfigurationService.getBasePrice()
        this.unformattedTaxes = this.carConfigurationService.getTaxes()
        this.unformattedEquipments = this.carConfigurationService.getPersonalizationPrice()
        if(this.configId == ''){
          this.callSaveService().then(
            () => {
              this.onSummaryNavigation = false
              this.showSummaryModal = true;
              if(this.screenCastActive){
                this.setSummaryOnScreenCast();
              }
              this.chg.detectChanges()
            }
          )
        } else {
          this.onSummaryNavigation = false;
          this.showSummaryModal = true;
          if(this.screenCastActive){
            this.setSummaryOnScreenCast();
          }
          this.chg.detectChanges()
        }
      } catch(error: any) {
        this.onSummaryNavigation = false;
        this.chg.detectChanges()
      }
    }, 1000);

  }

  openCinematicOrSummary(){
    this.showPopUp = false;
    (!this.isCinematicActiveForSelectedTrim || !this.streamingAvailable) ? this.openSummary() : this.showCinematicEvent(true,false)
  }

  private setSummaryOnScreenCast() {
    this.autobahnClient.setSummary(this.ephemeralDeviceID, 
      {
        optionalsBinding: this.optionalsBinding,
        defaultOptions: this.defaultOptions,
        imageView:this.imageView,
        imageLayers:this.imageLayers,
        ctas: this.ctas,
        config: this.desiredUnrealConfig,
        priceStatus: this.priceStatus,
        hiddenOptions:this.hiddenOptions,
        showSummary: this.showSummaryModal,
        country: this.country,
        language: this.languageId,
        optionsForAnalytics: this.optionsForAnalytics,
        configId: this.configId,
        dealerId: this.dealerId,
        customerName: this.customerName,
        customerSurname: this.customerSurname,
        showCustomerName: this.showCustomerName,
        basePrice: this.unformattedBasePrice,
        taxes: this.unformattedTaxes,
        equipments: this.unformattedEquipments,
        streamingAvailable: this.streamingAvailable,
        commercialName : this.commercialName,
        showCinematicEventFromSummary : this.showCinematicEventFromSummary
      });
  }

  toggleAccordion(event: object) {
    if(this.screenCastActive){
      this.autobahnClient.setAccordion(this.ephemeralDeviceID, event)
    }
  }

  onScreenCastActiveEmitter(value) {
    this.screenCastActive = value
  }

  onaccordionExpEvent(event) {
    this.accordionExp = event
  }
  
  onPackageSwiperSlideChanged(event) {
    if(this.screenCastActive) {
      this.autobahnClient.setCarConfiguratorPackageSlideIndex(this.ephemeralDeviceID, event)
    }
  }  

  /**
   * Event triggered from Summary COmponent
   */
  onOpenSaveModalEvent() {
    this.save()
    this.showSummaryModal = true
  }
 
  onIsCinematicStarted(event){
    this.isCinematicStarted = event;
  }

  testPipeline() {
    this.isPipelineLoading = true;
     this.renderPipelineService.getProducts(this.unrealSelectedModelName).subscribe({
        next: (product: any) => {
          console.debug("PPRODUCT",product)
          if(product){
            let availableOption = product.metadata.available_options;
            let actualConfig = new Set(this.desiredUnrealConfig)
            let config = availableOption.filter(element => actualConfig.has(element));
            let unCommonOpts = this.desiredUnrealConfig.filter(element => !availableOption.includes(element));
            console.debug("OPTS NON AVAILABLE IN COBA",unCommonOpts)
            let typeofCam = ['_ext_','_int_steeringwheel']
            typeofCam.forEach( cam => {
              let body = this.renderPipelineService.renderImgsMapper(product,config,cam)
              this.renderPipelineService.createImgs(body).subscribe({
                next: (resp: any) => {
                  console.debug("RESPONSE POST IMAGED",resp)
                  let url=''
                  if(resp && resp[0] && resp[0].url){
                    url = new URL(resp[0].url).pathname.slice(1)
                    if(url && url.length > 0){
                      this.renderPipelineServicePollImages$ = this.renderPipelineService.pollImages(url).pipe(
                        finalize(() => {
                          this.isPipelineLoading = false;
                          this.chg.detectChanges();
                        }) 
                      ).subscribe({
                        next: (resp: any) => {
                          this.unsafeRenderPipelineImgUrl = URL.createObjectURL(resp);
                          this.unsafeRenderPipelineImgUrlArray.push(this.unsafeRenderPipelineImgUrl) 
                          this.renderPipelineImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeRenderPipelineImgUrl)
                          console.debug("RESPONSE IMMAGINE",resp)
                          console.debug("RESPONSE URL", this.renderPipelineImgUrl)
                          this.renderPipelineImgUrlArray.push(this.renderPipelineImgUrl)
                          this.chg.detectChanges();
                        },
                        error:(error:any) => {
                          this.interactiveBottomBar.pipelineActive = false
                          console.error("ERROR DURING RETRIEVING POLL",error)
                          this.chg.detectChanges();
                        },
                      })
                    }
                  }
                },
                error:(error:any) => {
                  this.isPipelineLoading = false
                  this.interactiveBottomBar.pipelineActive = false
                  console.error("ERROR CREATE IMAGES",error)
                  this.chg.detectChanges();
                }
              })
            });
          }
        },
        error: (error: any) => {
            this.isPipelineLoading = false
            this.interactiveBottomBar.pipelineActive = false
            if(!environment.production){
              console.error("ERROR GET PRODUCT BY ID",error)
            }
            this.chg.detectChanges();
        }
     })
  }

  downloadImg(index){
    const fileName = `carImg0${index+1}`;
    const a = document.createElement('a');
    let unsafeRenderImg = this.unsafeRenderPipelineImgUrlArray[index]
    a.href = unsafeRenderImg;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(unsafeRenderImg);
  }

  
  closeImgPipeline(){
    this.renderPipelineImgUrl = ''
    this.interactiveBottomBar.pipelineActive = false
    this.renderPipelineImgUrlArray = [];
    this.unsafeRenderPipelineImgUrlArray = [];
    if(this.unsafeRenderPipelineImgUrl && this.unsafeRenderPipelineImgUrl.length > 0){
      URL.revokeObjectURL(this.unsafeRenderPipelineImgUrl)
      this.unsafeRenderPipelineImgUrl = ''
    }
    this.renderPipelineServicePollImages$?.unsubscribe();
  }
}