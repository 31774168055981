import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Pack, UIOptItem, UIOptItemInSet } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IDomElementData, IMAGE_TYPE_ENUM, LABEL_TYPE_ENUM, MENU_ENUM } from 'src/app/core/models/common.model';
import { Menu } from 'src/app/core/models/interface-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);
@Component({
  selector: 'app-packages-configuration',
  templateUrl: './packages-configuration.component.html',
  styleUrls: ['./packages-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ 
      trigger('openCloseAcc', [
        state('open', style({ 
            'height': '*'
          })),
        state('closed', style({ 
            'height': '0px'
          })),
        transition('open <=> closed', [
        animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
        ])
  ]
})

export class PackagesConfigurationComponent {

  @ViewChildren(SwiperComponent) swipers : QueryList<SwiperComponent>
  @Input() options: UIOptItem[];
  @Input() menu: Menu[]
  @Input() packages: Pack[]
  @Input() modelCode: string
  @Input() currency: string
  @Input() priceMask: string
  @Input() showPrices: boolean; 
  @Input() isMainScreen: boolean;
  @Input() configId: string;
  @Input() optionsForAnalytics: string;
  @Input() dealerId: string;
  @Input() modelName: string;
  @Input() commercialName: string;
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != '') this.inputText = inputText.toLowerCase()
    else this.inputText = text
  } 
  @Input() standAloneSet = {}
  @Input() set packageDetails(i: any) {
    this.toggleAccordionDetailsStatus(i?.packIndex, null)
  }
  @Input() accordionExp: number = 0;

  @Input() set packagesSwiperSlideChange(packagesSwiperSlideChange: {imageIndex: number, packageIndex: number}) {
    if(packagesSwiperSlideChange) {
      if(this.swipers) {
        let swiperComponent: SwiperComponent | undefined = this.swipers.get(packagesSwiperSlideChange.packageIndex)
        if(swiperComponent) {
          swiperComponent.swiperRef.slideTo(packagesSwiperSlideChange.imageIndex)
          swiperComponent.swiperRef.update();
          swiperComponent.swiperRef.pagination.update();
        }
      }
    }
  }
  @Input() formatSelection : string;
  @Input() set accordionOpenCloseSetter(value: boolean){
     this.accordionOpened = value;
     this.chg.markForCheck();
  }
 
  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()
  @Output() showPackageDetailEmitter: EventEmitter<{packIndex: number, opened: boolean}> = new EventEmitter<{packIndex: number, opened: boolean}>()
  @Output() standAloneSetEditingEmitter: EventEmitter<{}> = new EventEmitter<{}>()
  @Output() accordionExpEvent: EventEmitter<number> = new EventEmitter<number>()
  @Output() swiperSlideChanged: EventEmitter<{imageIndex: number, packageIndex: number}> = new EventEmitter<{imageIndex:number, packageIndex: number}> ()
  
  showModal: boolean = false;
  packageSelected: any = "";
  selectedOption: string = '';
  optionActive: any = '';
  accCounter: number = 0;
  menuItems: Menu | undefined;
  options$: Subscription;
  menuLabel: string = '';
  accordionOpen: boolean = false;
  standAloneSetEditing: {}
  selectedItemsInPackage: {}
  accordionOpened : boolean = true;
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;
  heightArray : any[] = [];
  setHeightDiv : number;
 
  
  private inputText: string;
  private domOptionElements: IDomElementData[] = [];

  constructor(
    public uiCommonService: UiCommonService,
    private carConfigurationService: CarConfigurationService,
    private el: ElementRef,
    private mxeAnalyticsService: MxeAnalyticsService,
    private store: Store<MxeReducers>,
    private autobahn : AutobahnClient,
    private chg : ChangeDetectorRef
  ) {
    
  }

  
  ngOnInit(): void {
    this.menuItems = this.menu.find(x => x.id === MENU_ENUM.PACK)
    if(this.menuItems?.id){
      this.menuLabel = this.menuItems?.id;
    }
    else{
      this.menuLabel = 'Packages';
    }
    this.standAloneSetEditing = this.standAloneSet["standAloneSetEditing"] ?? {}
    this.selectedItemsInPackage = this.standAloneSet["selectedItemsInPackage"] ?? {}
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
    this.accordionOpened = true;
    this.chg.detectChanges();
    
  }
  
  ngAfterViewInit(){
    if(this.isMainScreen) {
      this.formatSelectionDetails();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes["standAloneSet"] && changes["standAloneSet"].currentValue != changes["standAloneSet"].previousValue && !this.isMainScreen){
      const newValue = changes["standAloneSet"].currentValue
      this.standAloneSetEditing = newValue["standAloneSetEditing"]
      this.selectedItemsInPackage = newValue["selectedItemsInPackage"] 
    }
  }

  ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }

  /**
   * Triggered when an item of the list is selected
   * @param itemId 
   * @param packageId 
   * @param setId 
   */  
   onSetListItemChange(itemId: string, packageId: string, setId: string) {
    this.accordionOpen = false;
    this.selectedItemsInPackage[`${packageId}_${setId}`] = itemId
    this.standAloneSetEditing[`${packageId}_${setId}`] = false
    this.standAloneSetEditingEmitter.emit({ standAloneSetEditing: this.standAloneSetEditing, selectedItemsInPackage: this.selectedItemsInPackage})
  }

  /**
   * Changes the configuration selecting or deselecting the given option
   * @param packageId - option id
   * @param isSelected - option status
   */
  changeConfiguration(packageId: string, isSelected: boolean) {
      let res = this.carConfigurationService.checkConflicts(packageId, isSelected ? '0' : '1');
      this.formatSelectionDetails();
      //Check if it's needed
      const sets = this.packages.find(p => p.packageId === packageId)?.standAlone.sets
        sets?.forEach(set => {
          const selectedItem = this.selectedItemsInPackage[`${packageId}_${set.id}`]
          if(selectedItem){
            const setRes = this.carConfigurationService.checkConflicts(selectedItem, '1')
          }
        })
      
      if(
        (!isSelected && res.changes.toRemove.length > 0) 
        || 
        (!isSelected && res.changes.toAdd.length > 1) 
        ||
        (isSelected && res.changes.toAdd.length > 0)
        ||
        (isSelected && res.changes.toRemove.length > 1))
        {
        this.changeConfigurationEmitter.emit(res.changes)
      }
  }

  getImagePath(tab: string, optionId: string): string {
    return this.carConfigurationService.getImagePath(this.modelCode, tab, optionId, IMAGE_TYPE_ENUM.INFO_1400_875)
  }

  getLabel(id: string, type: string = '') {
    return this.uiCommonService.getLabel(id, type, '', this.modelCode);         
  }

  /**
   * Returns an array of image paths
   * @param id packageId
   * @returns 
   */
  getOptionsImageInPackage(id: string): string[] {
    const pack = this.packages.find(p => p.packageId === id)
    let imagePaths: string[] = []
    pack?.implicits.forEach(item => {
      if(item.group) 
        imagePaths.push(this.getImagePath(item.group, item.id))
    })

    const swiperBullets = document.querySelectorAll(`.${id} .swiper-pagination .swiper-pagination-bullet`)
    swiperBullets.forEach((element, index) => {
      element.setAttribute('data-linktracking', `cta:configurator:accordion:menu_pack:pack:${pack?.packageObj.section}:${id}:dots-${index + 1}`)
    })

    return imagePaths
  }

  /**
   * Get translations for opts in pack
   * @param id - packageId
   * @returns 
   */
  getPackageImplicitsDescriptions(id: string): string[] {
    const pack = this.packages.find(p => p.packageId === id)
    let description: string[] = []
    if(pack){
      pack.implicits.forEach(opt => {
        description.push (this.uiCommonService.getLabel(opt.id, LABEL_TYPE_ENUM.NAME, '', this.modelCode))
      })
      pack.standAlone.options.forEach(opt => {
        description.push (this.uiCommonService.getLabel(opt.id, LABEL_TYPE_ENUM.NAME, '', this.modelCode))
      })
    }
    return description
  }
  
  /**
   * Gets the preset item in standalone options for a given package and set
   * @param packageId 
   * @param setId 
   * @returns 
   */
   getPresetInSet(packageId: string, setId: string): string {
    return this.packages.find(p => p.packageId === packageId)?.standAlone.sets.find(s => s.id === setId)?.list.find(o => o.preset)?.id || ''
  }

  /**
   * choosing defaultOfSet following this fallback chain:
   * 1- first selected item
   * 2- opt with preset (if selectable)
   * 3- first selectable item from left to right
   * 4- opt with preset (even it not selectable)
   * 5- first item (if preset doesnt exist)
   * @param packageId - the id of the package
   * @param setId - the id of the set
   */
  getSelectedItemInList(packageId: string, setId: string): UIOptItem {
    if(this.selectedItemsInPackage[`${packageId}_${setId}`]){
      return this.options.find(o => o.id === this.selectedItemsInPackage[`${packageId}_${setId}`])!
    } else {
      return this.getDefaultSelectedItemInList(packageId, setId)  
    }
  } 

  /**
   * Gets the Label's translation of the selected item in set
   * @param packageId 
   * @param setId 
   * @returns 
   */
  getSelectedItemTranslation(packageId: string, setId: string): string {
    const selectedItem = this.getSelectedItemInList(packageId, setId)
    if(selectedItem){
      return this.getLabel(selectedItem.id, LABEL_TYPE_ENUM.NAME)
    } 
    return ''
  }
 
  private getDefaultSelectedItemInList(packageId: string, setId: string) {
    let items = this.packages.find(p => p.packageId === packageId)?.standAlone.sets.find(s => s.id === setId)?.list!
    const firstSelected = items?.find(o => o.status.selected === true)
    if(firstSelected)
      return firstSelected
    const firstPreset = items?.find(o => o.status.selectable === true && o.preset === true)
    if(firstPreset)
      return firstPreset
    const firstSelectable = items?.filter(o => o.status.selectable === true)[0]
    if(firstSelectable)
      return firstSelectable
    const firstPresetEvenNotSelectable = items?.find(o => o.preset === true)
      if(firstPresetEvenNotSelectable)
        return firstPresetEvenNotSelectable
    return items[0]
  }

  /**
   * Gets all list items of a given set
   * @param packageId 
   * @param setId 
   * @returns 
   */
   getListItems(packageId: string, setId: string): UIOptItemInSet[] {
    const set = this.packages.find(p => p.packageId === packageId)?.standAlone.sets.find(s => s.id === setId)
    if(set)
      return set.list
    return []
  }

  /**
   * Show the list of the item of a given set
   * @param id 
   */
  showSetList(packageId:string, setId: string) {
    this.accordionOpen = !this.accordionOpen;
    this.standAloneSetEditing[`${packageId}_${setId}`] = !this.standAloneSetEditing[`${packageId}_${setId}`]
    this.standAloneSetEditingEmitter.emit({ standAloneSetEditing: this.standAloneSetEditing, selectedItemsInPackage: this.selectedItemsInPackage })
  }

  /**
   * Get package price
   * @param id - package id
   * @returns 
   */
  getPackagePrice(id: string) {
    return this.formatPrice(this.carConfigurationService.getPackagePrice(id))
  }

  /**
   * Formats the price
   * @param price - the price
   */
  formatPrice(price: string): string {
    if(this.uiCommonService.isPriceValid(price))
      return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
    return ''
  }

  toggleAccordion(dataaccordion: string, e): void {
    e.stopPropagation && e.stopPropagation()
    this.accordionOpened = !this.accordionOpened

    const boxes = [`#${dataaccordion}_header`, `#${dataaccordion}_content` ]

    boxes.forEach(box => {
      let myTag = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(myTag) {
        if(!myTag.classList.contains('closed')) {
          myTag.classList.add('closed');
        } else {
          myTag.classList.remove('closed');
        }
      }
    })
    if(this.screenCastActive && this.isMainScreen){
      this.autobahn.setAccOpenClose(this.ephemeralDeviceID,this.accordionOpened);
    }
  }

  /**
   * Toggles the visibility of the package's details
   * @param i the index of the package in the UI repeater
   * @param event 
   */
  toggleAccordionDetailsStatus(i:number, event){
    if(this.accordionExp === i+1){
      //chiudi
      this.accordionExp = 0;
      this.showPackageDetailEmitter.emit({packIndex: i, opened: false})
    }
    else{
      //apri
      this.accordionExp = i+1;
      this.showPackageDetailEmitter.emit({packIndex: i, opened: true})
    }
    this.accordionExpEvent.emit(this.accordionExp)
    if(event) {
      event.stopPropagation();
    }
  }

  /**
   * Get all options for a given section
   * @param sectionId 
   */
  getOptionsBySection(sectionId: string, filter_fuoriserie): Pack[] {
    if(this.options && this.packages) {
      let optionsIds: string[] = []
      if(filter_fuoriserie != null) {
      if(filter_fuoriserie === '1'){
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))  
        const options_with_fuoriserie_colors = this.options.filter(o => 
          o.section === sectionId 
          && o.fuoriserie === false 
          && o.colorList.length > 0 
          && o.colorList.filter(c => c.fuoriserie === true
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0)
          optionsIds = fuoriserie_options.concat(options_with_fuoriserie_colors).map(o => o.id)
      } else {
        optionsIds = this.options.filter(o => 
          o.section === sectionId 
          && o.fuoriserie === false 
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).map(o => o.id) 
      }
    } else {
      optionsIds = this.options.filter(o => 
        o.section === sectionId 
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).map(o => o.id) 
    }
      return this.packages.filter(p => optionsIds.includes(p.packageId)).sort((a, b) => optionsIds.indexOf(a.packageId) - optionsIds.indexOf(b.packageId))
    }
    return []
  }
 
  

  formatSelectionDetails(): string {    
    
      var formatSelection : string = "";
      const selectedPackages = this.packages.filter(p => p.packageObj.status.selected)
      const prices: number[] = selectedPackages.map(p => parseFloat(this.carConfigurationService.getPackagePrice(p.packageId)))
      const accumulated = prices.reduce((acc, value) => acc + value, 0)
      const selectedFormattedCumulativePrice = accumulated > 0 ? `- ${this.formatPrice(`${accumulated}`)}` : ''
      var selected = this.getLabel("MXE_SELECTED");
      var formatSelectionDetail = `${selectedPackages.length} ${selected} ${selectedFormattedCumulativePrice}`;
      if((formatSelectionDetail != null || formatSelectionDetail != undefined) && formatSelectionDetail.length > 0){
        var formatSplitted = formatSelectionDetail.split(" ")[0];
        if((formatSplitted != null || formatSplitted != undefined) && formatSplitted.length > 0){
          var numberOfSelected = parseInt(formatSplitted);
          if(numberOfSelected == 1){
            var selected = this.getLabel("MXE_SELECTED_SING_M");
            formatSelection = `${selectedPackages.length} ${selected} ${selectedFormattedCumulativePrice}`
          }
          if(numberOfSelected > 1){
            var selected = this.getLabel("MXE_SELECTED_PLUR_M");
            formatSelection = `${selectedPackages.length} ${selected} ${selectedFormattedCumulativePrice}`
          }
           
        }
        else{
          formatSelection = "";
        }
      }
    if(this.screenCastActive){
       this.autobahn.setPackagesFormatSelectionDetail(this.ephemeralDeviceID,formatSelection);
    }
      return formatSelection;
  }

  /**
   * Returns if the section is visible or not
   * @param sectionId 
   * @returns 
   */
  sectionIsVisible(sectionId: string, filter_fuoriserie: string): boolean {
    if(filter_fuoriserie != null) {
      if(filter_fuoriserie === '1'){
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))  
        const options_with_fuoriserie_colors = this.options.filter(o => 
          o.section === sectionId 
          && o.fuoriserie === false 
          && o.colorList.length > 0 
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        
        return fuoriserie_options.concat(options_with_fuoriserie_colors).length > 0
      }
      return this.options.filter(o => o.section === sectionId && o.fuoriserie === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
    }
    return this.options.filter(o => o.section === sectionId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }
  
  /**
   * Returns if a given tab is visible
   * @param tabId 
   * @returns 
   */
    isTabVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }

  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string = '', action: string = ''): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }
  
  //#region Swiper
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    speed: 1000,
    navigation: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  onSwiper(swiper: any) {
  }

  onSlideChange(event: any , packageIndex: number) {
    let imageIndex = event[0].activeIndex;
    this.swiperSlideChanged.emit({
      imageIndex,
      packageIndex
    });   
  }
  //#endregion
}
export interface ISelectedItemInSet{
  setId: string,
  itemId: string
}