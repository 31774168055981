import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Menu, Tab } from 'src/app/core/models/interface-service.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { UIOptItem } from 'src/app/core/models/car-grid.model';

@Component({
	selector: 'app-new-car-upper-menu',
	templateUrl: './new-car-upper-menu.component.html',
	styleUrls: ['./new-car-upper-menu.component.scss']
})
export class NewCarUpperMenuComponent implements OnInit {
	@ViewChild('menuWrapper') menuWrapperEl: ElementRef;
	@ViewChild('searchInput') searchInput: ElementRef;
	
	@Output() selectMenuItem: EventEmitter<string> = new EventEmitter();
	@Output() searchBoxEmitter: EventEmitter<string> = new EventEmitter();
	@Output() searchBoxToggleEvent: EventEmitter<boolean> = new EventEmitter();
	
	@Input() menu: Menu[]
	@Input() set activatedItem(value: number) {
		this.selectedMenuItem = value;
		if(this.wrapperHTMLElement){
			this.wrapperHTMLElement.scrollTo({left: value * 100 -100, behavior: 'smooth'});
		}
	}
	
	@Input() set searchBoxValue(e: string) {
		this.searchText = e
	}
	@Input() set searchToggleValue(e: boolean) {
		this.searchExpanded = e
	}
	@Input() options: UIOptItem[]
	@Input() languageId: string
	
	public searchText: string
	public selectedMenuItem: number = 0
	public menuItems: Menu[];
	public searchExpanded: boolean = false;
	
	private wrapperHTMLElement: HTMLElement;

	constructor(
		private uiCommonService: UiCommonService
	) { }

	ngOnInit(): void {
		this.menuItems = this.filterMenuItems()
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes['menu']) {
			this.menuItems = this.filterMenuItems()
		}
	}
	
	ngAfterViewInit(){
		this.wrapperHTMLElement = this.menuWrapperEl.nativeElement as HTMLElement;
	}

	private filterMenuItems(): Menu[] {
		let excludedIds: string[] = ['MENU_LINES']
		if(this.menu){
			this.menu.forEach(item => {
				if(!this.haveSectionsVisible(item)) {
					excludedIds.push(item.id)
				}
			})
			return this.menu.filter(m => !excludedIds.includes(m.id))
		}else{
			return []
		}
		
	}

	private haveSectionsVisible(menuItem: Menu): boolean {
		let ret: boolean = false
		const menuTabs: Tab[] | undefined = menuItem?.tabs
		const highlightTabs: Tab[] | undefined = menuTabs.filter(x => x.id == 'TAB_HIGHLIGHT')
		if (menuTabs && highlightTabs && highlightTabs.length > 0) {
			highlightTabs?.forEach(tab => {
				tab.sections?.forEach(sec => {
					if (sec.filter_highlight === "1") {
						ret = this.options.some(opt => opt.highlight);
					}
				})

			})
		}
		else {
			menuTabs?.map(x => x.id).forEach(tabId => {
				if(this.options.filter(o => o.group === tabId).length > 0) {
					ret = true
				}
			})
		}
        return ret;
	}

	getLabel(id: string) {
		return this.uiCommonService.getLabel(id);
	}

	/**
	 * Triggered whne the user type in the search box
	 */
	onSearchBoxValueChange(event: string) {
		this.searchBoxEmitter.emit(event)
	}

	/**
	 * Toggles the search box visibility. When closing reset the serach filters
	 */
	toggleSearchBox() {
		this.searchExpanded = !this.searchExpanded
		this.searchBoxToggleEvent.emit(this.searchExpanded)
		this.searchBoxEmitter.emit('')
		this.searchText = ''
		if(!this.searchExpanded){
			this.searchInput.nativeElement.blur()
		} else {
			this.searchInput.nativeElement.focus()
		}
	}

	onSelectMenu(label: string, index: number) {
		this.selectMenuItem.emit(label)
		this.selectedMenuItem = index;
		if(this.wrapperHTMLElement){
			this.wrapperHTMLElement.scrollTo({left: index * 100 -100, behavior: 'smooth'});
		}
	}
}