import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, finalize, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CarGridRequest, CarGridResponse } from '../models/car-grid.model';
import {
    GetCountryPropRequest,
    GetCountryPropResponse
} from '../models/country-prop-service.model';
import {
    Family,
    CarModel,
    GetModelsRequest,
    GetModelsResponse,
    Model,
    ModelCode,
    TechData,
} from '../models/get-models-service.model';
import {
    GetInterfaceRequest,
    GetInterfaceResponse,
} from '../models/interface-service.model';
import {
    GetLoadConfigurationRequest,
    GetLoadConfigurationResponse,
} from '../models/load-conf-service.model';
import { GetPrintSendRequest } from '../models/print-send.model';
import {
    GetSaveConfigurationRequest,
    GetSaveConfigurationResponse,
} from '../models/save-conf-service.model';
import { CONFIGURATION_LOADED } from '../store/actions/configuration-actions';
import { MODELS_LOADED } from '../store/actions/models/models-actions';
import { MxeReducers } from '../store/reducers';
import enabledCarFamilies from '../../../assets/enabled-car-families.json'
import { AppSettingsActions } from '../store/actions/app-settings/app-settings-exported-actions';
import { IWheelNormServiceResponse } from 'src/app/components/summary-configuration/summary-configuration.component';
import * as Sentry from '@sentry/angular-ivy';



@Injectable()
export class MaseratiService {
    constructor(
        private httpClient: HttpClient,
        private store: Store<MxeReducers>
    ) {}

    getInterface(
        payload: GetInterfaceRequest
    ): Observable<GetInterfaceResponse> {
        const options = {
            headers: new HttpHeaders({ content: 'application/json' }),
        };
        const model = payload.modelCode ?? payload.modelName;
        return this.httpClient.get<GetInterfaceResponse>(
            `${environment.maserati_services_endpoint}/ccbe/public/api/interface/${payload.country}/${model}`,
            options
        );
    }

    getCarGrid(payload: CarGridRequest): Observable<CarGridResponse> {
        const options = {
            headers: new HttpHeaders({ content: 'application/json' }),
        };
        const model = payload.modelCode ?? payload.modelName;
        return this.httpClient.get<CarGridResponse>(
            `${environment.maserati_services_endpoint}/ccbe/public/api/carGrid/${payload.country}/${model}`,
            options
        );
    }

    getCountryProp(
        payload: GetCountryPropRequest
    ): Observable<GetCountryPropResponse> {
        const options = {
            headers: new HttpHeaders({ content: 'application/json' }),
        };
        const model = payload.modelCode ?? payload.modelName;
        return this.httpClient.get<GetCountryPropResponse>(
            `${environment.maserati_services_endpoint}/ccbe/public/api/countryProp/${payload.country}/${model}/${payload.language}`,
            options
        )
    }

    saveCarConfig(
        payload: GetSaveConfigurationRequest
    ): Observable<GetSaveConfigurationResponse> {
        const options = {
            params: new HttpParams({ fromObject: { ...payload } }),
        };
        return this.httpClient.get<GetSaveConfigurationResponse>(
            `${environment.maserati_services_endpoint}/comserv/public/cc/saveConfiguration`,
            options
        );
    }

    /**
     * This service loads a saved configuration using an unique code (configId)
     * @param payload - an object containing the configId
     * @returns
     */
    loadCarConfig(
        payload: GetLoadConfigurationRequest
    ): Observable<GetLoadConfigurationResponse> {
        const options = {
            params: new HttpParams({ fromObject: { ...payload } }),
        };
        return this.httpClient
            .get<GetLoadConfigurationResponse>(
                `${environment.maserati_services_endpoint}/comserv/public/cc/loadConfiguration`,
                options
            )
            .pipe(
                tap((result) => {
                    //Save loaded configuration in store
                    this.store.dispatch(
                        CONFIGURATION_LOADED({ config: result.result })
                    );
                })
            );
    }

    /**
     * Get all available models for the given country and language
     * @param payload
     *
     * @returns
     */
    getModels(payload: GetModelsRequest): Observable<GetModelsResponse> {
        console.debug('[MaseratiService] - Getting models...' )
        const options = {
            headers: new HttpHeaders({ content: 'application/json' }),
            params: new HttpParams({ fromObject: { ...payload } }),
        };
        return this.httpClient
            .get<GetModelsResponse>(
                `${environment.maserati_services_endpoint}/ccbe/public/api/countryModel/countryModelList`,
                options
            )
            .pipe(
                tap((response) => {
                    const models = response.countries[0].models.filter((m) =>
                        enabledCarFamilies.familyCommercialCodes.includes(m.familyCommercialCode.toLocaleLowerCase()) && m.modelCodes  && m.modelCodes.length > 0
                    ).sort((a,b) => this.modelSelectorOrder(a.familyCommercialCode.toLocaleLowerCase(),b.familyCommercialCode.toLocaleLowerCase()));
                    const currency: string = response.countries[0].currency
                    const priceMask: string = response.countries[0].priceMask
                    
                    const pricesDefined = models.map(z => z.modelCodes).reduce((a,r) => a = a.concat(r)).filter(x => x.price > 0).length > 0
                    if(currency && priceMask && currency != '' && priceMask != '' && pricesDefined){
                        this.store.dispatch(AppSettingsActions.APP_SETTINGS_SET_PRICES_AVAILABLE({ arePricesAvailable: true }))
                        this.store.dispatch(AppSettingsActions.APP_SETTINGS_TOGGLE_PRICES({
                            showPrices: true,
                          }))
                    }

                    // Temporary fix. To Remove when the service response in prod will have the attribute active_mxe for every model
                    models.forEach(model => {
                        model.modelCodes.forEach(modelCode => {
                            if(!modelCode.hasOwnProperty("active_mxe")){
                                modelCode.active_mxe = true;
                            }
                        });
                    });

                    this.store.dispatch(
                        MODELS_LOADED({
                            families: models.map((model) =>
                                this.createModel(model, currency, priceMask)
                            ).filter(m => m.models && m.models.length > 0) ,  // Temporary fix. To Remove when the service response in prod will have the attribute active_mxe for every model
                        })
                    );
                })
            );
    }

    private modelSelectorOrder(a: string, b: string) {
        return enabledCarFamilies.familyCommercialCodes.indexOf(a) - enabledCarFamilies.familyCommercialCodes.indexOf(b)
    }

    /**
     * This method calls ICT service in order to create and download a PDF file that visualize the configuration created
     * @param payload
     * @returns
     */
    printSend(endpoint: string, payload: GetPrintSendRequest): Observable<Blob> {
        const options = {
            headers: new HttpHeaders({
                Accept: 'application/pdf',
            }),
            responseType: 'blob' as 'json',
            params: new HttpParams({ fromObject: { ...payload } }),
        };
        return this.httpClient.get<Blob>(
            endpoint,
            options
        );
    }

    preflightNoHeaders(endpoint: string, params?: string): Observable<any>{
        const options = { }
        const url = params ? endpoint + params : endpoint
        return this.httpClient.get<any>(url, options)
    }
    
    // methods used to create a easier data structure from the response used to save in the store
    private createModel(model: Model, currency: string, priceMask: string): Family {
        return {
            familyName: model.familyCommercialName,
            familyCommercialCode: model.familyCommercialCode,
            model: model.model,
            models: this.createFamilyModels(model.modelCodes.filter(m => m.active_mxe === true), currency, priceMask, model.familyCommercialCode)
        };
    }

    private createFamilyModels(carmodels: ModelCode[], currency: string, priceMask: string, familyCommercialCode: string): CarModel[] {
        return carmodels.map((carmodel) => {
            return {
                familyCommercialCode: familyCommercialCode,
                modelName: carmodel.modelName,
                commercialName: carmodel.commercialName,
                modelCode: carmodel.modelCode,
                price: carmodel.price,
                yearModel: carmodel.my,
                techData: this.createTechData(
                    carmodel.translations[0].techData
                ),
                currency: currency,
                priceMask: priceMask,
                engineGroup: carmodel.engineGroup
            };
        });
    }

    private createTechData(techData: TechData[]): TechData[] {
        let keyFilter: string[] = [
            'engineSize',
            'acceleration',
            'maxSpeed',
            'maxTorque',
            'maxPower',
            'engineLayout',
            'traction',
            'length',
            'width_with_mirrors',
            'width_no_mirrors',
            'height',
            'wheelbase',
            'front_track',
            'rear_track',
            'front_overhang',
            'rear_overhang',
            'turning_circle',
            'unladen_weight',
            'boot_capacity',
            'fuel_tank_capacity',
            'wltp_consumption_low',
            'wltp_consumption_medium',
            'wltp_consumption_high',
            'wltp_consumption_extra_high',
            'wltp_consumption_combined',
            'wltp_emissions_low',
            'wltp_emissions_medium',
            'wltp_emissions_high',
            'wltp_emissions_extra_high',
            'wltp_emissions_combined'

        ];
        let techDataFilterdState: TechData[] = [];
        techDataFilterdState = techData.filter((t) =>
            keyFilter.includes(t.key)
        );
        return techDataFilterdState;
    }

    
}
