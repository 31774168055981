<!-- <div class="mxe__spinner-loader" *ngIf="isLoading">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div> -->
<div class="lang-selected-{{languageId}}" style="background: black;">
    <div class="toast" *ngIf="toastMessage">
        {{ toastMessage }}
      </div>
      
      <div class="modal-backdrop" *ngIf="modalMessage">
        <div class="modal">
          <h2>Attenzione</h2>
          <p>{{ modalMessage }}</p>
          <button (click)="closeModal()">OK</button>
        </div>
      </div>
      
    <router-outlet></router-outlet>
</div>


