<div class="maserati__car-configurator-bottom-panel">
    <ng-container *ngIf="monkeyWayStreamingReady || unrealDebugInterface">
        <div class="maserati__car-configurator--view-controls">
            <div>
                <!-- <button type="button" (click)="drivingExpIconActive = !drivingExpIconActive"
                    [ngClass]="{'button-active': drivingExpIconActive}"
                    class="maserati__car-configurator--icon maserati__car-configurator--action-driving-experience">
                </button> -->
                <button type="button" *ngIf="xRayEnabled"  [disabled]="!isXrayReady" (click)="onSwitchXRayMode()" [ngClass]="{'button-active': xRayIconActive}" 
                    class="maserati__car-configurator--icon maserati__car-configurator--action-rx-mode"
                    data-linktracking="cta:configurator:bar:x-ray" >
                </button>
                <button type="button" (click)="nightModeToggle()" [ngClass]="{'button-active': nightModeIconActive}"
                    class="maserati__car-configurator--icon maserati__car-configurator--action-night-mode"
                    data-linktracking="cta:configurator:bar:day-night-mode" >
                </button>
                <button type="button" (click)="environmentIconActive = !environmentIconActive; showCameraCarousel = false"
                    [ngClass]="{'button-active': environmentIconActive}" 
                    class="maserati__car-configurator--icon maserati__car-configurator--action-change-bg"
                    data-linktracking="cta:configurator:bar:environment-change">
                </button>
                <button type="button" (click)="fullScreenToggle()"
                    [ngClass]="{'button-active': fullScreenIconActive}"
                    class="maserati__car-configurator--icon maserati__car-configurator--action-fullscreen-toggle"
                    data-linktracking="cta:configurator:bar:full-screen-toggle" >
                </button>
                <button type="button"
                    [disabled]="xRayIconActive"    
                    (click)="hotspotsEvent()"
                    [ngClass]="{'button-active': hotspotsIconActive}"
                    class="maserati__car-configurator--icon maserati__car-configurator--action-screen-cast"
                    data-linktracking="cta:configurator:bar:mirror-mode" >
                </button>
                <button type="button" (click)="showCameraCarousel = !showCameraCarousel; environmentIconActive = false" 
                    [ngClass]="{'button-active': showCameraCarousel}"
                    class="maserati__car-configurator--icon maserati__car-configurator--action-play-video"
                    data-linktracking="cta:configurator:bar:cameras-change">
                </button>
                <button *ngIf="isPipelineAvailable" type="button" (click)="testPipelineEvent.emit(); pipelineActive = !pipelineActive"
                    [ngClass]="{'button-active': pipelineActive}" 
                    class="maserati__car-configurator--icon maserati__car-configurator--action-renderpipeline"
                    data-linktracking="cta:configurator:bar:active-pipeline">
                </button>
            </div>
        </div>
    
        <app-environments-carousel *ngIf="environmentIconActive" 
            [environments]="environments"
            [activeEnvironment]="activeEnvironment"
            (switchEnvironment)="onSwitchEnvironment($event)">
        </app-environments-carousel>
    
        <app-camera-carousel *ngIf="showCameraCarousel" 
            [countryCode]="countryCode"
            [cameras]="cameras" 
            [activeCamera]="activeCamera"
            [activeFamilyCommercialCode]="activeFamilyCommercialCode"
            [xRayIconActive]="xRayIconActive"
            (switchCamera)="onSwitchCamera($event)">
        </app-camera-carousel>
    </ng-container>
    <ng-container *ngIf="!monkeyWayStreamingReady && !unrealDebugInterface">
        <div class="maserati__car-configurator--view-controls--loading-3d">
            <span class="loader-text">{{getLabelDefValue('MXE_LOADING_3D','Loading 3D')}}</span>
            <ng-lottie width="25px" height="auto" [options]="options"></ng-lottie>
        </div>
    </ng-container>
    




</div>