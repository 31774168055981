import { Camera } from '../../models/unreal-available-models.model'

export const camerasInitialState: CamerasState = {
    cameras: [],
    activeCamera: {
        name: '',
        location: ''
    }
}

export interface CamerasState {
    cameras: Camera[],
    activeCamera: Camera
}