<div class="container-fluid">
  <div class="row">
    <div class="monkey-way-container">
        <video      
        #stream 
        tabindex="0" 
        class="video" 
        id="mwCustomStyles" 
        playsinline="true" 
        oncontextmenu="false;"></video>
    </div>
  </div>
</div>


<div class="screen-cast-controller-info" *ngIf="screenCastActive && showVideoStream">
  <div class="flex-info">
      <div class="dot">⦿</div>
      <div class="textual">
          <b>Currently casting...</b>
          <span>Interact with this preview to change casting view point</span>
      </div>
  </div>
  <button class="btn-interrupt-cast" (click)="stopScreenMirroringEvent.emit()">
      stop mirroring
  </button>
</div>
<div *ngIf="screenCastActive">
  <div class="switch-button-view-ui" [ngClass]="{'ui-enabled': viewPresenterScreenSidebar}">
    view ui
      <label class="switch">
        <input type="checkbox" />
        <span class="slider round" (click)="togglePresenterScreenSidebar()"></span>
      </label>
    </div>
</div>