import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetPrintSendRequest } from 'src/app/core/models/print-send.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mxe-send-pdf-modal',
  templateUrl: './send-pdf-modal.component.html',
  styleUrls: ['./send-pdf-modal.component.scss'],
})
export class SendPdfModalComponent implements OnInit {
  
  @Input() modelCode: string;
  @Input() countryCode: number;
  @Input() languageId: string;
  @Input() pdfEndpoint: string;
  @Output() valueChange = new EventEmitter();
  
  modalStatus: boolean;
  modalOpen: boolean;
  
  private carConfig: string;
  
  constructor(
    private maseratiService: MaseratiService,
    private carConfigurationService: CarConfigurationService
  ) {}

  ngOnInit(): void {
    this.carConfig = this.carConfigurationService.serializeCarConfiguration();
  }

  printSendPdf() {
    const payload: GetPrintSendRequest = {
      country: `${this.countryCode}`,
      lang: this.languageId,
      config: this.carConfig,
      configId: '',
      type: 'pdf',
      model: this.modelCode,
    };
    this.maseratiService.printSend(this.pdfEndpoint, payload).subscribe({
      next: (value: Blob) => {
        const blob = new Blob([value], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        console.debug('Downloading the pdf from: ', fileURL);
        window.open(fileURL);
        
      },
      complete: () => {
        console.info('[MASERATI SERVICE] printSend call completed');
      },

      error: (e) =>{
        if(!environment.production){
           console.error('An error occurred while creating the pdf file...', e)
        } else{
          console.error('An error occurred while creating the pdf file...')
        }
      }
       
    });

    this.modalStatus = false;
    this.valueChange.emit(this.modalStatus);
  }

  closeModal() {
    this.modalStatus = false;
    this.valueChange.emit(this.modalStatus);
  }
}
