import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { INTERFACE_LOADED } from '../actions/interface-actions';

@Injectable()
export class InterfaceEffects {

    constructor(private actions$: Actions) { }

    InterfaceState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(INTERFACE_LOADED),
                tap(actions => localStorage.setItem('interface', JSON.stringify(actions.interface)))
            ),
        { dispatch: false }
    );
}