import {
  ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector
} from '@angular/core';
import { DialogConfig } from '../../components/error-dialog/dialog-config';
import { DialogInjector } from '../../components/error-dialog/dialog-injector';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorDialogService {

  private dialogComponentRef: ComponentRef<ErrorDialogComponent>[] = []
  private componentIndex: number;
  
  mainScreen: boolean = true
  
  constructor(
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector

  ) { }

  public open(config: DialogConfig) {
    this.appendDialogComponentToBody(config);
  }

  private appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ErrorDialogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView)

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef.push(componentRef);

    this.componentIndex = this.dialogComponentRef.length
  }

  public removeDialogComponentFromBody() {
    this.componentIndex--
    this.dialogComponentRef[this.componentIndex].destroy()
    this.appRef.detachView(this.dialogComponentRef[this.componentIndex].hostView);
  }
}
