import { Action, createReducer, on } from '@ngrx/store';
import { EnvironmentsActions } from '../actions/unreal-environments/environments-exported-actions';
import { environmentsInitialState } from '../initials/environments-initial-state';

const _environmentsReducer = createReducer(environmentsInitialState,
    on(EnvironmentsActions.UPDATE_SELECTED_ENVIRONMENT, (state, payload) => ({
        ...state,
        activeEnvironment: payload.activeEnvironment
    })),
    on(EnvironmentsActions.UPLOAD_ENVIRONMENTS, (state, payload) => ({
        ...state,
        environments: payload.environments
    }))
)

export function environmentsReducer( state = environmentsInitialState, action: Action){
    return _environmentsReducer(state, action)
}