import { createAction, props } from '@ngrx/store';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { CarModel, Family } from '../../../models/get-models-service.model';

export const MODELS_LOADED = createAction(
    '[AppComponent] MODELS_LOADED',
    props<{ families: Family[] }>()
)

export const NEW_MODEL_SELECTED = createAction(
    '[CarModelCodeComponent] NEW_MODEL_SELECTED',
    props<{currentCarModel: CarModel}>()
)

export const MODEL_OPTIONS_PARSED = createAction(
    '[selectModelData] MODEL_OPTIONS_PARSED',
    props<{ packages: Pack[], options: UIOptItem[], hiddenOptions: UIOptItem[], optionsForAnalytics: string, defaultOptions: UIOptItem[] }>()
)

export const RESET_SELECTED_MODEL = createAction(
    '[CarConfigurationComponent] RESET_SELECTED_MODEL'
)

export const RESET_MODELS_STATE = createAction(
    'RESET_MODEL_STATE'
)

export const SET_LAST_MODEL = createAction(
    '[CarConfigurationComponent] SET_LAST_MODEL',
    props<{lastCarModel: CarModel,
    lastHiddenOptions: UIOptItem[],
    lastOptions: UIOptItem[],
    lastPackages: Pack[],
    lastDefaultOptions: UIOptItem[],
    lastOptionsForAnalytics: string

}>()
)
export const RESET_LAST_MODEL = createAction(
    '[CarConfigurationComponent] RESET_LAST_MODEL'
)
