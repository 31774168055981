<div id="container" class="fallback-container">
    <div style="width: 100%; height: 100%; text-align: center; color: white; display: flex; justify-content: center; align-items: center;" *ngIf="!imageLoaded">
        <div class="spinner"></div>
    </div>
    <img #activeImageElement class="mainImage" (error)="onImageLoadFailed()" (load)="onImageLoad()"  [src]="activeImageUrl" (touchstart)="onTouchMove($event)" >
    <div *ngIf="isMainScreen">
        <img id="drag-arrow" class="drag-arrow" src='./../../../assets/icons/mxe-fallback-interior-drag-arrow.svg'>
        <div id="drag-hand-container" class="drag-hand-container">
            <img id="drag-hand" class="drag-hand"  src = '../../../assets/icons/mxe-fallback-interior-drag-hand.svg'>
        </div>
    </div>

    <div *ngIf="isMainScreen" class="disclaimer"
        [ngStyle]="{'width': isTrimAvailableStreaming ? '270px' : '160px'}">
        <ng-container *ngIf="!isTrimAvailableStreaming">
            <img src="../../../assets/icons/mxe-fallback-2D-config-icon.svg">
            {{getLabelDefValue('MXE_CARCONFIGURATOR2D','You are on 2D configurator')}}
        </ng-container>
        <ng-container *ngIf="isTrimAvailableStreaming">{{getLabelDefValue('MXE_STREAMING_AVAILABLE','You are on 2D.')}}
            <u (click)="onStreamingConnection()">{{getLabelDefValue('MXE_TAP_HERE','Tap here to switch to 3D visualization')}}</u>
            <img src="../../../assets/icons/mxe-fallback-3D-config-icon.svg">
        </ng-container>
    </div>
    <app-fallback-interactive-bottom-bar
    *ngIf="isMainScreen"
    [cameraIndex]="swiperViews.indexOf(activeView)"
    [swiperImages]="swiperImages"
    (onSelectCamera)="onSelectCamera($event)"
    (onFullScreenToggle)="fullScreenToggleHandler($event)"
    (onStreamingConnect)="onStreamingConnection()"
    ></app-fallback-interactive-bottom-bar>
<app-fallback-toaster class="toaster-bar"
    [ngClass]="{'show-bar': showToasterAlertBar == true }"
    (valueChange)="showToasterAlertBar = false">
</app-fallback-toaster>
<div *ngIf="screenCastActive && isMainScreen">
     <div class="switch-button-view-ui-fallback" [ngClass]="{'ui-enabled': viewPresenterScreenSidebar}">
            view ui
            <label class="switch">
                <input type="checkbox" />
                <span class="slider round" (click)="togglePresenterScreenSidebar()"></span>
            </label>
        </div>
    </div>
</div>
