export interface MonkeyWaySessionsState {
    sessions: MonkeyWaySessionState[];
}

export interface MonkeyWaySessionState {
    sessionId: string;
    machineId: string;
    dealerId: string;
    ipAddress: string | undefined;
}


export const monkeyWaySessionsInitialState: MonkeyWaySessionsState = { sessions: [] }