import { ElementRef, Injectable } from '@angular/core';
import { StreamingService } from '@monkeyway/streaming-lib/dist/streaming/streaming';
import { ServiceOptions } from '@monkeyway/streaming-lib/dist/types/service-options';
import { ClientStreamInfo } from '@monkeyway/streaming-lib/dist/types/stream-info';
import { Subject, Subscription } from '@monkeyway/streaming-lib/node_modules/rxjs';
import { Observable } from 'rxjs';
import { ErrorDialogService } from './error-dialog.service';
import { environment } from 'src/environments/environment';
import { SentryMessaging } from './sentry-messaging.service';


@Injectable({
    providedIn: 'root'
})
export class MonkeyWayService {

    private streamingService: StreamingService;

    private streamInfo$: Observable<ClientStreamInfo>
    private _streamInfo: ClientStreamInfo | null = null;
    private _streamSubscription: Subscription | null = null;
    private _streamElement: ElementRef<HTMLVideoElement> | null;
    streamControlSubs$: Subject<boolean> = new Subject();
    streamingAvailable$: Subject<boolean> = new Subject();

    constructor(
        private errorDialogService: ErrorDialogService,
        private sentryMessaging: SentryMessaging
    ) {}


    get srcObject(): any {
        return this._streamInfo && this._streamInfo.stream;
    }


    setStreamElement(element: ElementRef<HTMLVideoElement>): void {
        console.log('setStreamElement', element);
        this._streamElement = element;

        if (this._streamSubscription) {
            console.log('restart');
            return this.streamControlSubs$.next(true);
        }
        console.log('start');
    }


    destroyStreamElement(): void {
        this._streamElement = null;
    }


    startSession(connectionKey: string, baseUrl: string, appEnvId: string): void {
        const streamingOptions: ServiceOptions = {
            baseUrl: baseUrl,
            appEnvId: appEnvId,
            apiKey: ""
        }
        this.streamingService = new StreamingService(streamingOptions);

        /*if (this._streamSubscription) {
            return;
        }*/
        if (connectionKey == '') { return };
        const startTime = Date.now()
        const streamInfo$ = this.streamingService.startWithConnectionKey(connectionKey,{ useAudio: true });
        this._streamSubscription = streamInfo$.subscribe({
            next: (info) => {
                this._streamInfo = info
                console.log(info);
                this.onConnected()
                const endTime = Date.now()
                this.sentryMessaging.logEvent(`mw.streaming_start`, 'info', {streamInfo: info, duration: `${endTime - startTime}ms`})
            },
            error: e => {
                console.warn('Error on stream subscription - activating fallback on 2D configurator')
                const endTime = Date.now()
                this.sentryMessaging.logEvent(`mw.streaming_start`, 'error', {error: e, duration: `${endTime - startTime}ms`})
                this.streamingAvailable$.next(false)
                if(this.streamingService) {
                    console.warn('STOP STREAMING SESSION ',this.streamingService)
                    try{
                        this.streamingService?.stop()
                    }
                    catch( error){
                        console.debug("Error stopping streaming",error)
                    }
                }
                //TODO - Open toast here
                //(this.errorDialogService.open({ data: { message: e, action: 'redirect'}, }))
            }
        })
    }

    async stopSession() {
        if (!this._streamSubscription) {
            return;
        }

        this.onDisconnected()
        this._streamSubscription.unsubscribe();
        this._streamSubscription = null;
    }

    stopMonkeyWaySession() {
        if (!this._streamSubscription) {
            return;
        }
        console.log('disconnected');
        this.streamingService?.stop();
        this._streamSubscription?.unsubscribe();
        this._streamSubscription = null;
        this.destroyStreamElement();
    }

    stopPresenterScreenSession(){
        this.onDisconnected()
    }

    private onConnected(): void {
        console.log('connected');
        this.streamingAvailable$.next(true)
        this.streamControlSubs$.next(true);
    }


    private onDisconnected(): void {
        console.log('disconnected');
        if(this.streamControlSubs$) {
            this.streamControlSubs$.next(false);
        }
        console.log('STOP STREAMING SESSION ',this.streamingService)
        this.streamingService?.stop();
    }

    public getStreamInfo() {
        return this._streamInfo;
    }
}
