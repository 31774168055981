import {createAction, props } from '@ngrx/store';
import { ConfigItem } from '../../initials/temporary-configs-initial-state';

export const TEMP_CONFIG_ADD = createAction(
    '[Temporary config] SAVING',
    props<{configItem : ConfigItem}>()
)

export const TEMP_CONFIG_DELETE = createAction(
    '[Temporary config] DELETING'
)

export const TEMP_CONFIG_INIT = createAction(
    '[Temporary config] INITIALIZING',
    props<{configItems: ConfigItem[]}>()
)

