import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { ErrorDialogService } from './error-dialog.service';
import { UiCommonService } from './ui-common.service';
import * as Sentry from '@sentry/angular-ivy'
import { UserState } from '../store/initials/user-initial-state';
import { HandledError } from '../models/handled-error';
import { environment } from 'src/environments/environment';
import { SentryMessaging } from './sentry-messaging.service';
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler, HttpInterceptor {

  private errorId;

  private redirectToHomeMessage = [
    'sso_auth_error',
    'sso_expired',
    'MXE_SSO_EXPIRED',
    `${this.getLabelWithDefaultValue('MXE_SSO_EXPIRED_ERROR','Your authentication has expired, click Continue to return to the homepage.')}`,
    `${this.getLabelWithDefaultValue('MXE_AUTH_EXPIRED','Your authentication has expired, click on "continue" to return to the homepage. If you were on the 3D configuration, you can find it in the "load\\restore configuration" section')}`
  ]

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private zone: NgZone,
    private uiCommonService: UiCommonService,
  ) { 
  }

  private get errorDialogService() {
    return this.injector.get(ErrorDialogService);
  }

  private get sentryMessagingService() {
    return this.injector.get(SentryMessaging)
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        return throwError(() => new Error(err));
      })
    );
  }

  handleError(error: any): void {
    const user: UserState | null = localStorage.getItem('user_state') ? JSON.parse(localStorage.getItem('user_state')!) : null
    //Verify if it is an Handled or Unhandled Error
    if (error instanceof HandledError) {
      this.zone.run(() => {
      //Flow for every handled error
      this.errorDialogService.open({
        data: {
          header: this.getHeader(error.message),
          message: error.message,
          action: this.getAction(error.message)
        }
      })
      })
      this.sentryMessagingService.logEvent(`${error.message}`, 'warning', {stack: error.stack})
      console.warn(`[ErrorHandler]: ${error.message}`) 
    } else {
      //Flow for every unhandled error
      let displayedMessage = 'Something went wrong. Please try again later.'
      let message = this.decodeUnhandledError(error);
      //UNCOMMENT IF WE WANT TO SHOW THE MXE DIALOG EVEN IF WE ALREADY SHOW THE SENTRY ONE
      // if (error.name !== 'QuotaExceededError') {
      //   this.zone.run(() =>
      //     this.errorDialogService.open({
      //       data: {
      //         header: this.getHeader(message),
      //         message: displayedMessage,
      //         action: this.getAction(message)
      //       }
      //     })
      //   );
      // }
      this.errorId = this.sentryMessagingService.logEvent(message, 'error', {error: error})
      //POPUP ON UNHANDLED ERRORS IS HIDED AT THE MOMENT DUE TO A SPECIFIC REQUEST
      // if(error.name !== 'QuotaExceededError') {
      //   this.zone.run(() => {
      //     Sentry.showReportDialog({
      //       errorId: this.errorId,
      //       title: "Oops! Something went wrong.",
      //       subtitle: "We have been notified about the error.",
      //       subtitle2: "If you'd like to provide additional information, please fill out the form below.",
      //       labelComments: "What happened?",
      //       labelSubmit: "Submit Feedback",
      //     });
      //   })
      // }
      console.error(`[ErrorHandler]: ${environment.environment !== 'debug' ? displayedMessage : message}`)
    }
  }

  private decodeUnhandledError(error: any): string {
    let message: string = '';
    
    if (error.rejection && error.rejection.message) {
      try {
        if (typeof JSON.parse(error.rejection.message) == 'object') {
          error = JSON.parse(error.rejection.message)
        }
      } catch {
        console.debug('error message is not an object')
      }
    }
    if (error != null) {
      if (error.rejection && error.rejection.message == 'No identity was set on response.') return 'No identity was set on response.'
      if (error.rejection && error.rejection.message.includes('Identity cookie not found.')) return 'Identity cookie not found.'
      message = 'Undefined client error'
      if (!(error instanceof HttpErrorResponse)) {
        if (error.rejection) {
          if (error.rejection.message) {
            message = error.rejection.message
          } else if (error.rejection.error) {
            message = error.rejection.error
          }
          else {
            message = error.rejection
          }
        } else if (error.message) {
          message = error.message ?? message
        } else if (error.details) {
          message = error.details
        } else if (error.error) {
          message = error.error
          if (error.kwargs) {
            let kw = error.kwargs
            if (kw.details) {
              message += `: ${kw.details}`
            }
            if (kw.id) {
              message += `\nTrace: ${kw.id}`
            }
          }
        }
      }
    }
    return message;
  }

  private getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  private getAction(message: string): string {
    return this.redirectToHomeMessage.filter(el => message.includes(el))?.length > 0 ? 'redirect' : ''
  }

  private getHeader(message: string) : string {
    return this.getAction(message) == 'redirect' ? 'Attention' : 'An error has occurred'
  }
}
