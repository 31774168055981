import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MxeReducers } from '../reducers';
import { Store } from '@ngrx/store';
import { MonkeyWaySessionActions } from '../actions/monkeyway-sessions/monkeyway-sessions-exported-actions';
import { MonkeyWaySessionState, MonkeyWaySessionsState } from '../initials/monkeyway-sessions-initial-state';


@Injectable()
export class MonkeyWaySessionsEffects {

  constructor(private actions$: Actions, private store: Store<MxeReducers>) { }

  addSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MonkeyWaySessionActions.MW_SESSION_ADD),
        tap((action) => {
          const sessionsState = localStorage.getItem('monkeyWay_sessions');   
          const newSession = action.session;
          let newList: MonkeyWaySessionState[] = [];

          if(sessionsState) {
            const actualList = (JSON.parse(sessionsState) as MonkeyWaySessionsState).sessions
            newList = [...actualList, newSession];
          } else {
            newList = [newSession];
          }
          const newState: MonkeyWaySessionsState = {
            sessions: newList
          }
          localStorage.setItem('monkeyWay_sessions', JSON.stringify(newState))
        })
      ),
    { dispatch: false }
  );

  deleteSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MonkeyWaySessionActions.MW_SESSION_DELETE),
        tap((action) => {
            if(localStorage.getItem('monkeyWay_sessions')) {
                const sessionList = (JSON.parse(localStorage.getItem('monkeyWay_sessions')!) as MonkeyWaySessionsState).sessions
                const newList = sessionList.filter(s => s.sessionId !== action.session.sessionId)
                const newSessionsState: MonkeyWaySessionsState = {sessions : newList}
                localStorage.setItem('monkeyWay_sessions', JSON.stringify(newSessionsState))
            }
        })
      ),
    { dispatch: false }
  );

  initSessions$ = createEffect(
    () => 
    this.actions$.pipe(
      ofType(MonkeyWaySessionActions.MW_SESSION_INIT),
      tap((action) => {
        localStorage.setItem('monkeyWay_sessions', JSON.stringify(action))
      })
    ),
    {dispatch: false}
  )

}
