import {createAction, props } from '@ngrx/store';
import { MonkeyWaySessionState } from '../../initials/monkeyway-sessions-initial-state';

export const MW_SESSION_ADD = createAction(
    '[MW SESSION] SAVING',
    props<{session : MonkeyWaySessionState}>()
)

export const MW_SESSION_DELETE = createAction(
    '[MW SESSION] DELETING',
    props<{session: MonkeyWaySessionState}>()
)

export const MW_SESSION_INIT = createAction(
    '[MW SESSION] INITIALIZING',
    props<{sessions: MonkeyWaySessionState[]}>()
)