import { Injectable } from "@angular/core";

@Injectable()
export class MxeAnalyticsService {
  /**
   * Builds a data-linktracking for the given option
   * @param menuLabel 
   * @param sectionId 
   * @param optionId 
   * @param color 
   * @param action 
   * @returns 
   */
  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, colorCode: string = '', actionName: string = ''): string {
    const color = colorCode && colorCode !== '' ? `:${this.normalizeStringForAnalytics(colorCode)}` : ''
      color
    const action = actionName && actionName !== '' ? `:${this.normalizeStringForAnalytics(actionName)}` : ''

    return `cta:configurator:accordion:${this.normalizeStringForAnalytics(menuLabel)}:${this.normalizeStringForAnalytics(tabId)}:${this.normalizeStringForAnalytics(sectionId)}:${this.normalizeStringForAnalytics(optionId)}${color}${action}`
  }

  /**
   * Used to normalize strings for Analytics
   * @param value the string to be normalized 
   * @returns 
   */
  normalizeStringForAnalytics(value: string): string {
    return value?.replace(" ","-").toLocaleLowerCase();
  }
}