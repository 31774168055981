import { UnrealState } from '../models/get-scene-state';
import { appSettingsInitialState, AppState } from './initials/app-settings-initial-state';
import { camerasInitialState, CamerasState } from './initials/cameras.initial-state';
import { carGridInitialState, CarGridState } from './initials/car-grid-initial-state';
import { configurationInitialState, ConfigurationState } from './initials/configuration-initial-state';
import { countryPropInitialState, CountryPropState } from './initials/country-prop-initial-state';
import { engineInitialState, EngineState } from './initials/engine-initial-state';
import { environmentsInitialState, EnvironmentsState } from './initials/environments-initial-state';
import { interfaceInitialState, InterfaceState } from './initials/interface-initial-state';
import { menuInitialState, MenuState } from './initials/menu-initial-state';
import { modelsInitialState, ModelsState } from './initials/models-initial-state';
import { monkeyWaySessionsInitialState, MonkeyWaySessionsState } from './initials/monkeyway-sessions-initial-state';
import { ConfigItem, ConfigState, temporaryConfigInitialState } from './initials/temporary-configs-initial-state';
import { unrealDesiredSceneStateInitialState } from './initials/unreal-desired-scene-state-initial-state';
import { IUserState, userInitialState } from './initials/user-initial-state';
import { appStateReducer } from './reducers/app-setting-reducers';
import { cameraReducer } from './reducers/cameras.reducers';
import { carGridReducer } from './reducers/car-grid-reducers';
import { configurationReducer } from './reducers/configuration-reducers';
import { countryPropReducer } from './reducers/country-prop-reducers';
import { engineReducer } from './reducers/engine-reducers';
import { environmentsReducer } from './reducers/environments-reducers';
import { interfaceReducer } from './reducers/interface-reducers';
import { menuReducer } from './reducers/menu-reducers';
import { modelReducer } from './reducers/models-reducers';
import { monkeyWaySessionsReducer } from './reducers/monkeyway-sessions-reducers';
import { temporaryConfigsReducer } from './reducers/temporary-configs-reducers';
import { unrealDesiredSceneStateReducer } from './reducers/unreal-desired-scene-state-reducers';
import { userReducer } from './reducers/user-reducers';

export const initialState: MxeReducers = {
    modelsState: modelsInitialState,
    userState: userInitialState,
    menuState: menuInitialState,
    interfaceState: interfaceInitialState,
    countryPropState: countryPropInitialState,
    appState: appSettingsInitialState,
    configurationState: configurationInitialState,
    carGridState: carGridInitialState,
    engineState: engineInitialState,
    camerasState: camerasInitialState,
    environmentsState: environmentsInitialState,
    desired_unreal_state: unrealDesiredSceneStateInitialState,
    temporary_configs_state: temporaryConfigInitialState,
    monkeyWay_sessions_state: monkeyWaySessionsInitialState

};

export const reducers = {
    modelsState: modelReducer,
    userState: userReducer,
    menuState: menuReducer,
    interfaceState: interfaceReducer,
    countryPropState: countryPropReducer,
    appState: appStateReducer,
    configurationState: configurationReducer,
    carGridState: carGridReducer,
    engineState: engineReducer,
    camerasState: cameraReducer,
    environmentsState: environmentsReducer,
    desired_unreal_state: unrealDesiredSceneStateReducer,
    temporary_configs_state: temporaryConfigsReducer,
    monkeyWay_sessions_state: monkeyWaySessionsReducer
};

export interface MxeReducers {
    modelsState: ModelsState,
    userState: IUserState,
    menuState: MenuState,
    interfaceState: InterfaceState,
    countryPropState: CountryPropState,
    appState: AppState,
    configurationState: ConfigurationState,
    carGridState: CarGridState,
    engineState: EngineState,
    camerasState: CamerasState,
    environmentsState: EnvironmentsState,
    desired_unreal_state: UnrealState,
    temporary_configs_state: ConfigState
    monkeyWay_sessions_state: MonkeyWaySessionsState
}
