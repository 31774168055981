<div class="mxe__car-upper-menu">
    <div class="mxe__search-toggle" [ngClass]="{'expanded': searchExpanded === true}">
        <button class="mxe-toggle-button"  (click)="toggleSearchBox()"></button>
        <div class="mxe-search-panel">
            <input #searchInput type="search" [ngModel]="searchText" (ngModelChange)="onSearchBoxValueChange($event)"/>
        </div>
    </div>
    <div class="cum-wrapper" id="bndscroll" #menuWrapper>
        <div class="cum-voices">
            <div class="cum-voice"  *ngFor="let l of menuItems; let i=index " swiperSlide>
                <div class="cum-int-voice" 
                [attr.data-linktracking]="'cta:configurator:title:' + l.label.toLowerCase()"
                    (click)="onSelectMenu(l.label.toLocaleLowerCase(), i)"
                    [ngClass]="{'active': selectedMenuItem === i}">
                    {{ getLabel(l.id)}}
                </div>
            </div>
        </div>
        
    </div>
</div>