import { Action, createReducer, on } from '@ngrx/store';
import { AppSettingsActions } from '../actions/app-settings/app-settings-exported-actions';
import { appSettingsInitialState } from '../initials/app-settings-initial-state';

const appSettingsReducer = createReducer(appSettingsInitialState,
    on(AppSettingsActions.APP_SETTINGS_INIT, (state, payload) => ({
        ...state,
        countryCode: payload.countryCode,
        languageId: payload.languageId,
        ctaLanguageId: payload.ctaLanguageId,
        arePricesAvailable: payload.arePricesAvailable,
        showPrices: payload.showPrices,
        ephemeralDeviceId: payload.ephemeralDeviceId,
        isScreenCastActive: payload.isScreenCastActive,
        streamingAvailable: payload.streamingAvailable
    })),
    on(AppSettingsActions.APP_SETTINGS_SAVE_CLIENT_NAME, ( state, payload) => ({
        ...state,
        clientName: payload.clientName,
        clientSurname: payload.clientSurname
    })),
   on(AppSettingsActions.APP_SETTINGS_SWITCH_COUNTRY, (state, payload) => ({
       ...state,
       countryCode: payload.countryCode,
       languageId: payload.languageId,
       ctaLanguageId: payload.ctaLanguageId,
       showPrices: payload.showPrices,
       arePricesAvailable: payload.arePricesAvailable
    })),
    on(AppSettingsActions.APP_SETTINGS_SWITCH_LANGUAGE, (state, payload) => ({
        ...state,
        languageId: payload.languageId
    })),
    on(AppSettingsActions.APP_SETTINGS_SWITCH_CTA_LANGUAGE, (state, payload) => ({
        ...state,
        ctaLanguageId: payload.ctaLanguageId
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_COUNTRY, (state, payload) => ({
        ...state,
        countryCode: payload.countryCode,
        languageId: payload.languageId,
        ctaLanguageId: payload.ctaLanguageId,
        showPrices: false,
        arePricesAvailable: false
     })),
    on(AppSettingsActions.APP_SETTINGS_TOGGLE_PRICES, ( state, payload) => ({
        ...state,
        showPrices: payload.showPrices
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_PRICES_AVAILABLE, (state, payload) => ({
        ...state,
        arePricesAvailable: payload.arePricesAvailable
    })),
    on(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED, (state, payload) => ({
        ...state,
        configId: payload.configId
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID, (state, payload) => ({
        ...state,
        ephemeralDeviceId: payload.ephemeralDeviceId
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_SCREENCAST_ACTIVE, (state, payload) => ({
        ...state,
        isScreenCastActive: payload.isScreenCastActive
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_ENV_NAME, (state, payload) => ({
        ...state,
        envName: payload.envName
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_STREAMING_STATUS, (state, payload) => ({
        ...state,
        streamingAvailable: payload.streamingAvailable
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS, (state, payload) => ({
        ...state,
        protocol: payload.protocol,
        host: payload.host,
        hostName: payload.hostName
    })),
    on(AppSettingsActions.APP_SETTINGS_SET_APPLICATION_STEP, (state, payload) => ({
        ...state,
        applicationStep: payload.applicationStep
    }))
);

export function appStateReducer( state = appSettingsInitialState, action: Action){
    return appSettingsReducer(state, action)
}