<div class="lang-selected-{{selectedCountry.languageId}}">

    <div *ngIf="(!ephemeralDeviceID && isLoading) || (!isSceneReady && isLoading)" class="loading-screen-view">
        <h2>Loading</h2>
        <div class="custom-loader"></div>
    </div>
    
    <div class="mxe__tv-screen-view" *ngIf="!isSceneReady && !isLoading">
        <div class="animated-gradient">
            <div *ngIf="ephemeralDeviceID" class="mxe__qr-placement-box">
                
                <div class="code-manually">
                    <div class="text-container">
                        Enter the code to play the experience on TV 
                        <br>
                        <div class="code-container">
                          <img class="edit-icon" src="../../../assets/icons/mxe__ico-edit-small-white.svg"/>
                          <b>{{ephemeralDeviceID}}</b>
                        </div> 
                    </div>
                </div>
                <div *ngIf="!hostName.includes('local') || host == 'localhost:8080'" class="full-screen-toggle" (click)="triggerFullscreen()" [ngClass]="{'active': isWindowFullScreenActive}">FULLSCREEN</div>
                <div *ngIf="hostName.includes('local') && host != 'localhost:8080'" class="refresh-toggle" (click)="refreshPage()">REFRESH</div>
                <div class="timer-container">
                    Code will expire in &nbsp;<b>{{expirationTimeCountdown}}</b>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="ephemeralDeviceID" class="mxe__qr-placement-box">
            <h2>Scan QR code to cast</h2> 
             <div class="qr-code-wrapper">
                <ngx-qrcode-styling [config]="qrCodeConfig"></ngx-qrcode-styling>
            </div> 
            <div class="code-manually">
                <div class="text-container" style="font-size: 100px;">
                    Enter code: 
                    <br>
                    <div class="code-container">
                      <img src="../../../assets/icons/mxe__ico-edit-small-white.svg" style="width:150px"/>
                      <b style="font-size: 300px;">{{ephemeralDeviceID}}</b>
                    </div> 
                 </div>
            </div>
            <div *ngIf="protocol == 'https:' || host == 'localhost:8080'" class="full-screen-toggle" (click)="triggerFullscreen()" [ngClass]="{'active': isWindowFullScreenActive}">FULLSCREEN</div>
            <div *ngIf="protocol == 'http:' && host != 'localhost:8080'" class="refresh-toggle" (click)="refreshPage()">REFRESH</div>
        </div> -->
    </div>
    <!-- when the sidebar is hidden put class "fullview-mode"-->
    <div *ngIf="pairingStep == pairingStepEnum.CAR_CONFIGURATOR"  class="tv-app-and-sidebar-wrapper" [ngStyle]="{'display:': isLoading ? 'none' : 'block'}">
        <div  class="app-cc-wrapper" [ngClass]="{'fullview-ui-active':!showSidebar}" *ngIf="pairingStep == pairingStepEnum.CAR_CONFIGURATOR" >
            <app-car-model-data class="lang-selected-{{selectedCountry.languageId}}" *ngIf="isSceneReady"
                [carModelData]="carModel"
                [price]="carTotalPrice"
                [priceStatus]="priceStatus"
                [showCinematic]="showCinematic"
                [splittedCommercialName]="splittedCommercialName">
            </app-car-model-data>
            <div class="monkey-way-container" *ngIf="streamingAvailable">
                <video 
                    #stream 
                    tabindex="0" 
                    class="video" 
                    id="mwCustomStyles"
                    playsinline 
                    style="display: none;" 
                    oncontextmenu="false;">
                </video>
            </div>
        </div>
        <div class="cinematic-text-container" *ngIf="(showCinematicEventFromSummary || showCinematic) && showCinematicLogo">
            <div class="cinematic-text">
                <img class="logo" [ngClass]="{'not-active': !showCinematicText}" src="../../../assets/maserati-cinematic-logo.svg">
                <div class="dedication-text" [ngClass]="{'not-active': !showCinematicText}" *ngIf="!showCustomerName">presents</div>
                <div class="dedication-text" [ngClass]="{'not-active': !showCinematicText}" *ngIf="showCustomerName">X</div>
                <div class="ml12" [ngClass]="{'active': textAnimationActive}">{{customerName}} {{customerSurname}}</div>
            </div>
        </div>
        <div class="maserati__fallback-container-wrapper" *ngIf="isSceneReady && !streamingAvailable && pairingStep == pairingStepEnum.CAR_CONFIGURATOR"[ngClass]="{'fallback-fullview':!showSidebar}">
            <app-fallback2D  
                [defaultOptions]="defaultOptions"
                [optionalsBinding]="optionalsBinding"
                [hiddenOptions]="hiddenOptions"
                [options]="modelOptions"
                [carModel]="carModel"
                [swiperViews]="swiperViews"
                [activeCamera]="activeView"
                [loadingCompleted] = "loadingCompleted"
                [isMainScreen]="false"
                [pairedActiveImageOffset] = "fallbackActiveImageOffset"
                [fallbackImageCurrentPosition] = "fallbackImageCurrentPosition">
            </app-fallback2D>
        </div>
        <div class="sidebar-cc-wrapper" [ngClass]="{'app-sidebar-hidden':!showSidebar}" *ngIf="pairingStep == pairingStepEnum.CAR_CONFIGURATOR">
            <app-sidebar-configurator *ngIf="isSceneReady"
                #sidebarEl
                [packages]="packages"
                [languageId]="selectedCountry.languageId"
                [priceStatus]="priceStatus"
                [carModel]="carModel"
                [menuItems]="menuItems"
                [isMainScreen]="false"
                [scrollPosition]="scrollPositionEvent"
                [toggleAccordion]="toggleAccordionEvent"
                [accordionExp] = "accordionExp"
                [options]="modelOptions"
                [searchFilter]="searchValue"
                [searchToggleValue]="searchToggleValue"
                [packageDetailsToggleValue]="packageDetailsToggleValue"
                [closedAccordions]="closedAccordions"
                [standAloneSetInt]="standAloneSetInt"
                [standAloneSet]="standAloneSet"
                [standAloneSetHighlightPacks]="standAloneSetHighlightPacks"
                [accordionOpen]="accordionOpen"
                [packagesSwiperSlideChange]="packagesSwiperSlideChange"
                [packagesFormatSelectionDetail]="packagesFormatSelectionDetail"
                [optFormatSelectionDetail]="optFormatSelectionDetail"
                [accFormatSelectionDetail]="accFormatSelectionDetail"
                [accordionOpenClose]="accordionOpenClose"
                [currentStateHigh]="currentStateHigh"
                [currentStateHighPacksDetail]="currentStateHighPacksDetail"
                [currentStateExt]="currentStateExt"
                [currentStateInt]="currentStateInt"
                [currentStateOpt]="currentStateOpt"
                [currentStateAccessories]="currentStateAccessories"
                [showMoreLivree]="showMoreLivree">
            </app-sidebar-configurator>
        </div>
    
        <app-summary-configuration *ngIf="showSummaryModal" 
            [options]="modelOptions"
            [defaultOptions]="defaultOptions"
            [menuItems]="menuItems"
            [currentCarModel]="carModel" 
            [countryCode]="country"
            [languageId]="language"
            [optionsForAnalytics]="optionsForAnalytics"
            [ctas] = "ctas"
            [config] = "desiredUnrealConfig"
            [hiddenOptions]="hiddenOptions"
            [priceStatus]="priceStatus"
            [configId]="configId"
            [dealerId]="dealerId"
            [optionalsBinding]="optionalsBinding"
            [imageView]="imageView"
            [imageLayers]="imageLayers"
            [customerName]="customerName"
            [customerSurname]="customerSurname"
            [showCustomerName]="showCustomerName"
            [unformattedBasePrice]="unformattedBasePrice"
            [unformattedEquipments]="unformattedEquipments"
            [unformattedTaxes]="unformattedTaxes"
            [totalPrice]="carTotalPrice"
            [isMainScreen]="false"
            [scrollPosition] = "summaryScrollPositionEvent"
            [accordionToggled]="summaryAccordionToggled">
        </app-summary-configuration>

        <app-thank-you-page *ngIf="showThankYouPage"
            [dealerCode]="dealerId"
            [configId]="configId"
            [currentCarModel]="carModel" 
            [countryCode]="country"
            [languageId]="language"
            [sendPdfBasePath]="sendPdfBasePath"
            [ctas] = "ctas">
        </app-thank-you-page>
    </div>
    <mxe-fullscreen-loader *ngIf="!configuratorLoaded && carModel!= null" 
    [code]="carModel.modelCode"
    (loadingCompleted)="loadingCompleted=true">
    </mxe-fullscreen-loader>
    <mxe-loading-services-page-tv *ngIf="displayLoadingServicesPage">      
    </mxe-loading-services-page-tv>
     <mxe-home *ngIf="pairingStep == pairingStepEnum.HOME"
        [isMainScreen]="false">
    </mxe-home>   
    <mxe-car-models-selector *ngIf="pairingStep == pairingStepEnum.MODEL_SELECTOR"
        [priceStatus] = "priceStatus"
        [languageId]="selectedCountry.languageId"
        [countryCode]="selectedCountry.countryCode"
        [isMainScreen]="false"
        [labels] = "labels"
        [families]="families"
        [activeSlideIndex]="activeSlideIndex"
        [activeSlideImageIndex]="activeSlideImageIndex"
        [swiperScrollDelta]="modelSelectorSwiperScrollDelta">
    </mxe-car-models-selector> 
    <mxe-car-trim 
       *ngIf="pairingStep == pairingStepEnum.TRIM_SELECTOR"
       [countryCode]="selectedCountry.countryCode"
       [languageId]="selectedCountry.languageId"
       [currentTrimCode]="currentTrimCode"
       [showPrices]="priceStatus"
       [selectedFamily]="selectedFamily"
       [activeSlideIndex]="activeSlideIndex"
       [activeSlideImageIndex]="activeSlideImageIndex"
       [isMainScreen]="false"
       [labels]="labels"
       [ephemeralDeviceID]="ephemeralDeviceID"
       [swiperScrollDelta]="trimSelectorSwiperScrollDelta">
    </mxe-car-trim>
    <app-client-name *ngIf="pairingStep == pairingStepEnum.USER" 
        [isMainScreen]="false" 
        [ephemeralDeviceID]="ephemeralDeviceID"
        [name]="customerName"
        [surname] = "customerSurname">
    </app-client-name> 
    <app-configuration-loader *ngIf="pairingStep == pairingStepEnum.CONFIGURATION_LOADER" 
        [isMainScreen] = "false"
        [ephemeralDeviceID]="ephemeralDeviceID"
        [configurationID]="configId"
        [restoreConfigSetter] = "restoreConfig"
        [loadConfigSetter] = "loadConfig"
        [temporaryItemsFromStore] = "temporaryItemsFromStore"
        [isMainScreen]="false"
        [imagePath]="imagePath"
        [families]="families"
        [selectedElementRestoreConfig] = "selectedElementRestoreConfig"
        [carModel]="carModel"
        [hiddenOptions]="hiddenOptions"
        [defaultOptions]="defaultOptions"
        [packages]="packages"
        [options]="modelOptions"
        [optionsForAnalytics]="optionsForAnalytics"
        [imageView]="imageView"
        [imageLayers]="imageLayers"
        >
    </app-configuration-loader>   
</div>