import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, fromEvent, interval, map, mergeMap, repeat, sample, Subscription, takeUntil, throwError } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import { APP_SETTINGS_SET_STREAMING_STATUS } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';
import { Subscription as MonkeyWaySubscription } from '@monkeyway/streaming-lib/node_modules/rxjs';
import { CarModel } from 'src/app/core/models/get-models-service.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { NavigationStart, Router } from '@angular/router';
import { ServiceStatus } from 'src/app/core/models/maserati-service-status';
import { HandledError } from '../../../core/models/handled-error';
import { SentryMessaging } from 'src/app/core/services/sentry-messaging.service';


@Component({
  selector: 'app-monkey-way',
  templateUrl: './monkey-way.component.html',
  styleUrls: ['./monkey-way.component.scss']
})
export class MonkeyWayComponent implements AfterViewInit, OnDestroy {


  @Input() streamWidth: string = '100vw';
  @Input() streamHeight: string = '100vh';
  @Input() screenCastActive = false;
  @Input() bAudio: boolean  

  @ViewChild('stream') streamElement!: ElementRef<HTMLVideoElement>;

  @Output() viewPresenterScreenSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() stopScreenMirroringEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() leaveSessionEvent = new EventEmitter();
  @Output() monkeyWayReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isTrimAvailableEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() viewPresenterScreenSidebar = true;
  @Input() carModel: CarModel;

  @Output() streamingStatusEmitter: EventEmitter<ServiceStatus> = new EventEmitter<ServiceStatus>();

  private element: HTMLElement;
  private previousDistance: number
  private yPosLast: number;
  private xPosLast: number;
  private touchEventSub: Subscription;
  private streamingAvailable$: MonkeyWaySubscription;
  private streamControlSubs$: MonkeyWaySubscription;
  showVideoStream: boolean = true;
  private localUnrealHeartbeat: Subscription | null
  private unrealDebugInterface: boolean; //FOR 3D ARTISTS DEBUG PURPOSE
  private routerSubscription$: Subscription


  constructor(
    private monkeyWay: MonkeyWayService,
    private autobahnClient: AutobahnClient,
    private chg: ChangeDetectorRef,
    private store: Store<MxeReducers>,
    private ngZone: NgZone,
    private uiCommonService: UiCommonService,
    private router: Router,
    private sentryMessaging: SentryMessaging
  ) {
    // this.routerSubscription$ = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event) => {
    //   if ((event as NavigationStart)?.navigationTrigger == 'popstate') {
    //     try {
    //       this.stopStreamingSession()
    //     }
    //     catch (error) {
    //       console.debug("ERROR DURING STOP STREAMING SESSION", error)
    //     }
    //   }
    // })
  }

  ngOnInit(): void {
    this.unrealDebugInterface = localStorage.getItem('unreal_debug_interface') ? JSON.parse(localStorage.getItem('unreal_debug_interface')!) as boolean : false;
    if (environment.enable_monkey_way_streaming) {
      this.startStreaming().then(() => {
        this._initSubscriptions();
      })
    } else {
      console.warn(`
      You are not receiving any streaming due to a cookie setting. If you need the streaming open the inspector, go to the Application tab, 
      click on Local Storage and set the 'dev_enable_streaming' to 'true. Then reload the page
      `)
    }
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes['bAudio']) {
      if (this.streamElement && this.streamElement.nativeElement) {
        if(!this.screenCastActive){
          this.streamElement.nativeElement.muted = !changes['bAudio'].currentValue;
        }
        else{
          this.streamElement.nativeElement.muted = true;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.monkeyWay.setStreamElement(this.streamElement);
    this.initializeEventListeners();
    setTimeout(() => {
      document.getElementById("mwCustomStyles")?.setAttribute(
        "style", "height:" + this.streamHeight + ";" + "width: auto");
    }, 500)

    this.touchEventHandler()
  }

  private initializeEventListeners() {
    if(this.streamElement && this.streamElement.nativeElement) {
      console.log('%cInitializing streaming Event Listeners...', 'color:orange;')
      this.streamElement.nativeElement.addEventListener("abort", (event) => { console.log('%cAborting loading stream', 'color: orange;', event) });
      this.streamElement.nativeElement.addEventListener("ended", (event) => {
        console.log(
          "%cVideo stopped either because it has finished playing or no further data is available.", 'color: orange;', event
        );
      })
  
      this.streamElement.nativeElement.addEventListener("error", () => {
        console.error(`%cError loading: ${this.streamElement.nativeElement.src}`, 'color: orange;');
      });
      this.streamElement.nativeElement.addEventListener("loadedmetadata", (event) => {
        console.log(
          "%cThe duration and dimensions of the media and tracks loaded.", 'color:orange;', event
        );
      });
      
      this.streamElement.nativeElement.addEventListener("loadeddata", (event) => {
        console.log(
          "%cThe readyState just increased to  " +
          "HAVE_CURRENT_DATA or greater for the first time.", 'color:orange;', event
        );
      });
      this.streamElement.nativeElement.addEventListener("pause", (event) => {
        console.log(
          "%cThe Boolean paused property is now 'true'. Either the pause() method was called or the autoplay attribute was toggled.", 'color:orange;', event
        );
      });
      this.streamElement.nativeElement.addEventListener("waiting", (event) => {
        console.log("%cVideo is waiting for more data.", 'color:orange;', event);
      });
      this.streamElement.nativeElement.addEventListener("stalled", (event) => {
        console.log("%cFailed to fetch data, but trying.",'color:orange;', event);
      });
      this.streamElement.nativeElement.addEventListener("suspend", (event) => {
        console.log("%cData loading has been suspended.", 'color:orange;',event);
      });
      this.streamElement.nativeElement.addEventListener("canplaythrough", (event) => {
        console.log(
          "%cThis Video can be reproduced without stopping the buffer", 'color:orange;',event
        );
      });
      this.streamElement.nativeElement.addEventListener("durationchange", (event) => {
        console.log("%cNot sure why, but the duration of the video has changed.",'color:orange;', event);
      });
      this.streamElement.nativeElement.addEventListener("playing", (event) => { console.log('%cStreaming IS PLAYING and no longer paused!', 'color: orange;', event); });
      this.streamElement.nativeElement.addEventListener('canplay', () => {
        console.log('%cVideo is ready to be started','color:orange;')
        if(this.streamElement && this.streamElement.nativeElement) {
          try{
            this.streamElement.nativeElement.play()
            .then(() => {
              console.log('%cStreaming STARTS PLAYING!', 'color: orange;');
              this.streamingStatusEmitter.emit(ServiceStatus.completed)
              this.monkeyWayReady.emit(true);
            })
            .catch((e: any) => {
              setTimeout(() => {
                this.streamElement.nativeElement.play()
                  .then(() => this.monkeyWayReady.emit(true))
                  if(!environment.production){
                    console.log("Error during start playback of media resource", e);
                  } else {
                    console.log("Error during start playback of media resource");
                  }
              }, 1000);
            });
          } catch (error: any) {
              if(!environment.production){
                console.error(error)
              }
          }
        }
      });
      this.streamElement.nativeElement.addEventListener('error', (error: Event) => {
        if(!environment.production){
          console.log("Error loading media resource", error);
        } else {
          console.log("Error loading media resource");
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.stopStreamingSession()
    this.localUnrealHeartbeat?.unsubscribe();
    this.localUnrealHeartbeat = null;
    if (this.touchEventSub) {
      this.touchEventSub.unsubscribe()
    }
    if (this.streamingAvailable$) {
      this.streamingAvailable$.unsubscribe()
    }
    this.routerSubscription$?.unsubscribe();
  }

  public togglePresenterScreenSidebar() {
    this.viewPresenterScreenSidebar = !this.viewPresenterScreenSidebar
    this.chg.detectChanges();
    this.viewPresenterScreenSidebarEvent.emit(this.viewPresenterScreenSidebar)
  }

  private touchEventHandler() {

    if (this.touchEventSub) {
      this.touchEventSub.unsubscribe()
    }
    this.element = this.streamElement.nativeElement;

    const touchEvent$ = fromEvent(this.element, 'touchstart', { passive: false }).pipe(
      map((e) => this.onTouchStart(e as TouchEvent)),
      mergeMap(() => fromEvent(this.element, 'touchmove', { passive: false }).pipe(
        sample(interval(10)),
      )),
      map((e) => this.onTouchMove(e as TouchEvent)),
      takeUntil(fromEvent(this.element, 'touchend', { passive: false }).pipe(
        map((e) => this.onTouchEnd(e as TouchEvent)),
      )),
      repeat()
    )
    this.touchEventSub = touchEvent$.subscribe()

  }

  private onTouchStart(e: TouchEvent) {
    const offsetLeft = this.element.getBoundingClientRect().left
    const offsetTop = this.element.getBoundingClientRect().top
    if (e.touches.length === 2) {
      e.preventDefault && e.preventDefault()
      e.stopImmediatePropagation && e.stopImmediatePropagation()

      const coordinatesOne = e.touches[0]
      const coordinatesTwo = e.touches[1]

      const xOne = (coordinatesOne.clientX - offsetLeft) / this.element.clientWidth
      const yOne = (coordinatesOne.clientY - offsetTop) / this.element.clientHeight;

      const xTwo = (coordinatesTwo.clientX - offsetLeft) / this.element.clientWidth
      const yTwo = (coordinatesTwo.clientY - offsetTop) / this.element.clientHeight;

      this.previousDistance = this.calcCrow(xOne, yOne, xTwo, yTwo)

    }
    else if (e.touches.length === 1) {
      const coordinates = e.touches[0]
      let x = (coordinates.clientX - offsetLeft) / this.element.clientWidth
      let y = (coordinates.clientY - offsetTop) / this.element.clientHeight

      this.sendEventToUnreal('input_press', x, y)
    }
  }

  private onTouchMove(e: TouchEvent) {
    const offsetLeft = this.element.getBoundingClientRect().left
    const offsetTop = this.element.getBoundingClientRect().top
    if (e.touches.length === 2) {
      e.preventDefault && e.preventDefault()
      e.stopImmediatePropagation && e.stopImmediatePropagation()

      const coordinatesOne = e.touches[0]
      const coordinatesTwo = e.touches[1]

      const xOne = (coordinatesOne.clientX - offsetLeft) / this.element.clientWidth
      const yOne = (coordinatesOne.clientY - offsetTop) / this.element.clientHeight;

      const xTwo = (coordinatesTwo.clientX - offsetLeft) / this.element.clientWidth
      const yTwo = (coordinatesTwo.clientY - offsetTop) / this.element.clientHeight;

      const distanceCalculation = this.calcCrow(xOne, yOne, xTwo, yTwo)
      const scrollDelta = distanceCalculation - this.previousDistance

      this.sendZoomToUnreal(scrollDelta)
      this.previousDistance = distanceCalculation

    }
    else if (e.touches.length === 1) {

      const coordinates = e.touches[0]
      const x = (coordinates.clientX - offsetLeft) / this.element.clientWidth
      const y = (coordinates.clientY - offsetTop) / this.element.clientHeight;

      if (this.yPosLast != y || this.xPosLast != x) this.sendEventToUnreal('move', x, y)

      this.xPosLast = x
      this.yPosLast = y
    }
  }

  private onTouchEnd(e: TouchEvent) {
    const offsetLeft = this.element.getBoundingClientRect().left
    const offsetTop = this.element.getBoundingClientRect().top
    const coordinates = e.changedTouches[0]
    const x = (coordinates.clientX - offsetLeft) / this.element.clientWidth
    const y = (coordinates.clientY - offsetTop) / this.element.clientHeight;

    this.sendEventToUnreal('input_release', x, y)
  }

  private calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 50; // sensibility
    const dLat = (lat2 - lat1);
    const dLon = (lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  private sendEventToUnreal(inputType: string, xPos: number, yPos: number) {
    this.autobahnClient.onMouseEvent({ inputEvent: inputType, xPos: xPos, yPos: yPos })
  }

  private sendZoomToUnreal(scrollDelta) {
    this.autobahnClient.onMouseEvent({ inputEvent: 'scroll', scrollDelta: scrollDelta })
  }

  private stopStreamingSession() {
    if (this.streamControlSubs$) {
      this.streamControlSubs$.unsubscribe()
    }
    this.monkeyWay.stopSession();
    this.monkeyWayReady.emit(false);
    this.monkeyWay.destroyStreamElement();
  }

  private _onDisconnect(): void {
    if (this.streamElement) {
      this.streamElement?.nativeElement.pause();
      this.streamElement.nativeElement.srcObject = null;
      this.streamElement.nativeElement.style.display = 'none';
      this.leaveSessionEvent.emit()
    }
  }

  private _onConnect(): void {
    try {
      this.streamElement.nativeElement.srcObject = this.monkeyWay.srcObject;
    } catch (error) {
      this.streamElement.nativeElement.src = URL.createObjectURL(this.monkeyWay.srcObject);
    }
    this.touchEventHandler()

    this.streamElement.nativeElement.style.height = this.streamHeight;
    this.streamElement.nativeElement.style.width = 'auto';
    this.streamElement.nativeElement.style.display = 'block';



  }


  private async startStreaming(): Promise<any> {
    this.autobahnClient.checkAvailableTrim(this.carModel.modelCode).then((availableTrim: boolean) => {
      if (availableTrim) {
        // this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: false}))
        this.isTrimAvailableEventEmitter.emit(true);
        return this.autobahnClient.getStreaming().then((res) => {
          console.warn('Trim available in Unreal, connecting')
          if (!this.unrealDebugInterface) {
            this.localUnrealHeartbeat = interval(5000).subscribe(() => {
              this.autobahnClient.localStreamingHeartbeat()
            })
            this.monkeyWay.startSession(res.connection.connectionKey, res.baseUrl, res.appEnvId);
          } else {
            this.streamingStatusEmitter.emit(ServiceStatus.completed)
          }
        })
          .catch((error: any) => {
            if(!environment.production){
              console.error(error)
            }
            let errorMessage = null;
            try {
              errorMessage = JSON.parse(error.message).error
            } catch (err: any) {
              console.log('Error message is not in JSON format')
            }
            if (errorMessage && errorMessage == 'MXE_SSO_EXPIRED') {
              throw new HandledError(`${this.getLabelWithDefaultValue('MXE_SSO_EXPIRED_ERROR', 'Your authentication has expired, click Continue to return to the homepage.')}`)
            }
            console.warn('No stream available. Activating fallback on 2D configurator')
            this.sentryMessaging.logEvent('mxe-error.fallback_activation','error',{reason: `allocation/starting streaming process failed`, error: error})
            this.showVideoStream = false
            this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({ streamingAvailable: false }))
            this.streamingStatusEmitter.emit(ServiceStatus.failed)
          })
      } else {
        this.isTrimAvailableEventEmitter.emit(false);
        console.warn(`MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal. Activating fallback on 2D configurator`)
        this.sentryMessaging.logEvent('mxe-warning.fallback_activation','warning',{reason: `MXE_TRIM_NOT_IMPLEMENTED: trim ${this.carModel.commercialName} (${this.carModel.modelCode}) not implemented in unreal`})
        this.showVideoStream = false
        this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({ streamingAvailable: false }))
        this.streamingStatusEmitter.emit(ServiceStatus.failed)
        return;
      }
    })
  }

  private getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  private _initSubscriptions() {
    this.streamControlSubs$ = this.monkeyWay.streamControlSubs$.subscribe(
      (connect) => {
        if (connect) {
          this.ngZone.run(
            () => {
              this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({ streamingAvailable: true }))
            }
          )
          this.showVideoStream = true
          this.chg.detectChanges()
          this._onConnect()
        } else {
          this._onDisconnect()
        }
      }
    )

    this.streamingAvailable$ = this.monkeyWay.streamingAvailable$.subscribe(
      (available) => {
        if (!available) {
          this.showVideoStream = false
          this.ngZone.run(
            () => {
              this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({ streamingAvailable: false }))
            }
          )
        }
      }
    )
  }
}
