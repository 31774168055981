import { Action, createReducer, on } from '@ngrx/store';
import { UPDATE_HOTSPOTS_STATE } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-actions';
import { UnrealDesiredSceneStateActions } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { unrealDesiredSceneStateInitialState } from '../initials/unreal-desired-scene-state-initial-state';

const _unrealDesiredSceneStateReducer = createReducer(unrealDesiredSceneStateInitialState,

    on(UnrealDesiredSceneStateActions.UPDATE_UNREAL_DESIRED_SCENE_STATE, (state, payload) => ({
        ...state,
        desired_unreal_state: payload
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_CONFIG_FOR_SCENE, (state, payload) => ({
        ...state,
        config: payload.config
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_MODEL_SELECTED, (state, payload) => ({
        ...state,
        modelCode: payload.modelCode
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_DAY_TIME, (state, payload) => ({
        ...state,
        daytime: payload.daytime
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_ENVIRONMENT, (state, payload) => ({
        ...state,
        environment: payload.environment
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_CAMERA, (state, payload) => ({
        ...state,
        camera: payload.camera
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_COUNTRY, (state, payload) => ({
        ...state,
        country: payload.country
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_LANGUAGE, (state, payload) => ({
        ...state,
        language: payload.language
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_ANIMATION_STATE, (state, payload) => ({
        ...state,
        animation_states: payload.animation_states
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_CURRENT_STATE, (state, payload) => ({
        ...state,
        currentState: payload.current_state
    })),
    on(UnrealDesiredSceneStateActions.RESET_CONFIG_FOR_SCENE, (state) => ({
        ...state,
        config: []
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_PRESENTER_SCREEN_STATE, (state, payload) => ({
        ...state,
        presenterScreen: payload.presenterScreen
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_FULL_SCREEN_STATE, (state, payload) => ({
        ...state,
        fullscreen: payload.fullscreen
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_HOTSPOTS_STATE, (state, payload) => ({
        ...state,
        hotspots: payload.hotspots
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_VIEW_UI_STATE, (state, payload) => ({
        ...state,
        viewUi: payload.viewUi
    })),
    on(UnrealDesiredSceneStateActions.UPDATE_XRAY_STATE, (state, payload) => ({
        ...state,
        xrayEnabled: payload.xrayEnabled
    })),
    on(UnrealDesiredSceneStateActions.RESET_SCENE_STATE, (state) => ({
        ...state,
        modelCode: '',
        config: [],
        animation_states: {},
        environment: '',
        camera: '',
        currentState: '',
        fullscreen: false,
        presenterScreen: false,
        hotspots: false,
        xrayEnabled: true
    })),

);

export function unrealDesiredSceneStateReducer(state = unrealDesiredSceneStateInitialState, action: Action) {
    return _unrealDesiredSceneStateReducer(state, action)
}