import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fallback-toaster',
  templateUrl: './fallback-toaster.component.html',
  styleUrls: ['./fallback-toaster.component.scss']
})
export class FallbackToasterComponent implements OnInit {
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeToaster() {
    this.valueChange.emit();
  }


}