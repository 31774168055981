import { Component, EventEmitter, OnInit, Output, Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, catchError, combineLatest, of } from 'rxjs';
import { GetSaveConfigurationRequest } from 'src/app/core/models/save-conf-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { MxeReducers } from 'src/app/core/store/reducers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorCodes } from "src/app/core/models/error-codes.enum";
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { CarModel } from 'src/app/core/models/get-models-service.model';
import { ICTA } from 'src/app/core/models/country-prop-service.model';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModelOfInterest } from 'src/app/core/models/common.model';
import { CountryISO } from 'ngx-intl-tel-input';
import { HandledError } from 'src/app/core/models/handled-error';

declare var AsyncPrivacy: any;
declare var AsyncPrivacyForm: any;

@Component({
  selector: 'app-save-form',
  templateUrl: './save-form.component.html',
  styleUrls: ['./save-form.component.scss']
})
export class SaveFormComponent implements OnInit {

  @Output() valueChange = new EventEmitter();
  @Output() SaveChange : EventEmitter<boolean> = new EventEmitter();
  @Output() customerNameChange = new EventEmitter<string>();
  @Output() customerSurNameChange = new EventEmitter<string>();
  @Output() emailChange = new EventEmitter<string>();
  @Output() modelOfInterestChange = new EventEmitter<string>();
  @Output() mobileInput = new EventEmitter<string>();
  modalStatus: boolean;
  carConfig: string;
  messageBody: any | null = null;
  errorMessage = '';
  modelCode: any;
  saveConfigurationForm: FormGroup;
  emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  numberPatter= "\\d+"
  languageCode: string;
  countryId: string;
  modelAppSubiscription$ : Subscription;
  saveConfigurationForm$: Subscription;
  modelName: string;
  modelsOfInterest : ModelOfInterest[] = []
  showOptionList : boolean;
  prevModelDesc: string
  choosenModelDesc: string;
  modelChoosen : boolean = false;
  countryISO = CountryISO
  selectedCountryIso : CountryISO
  
  @Input() configId: string;
  @Input() ctas: ICTA[];
  @Input() isSavingAvailable = true;
  @Input() set languageId(langId: string){
    if(langId){
      this.languageCode = langId
      const formElement = document.querySelector('.mxe__modal--save-configuration--form');
      if (formElement) {
        formElement.setAttribute('data-language', this.languageCode);
      }
    }
  }
  @Input() set countryCode(countryId:string){
    if(countryId){
      this.countryId = countryId
      const formElement = document.querySelector('.mxe__modal--save-configuration--form');
      if (formElement) {
        formElement.setAttribute('data-countryCode', this.countryId);
      }
    }

  }


  constructor(
    private maseratiService: MaseratiService,
    private store: Store<MxeReducers>,
    private carConfigurationService: CarConfigurationService,
    private fb: FormBuilder,
    private uiCommonService: UiCommonService,
    private httpClient: HttpClient
    
  ) { }

  async ngOnInit() {
    this.modelAppSubiscription$ = combineLatest([
      this.store.select((s) => s.modelsState),
      this.store.select((s) => s.carGridState.carGrid.general.country_iso_code),
    ]).subscribe(([modelState, countryIsoCode]) => {
      this.modelCode = modelState.currentCarModel!.modelCode;
      this.modelName = modelState.currentCarModel!.modelName
      this.carConfig = this.carConfigurationService.serializeCarConfiguration();
      this.selectedCountryIso = countryIsoCode.toLowerCase() as CountryISO
    });
    this.saveConfigurationForm = this.fb.group({
      customerName: ['', [Validators.required]],
      email: ['', [Validators.pattern(this.emailPattern), Validators.required]],
      customerSurName: ['', [Validators.required]],
      modOfInterest:['', [Validators.required]],
      phone:['',[Validators.pattern(this.numberPatter)]]  
    })
    await this.getAsyncPrivacy();
    this.saveConfigurationForm$ = this.saveConfigurationForm.valueChanges.subscribe(changes => {
      if ('customerName' in changes) {
        this.customerNameChange.emit(changes.customerName)
      }
      if('customerSurName' in changes){
        this.customerSurNameChange.emit(changes.customerSurName)
      }
      if ('email' in changes) {
        this.emailChange.emit(changes.email);
      }
      if('phone' in changes){
        this.mobileInput.emit(changes.phone)
      }
    });
    this.modelChoosen = false;
    this.prevModelDesc = "";
    this.choosenModelDesc = "";
    this.getModelOfInterest();
  }

  ngAfterViewInit(){
    let phoneInput = document.getElementById('phone');
    if(phoneInput){
      phoneInput.setAttribute('data-trackingfield','mobile')
    }
  }

  updateValidity(value: string){
    this.saveConfigurationForm.get(value)?.updateValueAndValidity();
  }

  showModelOfInterest(){
    this.showOptionList = !this.showOptionList;
  }

  chooseModel(model : ModelOfInterest){
    this.modelChoosen = true;
    this.saveConfigurationForm.controls['modOfInterest'].setValue(model.code)
    this.choosenModelDesc = model.descr;
    let elementSelected = document.getElementById(`${model.code}_header`);
    if(elementSelected && !elementSelected.classList.contains('selected')){
       elementSelected.classList.add('selected')
    }
    if(this.prevModelDesc != model.descr){
      let prevCode = this.modelsOfInterest.find(x => x.descr == this.prevModelDesc)?.code
      let prevElementSelected = document.getElementById(`${prevCode}_header`);
      if(prevElementSelected && prevElementSelected.classList.contains('selected')){
        prevElementSelected.classList.remove('selected')
      }
    }
    this.prevModelDesc = model.descr
    let choosenModel = this.modelsOfInterest.find(x => x.code == model.code)?.code
    this.modelOfInterestChange.emit(choosenModel)
  }

  ngOnDestroy(){
    this.modelAppSubiscription$.unsubscribe();
    this.saveConfigurationForm$.unsubscribe();
  }

  

  getAsyncPrivacy(){
    try {
        let element = document.querySelector(".async-privacy");
        if(element){
          let defaultError = element.querySelector(".errorBox > div > div:first-child")?.innerHTML
          let eventError = element.querySelector(".errorBox > div > div:first-child")?.innerHTML
          let eventValidate = element.querySelector(".errorBox > div > div:first-child")?.innerHTML
          switch(this.selectedCountryIso){
            case CountryISO.UnitedStates: {
              let asyncPrivacyForm = new AsyncPrivacyForm(element,{
                webService: environment.maseratiWsEndpoint + '/comserv/public/privacy/gdpr',
                defaultError: defaultError,
                submitElement: element,
              })
              break;
            }
            default:{
              let asyncPrivacy  = new AsyncPrivacy(element, {
                webService: environment.maseratiWsEndpoint + '/comserv/public/privacy/gdpr',
                defaultError: defaultError,
                submitElement: element,
                events: [{
                    type: "error",
                    fn: function(t) {
                      eventError = t.detail.message, element?.dispatchEvent(new CustomEvent("error"))
                    }
                }, {
                    type: "validate",
                    fn: function(t) {
                      eventValidate = t.detail.message, element?.dispatchEvent(new CustomEvent("valid"))
                    }
                }]
              })
            }
          }
          
        }
    } catch (error) {
        if(!environment.production){
          console.error('Error in async privacy:', error);
        }
    }
  }

  async getModelOfInterest(){
    let endpointModelsOfInterest = environment.endpointModelsOfInterest
    var params = {
      lovType: 'MODEL_OF_INTEREST',
      language: this.languageCode,
      country: this.countryId,
      filter: this.modelName,
      // callback: '_jp3'
    }

    const queryStringParams = new URLSearchParams(params).toString();
    const url = `${endpointModelsOfInterest}?${queryStringParams}`;

    try {
      
        const response = await this.httpClient.jsonp(url,'callback=_jp3').pipe(catchError( error => {
          console.debug("Error while retrieving model of Interest", error)
          return of()
        })).subscribe(data => {
          if(data){
            this.modelsOfInterest = data as ModelOfInterest[]
            if(this.modelsOfInterest && this.modelsOfInterest.length == 1){
              this.choosenModelDesc = this.modelsOfInterest[0].descr
              this.saveConfigurationForm.controls['modOfInterest'].setValue(this.modelsOfInterest[0].code)
              this.modelOfInterestChange.emit(this.modelsOfInterest[0].code)
            }
          }
        })
    } catch (error) {
        if(!environment.production){
          console.error('Error in model of interests call service:', error);
        }
    }

  }

  closeModal() {
    this.modalStatus = false;
    this.valueChange.emit(this.modalStatus);
  }

  getLabel(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  saveConfiguration() {
    this.SaveChange.emit(true);
  }

}
