import { Action, createReducer, on } from '@ngrx/store';
import { UPDATE_TAXES, UPDATE_TOTAL_PRICE } from '../actions/engine/engine-actions';
import { engineInitialState } from '../initials/engine-initial-state';


const _engineReducer = createReducer(engineInitialState,
    on(UPDATE_TOTAL_PRICE, (state, payload) => ({
        ...state,
        price: payload.price
    })),
    on(UPDATE_TAXES, (state, payload) => ({
        ...state,
        taxes: payload.taxes
    }))
);

export function engineReducer(state = engineInitialState, action: Action) {
    return _engineReducer(state, action);
}
