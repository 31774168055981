import { Component, OnInit } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';
import { IUserState } from 'src/app/core/store/initials/user-initial-state';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { IDealerMachinesInfo } from 'src/app/core/models/dealer-machines-info';
import { RedirectService } from 'src/app/core/services/redirect.service';
import { SET_DEALER_ID, SET_MACHINES_INFO } from '../../core/store/actions/users/user-actions';
import { IMachineInfo } from 'src/app/core/models/authorization/machine.model';
import countries from '../../../assets/countries.json'
import { APP_SETTINGS_SET_COUNTRY } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { RESET_MODELS_STATE } from 'src/app/core/store/actions/models/models-actions';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';

@Component({
  selector: 'app-experience-choice',
  templateUrl: './experience-choice.component.html',
  styleUrls: ['./experience-choice.component.scss']
})
export class ExperienceChoiceComponent implements OnInit {

  private executionInformations$: Subscription;
  public appExecutionInfos: { protocol: string, host: string,  hostName: string } = { protocol: 'https:', host: '', hostName: '' }
  private userState: IUserState;
  firstAnimationOnCloudOnPremiseStep = false;
  secondAnimationOnCloudOnPremiseStep = false;
  thirdAnimationOnCloudOnPremiseStep = false;
  fourthAnimationOnCloudOnPremiseStep = false;
  fifthAnimationOnCloudOnPremiseStep = false;
  goOnpremise = false;
  goOncloud = false;
  backToModesSlide = false;
  showTridentLoader : boolean = false;
  availableMachines: any[] = [];
  dealerMachinesInfo: IDealerMachinesInfo[] = [];
  dealerInfo = {}
  machinesInfos
  onprem_enabled: boolean
  oncloud_enabled: boolean

  constructor(
    public uiCommonService: UiCommonService,
    private store: Store<MxeReducers>,
    private authService: AuthService,
    private navigation: NavigationStateService,
    private autobahnClient: AutobahnClient,
    private redirectService: RedirectService,)
  { }

  ngOnInit(): void {
    this.executionInformations$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.userState),
      this.store.select(s => s.appState.hostName)
    ])
    .subscribe(
      ([protocol, host, userState, hostName]) => {
        const newBeArchEnabled = localStorage.getItem('new_be_architecture_enabled')? JSON.parse(localStorage.getItem('new_be_architecture_enabled')!) as boolean : false
        this.appExecutionInfos.protocol = protocol
        this.appExecutionInfos.host = host
        this.appExecutionInfos.hostName = hostName
        this.userState = userState
        this.dealerInfo = userState.dealersInfo.find(d => d.dealer_code === userState.dealerId)!
        this.availableMachines = userState.machinesInfo
        this.onprem_enabled = userState.dealersInfo.some(d => d['onprem_enabled'])
        this.oncloud_enabled = userState.dealersInfo.some(d => d['cloud_enabled'])
        if (!userState.jwt_token) {
          this.authService.userAuthentication();
        } else {
           this.authService.checkTokenExpiration(userState.jwt_token_expiration_time, userState.access_token_expiration_time)
           if(!newBeArchEnabled || (host !== 'localhost:8080' && hostName.includes('local') || !this.dealerInfo['onprem_enabled'])){
            this.navigation.navigate(['/mxe'])
            }
            else{
              // this.dealerMachinesInfo.push({
              //   serialNumber: 'WINAPy6uWC3fiZE',
              //   localIps: ["192.168.1.10"],
              //   dealerID: userState.dealerId,
              //   isAvailable: true
              // });      
              (this.availableMachines as IMachineInfo[]).forEach( async machine => {
                let instanceAvailable = await this.autobahnClient.getInstanceStatus(machine.serialNumber)
                this.dealerMachinesInfo.push({
                  serialNumber: machine.serialNumber,
                  localIps: machine.localIps,
                  isAvailable: instanceAvailable,
                  dealerID: machine.dealerID
                })
                console.debug("[Dealer Machine Infos] - ",this.dealerMachinesInfo)
              });
          }
        }
        
        
      }
    );
    setTimeout(() => {
      this.loadButtonConnectionWithAnimation();
    }, 1500) 
  }

  ngOnDestroy() {
    this.executionInformations$?.unsubscribe()
  }

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  loadButtonConnectionWithAnimation() {
    this.firstAnimationOnCloudOnPremiseStep = true;
    setTimeout(() => {
      this.secondAnimationOnCloudOnPremiseStep = true;
    }, 300)
    setTimeout(() => {
      this.thirdAnimationOnCloudOnPremiseStep = true;
    }, 600)
    setTimeout(() => {
      this.fourthAnimationOnCloudOnPremiseStep = true;
    }, 900)
    setTimeout(() => {
      this.fifthAnimationOnCloudOnPremiseStep = true;
    }, 1200)
  }

  goToOnPremiseMachines() {
    this.goOnpremise = true;
    this.backToModesSlide = false;
  }

  goOnCloud() {
    this.showTridentLoader = true;
    const selectedDealer = this.userState.dealersInfo.find(d => d.cloud_enabled && d.cloud_quota > 0)
    if(selectedDealer) {
      const country = countries.countryList.find(c => c.countryCode === selectedDealer.country)
      if (country) {
        const languageId = country.availableLanguages.length > 0 ? country.availableLanguages[0] : 'en'
        const actualCountryCode = localStorage.getItem('appSettings')? JSON.parse(localStorage.getItem('appSettings')!).countryCode : 999;
        if(country.countryCode !== actualCountryCode){
          this.store.dispatch(APP_SETTINGS_SET_COUNTRY({ countryCode: country.countryCode, languageId: languageId, ctaLanguageId: country.ctaLanguageId }))
          this.store.dispatch(RESET_MODELS_STATE())
        }
      }
      this.store.dispatch(SET_DEALER_ID({ dealerId: selectedDealer!.dealer_code }))
    }
    setTimeout(() => {
      this.navigation.navigate(['/mxe']);
    },2200)
  }

  backToModes(){
    this.backToModesSlide = true
    this.goOnpremise = false;
  }

  goToOnPremiseHome(machine: IDealerMachinesInfo) {
    this.showTridentLoader = true;
    const ip = machine.localIps[0];
    const dealerInfo = this.userState.dealersInfo.find(d => d.dealer_code === machine.dealerID)
    if (ip) {
      const country = countries.countryList.find(c => c.countryCode === dealerInfo?.country)
      if (country) {
        const languageId = country.availableLanguages.length > 0 ? country.availableLanguages[0] : 'en'
        const actualCountryCode = localStorage.getItem('appSettings')? JSON.parse(localStorage.getItem('appSettings')!).countryCode : 999;
        if(country.countryCode !== actualCountryCode){
          this.store.dispatch(APP_SETTINGS_SET_COUNTRY({ countryCode: country.countryCode, languageId: languageId, ctaLanguageId: country.ctaLanguageId }))
          this.store.dispatch(RESET_MODELS_STATE())
        }
      }
      this.store.dispatch(SET_DEALER_ID({ dealerId: machine.dealerID }))
      setTimeout(() => {
        this.redirectService.redirectSolution(this.userState, ip)
      }, 2200)
    }
  }
}
