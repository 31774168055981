import {Action, createReducer, on } from "@ngrx/store";
import { temporaryConfigInitialState, ConfigItem } from '../initials/temporary-configs-initial-state';
import {TemporaryConfigActions} from "../actions/temporary-configs/temporary-configs-exported-actions"

const _temporaryConfigsReducer = createReducer(temporaryConfigInitialState,
    on(TemporaryConfigActions.TEMP_CONFIG_ADD, (state, payload) => ({
        configItems: [...state.configItems, payload.configItem]
    })),
    on(TemporaryConfigActions.TEMP_CONFIG_DELETE, (state, payload) => ({
        ...state
    })),
    on(TemporaryConfigActions.TEMP_CONFIG_INIT, (state, payload) => ({
        configItems: payload.configItems
    })),
);

export function temporaryConfigsReducer(state = temporaryConfigInitialState, action: Action) {
    return _temporaryConfigsReducer(state, action)
}

 