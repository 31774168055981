import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest, throwError } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { ICountry } from 'src/app/core/models/country.model';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { AppSettingsActions } from 'src/app/core/store/actions/app-settings/app-settings-exported-actions';
import { RESET_MODELS_STATE, RESET_SELECTED_MODEL } from 'src/app/core/store/actions/models/models-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import countries from '../../../assets/countries.json';
import { PAIRING_STEP_ENUM } from 'src/app/core/models/common.model';
import { IDealer } from 'src/app/core/models/authorization/dealer.model';
import { SET_DEALER_ID } from 'src/app/core/store/actions/users/user-actions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { STEP_ENUM } from '../../core/models/common.model';
import { APP_SETTINGS_SET_SCREENCAST_ACTIVE, APP_SETTINGS_SET_STREAMING_STATUS } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';
import { HeartbeatService } from 'src/app/core/services/heartbeat.service';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import { MonkeyWaySessionState } from 'src/app/core/store/initials/monkeyway-sessions-initial-state';
import { AuthService } from 'src/app/core/services/authentication.service';
import { HandledError } from 'src/app/core/models/handled-error';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';


@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  animations: [ 
    trigger('openCloseAcc', [
      state('closed', style({ 
          'height': '0px'
        })),
        state('open', style({ 
          'height': '*'
        })),
      transition('closed <=> open', [
      animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
      ])
 ],
})
export class HomeMenuComponent {
  @Input() ephemeralDeviceId: string;
  @Input() streamingMode: string;
  @Output() homeMenuStatusChange = new EventEmitter();
  @Output() onToggleAccordion = new EventEmitter()
  applicationStep: string;

  priceStatus: boolean;
  selectedCountry: ICountry;
  selectedLanguageId: string;
  isModalCountryOpen = false;
  isModalLanguageOpen = false;
  countries: ICountry[] = [];
  arePricesAvailable$: any;
  arePricesAvailable: boolean = false;
  availableLanguages:string[];
  accordionOpened = [];
  screenCastActive: boolean;
  ephemeralDeviceID:string;
  languagesList: string[]
  showConfirmSwitchLanguage = false
  currentState : string[];

  selectedDealer: IDealer
  availableDealers: IDealer[] = []
  selectableDealers: IDealer[] = []
  countrySelectorEnabled: boolean;
  appExecutionInfos: { protocol: string, host: string, hostName: string} = { protocol: 'https:', host: '',hostName: '' }
  isProduction: boolean;
  monkeyWaySessions: MonkeyWaySessionState[] = []

  private previousSelectedLanguageId: string
  private countryCode: number | undefined;
  private countryCode$: Subscription;
  private languageId$: Subscription;
  private showPrices$: Subscription
  private screenCastActive$: Subscription
  private ephemeralDeviceID$: Subscription
  private dealers$: Subscription;
  private executionInformations$: Subscription;
  private monkeyWaySessions$: Subscription;
  private applicationStep$: Subscription;

  constructor(
    private store: Store<MxeReducers>,
    private chg: ChangeDetectorRef,
    private autobahnClient: AutobahnClient,
    private mxeAnalyticsService: MxeAnalyticsService,
    private uiCommonService: UiCommonService,
    private navigation: NavigationStateService,
    private zone: NgZone,
    private heartbeatService: HeartbeatService,
    private monkeyWay: MonkeyWayService,
    private authService: AuthService
  ) {
    this.applicationStep$ = this.store.select(s => s.appState.applicationStep).subscribe((step:string) => this.applicationStep = step)
    this.executionInformations$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.appState.hostName)
    ])
    .subscribe(
      ([protocol, host, hostName]) => {
        this.appExecutionInfos.protocol = protocol
        this.appExecutionInfos.host = host
        this.appExecutionInfos.hostName = hostName       
      })  
    this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
    screenCastActive => this.screenCastActive = screenCastActive)

    this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
      res => this.ephemeralDeviceID = res
    )

    this.dealers$ = this.store.select(s => s.userState).subscribe(
      (res) => {
        this.availableDealers = res.dealersInfo
        this.selectedDealer = this.availableDealers.find(d => d.dealer_code === res.dealerId)!
        this.selectableDealers = this.availableDealers.filter(d => d.dealer_code !== this.selectedDealer.dealer_code)
      }
    )
  }


  ngOnInit(): void {
    this.countrySelectorEnabled = environment.country_selector_enabled;
    this.showPrices$ = this.store.select(s => s.appState).subscribe(appState => {
      this.priceStatus = appState.showPrices;
    })

    this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
      lang => this.selectedLanguageId = lang
    )

    this.countries = countries.countryList
    
    this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
      countryCode => {
        this.countryCode = countryCode
        if(countryCode){
          this.selectedCountry = this.countries.find(c => c.countryCode === countryCode) || this.countries[0]
        } else {
          this.selectedCountry = this.countries[0]
        }
      }
    )

    this.arePricesAvailable$ = this.store.select(s => s.appState.arePricesAvailable).subscribe(
      result => this.arePricesAvailable = result
    )
    this.languagesList = this.selectedCountry.availableLanguages.filter(l => l != this.selectedLanguageId)

    this.monkeyWaySessions$ = this.store.select(s => s.monkeyWay_sessions_state.sessions).subscribe((sessions: MonkeyWaySessionState[]) => {
      if(sessions) {
        this.monkeyWaySessions = sessions
      }
    })
    
    this.initAdobeTracker()
    this.availableLanguages = this.selectedCountry.availableLanguages
    this.currentState = ["closed","closed"]
  }
  
  ngOnDestroy() {
    this.arePricesAvailable$?.unsubscribe()
    this.showPrices$?.unsubscribe()
    this.countryCode$?.unsubscribe()
    this.languageId$?.unsubscribe()
    this.screenCastActive$?.unsubscribe()
    this.ephemeralDeviceID$?.unsubscribe()   
    this.dealers$?.unsubscribe()
    this.executionInformations$?.unsubscribe();
    this.monkeyWaySessions$?.unsubscribe();
  }

  getLabel(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  onSelectLanguage(languageId: string, i){
    if(this.selectedLanguageId != languageId) {
      this.previousSelectedLanguageId = this.selectedLanguageId
      this.selectedLanguageId = languageId
      this.languagesList = this.selectedCountry.availableLanguages.filter(l => l != this.selectedLanguageId)
      this.showConfirmSwitchLanguage = true;
      this.currentState[i] = this.currentState[i]  === 'closed' ? 'open' : 'closed';
    }
  }

  onConfirmSwitchLanguage() {
    console.debug(`Selected language: ${this.selectedLanguageId}`)
    
    this.store.dispatch(AppSettingsActions.APP_SETTINGS_SWITCH_LANGUAGE({
      languageId: this.selectedLanguageId
    }))
    if(this.selectedCountry.availablectas.includes(this.selectedLanguageId)) {
      this.store.dispatch(AppSettingsActions.APP_SETTINGS_SWITCH_CTA_LANGUAGE({
        ctaLanguageId: this.selectedLanguageId
      }))
    } else {
      const defaultCtaLanguage = this.selectedCountry.ctaLanguageId
      this.store.dispatch(AppSettingsActions.APP_SETTINGS_SWITCH_CTA_LANGUAGE({
        ctaLanguageId: defaultCtaLanguage
      }))
    }

    if(this.screenCastActive){
      this.autobahnClient.switchLanguage(this.ephemeralDeviceID, this.selectedLanguageId)
    }
    this.store.dispatch(RESET_MODELS_STATE())
    this.showConfirmSwitchLanguage = false
    this.homeMenuChangeStatusEvent()
    this.navigation.navigate(['/mxe'])
    if(this.screenCastActive){
      this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID).then(() => {
        this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME)
      })
    }
  }

  onCancelSwitchLanguage(){
    this.selectedLanguageId = this.previousSelectedLanguageId
    this.languagesList = this.selectedCountry.availableLanguages.filter(l => l != this.selectedLanguageId)
    this.showConfirmSwitchLanguage = false;
  }

  logout() {
    if(this.applicationStep !== STEP_ENUM.CAR_CONFIGURATOR) {
      if(this.screenCastActive && this.ephemeralDeviceID) {
        this.onStopMirroring()
      } else {
        window.location.href = environment.homepage_url;
      }
    } else {
      this.resetStreamingAvailable()
      this.resetLoadedConfiguration()
      this.monkeyWay.stopSession().then(() => {
        this.zone.run(() => {
          this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: false}))
        })
        window.location.href = environment.homepage_url;
      })
    } 
  }

  public onStopMirroring() {
    if(this.screenCastActive) {
      console.log('ON STOP MIRRORING...')
      this.heartbeatService.mirroringStoppedByUser = true
      this.zone.run(() => {
        this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: false}))
      })
      this.autobahnClient.onLeaveScreenCast(this.ephemeralDeviceID).then(() => {
        this.appExecutionInfos.hostName.includes('local') && this.appExecutionInfos.host !='localhost:8080' ? window.location.href = environment.homepage_url : this.navigation.navigate(['/']) ;
      }).catch((error:any) => throwError(() => 'Unable to Logout'))
    }
  }

  private resetStreamingAvailable() {
    this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: true}))
    if(this.screenCastActive) {
      this.autobahnClient.setStreamingStatus(this.ephemeralDeviceID, true)
    } 
  }
  resetLoadedConfiguration() {
    this.store.dispatch(RESET_SELECTED_MODEL())
    this.store.dispatch(RESET_CONFIGURATION_LOADED())
  }


  togglePrices(){
      this.priceStatus = !this.priceStatus;
      this.store.dispatch(AppSettingsActions.APP_SETTINGS_TOGGLE_PRICES({
        showPrices: this.priceStatus,
      }))
      if(this.ephemeralDeviceId)
        this.autobahnClient.setPriceStatus(this.ephemeralDeviceId, this.priceStatus);
  }

  modalCountryOpen(){
    this.isModalCountryOpen = true;
    this.chg.detectChanges();
  }

  throwTestMessage() {
    throw new HandledError('Sentry Test Message')
  }

  throwtestError(){
    throw new Error("Sentry Test Error");
  }

  modalCountryStatusChange(event){
    this.isModalCountryOpen = event;
    //Added to close home menu component on country change
    this.homeMenuStatusChange.emit();
  }

  homeMenuChangeStatusEvent(){
    this.homeMenuStatusChange.emit();
  }
  normalizeForAnalytics(value: string): string{
    return this.mxeAnalyticsService.normalizeStringForAnalytics(value)
  }

  isAccordionExpanded(accordionId: string): boolean{
    if(!(accordionId in this.accordionOpened)){
      this.accordionOpened[accordionId] = true
      return true
    }
    return this.accordionOpened[accordionId]
  }

  toggleAccordion(dataaccordion: string, e, i): void {
    e.stopPropagation && e.stopPropagation()
    this.currentState[i] = this.currentState[i]  === 'closed' ? 'open' : 'closed';
    if(this.accordionOpened[dataaccordion]){
      this.accordionOpened[dataaccordion] = false
    } else {
      this.accordionOpened[dataaccordion] = true
    }
    this.onToggleAccordion.emit({accordionToggled :dataaccordion, value: this.accordionOpened[dataaccordion]});
  }

  showStreamingModeInfo(): boolean {
    return this.streamingMode != null && this.streamingMode != ''
  }

  getStreamingModeInfo(): string {
    if(this.streamingMode === 'cloud')
      return this.getLabel('MXE_STREAMING_MODE_CLOUD_NAME', 'CLOUD MODE')
    return this.getLabel('MXE_STREAMING_MODE_ON_PREMISE_NAME', 'ON PREMISE MODE')
  }

  getStreamingModeIcon() {
    if(this.streamingMode === 'cloud')
      return '/assets/icons/mxe-on_cloud.png'
    return '/assets/icons/mxe-on_premise.png'
  }

  onSelectDealer(dealer_code: string,i) {
    if(this.selectedDealer.dealer_code !== dealer_code){
      this.selectedDealer = this.availableDealers.find(d => d.dealer_code === dealer_code)!
      this.selectableDealers = this.availableDealers.filter(d => d.dealer_code !== this.selectedDealer.dealer_code)
      this.accordionOpened['accordion-dealers'] = false
      this.currentState[i] = this.currentState[i]  === 'closed' ? 'open' : 'closed';
      this.authService.wrongDealerConfigPopUpShown = false;
      this.store.dispatch(SET_DEALER_ID({ dealerId: dealer_code }))
      const currentDealer = this.availableDealers.find(d => d.dealer_code === dealer_code)
      if(currentDealer && currentDealer.country !== this.selectedCountry.countryCode) {
        this.selectedCountry = this.countries.find(c => c.countryCode === currentDealer.country) || this.countries[0]
        //Reset state after country's switch
        this.store.dispatch(AppSettingsActions.APP_SETTINGS_SWITCH_COUNTRY({
          countryCode: this.selectedCountry.countryCode,
          languageId: this.selectedCountry.availableLanguages.length > 0 ? this.selectedCountry.availableLanguages[0] : 'en',
          ctaLanguageId: this.selectedCountry.ctaLanguageId,
          showPrices: false,
          arePricesAvailable: false
        }))
        this.store.dispatch(RESET_MODELS_STATE())
        //reset countryProp
        this.navigation.navigate(['/mxe'])
        if(this.screenCastActive) {
          this.autobahnClient.switchCountry(this.ephemeralDeviceID, this.selectedCountry)
          this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME)
        }
      }
    }

  }

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  goToUserGuidlinesPage(){
    let fullPath = `${environment.homepage_url}/mxe/utilities/user-guidelines`
    window.open(fullPath)

  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.selectedLanguageId}`,
          "pageName": "side-nav:preferences", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }
}