import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Camera } from 'src/app/core/models/unreal-available-models.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { CamerasActions } from 'src/app/core/store/actions/unreal-cameras/cameras-exported-ections';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { EnvironmentsActions } from 'src/app/core/store/actions/unreal-environments/environments-exported-actions';
import { unrealDesiredSceneStateInitialState } from 'src/app/core/store/initials/unreal-desired-scene-state-initial-state';
import { MxeReducers } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-interactive-bottom-bar',
  templateUrl: './interactive-bottom-bar.component.html',
  styleUrls: ['./interactive-bottom-bar.component.scss']
})
export class InteractiveBottomBarComponent {

  @Input() cameras: Camera[];
  @Input() activeCamera: Camera;
  @Input() environments: string[];
  @Input() activeEnvironment: string;
  @Input() screenCastIconActive: boolean;  
  @Input() set dayNight(dayNight: string){
    this.nightModeIconActive = dayNight == 'night'
  };
  @Input() activeFamilyCommercialCode: string
  @Input() xRayEnabled: boolean;
  @Input()monkeyWayStreamingReady : boolean
  @Input() countryCode: string

  @Output() hotspotsToggleEvent: EventEmitter<boolean> = new EventEmitter;
  @Output() fullScreenToggleEvent: EventEmitter<boolean> = new EventEmitter;
  @Output() testPipelineEvent = new EventEmitter;
  @Output() screenMirroringEvent = new EventEmitter;
  private isXrayReady$: Subscription


  public isPipelineAvailable: boolean = environment.develop

  //icons 
  public nightModeIconActive: boolean;
  public drivingExpIconActive = false;
  public xRayIconActive = false;
  public environmentIconActive = false;
  public fullScreenIconActive = false;
  public hotspotsIconActive = false;
  public selectedEnvironment: string;
  public showCameraCarousel = false;
  public pipelineActive = false;
  private userHotspotsEvent: boolean = false
  public unrealDebugInterface: boolean = localStorage.getItem('unreal_debug_interface') ? JSON.parse(localStorage.getItem('unreal_debug_interface')!) as boolean : false;
  public isXrayReady: boolean = true
  options: AnimationOptions = {path: '../../../assets/icons/loading.json',};

  constructor(
    private store: Store<MxeReducers>,
    private autobahn: AutobahnClient,
    private uiCommonService: UiCommonService,
    private ngZone: NgZone,
    private chg: ChangeDetectorRef
  ) { } 

  ngOnInit() {
    this.isXrayReady$ = this.autobahn.xRayLoaded.subscribe(res => {
      if(res) {
        const parsedCode = JSON.parse(res as any)
        if((parsedCode && parsedCode.code && parsedCode.code.includes('ready') || (parsedCode && parsedCode.success === false)))
        parsedCode && parsedCode.success === false? console.error('Xray encountered an error...'): console.log('Xray ready')
        setTimeout(() => {
          this.isXrayReady = true;
        this.chg.detectChanges();},2000)
      }
    })
   }

   ngOnDestroy(){
    this.isXrayReady$?.unsubscribe()
  }
  
  public nightModeToggle() {
    this.nightModeIconActive = !this.nightModeIconActive
    const dayNightSceneState = this.nightModeIconActive ? 'night' : 'day'
    this.ngZone.run(
      () => {
        this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_DAY_TIME({
          daytime: dayNightSceneState
        }))
      }
    )
  }

  public onSwitchEnvironment(activeEnvironment: string) {
    this.store.dispatch(EnvironmentsActions.UPDATE_SELECTED_ENVIRONMENT({
      activeEnvironment: activeEnvironment
    }))
  }

  public onSwitchCamera(activeCamera: Camera) {
    this.store.dispatch(CamerasActions.UPDATE_SELECTED_CAMERA({
      activeCamera: activeCamera
    }))
  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  public onSwitchXRayMode(){
    if(this.activeCamera && this.activeCamera.location == 'int'){
      this.onSwitchCamera(this.cameras[0])
    }
    this.isXrayReady = false;
    this.autobahn.toggleXRayMode(this.xRayIconActive)
    this.xRayIconActive = !this.xRayIconActive
    // hotspots' logic
    if(this.xRayIconActive) {
      this.hotspotsIconActive = false
      // this.ngZone.run(
      //   () => {
      //     this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_HOTSPOTS_STATE( {hotspots: false }))
      //   }
      // )
    } else {
      this.hotspotsIconActive = this.userHotspotsEvent || this.fullScreenIconActive
      this.hotspotsToggleEvent.emit(this.hotspotsIconActive)
      // this.ngZone.run(
      //   () => {
      //     this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_HOTSPOTS_STATE( {hotspots: this.hotspotsIconActive }))
      //   }
      // )
    }
  }

  public fullScreenToggle(){
    this.fullScreenIconActive = !this.fullScreenIconActive; 
    this.fullScreenToggleEvent.emit(this.fullScreenIconActive)
    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_FULL_SCREEN_STATE({
      fullscreen: this.fullScreenIconActive
    }))
    if(!this.xRayIconActive){
      this.hotspotsIconActive = this.userHotspotsEvent || this.fullScreenIconActive;
    } else {
      this.hotspotsIconActive = false
    }
    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_HOTSPOTS_STATE( {hotspots: this.hotspotsIconActive }))
  }

  public hotspotsEvent(){
    this.hotspotsIconActive = !this.hotspotsIconActive; 
    this.userHotspotsEvent = this.hotspotsIconActive
    this.hotspotsToggleEvent.emit(this.hotspotsIconActive)
    this.ngZone.run(
      () => {
        this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_HOTSPOTS_STATE( {hotspots: this.hotspotsIconActive }))
      }
    )
  }

}
