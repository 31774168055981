import { IDealer } from "../../models/authorization/dealer.model"
import { IMachineInfo } from "../../models/authorization/machine.model"

export const userInitialState: IUserState = {
  jwt_token: "",
  jwt_token_expiration_time: 0,
  dealerId: "",
  authorization_code: '',
  access_token: "",
  access_token_expiration_time: 0,
  dealersInfo: [],
  machinesInfo: []
}

export interface IUserState {
  jwt_token: string,
  jwt_token_expiration_time: number,
  dealerId: string,
  authorization_code: string
  access_token: string
  access_token_expiration_time: number
  dealersInfo: IDealer[]
  machinesInfo: IMachineInfo[]
}

export class UserState implements IUserState {
  jwt_token: string
  jwt_token_expiration_time: number
  dealerId: string
  access_token: string
  authorization_code: string
  access_token_expiration_time: number
  dealersInfo: IDealer[]
  machinesInfo: IMachineInfo[]
  constructor() {
    this.jwt_token = '',
    this.jwt_token_expiration_time = 0,
    this.dealerId = ''
    this.authorization_code = ''
    this.access_token = ''
    this.access_token_expiration_time = 0
    this.dealersInfo = []
    this.machinesInfo = []
  }
}
