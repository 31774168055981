<div id="{{menuLabel}}" *ngFor="let tab of menuItems?.tabs" class="mxe__packages-config-panel accordion-{{tab.id}} active">
  <ng-container *ngIf="isTabVisible(tab.id)">
    <!--HEADER-->
    <div id="{{tab.id}}_header" class="mxe__packages--section-header">
      <div class="header-title" (click)="toggleAccordion(tab.id, $event)"
      [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + [accordionOpened ? ':close' : ':open']">
        <span class="header-text">
          {{ getLabel(tab.id) }}
        </span>
        <div class="header-icons">
          <span class="toggle-arrow"></span>
        </div>
      </div>
      <span *ngIf="isMainScreen" class="selected-packages-details">
        {{formatSelectionDetails()}}
      </span>
      <span *ngIf="!isMainScreen" class="selected-packages-details">
        {{formatSelection}}
      </span>
      <!--/HEADER-->
    </div>
    <!--inizio body accordion figlio-->
    <div id="{{tab.id}}_content" class="mxe__packages-padd" [@openCloseAcc]="accordionOpened ? 'open' : 'closed'">
      <div id="{{tab.id}}_wrapper" *ngFor="let section of tab.sections" class="mxe__exterior-color-config-items mxe__packages-list">
        <ng-container *ngIf="sectionIsVisible(section.id, section.filter_fuoriserie)">
          <div *ngFor="let pkg of getOptionsBySection(section.id, section.filter_fuoriserie); let i = index"
            [class.selected]="pkg.packageObj.status.selected" class="mxe__packages-item"
            [ngClass]="{'not-selectable': !pkg.packageObj.status.selectable && !pkg.packageObj.status.selected}"
            id="{{pkg.packageObj.section+'_'+pkg.packageId}}">
            <div class="pkg-accordion" [ngClass]="{'expanded': i+1 === accordionExp}">
              <div class="pkg-accordion-heading">
                <div class="accordion-toggle"></div>
                <swiper id="SWIPER_{{i}}" class="pkg-accordion-carousel" [ngClass]="pkg.packageId"
                  [config]="config" [slidesPerView]="1" [spaceBetween]="0" [loop]="true"
                  [pagination]="{ clickable: true }" [navigation]="false" (swiper)="onSwiper($event)"
                  (transitionEnd)="onSlideChange($event, i)">
                  <ng-template swiperSlide *ngFor="let imagePath of getOptionsImageInPackage(pkg.packageId)">
                    <div class="pkg-acc-sld-bg" style="background-image: url('{{imagePath}}');"
                      (click)="toggleAccordionDetailsStatus(i, $event)"
                      [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, pkg.packageId, '', 'info')">
                    </div>
                  </ng-template>
                </swiper>
                <div class="absolute-pkg-infos">
                  <span class="pkg-title">
                    {{getLabel(pkg.packageId, uiCommonService.LABEL_TYPE_ENUM.NAME)}}
                  </span>
                  <span class="pkg-price" *ngIf="showPrices"> {{ formatPrice(pkg.price) }}</span>
                </div>
                <button *ngIf="pkg.packageObj.status.selectable || pkg.packageObj.status.selected" class="btn-action-package"
                  (click)="changeConfiguration(pkg.packageId, pkg.packageObj.status.selected)"
                  [disabled]="!pkg.packageObj.status.selectable"
                  [class.pack-selet]="pkg.packageObj.status.selected"
                  [ngStyle]="{'opacity' : !pkg.packageObj.status.selectable ? '0.5' : '1'}">
                  <span *ngIf="pkg.packageObj.status.selected"
                    [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, pkg.packageId, '', 'remove')">{{getLabel('REMOVE_OPT')}}</span>
                  <span *ngIf="!pkg.packageObj.status.selected"
                    [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, pkg.packageId, '', 'add')">{{
                    getLabel('ADD_OPT')}}</span>
                </button>
                <span *ngIf="!pkg.packageObj.status.selectable && !pkg.packageObj.status.selected" class="package-not-available">{{
                  getLabel('OPT_NOT_AVAILABLE') }}</span>
              </div>
              <div class="pkg-accordion-content">
                <div class="pkg-content-list">
                  <div class="pkg-content-row"
                    *ngFor="let description of getPackageImplicitsDescriptions(pkg.packageId)">
                    <div class="outer-circle">
                      <div class="inner-circle"></div>
                    </div>
                    {{description}}
                  </div>
                  <div *ngFor="let set of pkg.standAlone.sets" class="subset-packages-accordion"
                    [ngClass]="{'active': accordionOpen}"
                    >

                    <div class="pkg-content-row"
                      *ngIf="getSelectedItemInList(pkg.packageId,
                      set.id) !== undefined && (!standAloneSetEditing[pkg.packageId+'_'+set.id] || standAloneSetEditing[pkg.packageId+'_'+set.id] === false)">
                      <div class="outer-circle">
                        <div class="inner-circle"></div>
                      </div>
                      <span class="edit-text"
                        [ngClass]="{'edit-active-text':standAloneSetEditing[pkg.packageId+'_'+set.id] === true}">
                        {{getSelectedItemTranslation(pkg.packageId, set.id)}}
                        <span *ngIf="showPrices">- {{formatPrice(getSelectedItemInList(pkg.packageId,
                          set.id).price)}}</span></span>
                      <span class="action-edit-icon"
                        [ngClass]="{'edit-active':standAloneSetEditing[pkg.packageId+'_'+set.id] === true}"
                        (click)="showSetList(pkg.packageId, set.id)"></span>
                    </div>
                    <div *ngFor="let item of set.list; let i = index">
                      <div>
                        <div class="pkg-content-row"
                          *ngIf="standAloneSetEditing[pkg.packageId+'_'+set.id] === true">
                          <input type="radio" name="set_{{pkg.packageId}}" value="{{item.id}}"
                            [class.active]="item.status.selected"
                            [ngClass]="{'disabled': !item.status.selectable}"
                            [checked]="getSelectedItemInList(pkg.packageId, set.id).id === item.id"
                            (change)="onSetListItemChange(item.id, pkg.packageId, set.id)"
                            [disabled]="!item.status.selectable">
                          <label class="item-name" [ngClass]="{'disabled': !item.status.selectable}"
                            [class.active]="item.status.selected">
                            {{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME)}} <span
                              *ngIf="showPrices">- {{formatPrice(item.price)}}</span>
                          </label>
                          <span class="rx-container">
                            <label *ngIf="!item.status.selectable" class="not-available">{{
                              getLabel('OPT_NOT_AVAILABLE') }}</label>
                            <span *ngIf="i === 0" class="hide-set-list"
                              (click)="showSetList(pkg.packageId, set.id)"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
       </div>
      <div class="section-disclaimer">
        {{ getLabel('PRICE_CUSTOMIZABLE_PACK_DISCLAIMER_DESCR') }}
      </div>
    </div>

   
<!--/CONTENT-->
  </ng-container>
</div>
<mxe-modal *ngIf="showModal"></mxe-modal>