import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { combineLatest, distinctUntilChanged, Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { CarModel, Family } from 'src/app/core/models/get-models-service.model';
import { Menu } from 'src/app/core/models/interface-service.model';
import { PAIRING_STEP_ENUM } from 'src/app/core/models/common.model'
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';
import { APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { ConfigItem } from 'src/app/core/store/initials/temporary-configs-initial-state';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-presenter-screen-modal',
  templateUrl: './presenter-screen-modal.component.html',
  styleUrls: ['./presenter-screen-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenterScreenModalComponent {

  @ViewChild('action') action: NgxScannerQrcodeComponent;
  @ViewChild('pairingCode') numberInput: ElementRef;

  @Output() modalValue: EventEmitter<boolean> = new EventEmitter;
  @Output() ephemeralDeviceIDEvent = new EventEmitter

  @Input() dealerId: string;
  @Input() carModel: CarModel
  @Input() priceStatus: boolean
  @Input() menuItems: Menu[];
  @Input() modelOptions: UIOptItem[]
  @Input() packages: Pack[]
  @Input() labels: any
  @Input() closedAccordions: string[]
  @Input() pairingStep: string
  @Input() scrollPosition: string
  @Input() selectedFamily: Family
  @Input() activeSlideIndex: number | undefined
  @Input() currentTrimCode: string
  @Input() accordionExp: number = 0
  @Input() temporaryConfigItems: ConfigItem[] = []
  @Input() isRestoreConfig: boolean;
  @Input() isLoadConfig: boolean;
  @Input() viewUi: boolean;

  public output: string;
  public actionIsStart: boolean = false
  public qrCodeIsOk = false;
  public qrCodeIsSync = false;
  public manualCodeIsOk = false;
  public syncUnsuccessfulQRCode = false;
  public syncUnsuccessfulManualCode = false;
  public manuallyInsertTriggered = false;
  public manualCodeCheck = false;
  public manualInputForm: FormGroup = new FormGroup({
    clientID: new FormControl('')
  })

  private outputSub$: Subscription;
  private appStateSub$: Subscription;
  private familiesSub$: Subscription;
  private labels$: Subscription;
  private ephemeralDeviceID: string;
  private selectedCountry: {
    countryCode: number | undefined,
    languageId: string,
    ctaLanguageId: string
  } = {
    countryCode: 104,
    languageId: 'en',
    ctaLanguageId: 'it'
  }
  private families: Family[] = []
  private executionInformations$: Subscription
  public appExecutionInfos: { protocol: string, host: string,  hostName: string } = { protocol: 'https:', host: '', hostName: '' }
  private streamingAvailable: boolean

  constructor(
    private autobahn: AutobahnClient,
    private changeDetector: ChangeDetectorRef,
    private store: Store<MxeReducers>,
    private uiCommonService: UiCommonService,
  ) {
    this.appStateSub$  = this.store.select(s => s.appState).subscribe(
      appstate => {
        this.selectedCountry.countryCode = appstate.countryCode
        this.selectedCountry.languageId = appstate.languageId
        this.selectedCountry.ctaLanguageId = appstate.ctaLanguageId
        this.streamingAvailable = appstate.streamingAvailable
      }
    )
    this.executionInformations$ = combineLatest([
      this.store.select(s => s.appState.protocol),
      this.store.select(s => s.appState.host),
      this.store.select(s => s.appState.hostName)
    ])
    .subscribe(
      ([protocol, host, hostName]) => {
        this.appExecutionInfos.protocol = protocol
        this.appExecutionInfos.host = host
        this.appExecutionInfos.hostName = hostName
        }
    );
    this.familiesSub$ = this.store.select(m => m.modelsState.families).subscribe(families => {
      this.families = families
  });
   }


  ngAfterViewInit() {
    let isTablet = false;
    //if(this.appExecutionInfos.protocol !== 'http:') {
      const userAgent = window.navigator.userAgent.toLowerCase();
      isTablet = /ipad|android(?!.*mobile)/.test(userAgent);
    // } else {
    //   isTablet = true;
    // }
    try {
      const formPairingCodeInput = this.numberInput.nativeElement as HTMLInputElement
      if (formPairingCodeInput) {
        formPairingCodeInput.focus();
        if(isTablet){
          formPairingCodeInput.inputMode = 'numeric'
        }
      }
    } catch(error: any) {
      if(!environment.production){
        console.log(error)
      }
    }
    this.action?.start()
    this.qrCodeSub()
    setTimeout(() => {
      this.actionIsStart = true
      this.changeDetector.detectChanges()
    }, 2000);
  }

  ngOnDestroy() {
    this.action?.stop();
    this.executionInformations$?.unsubscribe();
    this.appStateSub$?.unsubscribe();
    this.outputSub$?.unsubscribe();
    this.familiesSub$?.unsubscribe();
  }

  getLabel(optId: string,defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId,'', '', '', defaultValue)
  }
  
  closeModal() {
    this.modalValue.emit(false)
  }

  onError(error) {
    console.log(error);
  }

  toggleCamera() {
    this.action?.toggleCamera()
  }
  public insertCodeManually() {
    this.manuallyInsertTriggered = !this.manuallyInsertTriggered;
  }

  public manualSubmission() {
    this.manualCodeCheck = true
    const clientID = this.manualInputForm.value.clientID;
    this.triggerAction(clientID, 'manualCode')
  }

  private qrCodeSub() {
    this.outputSub$ = this.action?.data.asObservable().pipe(
      distinctUntilChanged()
    ).subscribe({
      next: (res: string) => {
        if (res) {
          this.triggerAction(res, 'qrCode')
        }
      }
    })
  }

  private triggerAction(ephemeralDeviceId: string, submissionType: string) {
    console.log(ephemeralDeviceId)
    this.ephemeralDeviceID = ephemeralDeviceId
    this.ephemeralDeviceIDEvent.emit(this.ephemeralDeviceID)

    this.autobahn.onJoinSession(this.ephemeralDeviceID, this.pairingStep === PAIRING_STEP_ENUM.CAR_CONFIGURATOR && this.streamingAvailable).then(
      response => {
        if (response['sessionToJoin']) {
          if (submissionType === 'qrCode') {
            this.qrCodeIsValid()
          }
          else if (submissionType === 'manualCode') {
            this.manualCodeIsValid()
          }
        } else {
          if (submissionType === 'qrCode') {
            this.qrCodeIsNotValid()
          }
          else if (submissionType === 'manualCode') {
            this.manualCodeIsNotValid()
          }
        }
      }
    )
  }

  private qrCodeIsValid() {
    this.qrCodeIsOk = true
    this.action?.stop()
    this.changeDetector.detectChanges()
    this.triggerModalClosingActions()
  }
  
  private manualCodeIsValid() {
    this.manualCodeCheck = this.syncUnsuccessfulManualCode = false
    this.manualCodeIsOk = true
    this.changeDetector.detectChanges()
    this.triggerModalClosingActions()
  }

  private async triggerModalClosingActions() {
    try{
      this.autobahn.switchCountry(this.ephemeralDeviceID, this.selectedCountry)
    } catch(err: any) {
      console.error('failed to publish switch cou')
    }
    // this.autobahn.setUrl(this.ephemeralDeviceID, this.appExecutionInfos)
    switch(this.pairingStep){
      case PAIRING_STEP_ENUM.CAR_CONFIGURATOR:
        await this.autobahn.setPresenterScreenSidebarData(this.ephemeralDeviceID, this.carModel, this.priceStatus, this.menuItems, this.modelOptions, this.packages, this.labels, this.closedAccordions, this.accordionExp)
        await this.autobahn.setScrollPosition(this.ephemeralDeviceID, this.scrollPosition)
        await this.autobahn.setDealerId(this.ephemeralDeviceID, this.dealerId)
        await this.autobahn.setToggleFullScreen(this.ephemeralDeviceID, this.viewUi)
        break;
      case PAIRING_STEP_ENUM.TRIM_SELECTOR:
        this.autobahn.setLabels(this.ephemeralDeviceID,this.labels)
        this.autobahn.setCarTrimFamilyCode(this.ephemeralDeviceID, this.selectedFamily)
        this.autobahn.setPriceStatus(this.ephemeralDeviceID, this.priceStatus)
        this.autobahn.setCurrentTrimCode(this.ephemeralDeviceID, this.currentTrimCode)
        this.autobahn.setModelSelectorActiveIndex(this.ephemeralDeviceID, this.activeSlideIndex? this.activeSlideIndex : 0)
        break;
      case PAIRING_STEP_ENUM.MODEL_SELECTOR:
        this.autobahn.setLabels(this.ephemeralDeviceID,this.labels)
        this.autobahn.setFamilies(this.ephemeralDeviceID, this.families)
        this.autobahn.requestPairingFromSimplePage(this.ephemeralDeviceID, this.pairingStep)
        this.autobahn.setPriceStatus(this.ephemeralDeviceID, this.priceStatus)
        this.autobahn.setModelSelectorActiveIndex(this.ephemeralDeviceID, this.activeSlideIndex? this.activeSlideIndex : 0)
        break;
      case PAIRING_STEP_ENUM.CONFIGURATION_LOADER:
        const setup = [
        this.autobahn.setFamilies(this.ephemeralDeviceID, this.families),
        this.autobahn.initializeLoadConfiguration(this.ephemeralDeviceID, this.isLoadConfig, this.isRestoreConfig, ''),
        this.autobahn.setTemporaryItemsFromStore(this.ephemeralDeviceID,this.temporaryConfigItems),
        ]
        Promise.all([setup]).then(() => this.autobahn.requestPairingFromSimplePage(this.ephemeralDeviceID, this.pairingStep))
        break;
      default:
        console.debug(`Pairing from ${this.pairingStep} Component`)
        this.autobahn.setLabels(this.ephemeralDeviceID,this.labels)
        await this.autobahn.requestPairingFromSimplePage(this.ephemeralDeviceID, this.pairingStep)
        break;
    }
    this.action?.stop()
    this.modalValue.emit()
  }

  private qrCodeIsNotValid() {
    this.syncUnsuccessfulQRCode = true
    this.changeDetector.detectChanges()
  }

  private manualCodeIsNotValid() {
    this.manualCodeCheck = false
    this.syncUnsuccessfulManualCode = true
    this.changeDetector.detectChanges()
  }
}
