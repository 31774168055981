import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { SessionConnection, SessionData } from '@monkeyway/streaming-lib/dist/types/session-data';
import { Store } from '@ngrx/store';
import autobahn, { Connection, ICallOptions, IConnectionOptions, IPublishOptions, Session } from 'autobahn';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { environment } from 'src/environments/environment';
import { IDealer } from '../models/authorization/dealer.model';
import { Pack, UIOptItem } from '../models/car-grid.model';
import { CarModel, Family } from '../models/get-models-service.model';
import { GetSceneState, Payload } from '../models/get-scene-state';
import { Layer, Menu } from '../models/interface-service.model';
import { AvailableModelsResp } from '../models/unreal-available-models.model';
import { IUserState } from '../store/initials/user-initial-state';
import { MxeReducers } from '../store/reducers';
import { APP_SETTINGS_SET_ENV_NAME, APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID } from '../store/actions/app-settings/app-settings-actions';
import { ConfigItem } from '../store/initials/temporary-configs-initial-state';
import AvailableTrims from '../../../assets/3d-trims-available-in-unreal.json';
import * as uuid from 'uuid';
import { Topics } from '../enums/topics';
import { logSubscription, logPublication } from '../services/pairing-logging.service'
import { compressObject, decompressObject } from '../message-compression/pako.client';
import { PAIRING_STEP_ENUM } from '../models/common.model';
import { MonkeyWaySessionState } from '../store/initials/monkeyway-sessions-initial-state';
import { MonkeyWaySessionActions } from '../store/actions/monkeyway-sessions/monkeyway-sessions-exported-actions';
import { HandledError } from '../models/handled-error';
import * as Sentry from '@sentry/angular-ivy';
import { SentryMessaging } from '../services/sentry-messaging.service';
import { MonkeyWayService } from '../services/monkey-way.service';

type CallbackFunction = (args: any, kwargs: any, details: any) => void
@Injectable()
export class AutobahnClient {

  public messagesEmitter: EventEmitter<string>
  public sessionJoined: EventEmitter<boolean> = new EventEmitter()
  public sceneStateChangeEvt: EventEmitter<{}> = new EventEmitter()
  public onEndCinematicEvt: EventEmitter<{}> = new EventEmitter()
  public cinematicStartedStatusEvt: EventEmitter<{}> = new EventEmitter()
  public getSceneStateChangeEvt: EventEmitter<GetSceneState> = new EventEmitter()
  public getAvailableModelsEvt: EventEmitter<AvailableModelsResp> = new EventEmitter()
  public xRayLoaded: EventEmitter<string> = new EventEmitter()


  public onAutobahnRemoteOpen: EventEmitter<boolean> = new EventEmitter<boolean>()
  public onAutobahnLocalOpen: EventEmitter<boolean> = new EventEmitter<boolean>()


  private url = environment.autobahn_configuration.broker_endpoint;
  private realm = environment.autobahn_configuration.realm;
  private max_retries: number = environment.autobahn_configuration.max_retries;
  private sessionId: string | null;
  private jwt_token_dummy = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJtYXNlcmF0aV9zc29fc2VydmVyIiwic3ViIjoibWFzZXJhdGlfbXhlX2Zyb250ZW5kIn0.VTIezimnPK3fMJd5uRktk_vfSRYCixQHW-beDtO-B8s'
  private userState: IUserState;
  private userState$: Subscription
  private selectedModel$: Subscription;
  private sessionsState$: Subscription;
  private sessionsState: MonkeyWaySessionState[] = []
  private selectedModel: string | null = null;
  private secondaryConnectionKey: string;
  private appEnvId: string;
  private baseUrl: string;
  private isStreamAllocated = false;
  private machineId: string | null;
  private appState$: Subscription;
  private appExecutionInfos: { protocol: string, host: string,  hostName: string } = { protocol: 'https:', host: '', hostName: '' }

  private remoteConnection: Connection;
  private localConnection: Connection;

  private autobahnRemoteSession: Session;
  private autobahnLocalSession: Session;

  private autobahnRemoteSessionOpened: Subject<boolean> = new Subject<boolean>()
  public autobahnRemoteSessionOpened$: Observable<boolean> = this.autobahnRemoteSessionOpened.asObservable();

  private autobahnLocalSessionOpened: Subject<boolean> = new Subject<boolean>()
  public autobahnLocalSessionOpened$: Observable<boolean> = this.autobahnLocalSessionOpened.asObservable();

  private autobahnRemoteQueue: { methodName: string, method: Function, args?: any[] }[] = []
  private autobahnLocalQueue: { methodName: string, method: Function, args?: any[] }[] = []



  private connectionOptions: IConnectionOptions = {
    url: '',
    realm: this.realm,
    max_retries: this.max_retries,
    authmethods: ["anonymous"],
    authextra: {
      "Authorization": this.jwt_token_dummy,
      "authrole": "frontend",
    },
    on_user_error: (error, customErrorMessage) => {
      this.errorDialogService.open({ data: { message: error }, })
    },
    on_internal_error: (error, customErrorMessage) => {
      this.errorDialogService.open({ data: { message: error }, })
    }
  }


  constructor(
    private store: Store<MxeReducers>,
    private errorDialogService: ErrorDialogService,
    private zone: NgZone,
    private sentryMessaging: SentryMessaging,
    private monkeyWayService: MonkeyWayService
  ) {

    this.sessionsState$ = this.store.select(s => s.monkeyWay_sessions_state.sessions).subscribe(
      (sessions: MonkeyWaySessionState[]) => {
        this.sessionsState = sessions
      }
    )
    this.autobahnRemoteSessionOpened$.subscribe((established: boolean) => {
      if (established)
        this.autobahnRemoteQueue = this.processQueue(this.autobahnRemoteQueue);
        this.onAutobahnRemoteOpen.emit(true);
    })

    this.autobahnLocalSessionOpened$.subscribe((established: boolean) => {
      if (this.autobahnLocalQueue.length > 0) {
        this.autobahnLocalQueue = this.processQueue(this.autobahnLocalQueue)
      }
    })

    if (!this.autobahnRemoteSession) {
      let remoteConnectionOptions = { ...this.connectionOptions, url: this.url }
      this.connectionRemoteInit(remoteConnectionOptions)
    }

    this.userState$ = this.store.select(s => s.userState).subscribe(
      userState => {
        this.userState = userState
      });

    this.selectedModel$ = this.store.select(s => s.modelsState.currentCarModel?.modelCode).subscribe(
      selectedModel => this.selectedModel = selectedModel ?? null
    )

    this.appState$ = combineLatest([this.store.select(s => s.appState.protocol), this.store.select(s => s.appState.host), this.store.select(s => s.appState.hostName)]).subscribe(
      ([protocol, host, hostName]: string[]) => {
        this.appExecutionInfos.protocol = protocol ?? 'https:'
        this.appExecutionInfos.host = host ?? ''
        this.appExecutionInfos.hostName = hostName ?? ''
        if (host !== 'localhost:8080' && hostName.includes('local')) {
          if (!this.autobahnLocalSession) {
            // let localConnectionOptions = {
            //   ...this.connectionOptions,
            //   url:`wss://${hostName}/ws`,
            //   authmethods: ['wampcra'],
            //   authExtra: {},
            //   onchallenge: this.onChallenge,
            //   authid: "fe_mas_client",
            // }

            //DA VERIFICARE
            let localConnectionOptions = {
              ...this.connectionOptions,
              url:`wss://${hostName}/ws`
            }
            this.connectionLocalInit(localConnectionOptions)
          }
        }
      }
    )
  }

  private onChallenge (session, method, extra) {
    if (method === 'wampcra') {
    return autobahn.auth_cra.sign("frontend_password", extra.challenge);
    } 
    return '';
}

  private async connectionInit(options: IConnectionOptions): Promise<{ session: Session, connection: Connection }> {
    return new Promise<{ session: Session, connection: Connection }>(async (resolve, reject) => {
      const connection = new Connection(options);
      connection.onopen = (session: Session, details: any) => {
        console.log(`[AUTOBAHN_CLIENT] Connected to WAMP router at ${options.url}. SessionId: ${session.id}`);
        resolve({ session: session, connection: connection });
      };
      connection.onclose = (reason: string, details: string) => {
        console.debug(`[AUTOBAHN IS CLOSING] at ${options.url}: ${reason}`);
        // if (/^wss:\/\//.test(options.url!)) {
        //   this.onAutobahnRemoteOpen.emit(false);
        // } else {
        //   this.onAutobahnLocalOpen.emit(false);
        // }
        this.isStreamAllocated = false;
        return false
      };
      connection.open();
    });
  }

  private async connectionLocalInit(options: IConnectionOptions){
    
    const connection = new Connection(options);
    connection.onopen = (session: Session, details: any) => {
        console.log(`[AUTOBAHN_CLIENT LOCAL] Connected to WAMP router at ${options.url}. SessionId: ${session.id}`);
        this.autobahnLocalSession = session
        //emitta alla tv
        this.onAutobahnLocalOpen.emit(true)
      };
    connection.onclose = (reason: string, details: string) => {
        console.debug(`[AUTOBAHN LOCAL IS CLOSING] at ${options.url}: ${reason}`);
        this.onAutobahnLocalOpen.emit(false);
        this.isStreamAllocated = false;
        return false
      };
    connection.open();
  }

  private async connectionRemoteInit(options: IConnectionOptions){
    const connection = new Connection(options);
    connection.onopen = (session: Session, details: any) => {
      console.log(`[AUTOBAHN_CLIENT REMOTE] Connected to WAMP router at ${options.url}. SessionId: ${session.id}`);
      this.autobahnRemoteSession = session
      //emitta alla tv
      this.autobahnRemoteSessionOpened.next(true);
    };
    connection.onclose = (reason: string, details: string) => {
      console.debug(`[AUTOBAHN REMOTE IS CLOSING] at ${options.url}: ${reason}`);
      this.autobahnRemoteSessionOpened.next(false);
      this.isStreamAllocated = false;
      return false
    };
    connection.open();
    

    
  }

  private addToQueue(queue: any[], methodName: string, method: Function, args?: any[]): any[] {
    queue.push({ methodName, method, args });
    console.log(`[AUTOBAHN_CLIENT] ${methodName} added to QUEUE`)
    return queue;
  }

  private processQueue(queue: any[]): any[] {
    queue.forEach(item => {
      console.log(`[AUTOBAHN_CLIENT]: Processing QUEUE: executing method ${item.methodName}`)
      const method = item.method
      const args = item.args
      if (args) method(...args);
      else method()
    })
    return []
  }

public async pairingSubscriptionsWrapper(
  topic: Topics, 
  method: (autobahnSession: Session, deviceId: string, callback: CallbackFunction) => Promise<void>, 
  deviceId: string,
  callback: CallbackFunction
  ) {
  const autobahnSessionUsed = topic == Topics.Remote ? this.autobahnRemoteSession: this.autobahnLocalSession
  await method.bind(this)(autobahnSessionUsed, deviceId, callback);
}

private async presenterScreenPublish(session, topic, payload) {
  const payloadSize = JSON.stringify(payload)?.length
  if(payloadSize && payloadSize > environment.autobahn_configuration.pairing_not_compressed_message_max_size) {
    const compressedPayload = compressObject(payload)
    logPublication(topic, payload)
    await session?.publish(topic, [], {data: compressedPayload})
  } else {
    logPublication(topic,payload)
    await session?.publish(topic, [], payload)
  }

}

private async presenterScreenSubscribe(session, topic, callback) {
   session?.subscribe(topic, (args, kwargs, details) => {
    if(kwargs.data) {
      const decompressedDatas = decompressObject(kwargs.data)
      logSubscription(topic, decompressedDatas);
      callback(args, decompressedDatas, details)
    } else {
      logSubscription(topic, kwargs)
      callback(args, kwargs, details)
    }

  })
}

  public async getDealerInfo(idToken: string, payloadObj: any): Promise<any> {
    if (!this.autobahnRemoteSession) {
      return new Promise((resolve, reject) => {
        this.autobahnRemoteQueue = this.addToQueue(this.autobahnRemoteQueue, 'getDealerInfo', () => {
          this.getDealerInfo(idToken, payloadObj).then((dealer) => resolve(dealer)).catch((error) => reject(error))
        }, [idToken, payloadObj])
      })
    } else {
      try {
        const dealer = await this.autobahnRemoteSession.call('com.management.dealer_info', [idToken, payloadObj]);
        return dealer;
      } catch (error) {
        if(!environment.production){
          console.error(error);
        }
        return null;
      }
    }
  }

  public async getInstanceStatus(machineId: string): Promise<boolean> {
    if(!this.autobahnRemoteSession){
      return new Promise((resolve,reject) =>{
        this.autobahnRemoteQueue = this.addToQueue(this.autobahnRemoteQueue,'getInstanceStatus',() => {this.getInstanceStatus(machineId).then((result : boolean) =>
        {resolve(result)}).catch(error => {reject(error)})})

      })
    }
    try{
      const encodedMachineId = encodeURIComponent(machineId)
      const instanceStatus:any =  await this.autobahnRemoteSession.call(`com.maserati.instance_status.${encodedMachineId}`)
      return JSON.parse(instanceStatus).isInstanceAvailable
    } catch(error) {
      if(!environment.production){
        console.error(error);
      }
      return false;
    }
  }

  public async checkAvailableTrim(modelCode: string): Promise<boolean> {
    let isTrimAvailable = (AvailableTrims['3d_available_trims'] as string[])?.some(t => t == modelCode)
    return isTrimAvailable;
  }

  public async getAvailableMachines(dealerId: string): Promise<any> {
    try {
      const positionalArguments: any[] = [
        this.userState.jwt_token,
        {
          code: this.userState.access_token,
          code_type: 'access_token',
          redirect_uri: environment.authentication.redirect_uri,
          client_id: environment.authentication.client_id,
        },
        dealerId,
      ];
  
      const availableMachines: any = await this.autobahnRemoteSession.call(
        'com.management.kunno.client.system',
        positionalArguments
      );
  
      return availableMachines;
    } catch (error: any) {
      //console.error(`Error fetching available machines: `, error.message || error);
      throw new Error('Failed to retrieve available machines for the current dealer');
    }
  }

  public async deallocateSessionsAndDispatchDeletes(): Promise<void> {
    await Promise.all(this.sessionsState.map(async (session) => {
      await this.deallocatePendingStreamSession(session);
      this.store.dispatch(MonkeyWaySessionActions.MW_SESSION_DELETE({ session: session }));
    }));
  }

  public async deallocatePendingStreamSession(session: MonkeyWaySessionState) {
    if (session.sessionId && session.machineId && session.dealerId) {
      try {
        let streamingData = {
          sessionId: session.sessionId,
          machineId: session.machineId,
          dealerId: session.dealerId
        }
        let options: IPublishOptions = {
          acknowledge: true
        }
        console.log('deallocating Stream Payload: ', streamingData)
        const topic = 'com.maserati.session_deallocated'
        const payload = { "msg": streamingData }
        logPublication(topic, payload)
        var result = await this.autobahnRemoteSession?.publish(topic, [], payload, options)
        console.log(`[AUTOBAHN CLIENT]: streaming session deallocated`, result)
      } catch (error: any) {
        if(!environment.production){
          console.error(error);
        }
      }
    }
    else {
      console.debug(`[AUTOBAHN_CLIENT] can not deallocate streaming, machineID: ${session.machineId}, sessionId: ${session.sessionId}, dealerId: ${session.dealerId}`);
    }
  }

  public async getStreaming(): Promise<any> {
    if (!this.autobahnRemoteSession) {
      return new Promise((resolve, reject) => {
        this.autobahnRemoteQueue = this.addToQueue(this.autobahnRemoteQueue, 'getStreaming', () => {
          this.getStreaming().then(resolve).catch(reject)
        });
      });
    }
    let unrealDebugInterface = localStorage.getItem('unreal_debug_interface') ? JSON.parse(localStorage.getItem('unreal_debug_interface')!) as boolean : false;
    let mainConnection: SessionConnection | null = null;
    let sessionData: SessionData | undefined;
    //STEP 1: ALLOCATE_STREAM_REQUEST (SKIP FOR UNREAL ARTIST FLOW)
    if (!unrealDebugInterface) {
      //In the future we can think about a retry mechanism on the allocate stream
      let session = await this.allocateStream();
      if(!session) throw new Error('[AUTOBAHN_CLIENT]: was not possible to get a streaming allocation') //NEEDS TO BE CATCHED OTHERWISE WE WILL HAVE DOUBLE LOGS ON SENTRY

      this.baseUrl = session.api_base_url;
      this.appEnvId = session.app_env_id;
      sessionData = session.session;
      console.debug(`[AUTOBAHN_CLIENT] Streaming source: ${session.app_env_name}`)
      console.warn('[AUTOBAHN_CLIENT] allocate stream call res: ', sessionData);
      this.sessionId = sessionData!.id;
      this.secondaryConnectionKey = sessionData!.secondaryConnections![0].connectionKey
      this.store.dispatch(APP_SETTINGS_SET_ENV_NAME({ envName: session.app_env_name }))
      mainConnection = sessionData!.mainConnection;
    } else {
      this.store.dispatch(APP_SETTINGS_SET_ENV_NAME({ envName: 'onPremise' }))
    }
    //STEP 2: STREAM_ALLOCATED REQUEST
    try {
      await this.onStreamAllocated(sessionData)
    } catch (error: any) {
      this.sentryMessaging.logEvent(error, 'error', { dealerId: this.userState.dealerId, sessionId: this.sessionId })
      throw new Error('[AUTOBAHN_CLIENT]: was not possible to establish webrtc connection with the allocated instance') //NEEDS TO BE CATCHED OTHERWISE WE WILL HAVE DOUBLE LOGS ON SENTRY
    }
    await this.onGetSceneState()
    let availableModels = await this.onGetAvailableModels()
    this.onSceneStateChanged();
    let res = {
      "baseUrl": this.baseUrl,
      "appEnvId": this.appEnvId,
      "connection": mainConnection,
      "availableModels": availableModels
    };
    return res;
  }

  public async allocateStream(): Promise<any> {
    //STOPPING OLD MONKEYWAY SESSION IF IS STILL ACTIVE
    if (this.monkeyWayService) {
      try {
        this.monkeyWayService.stopMonkeyWaySession();
      } catch (error: any) {
        this.sentryMessaging.logEvent('Something went wrong during stop of monkeyway previous session', 'warning', {})
      }
    }

    let allocationType: string = ''
    let transaction: any | null;
    if (!this.autobahnLocalSession) {
      allocationType = 'cloud'
    } else {
      allocationType = 'onpremise'
    }
    console.warn(`[AUTOBAHN_CLIENT]: running allocateStream on session id ${this.autobahnRemoteSession.id}. Allocating ${allocationType} instance`)
    let headers = [
      this.userState.jwt_token,
      {
        code: this.userState.access_token,
        code_type: 'access_token',
        redirect_uri: environment.authentication.redirect_uri,
        client_id: environment.authentication.client_id
      }
    ]
    //ALLOW TO PROPAGATE SENTRY TRACE TO THE BE
    let sentrySessionId = this.sentryMessaging.getSentrySessionId()
    const payload = {
      dealerId: this.userState.dealerId,
      'sentry-trace': sentrySessionId
    }
    //START RECORDING TRANSACTION ON SENTRY
    const transactionStartTime = Date.now();
    transaction = Sentry.startTransaction({
      op: 'wamp_call',
      name: `com.management.allocate_stream.${allocationType}`
    })

    let allocateStreamSession: any | null = null
    try {
      switch (allocationType) {
        //CLOUD ALLOCATION
        case 'cloud': {
          allocateStreamSession = await this.autobahnRemoteSession.call('com.management.allocate_stream.cloud', headers, payload);
          break;
        }
        //ONPREMISE ALLOCATION
        case 'onpremise': {
          const machineId = localStorage.getItem('machine_hostname');
          allocateStreamSession = await this.autobahnRemoteSession.call('com.management.allocate_stream.onpremise', headers, { ...payload, machineId: machineId })
          break;
        }
      }
      return allocateStreamSession;
    } catch (error: any) {
      //LOG ALLOCATE STREAM ERROR IN CONSOLE AN TO SENTRY
      console.error(error)
      transaction.setData('error', error)
      transaction.setStatus("internal_error")

      let sentryError: string = `undefined error on com.management.allocate_stream.${allocationType}`
      if (typeof error == 'object') {
        sentryError = JSON.stringify(error)
      } else if (error && error.message) {
        sentryError = error.message
      }
      this.sentryMessaging.logEvent(sentryError, 'error', { dealerId: this.userState.dealerId, allocationType: allocationType })
      //NO STREAMING ALLOCATION OCCURRED
      return null;
    } finally {
      const transactionEndTime = Date.now()
      transaction.setData('sentry-trace', sentrySessionId)
      transaction.setData('environment', allocationType)
      transaction.setData('duration', `${transactionEndTime - transactionStartTime}ms`)
      transaction.finish()
    }
  } 


  //DEPRECATED: USE getStreaming instead;
  public async allocateStreamConnection(): Promise<any> {
    if (!this.autobahnRemoteSession) {
      return new Promise((resolve, reject) => {
        this.autobahnRemoteQueue = this.addToQueue(this.autobahnRemoteQueue, 'allocateStreamConnection', () => {
          this.allocateStreamConnection().then(resolve).catch(reject)
        });
      });
    }
    console.log('[AUTOBAHN_CLIENT] Deallocating all pending streaming session...');
    this.deallocateSessionsAndDispatchDeletes();
  
    console.warn(`[AUTOBAHN_CLIENT] running allocateStreamConnection on session id ${this.autobahnRemoteSession.id}`, this.autobahnRemoteSession);
  
    let positionalArguments: any[] = [];

      positionalArguments.push(this.userState.jwt_token)

      const authorization = {
        code: this.userState.access_token,
        code_type: 'access_token',
        redirect_uri: environment.authentication.redirect_uri,
        client_id: environment.authentication.client_id
      }
      positionalArguments.push(authorization)

      const devMachineId = localStorage.getItem('dev_machine_id');
      const localMachineHostName = localStorage.getItem('machine_hostname');

      try {
        let allocateStreamSession: any = null

        let unrealDebugInterface = localStorage.getItem('unreal_debug_interface') ? JSON.parse(localStorage.getItem('unreal_debug_interface')!) as boolean : false;
        let mainConnection: SessionConnection | null = null;
        if (!unrealDebugInterface) {
          let sentrySessionId = this.sentryMessaging.getSentrySessionId()
          const payload = {
            dealerId: this.userState.dealerId,
            'sentry-trace': sentrySessionId
          }

          const startTime = Date.now() 
          if (devMachineId || localMachineHostName) {
            const machineId = localMachineHostName ?? devMachineId
            allocateStreamSession = await this.autobahnRemoteSession.call('com.management.allocate_stream', positionalArguments, { ...payload, machineId: machineId });
          } else {
            allocateStreamSession = await this.autobahnRemoteSession.call('com.management.allocate_stream', positionalArguments, payload);
          }
          const endTime = Date.now()
          this.sentryMessaging.logEvent('com.management.allocate_stream', 'info', {environment: devMachineId || localMachineHostName? 'onPremise': 'cloud', sessionData: allocateStreamSession.session, duration: `${endTime - startTime}ms`})


          this.baseUrl = allocateStreamSession.api_base_url;
          this.appEnvId = allocateStreamSession.app_env_id;
          this.store.dispatch(APP_SETTINGS_SET_ENV_NAME({ envName: allocateStreamSession.app_env_name }))
          const sessionData: SessionData = allocateStreamSession.session;
          console.debug(`[AUTOBAHN_CLIENT] Streaming source: ${allocateStreamSession.app_env_name}`)
          console.warn('[AUTOBAHN_CLIENT] allocate stream call res: ', sessionData);
          this.sessionId = sessionData.id;
          this.machineId = sessionData ? sessionData.renderer!.specs!['MachineId'] : localStorage.getItem('dev_machine_id');
          const monkeyWaySessionState: MonkeyWaySessionState = {
            sessionId: sessionData.id,
            machineId: this.machineId!,
            dealerId: this.userState.dealerId,
            ipAddress: sessionData.renderer?.ip
          }
          this.store.dispatch(MonkeyWaySessionActions.MW_SESSION_ADD({session: monkeyWaySessionState }))

          this.secondaryConnectionKey = sessionData.secondaryConnections![0].connectionKey

          mainConnection = sessionData.mainConnection;
          await this.onStreamAllocated(sessionData)

        } else {
          this.store.dispatch(APP_SETTINGS_SET_ENV_NAME({ envName: 'onPremise' }))
          await this.onStreamAllocated(undefined)
        }

        await this.onGetSceneState()
        let availableModels = await this.onGetAvailableModels()

        this.onSceneStateChanged();
    
    let res = {
      "baseUrl": this.baseUrl,
      "appEnvId": this.appEnvId,
      "connection": mainConnection,
      "availableModels": availableModels
    };
    
    return res;
  } catch (e: any) {
    this.machineId = null;
    this.sessionId = null;
    if (typeof e == 'object') {
      throw Error(JSON.stringify(e))
    }
    throw Error(e)
  }};
  

  public resetMachineIdAndSessionId(){
    this.machineId = null;
    this.sessionId = null;
  }

  private async onStreamAllocated(session: SessionData | undefined) {
    if (!session) {
      this.sessionId = uuid.v4();
    }
    let machineId = session ? session.renderer!.specs!['MachineId'] : localStorage.getItem('dev_machine_id');
    const actualEnvironment = this.autobahnLocalSession ? 'onPremise' : 'cloud'
    const startTime = Date.now();
    let transaction: any | null
    transaction = Sentry.startTransaction({
      op: 'wamp_call',
      name: `com.maserati.stream_allocated_${actualEnvironment}`
    })
    try {
      const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
      const startTime = Date.now()
      const streamAllocated = await autobahnSession.call('com.maserati.stream_allocated.' + machineId, [JSON.stringify({
        sessionID: this.sessionId,
        rendererID: machineId
      })])
      const endTime = Date.now()
      this.isStreamAllocated = true;
      console.debug('[AUTOBAHN_CLIENT] streamAllocated:', streamAllocated);
    } catch (e: any) {
      this.isStreamAllocated = false;
      console.error(e)
      transaction.setData('error', e)
      transaction.setStatus("internal_error")
      throw Error(e)
    } finally {
      if (transaction) {
        const endTime = Date.now()
        transaction.setData('sessionId', this.sessionId);
        transaction.setData('environment', this.autobahnLocalSession ? 'onPremise' : 'cloud');
        transaction.setData('duration', `${endTime - startTime}ms`)
        transaction.finish();
      }
    }
  }

  public async localStreamingHeartbeat() {
    try {
      const topic = `com.maserati.fe_alive.${this.sessionId}`
      await this.autobahnLocalSession?.call(topic)
    } catch (error: any) {
      throw Error(error)
    }
  }

  private inputEventCounter = 0;
  public async onMouseEvent(payload: {}) {
    try {
      let transaction: any = null;
      const actualEnvironment = this.autobahnLocalSession ? 'onPremise': 'cloud'
      if (this.inputEventCounter % 50 === 0) {
        transaction = Sentry.startTransaction({
          op: 'wamp_call',
          name: `com.maserati.input_event_${actualEnvironment}`
        })
      }
      const startTime = Date.now()
      const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
      autobahnSession.call('com.maserati.input_event.' + this.sessionId, [JSON.stringify(payload)]).tap(() => {
        this.inputEventCounter++;
      }).finally(() => {
        if (transaction) {
          const endTime = Date.now()
          transaction.setData('sessionId', this.sessionId);
          transaction.setData('environment', this.autobahnLocalSession ? 'onPremise' : 'cloud');
          transaction.setData('duration', `${endTime - startTime}ms`)
          transaction.finish();
        }
      })
    } catch (e: any) {
      if (!environment.production) {
        console.error(e);
      }
      throw Error(e)
    }
  }

  private setSceneCounter = 0;
  public async onSetSceneState(payload: {}) {
    const startTime =  Date.now();
    const actualEnvironment = this.autobahnLocalSession ? 'onPremise': 'cloud'
    if (payload && payload['desired_unreal_state'] && (payload['desired_unreal_state'].environment == '' || payload['desired_unreal_state'].environment == null)) {
      console.error('[AUTOBAHN_CLIENT - onSetScentState] Sending empty or null environment.')
    }
    let transaction: any | null = null;
    if (this.setSceneCounter % 3 === 0) {
      console.log('starting transaction')
      transaction = Sentry.startTransaction({
        op: 'wamp_call',
        name: `com.maserati.set_scene_state_${actualEnvironment}`
      })
    }
    if (this.isStreamAllocated) {
      if (!this.sessionId) { console.warn(`[AUTOBAHN_CLIENT] set sceneState without session id`); return }
      try {
        const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
        let sceneState: any;
        sceneState = await autobahnSession.call('com.maserati.set_scene_state.' + this.sessionId, [JSON.stringify(payload)]).tap(() => {
          this.setSceneCounter++;
        })
        .finally(() => {
          if (transaction) {
            const endTime = Date.now()
            transaction.setData('sessionId', this.sessionId);
            transaction.setData('environment', this.autobahnLocalSession ? 'onPremise' : 'cloud');
            transaction.setData('duration', `${endTime - startTime}ms`)
            transaction.finish();
          }
        })
        console.log(sceneState)
      } catch (err: any) {
        if(!environment.production){
          console.error(err);
        }
        throw err
      }
    }
  }


  private async onSceneStateChanged() {
    const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
    await autobahnSession.subscribe(`com.maserati.scene_state_changed.${this.sessionId}`, (res) => {
      console.debug('[AUTOBAHN CLIENT] autobahn desired scene State CHANGED', JSON.parse(<unknown>res as string))
      this.sceneStateChangeEvt.emit(res)
    })
  }

  private getAvailableModelsCounter = 0
  private async onGetAvailableModels() {
    const startTime= Date.now();
    if (!this.sessionId) { console.warn(`[AUTOBAHN_CLIENT] set scenestate without session id`); return }
    let transaction: any = null;
    const actualEnvironment = this.autobahnLocalSession ? 'onPremise': 'cloud'
      transaction = Sentry.startTransaction({
        op: 'wamp_call',
        name: `com.maserati.get_available_models_${actualEnvironment}`
      });
    try {
      const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
      const models = await autobahnSession.call('com.maserati.get_available_models.' + this.sessionId)
      .finally(() => {
        if (transaction) {
          const endTime = Date.now()
          transaction.setData('sessionId', this.sessionId);
          transaction.setData('environment', this.autobahnLocalSession ? 'onPremise' : 'cloud');
          transaction.setData('duration', `${endTime - startTime}ms`)
          transaction.finish();
        }
      })
      const availableModels = JSON.parse(models as string)
      if (this.selectedModel != '') {
        availableModels.availableModels.map((element) => {
          return { ...element, subElements: element.modelNumbers.filter((subElement) => subElement == this.selectedModel) }
        })
        console.warn('[AUTOBAHN_CLIENT] autobahn availableModels', availableModels);
        this.getAvailableModelsEvt.emit(availableModels)
      }
      return availableModels;
    } catch (err: any) {
      if(!environment.production){
        console.error(err);
      }
      throw err
    }
  }

  private async onGetSceneState() {
    if (!this.sessionId) { console.warn(`[AUTOBAHN_CLIENT] set scenestate without session id`); return }
    try {
      const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
      const getSceneState = await autobahnSession.call('com.maserati.get_scene_state.' + this.sessionId)
      const sceneState: GetSceneState = JSON.parse(getSceneState as string)
      console.warn('[AUTOBAHN_CLIENT] autobahn desired scene State', sceneState)
      this.getSceneStateChangeEvt.emit(sceneState)
    } catch (err: any) {
      if(!environment.production){
        console.error(err);
      }
      throw err
    }
  }

  // triggered from presenter screen
  async getSimpleEphemeralDeviceId(): Promise<any> {
    try {
      const ephemeralDeviceID: any = await this.autobahnRemoteSession.call('com.management.get_ephemeral_device_id') as string
      return {
        deviceID: ephemeralDeviceID,
      }

    } catch (err: any) {
      if(!environment.production){
        console.error(err);
      }
      throw err
    }
  }

  async registerMonkeyWayTopics(ephemeralDeviceID: string, joinSessionFn, leaveSessionFn, leaveMwSessionFn): Promise<any> {
    try {
      await this.autobahnRemoteSession.register("com.presenter.leave_session." + ephemeralDeviceID, leaveSessionFn)
      await this.autobahnRemoteSession.register("com.presenter.join_session." + ephemeralDeviceID, joinSessionFn)
      await this.autobahnRemoteSession.register("com.presenter.stop_mw_session." + ephemeralDeviceID, leaveMwSessionFn)
    } catch (err: any) {
      if(!environment.production){
        console.error(err);
      }
      throw err
    }
  }

  async registerSideBarScrollEvent(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.scrollEvent.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  async registerSummaryToggleAccordion(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.toggle_accordion.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  async registerSummarySet(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.setSummary.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  async registerSummaryScrollEvent(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.summaryScrollEvent.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }



  public async setPresenterScreenSidebarData(ephemeralDeviceID: string, carModel: CarModel, priceStatus: boolean, menuItems: Menu[], sidebarOpt: UIOptItem[], sidebarPacks: Pack[], labels: any, closedAccordions: string[], accordionExp) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_config_data.${ephemeralDeviceID}`
    const payload = {
      carModel: carModel,
      priceStatus: priceStatus,
      menuItems: menuItems,
      sidebarOpt: sidebarOpt,
      sidebarPacks: sidebarPacks,
      labels: labels,
      closedAccordions: closedAccordions,
      accordionExp: accordionExp
    }
    const sidebarConfigData = this.presenterScreenPublish(autobahnSession,topic, payload)
    try {
      return sidebarConfigData;
    } catch (err: any) {
      if(!environment.production){
        console.error(`ERROR ON SET PRESENTER SCREEN SIDEBAR DATA ${err}`)
      }
      else{
        console.error(`ERROR ON SET PRESENTER SCREEN SIDEBAR DATA`)
      }
      throw err
    }
  }

  public async getPresenterScreenData(autobahnSession: Session, ephemeralDeviceID, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_config_data.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getSidebarOptChange(autobahnSession: Session, ephemeralDeviceID, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_opt_change.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getSidebarPacksChange(autobahnSession: Session, ephemeralDeviceID, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_packs_change.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getToggleAccordion(autobahnSession: Session, ephemeralDeviceID, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_accordion_toggle.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getSearchValue(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_search.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getSearchToggleValue(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_search_toggle.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getPackageDetailsToggleValue(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.package_details_toggle.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }


  public async getToggleFullScreen(autobahnSession: Session, ephemeralDeviceID, callbackFn): Promise<void> {
    const topic = `com.presenter.sidebar_full_screen_toggle.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  // triggered from presenter screen
  public async getHeartbeat(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.heartbeat.${ephemeralDeviceID}`
    await autobahnSession?.register(topic, (...args) => {
      logSubscription(topic, args[1])
      callbackFn(...args)
    })
  }

  public async getCarTotalPrice(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.car_total_price.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getPriceStatus(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.price_status.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setHeartbeat(ephemeralDeviceID: string, heartbeatResp: number) {
    try {
      const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
      const topic = `com.presenter.heartbeat.${ephemeralDeviceID}`
      const payload = { heartbeat: heartbeatResp }
      logPublication(topic, payload)
      await autobahnSession?.call(topic, [], payload);
    } catch (err: any) {
      //this.sessionJoined.emit(false)
      throw err;
    }
  }

  //triggered from tablet
  public async onLeaveScreenCast(ephemeralDeviceID) {
    try {
      const topic = `com.presenter.leave_session.${ephemeralDeviceID}`
      const payload = { msg: 'no payload needed'}
      logPublication(topic, payload)
      await this.autobahnRemoteSession?.call(topic)
      this.sessionJoined.emit(false)
    } catch (error:any) {
      if(!environment.production){
        console.error(error)
      }
      throw new Error(error)
    }

  }

  public async stopMonkeyWaySession(ephemeralDeviceID: string) {
    const maxRetries = environment.autobahn_configuration.max_retries;
    const retryAfter = environment.autobahn_configuration.pairing_streaming_session_retry_after;
    let retryCount = 0;

    const stopSessionWithRetry = async () => {
      try {
        const topic = `com.presenter.stop_mw_session.${ephemeralDeviceID}`
        const payload = { msg: 'no payload needed'}
        await this.autobahnRemoteSession?.call(topic);
      } catch (error: any) {
        console.warn(`Error stopMonkeyWaySession on ${new Date().toLocaleString()} retry ${retryCount + 1}: ${error.error}`);
        retryCount++;
        if (retryCount < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, retryAfter));
          return stopSessionWithRetry();
        } else {
          console.error('Max retry count exceeded. Unable to stop MonkeyWay session.');
          throw new HandledError('Unable to stop MonkeyWay session');
        }
      }
    };

    await stopSessionWithRetry();
  }


  //triggered from tablet
  public async onJoinSession(ephemeralDeviceID: string, requiresVideoStreaming: boolean = true): Promise<{}> {
    const maxRetries = environment.autobahn_configuration.max_retries;
    const retryAfter = environment.autobahn_configuration.pairing_streaming_session_retry_after;
    let retryCount = 0;

    const joinSessionWithRetry = async () => {
      try {
        if (requiresVideoStreaming) {
          const topic = `com.presenter.join_session.${ephemeralDeviceID}`
          const payload = {
            connectionKey: this.secondaryConnectionKey,
            baseUrl: this.baseUrl,
            appEnvId: this.appEnvId,
          }
          logPublication(topic, payload)
          const joinSession: any = await this.autobahnRemoteSession?.call(topic, [], payload);

          const sessionToJoin = joinSession as string;
          this.zone.run(() => {
            this.store.dispatch(APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID({ ephemeralDeviceId: ephemeralDeviceID }));
          });
          this.sessionJoined.emit(true);
          return { sessionToJoin: sessionToJoin };
        } else {
          //if not video streaming is required then just ping the tv to see if responds
          const topic = `com.presenter.join_session.no_video.${ephemeralDeviceID}`
          const payload = { msg: 'no payload required' }
          logPublication(topic, payload)
          const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession
          const result = await autobahnSession.call(topic, [], payload)
          this.zone.run(() => {
            this.store.dispatch(APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID({ ephemeralDeviceId: ephemeralDeviceID }));
          });
          this.sessionJoined.emit(true);
          return { sessionToJoin: 'no_video_session' };
        }
      } catch (e: any) {
        console.warn(`Error onJoinSession on ${new Date().toLocaleString()} retry ${retryCount + 1}: ${e.error}`);
        retryCount++;
        if (retryCount < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, retryAfter));
          return joinSessionWithRetry();
        } else {
          if(!environment.production){
            console.error(`Max retry count exceeded. Unable to join session with ID ${ephemeralDeviceID}`);
          }
         throw new HandledError(`Unable to join session with ID ${ephemeralDeviceID}`);
        }
      }
    };
    return joinSessionWithRetry();
  }


  public toggleXRayMode(isActive: boolean) {
    const topic = 'com.maserati.request_xray.'
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    let payload = [JSON.stringify({'bXRay': !isActive})]
    try {
      autobahnSession.call(topic + this.sessionId, payload).then(res => {
        this.xRayLoaded.emit(res as string)
      })
    } catch (e) {
      if(!environment.production){
        console.error(e);
      }
    }
  }

  public activeCinematicMode(cinematicName : string, bScreensaver: boolean){
    const startTime = Date.now()
    let transaction: any = null;
    const actualEnvironment = this.autobahnLocalSession ? 'onPremise': 'cloud'
      transaction = Sentry.startTransaction({
        op: 'wamp_call',
        name: `com.maserati.request_cinematic_${actualEnvironment}`
      })
    const topic = 'com.maserati.request_cinematic.' + this.sessionId;
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    let obj = {"cinematicName": cinematicName, "bScreenSaver":bScreensaver}
    let payload = [JSON.stringify(obj)]
    try {
      logPublication(topic,payload)
      autobahnSession.call(topic,payload).then( res => {

        this.cinematicStartedStatusEvt.emit(res as string)
        if (transaction) {
          const endTime = Date.now()
          transaction.setData('sessionId', this.sessionId);
          transaction.setData('environment', this.autobahnLocalSession ? 'onPremise' : 'cloud');
          transaction.setData('duration', `${endTime - startTime}ms`)
          transaction.finish();
        }
      })
    } catch (e) {
      if(!environment.production){
        console.error(e);
      }
    }
  }

  public activeCinematicAudio(bAudio: boolean){
    const topic = 'com.maserati.request_audio.' + this.sessionId;
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    let obj = {"bAudio": bAudio}
    let payload = [JSON.stringify(obj)]
    try {
      logPublication(topic,payload)
      autobahnSession.call(topic,payload)
    } catch (e) {
      if(!environment.production){
        console.error(e);
      }
    }
  }

  public stopCinematicMode(isCheck: boolean = false){
    const topic = 'com.maserati.end_cinematic.' + this.sessionId;
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    let obj = { 'bCheckStopCinematic': isCheck}
    const payload = [JSON.stringify(obj)]
    try {
      logPublication(topic,payload)
      autobahnSession.call(topic,payload)
    } catch (e) {
      if(!environment.production){
        console.error(e);
      }
    }
  }
  
  public async onStopCinematicMode() {
    const autobahnSession: Session = this.autobahnLocalSession ?? this.autobahnRemoteSession
    await autobahnSession.subscribe(`com.maserati.onEnd_cinematic.${this.sessionId}`, (res) => {
      console.debug('[AUTOBAHN CLIENT] autobahn onEnd_cinematic', JSON.parse(<unknown>res as string))
      this.onEndCinematicEvt.emit(res)
    })
  }

  public setShowCinematic(ephemeralDeviceID: string, showCinematic: boolean, showCustomerName : boolean,commercialName : string, customerName : string ,
    customerSurname : string, showCinematicLogo : boolean, showCinematicText: boolean, textAnimationActive: boolean){
    const topic = 'com.presenter.showCinematic.' + ephemeralDeviceID;
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    const payload =  {
      showCinematic: showCinematic,
      showCustomerName : showCustomerName,
      commercialName : commercialName,
      customerName : customerName,
      customerSurname : customerSurname,
      showCinematicLogo: showCinematicLogo,
      showCinematicText: showCinematicText,
      textAnimationActive: textAnimationActive
    }
    this.presenterScreenPublish(autobahnSession,topic,payload)
  }

  public async getShowCinematic(autobahnSession: Session,ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.showCinematic.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getBAudioCinematic(autobahnSession: Session,ephemeralDeviceID: string, callbackFn){
    const topic = `com.maserati.request_audio.${ephemeralDeviceID}`;
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public setBAudioCinematic(ephemeralDeviceID: string, bAudio: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.maserati.request_audio.${ephemeralDeviceID}`
    const payload = { bAudio: bAudio }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setScrollPosition(ephemeralDeviceID: string, position: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.scrollEvent.${ephemeralDeviceID}`
    const payload = { scrollPosition: position }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setSummaryScrollPosition(ephemeralDeviceID: string, position: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.summaryScrollEvent.${ephemeralDeviceID}`
    const payload = { scrollPosition: position }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setOptionsItem(ephemeralDeviceID: string, options: UIOptItem[]) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_opt_change.${ephemeralDeviceID}`
    const payload = { sidebarOpt: options }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setPacksItem(ephemeralDeviceID: string, packs: Pack[]) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_packs_change.${ephemeralDeviceID}`
    const payload = { packages: packs }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setAccordionToggle(ephemeralDeviceID: string, accordionClass: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_accordion_toggle.${ephemeralDeviceID}`
    const payload = { accordionClass: accordionClass }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public onSearch(ephemeralDeviceID: string, searchValue: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_search.${ephemeralDeviceID}`
    const payload = { searchValue: searchValue }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public onSearchToggle(ephemeralDeviceID: string, searchToggleValue: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_search_toggle.${ephemeralDeviceID}`
    const payload = { searchToggleValue: searchToggleValue }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public onPackageDetailsToggle(ephemeralDeviceID: string, packageDetailsToggleValue: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.package_details_toggle.${ephemeralDeviceID}`
    const payload = { packageDetailsToggleValue: packageDetailsToggleValue }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setToggleFullScreen(ephemeralDeviceID: string, fullScreenActive: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.sidebar_full_screen_toggle.${ephemeralDeviceID}`
    const payload = { toggleFullScreen: fullScreenActive }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setCarTotalPrice(ephemeralDeviceID: string, carTotalPrice: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car_total_price.${ephemeralDeviceID}`
    const payload = { carTotalPrice: carTotalPrice }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async setPriceStatus(ephemeralDeviceID: string, priceStatus: boolean): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.price_status.${ephemeralDeviceID}`
    const payload = { priceStatus: priceStatus }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getStandAloneSet(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.stand_alone_set.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public setStandAloneSetEditing(ephemeralDeviceID: string, standAloneSet: {}) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.stand_alone_set.${ephemeralDeviceID}`
    const payload = { standAloneSet: standAloneSet }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getStandAloneSetHighlightPacks(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.stand_alone_set_highlightPacks.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public setStandAloneSetHighlightPacks(ephemeralDeviceID: string, standAloneSetHighlightPacks: {}) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.stand_alone_set_highlightPacks.${ephemeralDeviceID}`
    const payload = { standAloneSetHighlightPacks: standAloneSetHighlightPacks }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getStandAloneSetEditingInterior(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.stand_alone_set_interior.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public setStandAloneSetEditingInterior(ephemeralDeviceID: string, standAloneSetInt: {}) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.stand_alone_set_interior.${ephemeralDeviceID}`
    const payload = { standAloneSetInt: standAloneSetInt }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async requestPairingFromSimplePage(ephemeralDeviceId: string, pairingStep: string): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.screencast.${ephemeralDeviceId}`
    const payload = { pairingStep: pairingStep }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async activatePairingFromSimplePage(autobahnSession: Session, ephemeralDeviceId: string, callbackFn): Promise<void> {
    const topic = `com.presenter.screencast.${ephemeralDeviceId}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async requestClosePairingFromSimplePage(ephemeralDeviceID: string) {
    try {
      console.debug(`Closing pairing from EphemeralDeviceID: ${ephemeralDeviceID}`)
      const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
      const topic = `com.presenter.screencast.close.${ephemeralDeviceID}`
      const payload = { msg: 'no payload required'}
      this.presenterScreenPublish(autobahnSession,topic, payload)
      this.sessionJoined.emit(false)
    } catch (error) {
      if(!environment.production){
        console.error(error);
      }
    }

  }

  public async closePairingFromSimplePage(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.screencast.close.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public setSummary(ephemeralDeviceID: string, summary: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.setSummary.${ephemeralDeviceID}`
    const payload = summary
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setAccordion(ephemeralDeviceID: string, accordionOpened: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.toggle_accordion.${ephemeralDeviceID}`
    const payload = accordionOpened
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public setModelSelectorImageUpdate(ephemeralDeviceID: string, activeModelName: string, activeImageIndex: number) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.model_selector.image_index.${ephemeralDeviceID}`
    const payload = { activeModelName: activeModelName, activeImageIndex: activeImageIndex }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getModelSelectorImageUpdate(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.model_selector.image_index.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setModelSelectorActiveIndex(ephemeralDeviceID: string, activeSlideIndex: number): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.model_selector.active_slide_index.${ephemeralDeviceID}`
    const payload = {activeSlideIndex: activeSlideIndex }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getDisplayLoadingServicesPage(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.loading_services.page.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setDisplayLoadingServicesPage(ephemeralDeviceID: string, displayLoadingServices : boolean): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.loading_services.page.${ephemeralDeviceID}`
    const payload = {displayLoadingServices: displayLoadingServices }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getModelSelectorActiveIndex(autobahnSession: Session, ephemeralDeviceID: string, callbackFn): Promise<void> {
    const topic = `com.presenter.model_selector.active_slide_index.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setCustomerName(ephemeralDeviceID: string, name: any): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.client-name.name.${ephemeralDeviceID}`
    const payload = { customerName: name }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async setCustomerSurname(ephemeralDeviceID: string, surname: any): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.client-name.surname.${ephemeralDeviceID}`
    const payload = { customerSurname: surname}
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }



  public async getCustomerName(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.client-name.name.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getCustomerSurname(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.client-name.surname.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getConfigurationId(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.configuration-loader.configurationId.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setCarTrimFamilyCode(ephemeralDeviceID: string, selectedFamily: Family | null) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.model_selector.set_family_code.${ephemeralDeviceID}`
    const payload = { selectedFamily: selectedFamily }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getCarTrimFamilyCode(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.model_selector.set_family_code.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setCarConfiguratorData(ephemeralDeviceID: string, parameters) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car_configurator_data.${ephemeralDeviceID}`
    const payload= parameters
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getCarConfiguratorData(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car_configurator_data.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async getFallback2dData(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.fallback_2d_data.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async clearTimeout(ephemeralDeviceID: string) {
    try {
      const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
      const topic = `com.presenter.clear_timeout.${ephemeralDeviceID}`
      const payload = { msg: 'no payload required'}
      this.presenterScreenPublish(autobahnSession,topic, payload)
    } catch (error) {
      if(!environment.production){
        console.error(error);
      }
    }
  }
  public async onClearTimeout(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.clear_timeout.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setConfiguratorNotLoaded(ephemeralDeviceID: string, carModel) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.load_configurator_not_loaded.${ephemeralDeviceID}`
    const payload = { carModel: carModel }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getConfiguratorNotLoaded(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.load_configurator_not_loaded.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async loadConfigurator(ephemeralDeviceID: string, parameters) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.load_configurator.${ephemeralDeviceID}`
    const payload = { progress: parameters }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }
  public async setFallback2dData(ephemeralDeviceID: string, parameters: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.fallback_2d_data.${ephemeralDeviceID}`
    const payload = parameters
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async onLoadConfigurator(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.load_configurator.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async throwError(ephemeralDeviceID: string, error) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.error.${ephemeralDeviceID}`
    const payload = { error: error }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async onThrowError(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.error.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setCurrentTrimCode(ephemeralDeviceID: string, currentTrimCode) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.trim_selector.current_trim.${ephemeralDeviceID}`
    const payload = { currentTrimCode: currentTrimCode }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getCurrentTrimCode(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.trim_selector.current_trim.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async switchCountry(ephemeralDeviceID: string, selectedCountry) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.update_country.${ephemeralDeviceID}`
    const payload = selectedCountry
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async onSwitchCountry(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.update_country.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async switchLanguage(ephemeralDeviceID: string, languageId) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.update_language.${ephemeralDeviceID}`
    const payload = {languageId: languageId }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async onSwitchLanguage(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.update_language.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setLabels(ephemeralDeviceID: string, labels): Promise<void> {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.set_labels.${ephemeralDeviceID}`
    const payload = labels
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getLabels(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.set_labels.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setFamilies(ephemeralDeviceID: string, families) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.families.${ephemeralDeviceID}`
    const payload = { families: families }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getFamilies(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.families.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setThankYouPage(ephemeralDeviceID: string, thankYouPageParams) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.thank_you_page.${ephemeralDeviceID}`
    const payload = thankYouPageParams
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getThankYouPage(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.thank_you_page.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setStreamingStatus(ephemeralDeviceID: string, streamingAvailable: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.set_streaming_status.${ephemeralDeviceID}`
    const payload = { streamingAvailable: streamingAvailable }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getStreamingStatus(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.set_streaming_status.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setFallbackActiveImage(ephemeralDeviceID: string, activeImageIndex: number) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.fallback_active_image.${ephemeralDeviceID}`
    const payload = { activeImageIndex: activeImageIndex }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getFallbackActiveImage(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.fallback_active_image.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setFallbackImageOffset(ephemeralDeviceID: string, leftOffset: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.fallback_active_image_offset.${ephemeralDeviceID}`
    const payload = { leftOffset: leftOffset }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getFallbackActiveImageOffset(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.fallback_active_image_offset.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setFallbackImageInitialPosition(ephemeralDeviceID: string, setCurrentPosition: number) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.fallback_initial_position.${ephemeralDeviceID}`
    const payload = { setCurrentPosition: setCurrentPosition }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async onsetFallbackImageInitialPosition(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.fallback_initial_position.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setSelectorSwiperScroll(ephemeralDeviceID: string, parameters: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.trim_selector-swiper_delta.${ephemeralDeviceID}`
    const payload = parameters
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getSelectorSwiperScroll(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.trim_selector-swiper_delta.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setCarConfiguratorPackageSlideIndex(ephemeralDeviceID: string, parameters: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_packages_slideIndex.${ephemeralDeviceID}`
    const payload = parameters
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getCarConfiguratorPackageSlideIndex(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_packages_slideIndex.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async initializeLoadConfiguration(ephemeralDeviceID: string, loadConfig: boolean, restoreConfig: boolean, configurationID: string | null = null) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_loadconfig_initialize.${ephemeralDeviceID}`
    const payload: { restoreConfig: boolean, loadConfig: boolean, configurationID?: string } = {
      restoreConfig: restoreConfig,
      loadConfig: loadConfig
    };
    if (configurationID !== null) {
      payload.configurationID = configurationID;
    }

    this.presenterScreenPublish(autobahnSession,topic, payload)
  }
  

  public async onInitializeLoadConfiguration(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_loadconfig_initialize.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }



  public async getTemporaryItemsFromStore(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_temporaryItemsFromStore.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setTemporaryItemsFromStore(ephemeralDeviceID: string, temporaryItemsFromStore: ConfigItem[]) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_temporaryItemsFromStore.${ephemeralDeviceID}`
    const payload = { temporaryItemsFromStore: temporaryItemsFromStore }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getImgStringPathStore(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_imagePath.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setImgStringPathStore(ephemeralDeviceID: string, imagePath: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_imagePath.${ephemeralDeviceID}`
    const payload = { imagePath: imagePath }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getSelectedElementRestoreconfig(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_selected_element_RestoreConfig.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setSelectedElementRestoreconfig(ephemeralDeviceID: string, selectedElementRestoreConfig: any) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_selected_element_RestoreConfig.${ephemeralDeviceID}`
    const payload = { selectedElementRestoreConfig: selectedElementRestoreConfig }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async setPackagesFormatSelectionDetail(ephemeralDeviceID: string, formatSelection: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_packages_formatSelection.${ephemeralDeviceID}`
    const payload = { formatSelection: formatSelection }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getPackagesFormatSelectionDetail(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_packages_formatSelection.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setOptFormatSelectionDetail(ephemeralDeviceID: string, formatSelection: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_formatSelection.${ephemeralDeviceID}`
    const payload = { formatSelection: formatSelection }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getOptFormatSelectionDetail(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_formatSelection.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setAccFormatSelectionDetail(ephemeralDeviceID: string, formatSelection: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_acc_formatSelection.${ephemeralDeviceID}`
    const payload = { formatSelection: formatSelection }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getAccFormatSelectionDetail(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_acc_formatSelection.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setAccOpenClose(ephemeralDeviceID: string, accordionOpenClose: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_acc_openclose.${ephemeralDeviceID}`
    const payload = { accordionOpenClose: accordionOpenClose }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getAccOpenClose(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_acc_openclose.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setAccordionState(ephemeralDeviceID: string, currentState: string[], componentName: string) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_accordion_currentState.${ephemeralDeviceID}`
    const payload = { currentState: currentState, componentName: componentName }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getAccordionState(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_accordion_currentState.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setAccordionHighlightPacksDetailState(ephemeralDeviceID: string, currentStateHighPacksDetail: string[]) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_accordion_currentStateHighPacksDetail.${ephemeralDeviceID}`
    const payload = { currentStateHighPacksDetail: currentStateHighPacksDetail }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getAccordionHighlightPacksDetailState(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_accordion_currentStateHighPacksDetail.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }
  public async setStandAlonePacksHighlightAccOpen(ephemeralDeviceID: string, accordionOpen: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_accordionstatus.${ephemeralDeviceID}`
    const payload = { accordionOpen: accordionOpen }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getStandAlonePacksHighlightAccOpen(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_accordionstatus.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setDealerId(ephemeralDeviceID: string, dealerId: string) {
    //Sensitive Data
    const topic = `com.presenter.dealerId.${ephemeralDeviceID}`
    const payload = { dealerId: dealerId }
    this.presenterScreenPublish(this.autobahnRemoteSession,topic, payload)
  }

  public async getDealerId(ephemeralDeviceID: string, callbackFn) {
    //Sensitive Data
    const topic = `com.presenter.dealerId.${ephemeralDeviceID}`
    this.presenterScreenSubscribe(this.autobahnRemoteSession, topic, callbackFn)
  }

  public async setshowMoreLivree(ephemeralDeviceID: string, showMoreLivree: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_showMoreLivree.${ephemeralDeviceID}`
    const payload = { showMoreLivree: showMoreLivree }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getshowMoreLivree(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_showMoreLivree.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setIsWrapperFromLoader(ephemeralDeviceID: string, isWrapper: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_isWrapper.${ephemeralDeviceID}`
    const payload = { isWrapper: isWrapper }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getIsWrapperFromLoader(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_isWrapper.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setSplittedCommercialName(ephemeralDeviceID: string, splittedCommercialName: string[]) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_splittedCommercialName.${ephemeralDeviceID}`
    const payload = { splittedCommercialName: splittedCommercialName }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getSplittedCommercialName(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_splittedCommercialName.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }
  

  public async setShowCinematicLogo(ephemeralDeviceID: string, showCinematicLogo: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_showCinematicLogo.${ephemeralDeviceID}`
    const payload = { showCinematicLogo: showCinematicLogo }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getshowCinematicLogo(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_showCinematicLogo.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async setShowCinematicText(ephemeralDeviceID: string, showCinematicText: boolean) {
    const autobahnSession = this.autobahnLocalSession ?? this.autobahnRemoteSession;
    const topic = `com.presenter.car-configurator_showCinematicText.${ephemeralDeviceID}`
    const payload = { showCinematicText: showCinematicText }
    this.presenterScreenPublish(autobahnSession,topic, payload)
  }

  public async getshowCinematicText(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-configurator_showCinematicText.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async fromConfiguratorToModelSelection(ephemeralDeviceID: string, labels, families, priceStatus) {
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    const topic = `com.presenter.car-config_to-model.${ephemeralDeviceID}`
    const payload = {
      labels: labels,
      families: families,
      priceStatus: priceStatus
    }
    this.presenterScreenPublish(autobahnSession,topic, payload).then(() => {
      this.requestPairingFromSimplePage(ephemeralDeviceID, PAIRING_STEP_ENUM.MODEL_SELECTOR)
      this.setModelSelectorActiveIndex(ephemeralDeviceID, 0)
    })
  }

  public async onFromConfiguratorToModelSelection(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-config_to-model.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async fromConfiguratorToTrimSelection(ephemeralDeviceID: string, labels, currentFamily, priceStatus) {
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    const topic = `com.presenter.car-config_to-trim.${ephemeralDeviceID}`
    const payload = {
      labels: labels,
      currentFamily: currentFamily,
      priceStatus: priceStatus
    }
    this.presenterScreenPublish(autobahnSession, topic, payload).then(() => {
      this.requestPairingFromSimplePage(ephemeralDeviceID, PAIRING_STEP_ENUM.TRIM_SELECTOR)
      this.setModelSelectorActiveIndex(ephemeralDeviceID, 0)
    })
  }

  public async onFromConfiguratorToTrimSelection(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.car-config_to-trim.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

  public async initializePairing(autobahnSession: Session, ephemeralDeviceID:string, callbackFn) {
    const topic = `com.presenter.join_session.no_video.${ephemeralDeviceID}`
    await autobahnSession?.register(topic, (...args) => {
      logSubscription(topic, args[1])
      callbackFn(...args)
    })
  }

  public async setLastConfigurationsFromStore(ephemeralDeviceID: string, lastConfiguration : any,){
    const autobahnSession = this.autobahnLocalSession?? this.autobahnRemoteSession
    const topic = `com.presenter.last-configuration.${ephemeralDeviceID}`
    const payload = lastConfiguration
    await this.presenterScreenPublish(autobahnSession, topic, payload)
  }

  public async onSetLastConfigurationsFromStore(autobahnSession: Session, ephemeralDeviceID: string, callbackFn) {
    const topic = `com.presenter.last-configuration.${ephemeralDeviceID}`
    await this.presenterScreenSubscribe(autobahnSession, topic, callbackFn)
  }

   
}

