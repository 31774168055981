import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { AppSettingsActions } from '../actions/app-settings/app-settings-exported-actions';
import { UnrealDesiredSceneStateActions } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { AppState } from '../initials/app-settings-initial-state';
import { MxeReducers } from '../reducers';

@Injectable()
export class AppSettingEffects {

    constructor(
        private actions$: Actions,
        private store: Store<MxeReducers>
    ) { }

    AppState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_INIT),
                tap(actions => {
                    localStorage.setItem('appSettings', JSON.stringify({ countryCode: actions.countryCode, languageId: actions.languageId, ctaLanguageId: actions.ctaLanguageId, arePricesAvailable: actions.arePricesAvailable, showPrices: actions.showPrices, ephemeralDeviceId: actions.ephemeralDeviceId, streamingAvailable: actions.streamingAvailable, protocol: actions.protocol, host: actions.host, hostName: actions.hostName }));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_COUNTRY({ country: '' + actions.countryCode }));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_LANGUAGE({ language: actions.languageId }))
                }),
            ),
        { dispatch: false }
    );

    AppStateCustomerNameChanged$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SAVE_CLIENT_NAME),
                tap(actions => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.clientName = actions.clientName,
                    appSettings.clientSurname = actions.clientSurname
                    localStorage.setItem('appSettings', JSON.stringify(appSettings))
                })
            ),
        { dispatch: false }
    );

    AppStateSwitchCountry$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SWITCH_COUNTRY),
                tap(actions => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.countryCode = actions.countryCode 
                    appSettings.languageId = actions.languageId
                    appSettings.ctaLanguageId = actions.ctaLanguageId
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_COUNTRY({ country: '' + actions.countryCode }));
                }),
            ),
        { dispatch: false }
    );

    AppStateSwitchLanguage$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SWITCH_LANGUAGE),
                tap(actions => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.languageId = actions.languageId
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_LANGUAGE({language: '' + actions.languageId }));
                }),
                ),
            { dispatch: false }
        );

        AppStateSwitchCtaLanguage$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SWITCH_CTA_LANGUAGE),
                tap(actions => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')

                    appSettings.ctaLanguageId = actions.ctaLanguageId
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
                ),
            { dispatch: false }
        );
    
    AppStateSetCountry$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SET_COUNTRY),
                tap(actions => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.countryCode = actions.countryCode 
                    appSettings.languageId = actions.languageId 
                    appSettings.ctaLanguageId = actions.ctaLanguageId 
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_COUNTRY({ country: '' + actions.countryCode }));
                }),
            ),
        { dispatch: false }
    );

    AppStateSetPriceAvailable$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SET_PRICES_AVAILABLE),
                tap(action => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.arePricesAvailable = action.arePricesAvailable
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
            ),
        { dispatch: false }
    );

    AppStateTogglePrices$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_TOGGLE_PRICES),
                tap(action => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.showPrices = action.showPrices
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
            ),
        { dispatch: false }
    );

    AppStateSaveConfiguration$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED),
                tap(action => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.configId = action.configId
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
            ),
        { dispatch: false }
    );

    AppStateSetEphemeralDeviceId$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID),
                tap(action => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.ephemeralDeviceId = action.ephemeralDeviceId
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
            ),
        { dispatch: false }
    );

    AppStateSetIsScreenCastActive$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(AppSettingsActions.APP_SETTINGS_SET_SCREENCAST_ACTIVE),
            tap(action => {
                let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                appSettings.isScreenCastActive = action.isScreenCastActive
                localStorage.setItem('appSettings', JSON.stringify(appSettings));
            }),
        ),
        { dispatch: false }
    );

    AppStateSetStreamingStatus$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(AppSettingsActions.APP_SETTINGS_SET_STREAMING_STATUS),
            tap(action => {
                let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                appSettings.streamingAvailable = action.streamingAvailable
                localStorage.setItem('appSettings', JSON.stringify(appSettings));
            }),
        ),
        { dispatch: false }
    );

    AppStateSetAppExecutionEnvironment = createEffect(() =>
    this.actions$
        .pipe(
            ofType(AppSettingsActions.APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS),
            tap(action => {
                let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                appSettings.protocol = action.protocol
                appSettings.host = action.host
                appSettings.hostName = action.hostName
                localStorage.setItem('appSettings', JSON.stringify(appSettings))
            })
        ),
        { dispatch: false }
    )
    AppStateSetApplicationStep$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AppSettingsActions.APP_SETTINGS_SET_APPLICATION_STEP),
                tap(action => {
                    let appSettings = this.tryParseAppState(localStorage.getItem('appSettings') || '')
                    appSettings.applicationStep = action.applicationStep
                    localStorage.setItem('appSettings', JSON.stringify(appSettings));
                }),
            ),
        { dispatch: false }
    );

    private tryParseAppState(appSettings: string): AppState{
        try{
            const parsedAppSettings = JSON.parse(appSettings) as AppState
            return parsedAppSettings
        } catch {
            console.error('Cannot deserialize AppSettings from local storage')
            return {
                countryCode: undefined,
                languageId: '',
                arePricesAvailable: false,
                showPrices: false,
                ctaLanguageId: 'it',
                configId: '',
                ephemeralDeviceId: '',
                isScreenCastActive: false,
                envName: '',
                streamingAvailable: true,
                protocol: 'https:',
                host: '',
                hostName: '',
                applicationStep: ''
            }
        }
    }
}