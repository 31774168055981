
import { createAction, props } from '@ngrx/store';

export const UPDATE_SELECTED_ENVIRONMENT = createAction(
    'UPDATE_SELECTED_ENVIRONMENT',
    props<{
        activeEnvironment: string
    }>()
)

export const UPLOAD_ENVIRONMENTS = createAction(
    '[AutobahnClient] UPLOAD_ENVIRONMENTS',
    props<{
        environments: string[]
    }>()
)