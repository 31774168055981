import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, tap } from 'rxjs';
import { MxeReducers } from '../store/reducers';
import { AuthService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private storeSub: Subscription;
  private isLogged : boolean | Promise<boolean> = false;

  constructor(
    private store: Store<MxeReducers>,
    private router: Router,
    private authService: AuthService
  ) {
    this.storeSub = this.store.select(s => s.userState).pipe(
      // finalize(() => console.log('[finalize] Called')),
      tap((data) => {
        if(!data.jwt_token){
          this.isLogged = false;
        } else {
          this.isLogged = this.authService.checkTokenExpiration(data.jwt_token_expiration_time, data.access_token_expiration_time);
        }
        })
    ).subscribe()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isLogged) {
      this.router.navigate(['/mxe'])
    }
    this.storeSub.unsubscribe();
    return this.isLogged;
  }

}
