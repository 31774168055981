import { Action, createReducer, on } from '@ngrx/store';
import { CAR_GRID_LOADED } from '../actions/car-grid-actions';
import { carGridInitialState } from '../initials/car-grid-initial-state';

const _carGridReducer = createReducer(carGridInitialState,
    on(CAR_GRID_LOADED, (state, payload) => ({
        ...state,
        loaded: true,
        carGrid: payload.carGrid
    }))
);

export function carGridReducer(state = carGridInitialState, action: Action) {
    return _carGridReducer(state, action);
}
