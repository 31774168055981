import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IMAGE_TYPE_ENUM, LABEL_TYPE_ENUM, MENU_ENUM } from 'src/app/core/models/common.model';
import { Menu } from 'src/app/core/models/interface-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation]);
@Component({
  selector: 'app-highlight-configuration',
  templateUrl: './highlight-configuration.component.html',
  styleUrls: ['./highlight-configuration.component.scss'],
  animations: [ 
    trigger('openCloseAcc', [
      state('open', style({ 
          'height': '*'
        })),
      state('closed', style({ 
          'height': '0px'
        })),
      transition('open <=> closed', [
      animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
      ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HighlightConfigurationComponent implements OnInit {
  @ViewChildren(SwiperComponent) swipers : QueryList<SwiperComponent>
  @Input() menu: Menu[];
  @Input() modelCode: string;
  @Input() modelName: string;
  @Input() familyCommercialCode: string
  @Input() packages: Pack[]
  @Input() options : UIOptItem[]
  @Input() showPrices: boolean;
  @Input() currency: string;
  @Input() priceMask: string;
  @Input() isMainScreen: boolean;
  @Input() currentState : string[]
  @Input() currentStateHighPacksDetail  : string[] 
  @Input() set packagesSwiperSlideChange(packagesSwiperSlideChange: {imageIndex: number, packageIndex: number}) {
    if(packagesSwiperSlideChange) {
      if(this.swipers) {
        let swiperComponent: SwiperComponent | undefined = this.swipers.get(packagesSwiperSlideChange.packageIndex)
        if(swiperComponent) {
          swiperComponent.swiperRef.slideTo(packagesSwiperSlideChange.imageIndex)
          swiperComponent.swiperRef.update();
          swiperComponent.swiperRef.pagination.update();
        }
      }
    }
  }
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != ''){
      this.inputText = inputText.toLowerCase()
      this.exteriors = this.DOMdata.get(MENU_ENUM.EXT) ? this.DOMdata.get(MENU_ENUM.EXT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
      this.interiors = this.DOMdata.get(MENU_ENUM.INT) ? this.DOMdata.get(MENU_ENUM.INT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
      this.packs = this.DOMdata.get(MENU_ENUM.PACK) ? this.DOMdata.get(MENU_ENUM.PACK).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
      this.optionals = this.DOMdata.get(MENU_ENUM.OPT) ? this.DOMdata.get(MENU_ENUM.OPT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
      this.accessory = this.DOMdata.get(MENU_ENUM.ACC) ? this.DOMdata.get(MENU_ENUM.ACC).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
    } 
    else{
      this.inputText = text
      this.exteriors = this.DOMdata.get(MENU_ENUM.EXT) ? this.DOMdata.get(MENU_ENUM.EXT) : []
      this.interiors = this.DOMdata.get(MENU_ENUM.INT) ? this.DOMdata.get(MENU_ENUM.INT) : []
      this.packs = this.DOMdata.get(MENU_ENUM.PACK) ? this.DOMdata.get(MENU_ENUM.PACK) : []
      this.optionals = this.DOMdata.get(MENU_ENUM.OPT) ? this.DOMdata.get(MENU_ENUM.OPT) : []
      this.accessory = this.DOMdata.get(MENU_ENUM.ACC) ? this.DOMdata.get(MENU_ENUM.ACC) : []
    }
    this.totalOptSelected = 0; 
    this.checkIfHighlightAreSelected(this.exteriors)
    this.checkIfHighlightAreSelected(this.interiors)
    this.checkIfHighlightAreSelected(this.packs)
    this.checkIfHighlightAreSelected(this.optionals)
    this.checkIfHighlightAreSelected(this.accessory)
  } 
  @Input() set standAloneSetHighlightPacks(value){
    if(value){
      this.standAloneSetEditing = value["standAloneSetEditing"]
      this.selectedItemsInPackage = value["selectedItemsInPackage"]
      if(this.screenCastActive && this.isMainScreen){
        this.autobahn.setStandAloneSetHighlightPacks(this.ephemeralDeviceID, { standAloneSetEditing: this.standAloneSetEditing, selectedItemsInPackage: this.selectedItemsInPackage })
      }
    }
  } 
  @Input() accordionOpen: boolean 
  
  @Output() swiperSlideChanged: EventEmitter<{imageIndex: number, packageIndex: number}> = new EventEmitter<{imageIndex:number, packageIndex: number}> ()
  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()
 
  exteriors : UIOptItem[]= []
  interiors : UIOptItem[]= []
  packs : UIOptItem[]= []
  optionals : UIOptItem[]= []
  accessory : UIOptItem[]= []
  totalOptSelected : number = 0;
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;
  standAloneSetEditing: {}
  selectedItemsInPackage: {}

  public highlightedOptions: UIOptItem[] = []
 
  /*a dictionary composed as follows: 
  - key : the id of the menu; 
  - value: the list of featured options belonging to that menu
  */
 public DOMdata = new Map() 
 public menuLabel: string;
 public accordionOpened = true
 private inputText: string;



  constructor( 
    private carConfigurationService: CarConfigurationService,
    private chg: ChangeDetectorRef,
    private el: ElementRef,
    public uiCommonService: UiCommonService,
    private autobahn : AutobahnClient,
    private mxeAnalyticsService: MxeAnalyticsService,
    private store : Store<MxeReducers>) { }

  ngOnInit(): void {
    this.currentState = ["open"];
    this.currentStateHighPacksDetail = ["closed","closed","closed","closed","closed","closed","closed","closed","closed","closed"]
    if(this.standAloneSetHighlightPacks){
      this.standAloneSetEditing = this.standAloneSetHighlightPacks["standAloneSetEditing"] ?? {}
      this.selectedItemsInPackage = this.standAloneSetHighlightPacks["selectedItemsInPackage"] ?? {}
    }
    else{
      this.standAloneSetEditing = {}
      this.selectedItemsInPackage = {}
    }
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes['options']){
      this.highlightedOptions = this.getHighlightedOptions(changes['options'].currentValue)
      if(this.menu) {
        const menuHighLights = this.menu.find(x => x.id === MENU_ENUM.HIGH);
        if(menuHighLights?.id) {
          this.menuLabel = menuHighLights.id;
        } else {
          this.menuLabel = 'Highlights';
        }
  
        const excludedIds = ['MENU_LINES', 'MENU_HIGHLIGHT'];
        this.menu.filter(m => !excludedIds.includes(m.id)).forEach(menu => {
  
          let highlightedOptionsInMenu: UIOptItem[]= [];
          menu?.tabs.forEach(t =>  {
            const highlightedOptionsInTab: UIOptItem[] = this.highlightedOptions.filter(ho => ho.group === t.id);
            if(highlightedOptionsInTab && highlightedOptionsInTab.length > 0) {
              highlightedOptionsInMenu.push(...highlightedOptionsInTab);
            }
          })
          if(highlightedOptionsInMenu.length > 0) {
            this.DOMdata.set(menu.id, highlightedOptionsInMenu)
          }
        })
      }
      if(changes && changes["standAloneSetHighlightPacks"] && changes["standAloneSetHighlightPacks"].currentValue != changes["standAloneSetHighlightPacks"].previousValue && !this.isMainScreen){
        const newValue = changes["standAloneSetHighlightPacks"].currentValue
        this.standAloneSetEditing = newValue["standAloneSetEditing"]
        this.selectedItemsInPackage = newValue["selectedItemsInPackage"]
      }
      this.totalOptSelected = 0;
      //caso in cui si aggiunga un opt highlight con filtro attivo
      if(this.inputText && this.inputText.length > 0){
        this.exteriors = this.DOMdata.get(MENU_ENUM.EXT) ? this.DOMdata.get(MENU_ENUM.EXT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
        this.interiors = this.DOMdata.get(MENU_ENUM.INT) ? this.DOMdata.get(MENU_ENUM.INT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
        this.packs = this.DOMdata.get(MENU_ENUM.PACK) ? this.DOMdata.get(MENU_ENUM.PACK).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
        this.optionals = this.DOMdata.get(MENU_ENUM.OPT) ? this.DOMdata.get(MENU_ENUM.OPT).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
        this.accessory = this.DOMdata.get(MENU_ENUM.ACC) ? this.DOMdata.get(MENU_ENUM.ACC).filter(o => this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)) : []
      }
      else{
        this.exteriors = this.DOMdata.get(MENU_ENUM.EXT) ? this.DOMdata.get(MENU_ENUM.EXT) : []
        this.interiors = this.DOMdata.get(MENU_ENUM.INT) ? this.DOMdata.get(MENU_ENUM.INT) : []
        this.packs = this.DOMdata.get(MENU_ENUM.PACK) ? this.DOMdata.get(MENU_ENUM.PACK) : []
        this.optionals = this.DOMdata.get(MENU_ENUM.OPT) ? this.DOMdata.get(MENU_ENUM.OPT) : []
        this.accessory = this.DOMdata.get(MENU_ENUM.ACC) ? this.DOMdata.get(MENU_ENUM.ACC) : []
      }
      this.checkIfHighlightAreSelected(this.exteriors)
      this.checkIfHighlightAreSelected(this.interiors)
      this.checkIfHighlightAreSelected(this.packs)
      this.checkIfHighlightAreSelected(this.optionals)
      this.checkIfHighlightAreSelected(this.accessory)
    }
  }

  ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }


  checkIfHighlightAreSelected(section : UIOptItem[]){
    if(section && section.length > 0){
      section.forEach(s =>{
        if(s.status.selected){
          this.totalOptSelected += 1
        }
      })
    }
  }

  toggleAccordion(event,i): void {
    event.stopPropagation && event.stopPropagation();
    this.accordionOpened = !this.accordionOpened;
    this.currentState[i] = this.currentState[i]  === 'open' ? 'closed' : 'open';
    
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionState(this.ephemeralDeviceID,this.currentState,'high')
    }

    const boxes = [`#${this.menuLabel}_header`,`#${this.menuLabel}_content`]

    boxes.forEach(box => {
      let element = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(element) {
        if(!element.classList.contains('closed')) {
          element.classList.add('closed');
        } else {
          element.classList.remove('closed');
        }
      }
    })
    this.chg.detectChanges()
  }

  toggleAccordionDetailsStatus(i:number, event){
    
    if(event) {
      event.stopPropagation();
    }
    this.currentStateHighPacksDetail[i] = this.currentStateHighPacksDetail[i] == 'closed' ? 'open' : 'closed'
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionHighlightPacksDetailState(this.ephemeralDeviceID,this.currentStateHighPacksDetail)
    }
  }

  getOptionsImageInPackage(id: string): string[] {
    const pack = this.packages.find(p => p.packageId === id)
    let imagePaths: string[] = []
    pack?.implicits.forEach(item => {
      if(item.group) 
        imagePaths.push(this.getImagePath(item.group, item.id))
    })

    const swiperBullets = document.querySelectorAll(`.${id} .swiper-pagination .swiper-pagination-bullet`)
    swiperBullets.forEach((element, index) => {
      element.setAttribute('data-linktracking', `cta:configurator:accordion:menu_pack:pack:${pack?.packageObj.section}:${id}:dots-${index + 1}`)
    })

    return imagePaths
  }

  
  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string = '', action: string = ''): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }

  getPackageImplicitsDescriptions(id: string): string[] {
    const pack = this.packages.find(p => p.packageId === id)
    let description: string[] = []
    if(pack){
      pack.implicits.forEach(opt => {
        description.push (this.uiCommonService.getLabel(opt.id, LABEL_TYPE_ENUM.NAME, '', this.modelCode))
      })
      pack.standAlone.options.forEach(opt => {
        description.push (this.uiCommonService.getLabel(opt.id, LABEL_TYPE_ENUM.NAME, '', this.modelCode))
      })
    }
    return description
  }

  getPackById(id : string) : Pack{
    const pack = this.packages.find(p => p.packageId === id)
    return pack!
  }

  showSetList(packageId:string, setId: string) {
    this.accordionOpen = !this.accordionOpen;
    this.standAloneSetEditing[`${packageId}_${setId}`] = !this.standAloneSetEditing[`${packageId}_${setId}`]
    this.standAloneSetHighlightPacks = { standAloneSetEditing: this.standAloneSetEditing, selectedItemsInPackage: this.selectedItemsInPackage}
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setStandAlonePacksHighlightAccOpen(this.ephemeralDeviceID,this.accordionOpen)
    }
  }

  onSetListItemChange(itemId: string, packageId: string, setId: string) {
    this.accordionOpen = false;
    this.selectedItemsInPackage[`${packageId}_${setId}`] = itemId
    this.standAloneSetEditing[`${packageId}_${setId}`] = false
    this.standAloneSetHighlightPacks = { standAloneSetEditing: this.standAloneSetEditing, selectedItemsInPackage: this.selectedItemsInPackage}
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setStandAlonePacksHighlightAccOpen(this.ephemeralDeviceID,this.accordionOpen)
    }
    this.chg.detectChanges();
  }

  getSelectedItemInList(packageId: string, setId: string): UIOptItem {
    if(this.selectedItemsInPackage[`${packageId}_${setId}`]){
      return this.options.find(o => o.id === this.selectedItemsInPackage[`${packageId}_${setId}`])!
    } else {
      return this.getDefaultSelectedItemInList(packageId, setId)  
    }
  } 
  getSelectedItemTranslation(packageId: string, setId: string): string {
    const selectedItem = this.getSelectedItemInList(packageId, setId)
    if(selectedItem){
      return this.getLabel(selectedItem.id, LABEL_TYPE_ENUM.NAME)
    } 
    return ''
  }
 
  private getDefaultSelectedItemInList(packageId: string, setId: string) {
    let items = this.packages.find(p => p.packageId === packageId)?.standAlone.sets.find(s => s.id === setId)?.list!
    const firstSelected = items?.find(o => o.status.selected === true)
    if(firstSelected)
      return firstSelected
    const firstPreset = items?.find(o => o.status.selectable === true && o.preset === true)
    if(firstPreset)
      return firstPreset
    const firstSelectable = items?.filter(o => o.status.selectable === true)[0]
    if(firstSelectable)
      return firstSelectable
    const firstPresetEvenNotSelectable = items?.find(o => o.preset === true)
      if(firstPresetEvenNotSelectable)
        return firstPresetEvenNotSelectable
    return items[0]
  }

    /**
   * Call the configuration service in order to have a path of the option image with the given color
   * @param group - Option's group
   * @param optionId - Option ID
   * @param color - Color Code (Optional)
   * @returns 
   */
    getImagePathExtInt(group: string, optionId: string, color = ''): string {
      return this.carConfigurationService.getImagePath(this.modelCode, group, optionId, IMAGE_TYPE_ENUM.MENU_ICON, color, this.familyCommercialCode)
    }

    getImagePath(group: string, optionId: string): string {
      return this.carConfigurationService.getImagePath(this.modelCode, group, optionId, IMAGE_TYPE_ENUM.INFO_1400_875)
    }

    getLabel(id: string, type = '', color = '') {
      return this.uiCommonService.getLabel(id, type, color, this.modelCode);
    }
    getLabelWithDefaultValue(optId: string, defaultValue: string) {
      return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
    }

    formatPrice(price: string): string {
      if(this.uiCommonService.isPriceValid(price))
        return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
      return ''
    }

    formatSelectionDetails(): string {
      var formatSelection : string = "";
      if(this.totalOptSelected < 2){
        var selected = this.getLabel("MXE_SELECTED_SING_M");
        formatSelection = `${this.totalOptSelected} ${selected}`;
      }
      if(this.totalOptSelected > 1){
        var selected = this.getLabel("MXE_SELECTED_PLUR_M");
        formatSelection = `${this.totalOptSelected} ${selected}`;
      }
      if(this.screenCastActive || !this.isMainScreen){
        this.autobahn.setAccFormatSelectionDetail(this.ephemeralDeviceID,formatSelection);
      }
      return formatSelection;
    }

    changeConfiguration(id: string, isSelected: boolean, componentName = '') {
      const res = this.carConfigurationService.checkConflicts(id, isSelected ? '0' : '1')
      if(componentName && componentName.length > 0){
        const sets = this.packages.find(p => p.packageId === id)?.standAlone.sets
        sets?.forEach(set => {
          const selectedItem = this.selectedItemsInPackage[`${id}_${set.id}`]
          if(selectedItem){
            const setRes = this.carConfigurationService.checkConflicts(selectedItem, '1')
          }
        })
      }
      if(
        (!isSelected && res.changes.toRemove.length > 0) 
        || 
        (!isSelected && res.changes.toAdd.length >= 1) 
        ||
        (isSelected && res.changes.toAdd.length > 0)
        ||
        (isSelected && res.changes.toRemove.length >= 1))
      {
        this.changeConfigurationEmitter.emit(res.changes)
        
      }
      
    }

  private getHighlightedOptions(options: UIOptItem[]): UIOptItem[] {
    const mockedHighlights = localStorage.getItem('mocked_highlights')? JSON.parse(localStorage.getItem('mocked_highlights')!) as boolean: false; 
    const MOCK_GROUPS: string[] = ['EXT', 'RIMS', 'CAL', 'INT', 'PACK', 'ACC', 'MEC', 'TRIM']

    let ret: UIOptItem[] = options.filter(opt => opt.highlight);
    if (mockedHighlights) {
      MOCK_GROUPS.forEach(group => {
        const mock_option = options.find(opt => opt.group.includes(group))
        if (mock_option) {
          ret.push(mock_option);
        }
      })
      ret = ret
        .filter(Boolean)
        .filter((value, index, self) => { return self.indexOf(value) === index })
        .map((opt) => { return { ...opt, highlight: true } })
    }
    return ret;
  }

   //#region Swiper
   config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    speed: 1000,
    navigation: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  onSwiper(swiper: any) {
  }

  onSlideChange(event: any , packageIndex: number) {
    let imageIndex = event[0].activeIndex;
    this.swiperSlideChanged.emit({
      imageIndex,
      packageIndex
    });   
  }
  //#endregion

}
