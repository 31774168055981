<div class="mxe__modal--save-configuration full-modal">
  <div class="mxe__modal--save-configuration--content saveform">
    <div class="cta-tool-webtolead web-to-eloqua">
      <div class="web-to-eloqua-section">
        <div class="mxe__padd form shopping-tools--wtl magnolia-environment--type">
          <form id="save_form_action" action="#" method="POST" class="mxe__modal--save-configuration--form"
            name="save_form_action" [formGroup]="saveConfigurationForm" (ngSubmit)="saveConfiguration()"
            data-language="" data-zip="" data-model="" data-request-type="TD" data-countryCode="">
            <!-- NAME -->
            <div class="mxe__modal--save-configuration--input-grp">
              <label class="mxe__modal--save-configuration--input-label" for="clientname">
                {{getLabel('MXE_CLIENT_NAME', 'client name')}}*
              </label>
              <input class="mxe__modal--save-configuration--input-field" type="text" id="clientname"
                placeholder="" formControlName="customerName" data-trackingfield="first name" (input)="updateValidity('customerName')">
            </div>
             <!-- SURNAME -->
            <div class="mxe__modal--save-configuration--input-grp">
              <label class="mxe__modal--save-configuration--input-label" for="clientsurname">
                {{getLabel('MXE_CLIENT_SURNAME', 'client surname')}}*
              </label>
              <input class="mxe__modal--save-configuration--input-field" type="text" id="clientsurname"
                placeholder="" formControlName="customerSurName" (input)="updateValidity('customerSurName')"
                data-trackingfield="last name">
            </div>
             <!-- EMAIL -->
            <div class="mxe__modal--save-configuration--input-grp">
              <label class="mxe__modal--save-configuration--input-label" for="email">
                {{getLabel('MAILING_SENDER_EMAIL', 'E-mail')}}*
              </label>
              <input class="mxe__modal--save-configuration--input-field" type="email" id="email"
                placeholder="" formControlName="email" (input)="updateValidity('email')"
                data-trackingfield="email">
              <div class="error-label"
                *ngIf="!saveConfigurationForm.controls['email'].valid && saveConfigurationForm.controls['email'].value ">
                {{getLabel('SO_SAVE_INVALID_EMAIL', 'Invalid mail address')}}
              </div>
            </div>
             <!-- MOBILE -->
            <div class="mxe__modal--save-configuration--input-grp">
              <label class="mxe__modal--save-configuration--input-label" for="mobile">
                {{getLabel('MXE_PHONE_NUMBER', 'Mobile')}}
              </label>
              <div class="mobile-input" *ngIf="selectedCountryIso">

                <ngx-intl-tel-input [cssClass]="'custom'"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="true"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="selectedCountryIso" 
                  [phoneValidation]="false"
                  [separateDialCode]="true" name="phone"
                  formControlName="phone"
                  >
                </ngx-intl-tel-input>
                </div>
              </div>
            <!-- MODEL OF INTEREST -->
              <div class="mxe__modal--save-configuration--input-grp">
                <label class="mxe__modal--save-configuration--input-label" for="modelOfInterest">
                  {{getLabel('MXE_MODEL_OF_INTEREST', 'Model of interest')}}*
                </label>
                <div class="select-option-container">
                  <ul class="sel" name="model_of_interest" (click)="showModelOfInterest()"
                    [ngClass]="showOptionList ? 'active' : ''" data-trackingfield="model_of_interest">
                    <li class="sel__placeholder">{{choosenModelDesc ? choosenModelDesc : getLabel('MXE_CHOOSE_OPTION',
                      'Choose an option') }}</li>
                    <li class="sel__list-container" role="list">
                      <ul class="sel__box" id="sel-model_of_interest">
                        <li id="{{model.code}}_header" class="sel__box__options" *ngFor="let model of modelsOfInterest"
                          [value]="model.code" (click)="chooseModel(model)">{{model.descr}}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mxe__modal--save-configuration--input-grp">
                <label class="mxe__modal--save-configuration--input-label">
                  * {{getLabel('MXE_REQUIRED_FIELD', 'Required fields')}}
                </label>
              </div>
              <div class="async-privacy"></div>
              <div class="button-div">
                <button *ngIf="isSavingAvailable" class="btn btn-accent btn-fullwidth"
                  [ngClass]="saveConfigurationForm.invalid? 'btndisabled' : ''"
                  [disabled]="saveConfigurationForm.invalid">{{getLabel('NAVIGATION_2','Save')}}</button>
                <button *ngIf="!isSavingAvailable" class="btn btn-accent btn-fullwidth" [disabled]="true"><img
                    class="spinning" src="../../../assets/icons/mxe-ico-refresh-black.svg"></button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>