import { createAction, props } from '@ngrx/store';

export const CTAS_LOADED = createAction(
    '[carGridCompleted] CTAS_LOADED',
    props<{ctas: {}}>()
)

export const LABELS_LOADED = createAction(
    'LABELS_LOADED',
    props<{labels: {}}>()
)
	