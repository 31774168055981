import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    CarGridResponse,
    ColorItem,
    IOptStatus,
    Opt,
    Pack,
    Standalone,
    UIOptItem,
    UIOptItemInSet
} from '../models/car-grid.model';
import {
    IConflictsData,
    IMAGE_TYPE_ENUM,
    OPTION_TYPE_ENUM
} from '../models/common.model';
import { Menu } from '../models/interface-service.model';
import { AppSettingsActions } from '../store/actions/app-settings/app-settings-exported-actions';
import {
    UPDATE_TAXES,
    UPDATE_TOTAL_PRICE
} from '../store/actions/engine/engine-actions';
import {
    MODEL_OPTIONS_PARSED
} from '../store/actions/models/models-actions';
import { UnrealDesiredSceneStateActions } from '../store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from '../store/reducers';
import { UiCommonService } from './ui-common.service';
declare const Engine: any;

@Injectable()
export class CarConfigurationService {
    flattenedOptions: Array<Opt> = [];
    
    private engine = new Engine();
    //The initialstate as retrieved from ICT services.
    //We get it from the store
    private rootState: CarGridResponse;
    //Car Configuration State
    private state: CurrentEngineState = {
        opts: [],
        renderingStatus: {},
        totalPrice: '',
        taxes: 0,
        transaction: false,
        packsPrices: {},
        selectedPack: '',
    };

    //the initial config of the app. present if loaded from LoadConfiguration
    // TODO defing a better type for initialConfiguration
    private initialConfiguration: any = null;
    private isInitialConfigurationLoaded = false;
    private menuItems: Menu[];
    private options: UIOptItem[] = []
    private defaultOptions: UIOptItem[] = []
    private hiddenOptions: UIOptItem[] = []
    menuState$: Subscription;
    modelState$: Subscription;


    constructor(
        private readonly store: Store<MxeReducers>,
        private uiCommonService: UiCommonService,
        private ngZone: NgZone
    ) {
        this.init();
    }

    private init() {
        combineLatest([
            this.store.select((c) => c.configurationState),
            this.store.select((c) => c.carGridState),
        ]).subscribe(([loadedConf, carGridState]) => {
            if (loadedConf.loaded) {
                this.isInitialConfigurationLoaded = true;
                this.initialConfiguration = loadedConf.loadedConfig;
            } else {
                this.isInitialConfigurationLoaded = false;
            }
            if (carGridState.loaded) {
                this.rootState = carGridState.carGrid;
            }
        });
        this.menuState$ = this.store
            .select((s) => s.menuState)
            .subscribe((menu) => (this.menuItems = menu.menuItems));
        this.modelState$ = this.store.select(s => s.modelsState).subscribe(
            modelState => {
                this.options = modelState.options;
                this.defaultOptions = modelState.defaultOptions;
                this.hiddenOptions = modelState.hiddenOptions;
            }
        )
    }

    // getPacksList(selectedOnly: boolean) {
    // 	let filter: any = {
    // 		group: 'PACK',
    // 		hidden: '0',
    // 		must: '0',
    // 	};

    // 	if (selectedOnly) {
    // 		filter['selected'] = '1';
    // 	}
    // 	//return this.state.opts.length > 0 ? this.state.opts.filter(filter).sortByOrder(["priority", "desc"]).value() : [];
    // 	return this.state.opts.length > 0
    // 		? this.state.opts.filter(filter).values()
    // 		: [];
    // }

    // getCommercialPackPrice(id: keyof typeof this.state.packsPrices) {
    // 	return this.state.packsPrices[id] || 0;
    // }

   

    // getSetSelectionOfPack(setOfpack: any) {
    // 	if (!setOfpack) {
    // 		return '';
    // 	}

    // 	// choosing defaultOfSet following this fallback chain:
    // 	// 1- first selected item
    // 	// 2- opt with preset (if selectable)
    // 	// 3- first selectable item from left to right
    // 	// 4- opt with preset (even it not selectable)
    // 	// 5- first item (if preset doesnt exist)
    // 	let optObj =
    // 		setOfpack.list.filter({ selected: '1' }).first() ||
    // 		setOfpack.list
    // 			.filter((item) => {
    // 				return (
    // 					item.status.selectable &&
    // 					item.preset == '1'
    // 				);
    // 			})
    // 			.first() ||
    // 		setOfpack.list
    // 			.filter((item) => {
    // 				return item.status.selectable;
    // 			})
    // 			.first() ||
    // 		setOfpack.list.filter({ preset: '1' }).first() ||
    // 		setOfpack.list.first();

    // 	if (optObj.pack == '1') {
    // 		optObj = null;
    // 	}

    // 	if (this.state.selectedPack) {
    // 		optObj = this.state.selectedPack;
    // 	}

    // 	var label = optObj
    // 		? this.getLabel(optObj.id, 'opt_name', optObj.group)
    // 		: '';

    // 	return {
    // 		opt: optObj,
    // 		label: label,
    // 	};
    // }

    getPackagePrice(id: string): string {
        return this.engine.calculatePackBasePrice(id)
    }

    getTotalPrice() {
        return this.state.totalPrice;
    }

    getBasePrice() {
        return this.rootState.general
            ? parseInt(this.rootState.general.price, 10)
            : 0;
    }

    getPersonalizationPrice() {
        return parseFloat(this.getTotalPrice()) - this.getBasePrice() - this.getTaxes();
    }

    getTaxes() {
        return this.state.taxes;
    }

    // getOptsByFilter(customFilter): Array<OptItem> {
    // 	return this.state.opts.length > 0
    // 		? this.state.opts.filter(customFilter)
    // 				.map((opt) => {
    // 					opt.status = this.getOptStatus(opt.id);
    // 					return opt;
    // 				})
    // 		: [];
    // }

    getOpt(id: any, skipStatus = false): any {
        try {
            if (this.state.opts.length > 0) {
                let opt = this.state.opts.find((x) => x.id == id);
                if (opt && !skipStatus) {
                    opt.status = this.getOptStatus(opt.id);
                }
                return opt;
            }
        } catch (e) {
            return null;
        }
    }

    // getMenuTabs(menu_id: string): Promise<Tab[]>{
    // 	return new Promise<Tab[]>(
    // 		(resolve) => {
    // 			this.store.select(s => s.interfaceState)
    // 			.subscribe({
    // 				next: (interfaceState) => {
    // 					resolve(interfaceState.interface.menu.find(m => m.id === menu_id)?.tabs || [])
    // 				},
    // 				error: error => console.error(error),
    // 				complete: () => console.debug('GetMenuTabs completed')
    // 			})
    // 		}
    // 	)
    // }

    getOptStatus(id) {
        return this.state.renderingStatus ? this.state.renderingStatus[id] : {};
    }

    // hasTransaction() {
    // 	return this.state.transaction;
    // }

    initEngine(carGridData: CarGridResponse) {
        console.log('init engine');

        var EventToRemove = [
            'ready.initservice',
            'completed.initservice',
            'transactionRollback.initservice',
            'transactionConfirmed.initservice',
            'simulatedColor.initservice',
            'simulated.initservice',
        ];

        this.engine.initData(carGridData);

        console.log('[CarConfigurationService] - Engine started');
        console.log(this.engine);
        this.state.opts = this.engine.Opts().DB().get();

        //executed in case of accidental page refreshing while on car configurator
        if (this.options && this.options.length > 0) {

            this.engine.Opts().DB().update({ selected: '0' }, false);

            this.options.filter(o => o.status.selected).forEach((opt) => {
                let data: any = { selected: '1' };
                if (opt.colorList && opt.status.colorSelected) {
                    data._selectedColor = opt.status.colorSelected
                }
                if (opt.quantity) {
                    data.quantity = opt.quantity
                }
                // update selection status for opts contained in the loaded configuration
                this.engine.Opts().DB({ id: opt.id }).update(data, false);
            })

            this.hiddenOptions.filter(o => o.status.selected).forEach((opt) => {
                let data: any = { selected: '1' };
                if (opt.colorList && opt.status.colorSelected) {
                    data._selectedColor = opt.status.colorSelected
                }
                if (opt.quantity) {
                    data.quantity = opt.quantity
                }
                // update selection status for opts contained in the loaded configuration
                this.engine.Opts().DB({ id: opt.id }).update(data, false);
            })

            this.defaultOptions.filter(o => o.status.selected).forEach((opt) => {
                let data: any = { selected: '1' };
                if (opt.colorList && opt.status.colorSelected) {
                    data._selectedColor = opt.status.colorSelected
                }
                if (opt.quantity) {
                    data.quantity = opt.quantity
                }
                // update selection status for opts contained in the loaded configuration
                this.engine.Opts().DB({ id: opt.id }).update(data, false);
            })

            this.state.opts = this.engine.Opts().DB().get();
            this.engine.Opts().resetCache(); // reset cache

            console.log('Engine ready!');
        } else if (this.isInitialConfigurationLoaded) //executing on load configuration
        {
            // load opts from the configuration.
            var optWs = this.parseCarConfigurationFromString(
                this.initialConfiguration.config
            );
            // reset selection status
            this.engine.Opts().DB().update({ selected: '0' }, false);
            optWs.forEach((optW) => {
                let data: any = { selected: '1' };
                if (optW.colorList && optW._selectedColor) {
                    data._selectedColor = optW._selectedColor;
                }
                if (optW.allowMultiple == '1') {
                    data.quantity = optW.__quantity;
                }
                // update selection status for opts contained in the loaded configuration
                this.engine.Opts().DB({ id: optW.id }).update(data, false);
            });

            // reset taffy cache and reload menu
            this.state.opts = this.engine.Opts().DB().get();
            this.engine.Opts().resetCache(); // reset cache

            console.log('Engine ready!');
        }

        this.state.taxes = this.engine.getTaxes()
        this.state.totalPrice = this.engine.calculateTotalCarPrice() + this.state.taxes;
        this.flattenOptions(this.state.opts);
        this.groupObjects();
    }

    //#region Utils
    private getConfigForScene(uiOptions: UIOptItem[]) {
        let selectedOptions = uiOptions.filter((o) => o.status.selected);
        let uiOptionsPacksSelectedIds = uiOptions.filter((o) => o.status.selected && (o.group == 'PACK' || o.layerGroup == 'PACK')).map(x => x.id);
        let packagesObjSelected = this.getPackages(uiOptions).filter(p => uiOptionsPacksSelectedIds.includes(p.packageId) && p.implicits && p.implicits.length > 0);
        if(packagesObjSelected && packagesObjSelected.length > 0){
            packagesObjSelected.map(x => x.implicits).reduce((a,r) => a.concat(r)).forEach( p =>{
                if(!selectedOptions.find(s => s.id == p.id)){
                    selectedOptions.push(p)
                }
            })
        }
        let config = selectedOptions.map((o) => this.createSerializedOptionForScene(o));
        let uniqueConfig = [...new Set(config)]
        this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_CONFIG_FOR_SCENE({
            config: uniqueConfig
        }))
    }

    /**
     * Returns the exterior color 
     * Mainly used in the summary for the inventory count
     * @returns 
     */
     getExteriorColor(): string {
        //There cannot be a car without an exterior selected color
        return this.flattenedOptions.find(o => o.id === 'EXT')?.colorList.find(c => c.selected === true)?.colIdent!
    }

    private createSerializedOptionForScene(o: UIOptItem): any {
         return o.status.colorSelected ? `${o.id}_${o.status.colorSelected}` : `${o.id}`;
    }

    private createSerializedOption(o) {
        let colorSuffix = '';
        if (o._selectedColor) {
            colorSuffix = '_' + o._selectedColor;
        }

        var serializedOpt = o.group + '/' + o.id + colorSuffix;
        if (o.group.indexOf('ACC_') == 0) {
            serializedOpt += '#' + o.quantity;
        }
        return serializedOpt;
    }

    serializeCarConfiguration(): string {
        let carConfig = this.state.opts
            .filter((o) => o.selected == '1')
            .map((o) => this.createSerializedOption(o));
        return carConfig.join(';');
    }

    parseCarConfigurationFromString(str_config: string) {
        let config: any = [];
        let opts = str_config.split(';');

        opts.map((opt) => config.push(this.createOption(opt))).filter(
            (o) => o != null
        );
        return config;
    }

    private createOption(OPT: string) {
        if (OPT) {
            let codes = OPT.split('/'),
                group = codes[0],
                opt_codes_color = codes[1],
                opt_codes_quantity = opt_codes_color.split('#'),
                quantity = opt_codes_quantity[1],
                opt_codes = opt_codes_quantity[0].split('_'),
                opt_code = opt_codes[0],
                color = opt_codes[1];

            var opt = this.getOpt(opt_code, true);

            if (opt) {
                if (color) {
                    if (color in opt.colorList) {
                        opt._selectedColor = color;
                    } else {
                        if(!environment.production){
                            console.error('No color found', OPT);
                        }
                    }
                }
                if (typeof quantity !== 'undefined') {
                    opt.__quantity = parseInt(quantity, 10);
                }
            } else {
                if(!environment.production){
                    console.error('No opt found', OPT);
                }
            }
            return opt;
        }
        return undefined;
    }
    //#endregion

    /**
     * Checks if a selection generates conflicts with the current configuration
     * @param idOPT Optional ID
     * @param status selected or not
     * @returns
     */
    checkConflicts(optId, status) {
        var original = !!this.engine._events_disabled;
        if (!original) {
            this.engine.disableEvents();
        }

        //Call the engine to check if the selection triggers other changes
        var res: OptItem[] = this.engine.update(
            optId,
            'selected',
            status,
            true
        );
        let changes: IConflictsData = {
            toAdd: [],
            toRemove: [],
        };
        if(res){
            let toAdd = res.filter(o => o.selected == '1' && o.hidden != '1' && o.onlyImplicit != '1').map((o) => this.toIConflictOptionItem(o));

            let toRemove = res.filter(o => o.selected == '0' && o.hidden != '1' && o.onlyImplicit != '1').map((o) => this.toIConflictOptionItem(o));
    
            if (!original) {
                this.engine.enableEvents();
            }
    
            this.state.taxes = this.engine.getTaxes()
            this.state.totalPrice = this.engine.calculateTotalCarPrice() + this.state.taxes;
            this.updateOptsState();
    
            changes.toAdd = toAdd
            changes.toRemove = toRemove
            
            this.store.dispatch(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED({configId: ''}))
            return { changes: changes, price: this.state.totalPrice };
        }
        return { changes: changes, price: this.state.totalPrice };
    }

    private toIConflictOptionItem(o: OptItem): any {
        return {
            id: o.id,
            price: o.price,
        };
    }

    /**
     * Revert the option's selection
     * @param idOPT option id
     * @param status selected / unselected
     */
    updateConfiguration(idOPT, status) {
        if (!this.engine.update(idOPT, 'selected', status, true)) {
            console.error('OPTId not selectable due to rule conflicts');
        }
        this.state.taxes = this.engine.getTaxes()
        this.state.totalPrice = this.engine.calculateTotalCarPrice() + this.state.taxes;
        this.updateOptsState();
    }

    /**
     * Check if color's update can be done with the current configuration
     * @param optId the option code
     * @param colorCode the color code
     * @param force
     */
    updateColor(optId: string, colorCode: string, force = true) {
        if (this.engine.updateColor(optId, colorCode, force)) {
            console.log(
                'Color updating',
                optId,
                colorCode,
                'permanent:',
                force
            );
        } else {
            console.error(
                `Error updating color: ${colorCode} for option ${optId}`
            );
        }
        this.store.dispatch(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED({configId: ''}))
        this.state.taxes = this.engine.getTaxes()
        this.state.totalPrice = this.engine.calculateTotalCarPrice() + this.state.taxes;
        this.updateOptsState();
    }

    /**
     * Update option status (selected or not)
     * @param optId the option code
     * @param status the option status
     * @param force
     */
    updateOpt(optId: string, status: string, force = true) {
        console.log('Opt updating', optId, status, 'permanent:', force);
        this.engine.update(optId, 'selected', status, force);
    }

    updateQuantity(idOPT: string, quantity: number) {
        console.log('Quantity updating', idOPT, quantity);
        this.engine.updateQuantity(idOPT, quantity);
        this.store.dispatch(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED({configId: ''}))
        this.state.taxes = this.engine.getTaxes()
        this.state.totalPrice = this.engine.calculateTotalCarPrice() + this.state.taxes;
        this.updateOptsState();
    }

    updateTotalPrice() {
        const price = this.engine.calculateTotalCarPriceWithTaxes();
        const taxes = this.engine.getTaxes();

        this.store.dispatch(UPDATE_TOTAL_PRICE({ price: price }));
        this.store.dispatch(UPDATE_TAXES({ taxes: taxes }));
    }

    /**
     * Returns the path of the option's image with the given color
     * @param modelCode - The car model code
     * @param tab - the option group
     * @param optionId - The option code
     * @param type - The img type
     * @param color - Optional. The color code
     * @returns
     */
    getImagePath(
        modelCode: string,
        group: string,
        optionId: string,
        type: string,
        color = '',
        familyCommercialCode = ''
    ): string {
        let img_name = '';
        switch (type) {
            case IMAGE_TYPE_ENUM.MENU_ICON:
                img_name = 'menu_icon.jpg';
                break;
            case IMAGE_TYPE_ENUM.INFO_1400_875:
                img_name = 'info1400x875.jpg';
                break;
            default:
                img_name = 'menu_icon.jpg';
                break;
        }
        const familyWithOldExteriorImages = ['gh', 'lv', 'qp']
        if(!familyWithOldExteriorImages.includes(familyCommercialCode.toLocaleLowerCase())){
            switch(group) {
                case 'EXT':
                case 'CAL':
                case 'RIMS':
                    return color != '' 
                        ? `${environment.image_repo_url}/${modelCode}/MXE/${group.toLocaleUpperCase()}/${color}.png`
                        : `${environment.image_repo_url}/${modelCode}/MXE/${group.toLocaleUpperCase()}/${optionId}.png`
                default:
                    return color != ''
                        ? `${environment.image_repo_url}/${modelCode}/${group}/${optionId}/${color}/${img_name}`
                        : `${environment.image_repo_url}/${modelCode}/${group}/${optionId}/${img_name}`;
            }
        } else {
            return color != ''
                ? `${environment.image_repo_url}/${modelCode}/${group}/${optionId}/${color}/${img_name}`
                : `${environment.image_repo_url}/${modelCode}/${group}/${optionId}/${img_name}`;
        }
    }

    /**
     * Flattens all options contained in carGrid
     * @param options 
     */
    flattenOptions(options: any) {
        this.flattenedOptions = [];
        Object.keys(options).forEach((key) => {
            const opt = options[key];
            let optStatus = this.engine.renderingStatusByOPT(opt['id'])
            // Get available colors list from engine
            let colorList: ColorItem[] = [];
            if (opt['colorList'] && optStatus.availableColors.length > 0) {
                optStatus.availableColors.forEach((color) => {
                    const colorCandidate = opt['colorList'][color]
                    if(colorCandidate) {
                        let colorItem: ColorItem = {
                            colIdent: color,
                            selected:
                                opt['_selectedColor'] &&
                                opt['_selectedColor'] ==
                                    color
                                    ? true
                                    : false,
                            price: colorCandidate['price'],
                            fuoriserie: colorCandidate['fuoriserie'] == 1,
                            highlight: colorCandidate['highlight'] == 1
                        };
                        colorList.push(colorItem);
                    }
                })
            } 
            let fo: Opt = {
                id: opt['id'],
                allowMultiple: opt['allowMultiple'],
                base: opt['base'],
                colorList: colorList,
                group: opt['group'],
                hidden: opt['hidden'],
                layerGroup: opt['layerGroup'],
                must: opt['must'],
                onlyImplicit: opt['onlyImplicit'],
                price: opt['price'],
                priority: opt['priority'],
                selected: opt['selected'],
                subGroup: opt['subGroup'],
                quantity: opt['quantity'],
                status: optStatus,
                fuoriserie: opt['fuoriserie'] == 1,
                highlight: opt['highlight'] == 1
            };
            this.flattenedOptions.push(fo);
        });
    }

    private getPackages(uiOptions: UIOptItem[]) {
        let packages: Pack[] = []
        Object.keys(this.engine.Packs()).forEach(key => {
            //if (this.engine.checkSelectable(key)) {
                const pack: Pack | null = this.createPack(key, uiOptions) 
                if(pack && pack.packageObj.must == '0' && pack.packageObj.group == 'PACK'){
                    packages.push(pack)
                }
            //}
        })
        return packages
    }

    private createPack(id: string, uiOptions: UIOptItem[]): Pack | null {
        const packData = this.engine.Packs()[id]
        let standAlone: Standalone = {
            sets: [],
            options: []
        }
        let implicits: UIOptItem[] = []
        let otherOpt = this.flattenedOptions.map(o => this.toUIOptItem(o, [], '', false, OPTION_TYPE_ENUM.OPTION, [])!).filter(x => x);

        let packObj = uiOptions.find(o => o.id === id)
        if(packObj){
            if (packData && packData.implicits) { 
                packData.implicits.forEach(implicit => {
                    const optObj = uiOptions.find(o => o.id === implicit.id)
                        if(optObj){
                            implicits.push(optObj);
                        }else{
                            const optObj = otherOpt.find(o => o.id === implicit.id)
                            if(optObj){
                                implicits.push(optObj);
                        }
                    }
                })
            }
            if (packData && packData.standalone) {
                packData.standalone.options.forEach(standAloneOption => {
                    const optObj = uiOptions.find(o => o.id === standAloneOption.id)
                    if(optObj){
                        standAlone.options.push(optObj);
                    }else{
                        const optObj = otherOpt.find(o => o.id === standAloneOption.id)
                        if(optObj){
                            standAlone.options.push(optObj);
                        }
                    }
                })
                packData.standalone.sets.forEach(set => {
                    let setData = {...set}
                    setData.list = []
                    set.list.forEach(listItem => {
                        const optObj = uiOptions.find(o => o.id === listItem.id) as UIOptItemInSet
                        if(optObj){
                            optObj.preset = listItem.preset === '1'
                            setData.list.push(optObj);
                        }
                        else{
                            const optObj = otherOpt.find(o => o.id === listItem.id) as UIOptItemInSet
                            if(optObj){
                                optObj.preset = listItem.preset === '1'
                                setData.list.push(optObj);
                            }
                        }
                    })
                    standAlone.sets.push(setData)
                })
            }
    
            return {
                packageId: id,
                packageObj: packObj,
                implicits: implicits,
                standAlone: standAlone,
                price: this.getPackagePrice(id),
            };
        } else {
            console.debug(`Invalid package id: ${id}`);
            return null;
        }
    }

    private groupObjects(flattenOpt = this.flattenedOptions) {
        let uiOptions: UIOptItem[] = []
        let hiddenOptions: UIOptItem[] = []
        let defaultOptions: UIOptItem[] = []
        this.menuItems.forEach((item) => {
            item.tabs.forEach((tab) => {
                if (tab.sections) {
                    tab.sections.forEach((section) => {
                        if (section.options) {
                            section.options.forEach((option) => {
                                if ('group' in option) {
                                        let optionsInGroup = flattenOpt.filter(
                                            (o) =>
                                                o.group === option.group &&
                                                o.hidden != '1' 
                                        )
                                        optionsInGroup.forEach(op => {
                                            if(this.colorsHaveMatch(op.colorList, option.colors)) {
                                                const uiOptItem  = this.toUIOptItem(
                                                    op,
                                                    option.colors,
                                                    section.id,
                                                    section.filter_fuoriserie == '1',
                                                    OPTION_TYPE_ENUM.OPTION,
                                                    uiOptions
                                                )
                                                if(uiOptItem) {
                                                    uiOptions.push(uiOptItem)
                                                } 

                                            }
                                        })
                                        let hiddenAndSelectedOptions = flattenOpt.filter(
                                            (o) =>
                                                o.group === option.group 
                                                && o.hidden == '1' 
                                                && o.selected == '1'
                                        )
                                        hiddenAndSelectedOptions.forEach(op => {
                                            if(this.colorsHaveMatch(op.colorList, option.colors)) {
                                                const uiOptItem  = this.toUIOptItem(
                                                    op,
                                                    option.colors,
                                                    section.id,
                                                    section.filter_fuoriserie == '1',
                                                    OPTION_TYPE_ENUM.OPTION,
                                                    uiOptions
                                                )
                                                if(uiOptItem) {
                                                    hiddenOptions.push(uiOptItem)
                                                } 
                                            }
                                        })
                                } if('checkbox' in option) {
                                    let op;
                                    if (op = flattenOpt.find(o => o.id === option.checkbox && o.hidden != '1')) {
                                        if(this.colorsHaveMatch(op.colorList, option.colors)){
                                            const uiOptItem  = this.toUIOptItem(
                                                op,
                                                option.colors,
                                                section.id,
                                                section.filter_fuoriserie == '1',
                                                OPTION_TYPE_ENUM.CHECKBOX,
                                                uiOptions
                                            )
                                            if(uiOptItem) {
                                                uiOptions.push(uiOptItem)
                                            } 
                                        }
                                    }
                                } else {
                                    let op;
                                    if (op = flattenOpt.find(o => o.id === option['code'] && o.hidden != '1')) {
                                        if(this.colorsHaveMatch(op.colorList, option['colors'])){
                                            const uiOptItem  = this.toUIOptItem(
                                                op,
                                                option['colors'],
                                                section.id,
                                                section.filter_fuoriserie == '1',
                                                OPTION_TYPE_ENUM.OPTION,
                                                uiOptions
                                            )
                                            if(uiOptItem) {
                                                uiOptions.push(uiOptItem)
                                            } 
                                        }
                                    }
                                }
                            });
                        }
                        //TODO create a different handling logic for variants
                        if ('variants' in section) {
                            if ('radio' in section['variants']) {
                                section['variants']['radio'].forEach(
                                    (option) => {
                                        let op;
                                        if (
                                            (op = flattenOpt.find(
                                                (o) =>
                                                    o.id === option.code &&
                                                    o.hidden != '1' 
                                            ))
                                        ) {
                                            const uiOptItem  = this.toUIOptItem(
                                                op,
                                                [],
                                                section.id,
                                                section.filter_fuoriserie == '1',
                                                OPTION_TYPE_ENUM.RADIO,
                                                uiOptions
                                            )
                                            if(uiOptItem) {
                                                uiOptions.push(uiOptItem)
                                            } 
                                        }
                                    }
                                );
                            }
                        }
                    });
                }
            });
        });
        const visibleAndSelectedOpts = uiOptions.filter(o => o.status.selected).map(o => o.id)
        defaultOptions = flattenOpt.filter(o => 
                (!visibleAndSelectedOpts.includes(o.id) && !hiddenOptions.map(h => h.id).includes(o.id)) 
                && o.status.selected == true
            ).map(o => this.toUIOptItem(o, [], '', false, OPTION_TYPE_ENUM.OPTION, [])!).filter(x => x);
        this.getConfigForScene(uiOptions.concat(hiddenOptions).concat(defaultOptions))
        this.store.dispatch(
            MODEL_OPTIONS_PARSED({
                packages: this.getPackages(uiOptions),
                options: uiOptions,
                hiddenOptions: hiddenOptions,
                optionsForAnalytics: this.getConfigForAnalytics(uiOptions.concat(hiddenOptions)),
                defaultOptions
            })
        );
    }

    private getConfigForAnalytics(uiOptions: UIOptItem[]): string {
        let config = uiOptions
            .filter((o) => o.status.selected)
            .map((o) => this.createSerializedOptionForAnalytics(o));
        let uniqueConfig = [...new Set(config)]
        return uniqueConfig.join(';')
    }

    private createSerializedOptionForAnalytics(o: UIOptItem): any {
        let colorSuffix = '';
        if (o.status.colorSelected) {
            colorSuffix = '_' + o.status.colorSelected;
        }

        var serializedOpt = o.group + '/' + o.id + colorSuffix;
        if (o.group.indexOf('ACC_') == 0) {
            serializedOpt += '#' + o.quantity;
        }
        return serializedOpt;
    }

    private colorsHaveMatch(carGridColors: ColorItem[], interfaceColors: string[]): boolean {
        if(interfaceColors && interfaceColors.length > 0){
            return carGridColors.filter((c) => interfaceColors.includes(c.colIdent)).length > 0
        }
        return true
    }

    private toUIOptItem(
        opt: Opt,
        colors: string[],
        section: string,
        sectionIsFuoriserie: boolean,
        type: string,
        uiOptions: UIOptItem[] 
    ): UIOptItem | undefined {
        let optColorsInOtherSections: string[] = []
        uiOptions.filter(o => o.id === opt.id)
                 .forEach(item => {
                    optColorsInOtherSections = optColorsInOtherSections.concat(item.colorList.filter(c => c.fuoriserie == sectionIsFuoriserie).map(c => c.colIdent))
                 })
        let optIsFuoriserie
        let optColors: ColorItem[] = []
        if(opt.colorList && opt.colorList.length > 0) {
            if(colors && colors.length > 0){
                if(sectionIsFuoriserie) {
                    optColors = opt.colorList.filter(c => colors.includes(c.colIdent) && !optColorsInOtherSections.includes(c.colIdent) && c.fuoriserie)
                    if(optColors.length > 0){
                        optIsFuoriserie = true
                        return {
                            group: opt.group,
                            layerGroup: opt.layerGroup,
                            section: section,
                            id: opt.id,
                            quantity: opt.quantity,
                            colorList: optColors,
                            price: opt.price,
                            type: type,
                            status: opt.status,
                            fuoriserie: optIsFuoriserie,
                            highlight: opt.highlight,
                            onlyImplicit: opt.onlyImplicit === '1',
                            must: opt.must
                        };
                    }
                } else {
                    optColors = opt.colorList.filter(c => colors.includes(c.colIdent) && !optColorsInOtherSections.includes(c.colIdent) && !c.fuoriserie)
                    if(optColors.length > 0){
                        optIsFuoriserie = false
                        return {
                            group: opt.group,
                            layerGroup: opt.layerGroup,
                            section: section,
                            id: opt.id,
                            quantity: opt.quantity,
                            colorList: optColors,
                            price: opt.price,
                            type: type,
                            status: opt.status,
                            fuoriserie: optIsFuoriserie,
                            highlight: opt.highlight,
                            onlyImplicit: opt.onlyImplicit === '1',
                            must: opt.must
                        };
                    }
                }
            } else {
                if(sectionIsFuoriserie){
                    optColors = opt.colorList.filter(c => !optColorsInOtherSections.includes(c.colIdent) && c.fuoriserie)
                    if(optColors.length > 0){ 
                        optIsFuoriserie = true
                        return {
                            group: opt.group,
                            layerGroup: opt.layerGroup,
                            section: section,
                            id: opt.id,
                            quantity: opt.quantity,
                            colorList: optColors,
                            price: opt.price,
                            type: type,
                            status: opt.status,
                            fuoriserie: optIsFuoriserie,
                            highlight: opt.highlight,
                            onlyImplicit: opt.onlyImplicit === '1',
                            must: opt.must
                        };
                    }
                } else {
                    optColors = opt.colorList.filter(c => !optColorsInOtherSections.includes(c.colIdent) && !c.fuoriserie)
                    if(optColors.length > 0){ 
                        optIsFuoriserie = false
                        return {
                            group: opt.group,
                            layerGroup: opt.layerGroup,
                            section: section,
                            id: opt.id,
                            quantity: opt.quantity,
                            colorList: optColors,
                            price: opt.price,
                            type: type,
                            status: opt.status,
                            fuoriserie: optIsFuoriserie,
                            highlight: opt.highlight,
                            onlyImplicit: opt.onlyImplicit === '1',
                            must: opt.must
                        };
                    }
                }
            }
        } else {
            optIsFuoriserie = opt.fuoriserie
            return {
                group: opt.group,
                layerGroup: opt.layerGroup,
                section: section,
                id: opt.id,
                quantity: opt.quantity,
                colorList: optColors,
                price: opt.price,
                type: type,
                status: opt.status,
                fuoriserie: optIsFuoriserie,
                highlight: opt.highlight,
                onlyImplicit: opt.onlyImplicit === '1',
                must: opt.must
            };
        }
        return undefined;
    }


    private updateOptsState() {
        this.state.opts = this.engine.Opts().DB().get();
        this.flattenOptions(this.state.opts);
        this.ngZone.run(() => {
            this.groupObjects();
        });
    }
}

export class CurrentEngineState {
    opts: Array<OptItem>;
    renderingStatus: any;
    totalPrice: string;
    taxes: number;
    transaction: boolean;
    packsPrices: any;
    selectedPack: string;
}

export class OptItem {
    group: string;
    layerGroup: string;
    subGroup: string;
    allowMultiple: string;
    base: string;
    must: string;
    hidden: string;
    onlyImplicit: string;
    selected: string;
    price: string;
    priority: number;
    colorList: any;
    id: string;
    originallySelected: string;
    //_originalColorList: [];
    _selectedColor: string;
    preset: boolean;
    quantity: number;
    pack: string;
    mandatoryGroup: boolean;
    status: IOptStatus
}
