<div id="{{menuLabel}}" class="mxe__exterior-config-panel accordion-{{menuLabel}} active">
    <!--HEADER-->
    <div id="{{menuLabel}}_header" class="mxe__optionals--section-header">
        <div class="header-title" (click)="toggleAccordion(menuLabel,0,null, $event)"
        [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + menuLabel.toLocaleLowerCase()+ [accordionOpened ? ':close' : ':open']">
            <span class="header-text">
                {{getLabel(menuLabel).toLocaleLowerCase()}}
            </span>
            <div class="header-icons">
                <span class="toggle-arrow"></span>
            </div>
        </div>
        <span class="selected-options-infos" *ngIf="totalOptSelected > 0" [innerHtml]="formatSelectionDetails()"></span>
     </div>
    <!--/HEADER-->
    <!--CONTENT-->
    <div id="{{menuLabel}}_content" class="mxe__optionals--section-content" [@openCloseAcc]="currentState[0]">
        <div class="mxe__optional-config-wrapper">
            <ng-container *ngFor="let tab of visibleItems; let j = index">
                <div class="mxe__opt-wrapper">
                    <ng-container *ngFor="let section of tab.sections">
                        <div class="mxe__exterior--accordion accordion-{{tab.id}} inner-header-cont active"
                            *ngIf="sectionIsVisible(section.id, section.filter_fuoriserie)">
                            <div id="{{tab.id}}_header"
                                class="mxe__optionals--section-header sub-header"
                                [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + section.id.toLocaleLowerCase() + [accordionOpened ? ':close' : ':open']"
                                >
                                <div class="header-title" (click)="toggleAccordion(tab.id,null,j+1, $event)">
                                    <span class="header-text">
                                        {{getLabel(tab.id)}}
                                    </span>
                                    <div class="header-icons">
                                        <span class="toggle-arrow minor"></span>
                                    </div>
                                </div>
                                <span class="selected-options-infos"
                                [innerHtml]="getSelectedOptionDetails(section.id)"></span>
                                
                            </div>
                            <div id="{{tab.id}}_content" class="mxe__optionals--section-content sub-content" [@openCloseAcc]="currentState[j+1]">
                                <div class="mxe__optional-config-panel">
                                <ng-container *ngFor="let opt of getOptionsBySection(section.id, section.filter_fuoriserie);let i = index">
                                    <div class="optional"
                                        *ngIf="getLabel(opt.id,'opt_name')"
                                        id="{{opt.section+'_'+opt.id}}">
                                        <div class="mxe__optional-image">
                                            <button class="btn-opt-selection" [class.active]="opt.status.selected"
                                                [disabled]="!opt.status.selectable">
                                                <span class="mxe__image-wrapper"
                                                    (click)="changeConfiguration(opt.id, opt.status.selected)"
                                                    [ngClass]="{'not-selectable': !opt.status.selectable}"
                                                    [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, opt.id, '', opt.status.selected ? 'remove-image' : 'add-image')">
                                                    <img [src]="getImagePath(opt.group, opt.id)" />
                                                </span>
                                            </button>
                                        </div>
                                        <div class="mxe__optional-details"
                                            [ngClass]="{'not-selectable': !opt.status.selectable}">
                                            <div class="opt_title">{{ getLabel(opt.id,
                                                uiCommonService.LABEL_TYPE_ENUM.NAME) }}</div>
                                            <div class="opt_infos">
                                                <div class="opt_price" *ngIf="showPrices">{{
                                                    this.formatPrice(opt.price)}}</div>
                                            </div>
                                            <span class="in-package" *ngIf="opt.status.selectedInPack">
                                                {{getLabel('OPT_INCLUDED_IN_PACK')}}
                                            </span>
                                            <div class="btn-actions">
                                                <button *ngIf="opt.status.labelKey != 'OPT_INCLUDED_AS_MUST'" href="#" class="btn btn-accent"
                                                    (click)="changeConfiguration(opt.id, opt.status.selected)"
                                                    [class.opt-selected]="opt.status.selected"
                                                    [disabled]="!opt.status.selectable">
                                                    <span *ngIf="!opt.status.selected"
                                                        [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, opt.id, '', 'add')">
                                                        {{getLabel('ADD_OPT')}}
                                                    </span>
                                                    <span *ngIf="opt.status.selected"
                                                        [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, opt.id, '', 'remove')">
                                                        {{getLabel('REMOVE_OPT')}}
                                                    </span>
                                                </button>
                                                <span class="included" *ngIf="opt.status.labelKey === 'OPT_INCLUDED_AS_MUST'">{{getLabel('OPT_INCLUDED_AS_MUST')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <div class="section-disclaimer">{{ getLabel('OPTIONALS_COMPOSITION_ADJUSTEMENT_DESCR') }}</div>
        </div>
    </div>
    <!--/CONTENT-->
</div>

<mxe-video-modal-with-descriptions *ngIf="isModalVideoOpen" [videoSource]="videoSelected"
[videoTitle]="videoTitleSelected" [videoDescription]="videoDescriptionSelected"
(valueChange)='videoModalStatusController($event)'></mxe-video-modal-with-descriptions>