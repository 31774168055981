
import { createAction, props } from '@ngrx/store';

export const UPDATE_TOTAL_PRICE = createAction(
    'UPDATE_TOTAL_PRICE',
    props<{ price: number }>()
)

export const UPDATE_TAXES = createAction(
    'UPDATE_TAXES',
    props<{ taxes: number }>()
)
