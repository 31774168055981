import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { CarModel } from 'src/app/core/models/get-models-service.model';

@Component({
  selector: 'app-car-model-data',
  templateUrl: './car-model-data.component.html',
  styleUrls: ['./car-model-data.component.scss']
})
export class CarModelDataComponent implements OnInit {

  modelCode: string;
  modalStatus = false;

  @Input() carModelData: CarModel;
  @Input() currentTrimFamily: string;
  @Input() priceStatus: boolean;
  @Input() price: string;
  @Input() showCinematic : boolean;
  @Input() idleTimeIsExpired : boolean;
  @Input() ephemeralDeviceID : string;
  @Input() isMainScreen: boolean;
  @Input() screenCastActive : boolean;
  _splittedCommercialName : string[] = [];
  @Input() set splittedCommercialName (value) {
    this._splittedCommercialName = value;
  }


  @Output() valueChange = new EventEmitter();

  

  constructor(
    private autobahn: AutobahnClient
  ) { }



  ngOnInit(): void {
    this.modelCode = this.carModelData.modelCode
    this.splitCommercialName()
  }

  private splitCommercialName(){
    this.carModelData.commercialName !== this.currentTrimFamily ? this._splittedCommercialName = this.carModelData.commercialName.trim().split(/\s+/) : this._splittedCommercialName[0] = this.carModelData.commercialName
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setSplittedCommercialName(this.ephemeralDeviceID, this._splittedCommercialName)
    }
  }

  openTrimModal(){
    this.modalStatus = true;
    this.valueChange.emit(this.modalStatus);
  }
}
