import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss']
})
export class FullscreenLoaderComponent implements OnInit {
  @Output() loadingCompleted = new EventEmitter()

  @Input() code: string;
  @Input() isMainScreen : boolean
  @Input() screenCastActive
  @Input() ephemeralDeviceID: string

  imagePath: string = '';
  progress: number = 0;
  loadingDescription : string = ''
  loadingDescriptionState : boolean;  

  
  constructor(
    private chg: ChangeDetectorRef,
    private uiCommonService: UiCommonService,
    private autobahnClient: AutobahnClient,
  ) { }

  ngOnInit(): void {
    this.createImagePath();
    this.chg.detectChanges();
  }

  ngOnChanges(changes : SimpleChanges){
    if(!this.isMainScreen && changes['isWrapper'] && changes['isWrapper'].firstChange && changes['isWrapper'].currentValue == undefined ){
       this.autobahnClient.setIsWrapperFromLoader(this.ephemeralDeviceID,true);
    }

  }

  createImagePath(){

    const basePath = 'https://configurator-test.maserati.com/ccbe/public/api/photocopier';
    const tmpBasePath = '../assets/loader-cars/'
    const country = 104;

    //this.imagePath = basePath+'?country='+country+'&modelId='+this.code+'&view=mainExtRear&scaleX=1920&cropY=1080'

    this.imagePath = tmpBasePath+this.code+'.png';
    return this.imagePath

  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  progressBarIncrement(value: number){

      if(this.progress <100){
        this.progress = this.progress + value
          this.chg.detectChanges();
      }else{
        this.loadingCompleted.emit()
      }
    
  }
}
