import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, retryWhen, throwError, timer } from 'rxjs';
import { Config, Configuration, Product, Resolution } from '../models/render-pipeline.model';


@Injectable({
  providedIn: 'root'
})
export class RenderPipelineService {

  constructor(private httpClient: HttpClient) { 

  }

  baseUrl: string = 'https://cyp-mxe-demo.mxe-backend.com/v1'
  apiKey: string = 'b7d08c40-75d9-4aa0-893b-95c0ad1e8df1'
  requestOptions = {headers: new HttpHeaders().set('x-api-key',this.apiKey).set('accept','application/json')}
  requestOptionsPollImg = {headers: new HttpHeaders().set('x-api-key',this.apiKey).set('accept','image/jpeg'), responseType: 'blob' as 'json'}
  
  

  getProducts(productId): Observable<any>{
     let productUrl=`/products/${productId}`
      return this.httpClient.get<any>(this.baseUrl + productUrl,this.requestOptions).pipe(
       catchError(this.handleError)
     )
  }

  createImgs(body: Product[]): Observable<any>{
    let imgUrl = '/media/images'
    let mockBody = [{"product_id":"car_GT","tracking_id":"TestScene1","background_id":"NotSoDaringBlue","perspective_id":"cam_GT_ext_34_front","configurations":[{"configuration":{"chosenMarket":"Initial","chosenOptions":["EXT_94086127","Q2FN","Q2VW","INT_94086219","Q3YH","QE02","Q0FJ","Q88P","Q1RW","Q2FK","QE03","QDCB","Q4YG","QLCU","Q7EQ","Q452","Q6BQ","Q407","Q140","Q4TI","Q4B0","Q220","Q2DC","Q2E2","Q2A1","Q2FU","QJVG","Q508","Q316","QJRC","Q40J","Q499","Q8FA","Q602","Q9FX","Q55B","QRFX","Q18E","Q1GL","Q2DB","Q2DM","Q547","Q689","Q7N3","Q8EW","QGXP","QLSB","QRDG","QRTM","Q7XV","QCEU","QJGB","QJY2","Q416","Q5DD","Q5C3","QGX9","Q52A","Q365","Q7WU","Q02V","Q631","Q58E","QXGR","Q2FE","Q009","Q2FD","Q2FG","QCD7","Q55R","QXXE"],"chosenProductVersion":"Initial_None","savedStateName":"__default__"},"entityName":"car_GT","type":"MVEntityConfigurationCore"}],"resolution":{"width":1920,"height":1080},"file_type":"jpeg","output_quality":"High","target_queue":"fast","render_quality":"high","super_format":false,"frame_delay":10}]
    
    return this.httpClient.post<string>(this.baseUrl + imgUrl, body,this.requestOptions).pipe(
      catchError(this.handleError)
    )
  }

  notifier(err : Error, retryDelay: number, jobUrl:string) : Observable<unknown>{
    console.log(`[Retry attempt]: ${retryDelay} for JOB ${jobUrl} `)
    const isThereError = err.message.includes('404')
    return isThereError? timer(Math.min(retryDelay * 2000, 30000)) : throwError( () => err)

  }

  pollImages(url: string){
    let retryDelay = 0;
    let pollimgUrl= 'media/images/poll'
    return this.httpClient.get<any>(`${this.baseUrl}/${pollimgUrl}?job_id=${url}`, this.requestOptionsPollImg).pipe(
      retry({ delay: (error) => this.notifier(error, retryDelay++, url)})
    )
  }

  renderImgsMapper(product: any, config: string[], typeOfCam: string): Product[]{
    let body: Product[] = []
    let perspective_id;

    if(typeOfCam == '_ext_'){
      perspective_id = product.scenedata.still_perspectives[0]
    }
    else{
      perspective_id = product.scenedata.still_perspectives.find( s => s.includes(typeOfCam))
    }
    console.debug("Perspective_id",perspective_id)
    
    let resolution : Resolution = {
      width: 1920,
      height: 1080
    }
    let configItem : Config= {
      chosenMarket:'Initial',
      chosenOptions: config,
      chosenProductVersion: 'Initial_None',
      savedStateName: '__default__'
    }
    let configurations : Configuration[] = [{
      configuration: configItem,
      entityName: product.id,
      type: 'MVEntityConfigurationCore'
    }]
    let productItem : Product = {
      product_id : product.id,
      tracking_id: 'TestScene1',
      background_id: 'NotSoDaringBlue',
      perspective_id: perspective_id,
      configurations: configurations,
      resolution: resolution,
      file_type: 'jpeg',
      output_quality: 'High',
      target_queue: 'fast',
      render_quality: 'high',
      super_format: false,
      frame_delay: 10
    }
    body.push(productItem)
    return body ?? []

  }




  private handleError(error: any){
    return throwError( () => new Error(error.error.message))
  }
  
}
