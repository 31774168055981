import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { EngineActions } from '../actions/engine/engine-exported-actions';

@Injectable()
export class EngineEffects {

    constructor(private actions$: Actions) { }

    EngineUpdateTotalPrice$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(EngineActions.UPDATE_TOTAL_PRICE),
                tap(actions => localStorage.setItem('price', JSON.stringify(actions.price)))
            ),
        { dispatch: false }
    );
    
    EngineUpdateTaxes$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(EngineActions.UPDATE_TAXES),
                tap(actions => localStorage.setItem('taxes', JSON.stringify(actions.taxes)))
            ),
        { dispatch: false }
    );
}