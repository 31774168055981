<div class="mxe__client-name--wrapper">
  <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
  <button type="button" class="btn burger-menu" data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
    <span class="bar1"></span>
    <span class="bar2"></span>
    <span class="bar3"></span>
  </button>
  <button *ngIf="isMainScreen" class="mxe__close-action" data-linktracking ="side-nav:close"  (click)="returnToHome()">
    {{getLabel('MXE_BACK_TO_HOME', 'Back to home')}}
  </button>
  <mxe-pairing-toggle
    [isMainScreen]="isMainScreen"
    [priceStatus]="priceStatus"
    [pairingStep]="PairingStep.USER"
    [labels]="labels"
    (screenCastActiveEmitter)="onScreenCastActiveEmitter($event)"
    (mirroringStoppedByUserEmitter)="mirroringStoppedByUser = $event">
  </mxe-pairing-toggle>
  <div class="mxe__client-name--container">
    <form class="mxe__client-name--form" [formGroup]="clientNameForm" (ngSubmit)="sendConfig()">
      <div class="igroup">
      <input autocomplete="off" class="mxe__client-name--input-field" type="text" id="name" formControlName="clientName"
        (keyup)="setCustomerName($event)"
        required="required"
        [ngClass]="{'empty-field': valNameEmpty}"
        (blur)="getValName($event)"/>
        <label class="mxe__client-name--input-label" for="pwd">{{getLabel('MXE_CLIENT_NAME', 'client name')}}</label>

      </div>
      <div class="igroup">
        <input autocomplete="off" id="client-name" class="mxe__client-name--input-field" type="text" id="surname"
          formControlName="clientSurname"  id="client-surname" (keyup)="setCustomerSurname($event)" required="required"
          [ngClass]="{'empty-field': valSurnameEmpty}"
          (blur)="getValSurname($event)"/>
          <label class="mxe__client-name--input-label" for="pwd">{{getLabel('MXE_CLIENT_SURNAME', 'client surname')}}</label>
      </div>
      <!-- <div class="error-label" *ngIf="errorLabel!=''"> {{errorLabel}}</div>-->
      <br />
      <button type="submit" [ngClass]="
          clientNameForm.invalid
          ? 'btn btn-accent btn-fullwidth btn-disabled'
          : 'btn btn-accent btn-fullwidth'
          "
          data-linktracking ="cta:configurator-client-name:start">
          {{getLabel('MXE_START_NEW_CONFIGURATION_BUTTON', 'start')}}
      </button>
      <button class="skip-btn" (click)="skipDataInsert()" type="button" data-linktracking ="cta:configurator-client-name:skip">
        {{getLabel('MXE_SKIP_BUTTON', 'skip')}}
        <img src="../../../assets/icons/mxe__skip-icon.svg" alt=">>" />
      </button>
    </form>
  </div>
</div>
<app-home-menu
(homeMenuStatusChange)="homeMenuStatusController($event)"
*ngIf="homeMenuIsOpen"></app-home-menu>