import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { CTAS_LOADED, LABELS_LOADED } from '../actions/country-prop-actions';

@Injectable()
export class CountryPropEffects {

    constructor(private actions$: Actions) { }
    CTASState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(CTAS_LOADED),
                tap(actions => localStorage.setItem('ctas', JSON.stringify(actions.ctas)))
            ),
        { dispatch: false }
    );

    LabelsState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(LABELS_LOADED),
                tap(actions => localStorage.setItem('labels', JSON.stringify(actions.labels)))
            ),
        { dispatch: false }
    );
}