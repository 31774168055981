<div class="switch-button" [ngClass]="{'screenCastActive': screenCastActive}" *ngIf="isMainScreen">
    <div class="maserati__car-configurator--view-controls maserati__car-configurator--icon maserati__car-configurator--action-screen-cast">
    </div>
    <label class="switch">
        <input type="checkbox" />
        <span class="slider round" (click)="onPairingToggle()"></span>
    </label>
</div>

<app-presenter-screen-modal
    *ngIf="showScreenCastModal"
    [packages]="packages" 
    [priceStatus]="priceStatus"
    [viewUi] = "viewUi"
    [carModel]="carModel" 
    [menuItems]="menuItems" 
    [modelOptions]="modelOptions" 
    [labels]="labels"
    [closedAccordions]="closedAccordions"
    [pairingStep]="pairingStep"
    [scrollPosition]="scrollPosition"
    [selectedFamily]="selectedFamily"
    [activeSlideIndex]="activeSlideIndex"
    [currentTrimCode]="currentTrimCode"
    [accordionExp] = "accordionExp"
    (modalValue)="screenCastModalToggle($event)"
    [temporaryConfigItems]="temporaryConfigItems"
    [isRestoreConfig]="isRestoreConfig"
    [isLoadConfig]="isLoadConfig"
    [dealerId] = "dealerId"
    (ephemeralDeviceIDEvent)="ephemeralDeviceID = $event">
</app-presenter-screen-modal>

<app-stop-screen-cast-modal *ngIf="showScreenCastAlert" 
    (modalClosingEvent)="showScreenCastAlert = false"
    (modalConfirmedEvent)="onStopMirroring()">  
</app-stop-screen-cast-modal>