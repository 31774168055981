<div class="mxe__model--selector-container mxe__cms-firspage">
   <button type="button" class="btn burger-menu" data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
      <span class="bar1"></span>
      <span class="bar2"></span>
      <span class="bar3"></span>
    </button>
   <img class="mxe__fixed-top-logo" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati">
   <button *ngIf="isMainScreen" class="mxe__close-action" data-linktracking = "cta:choose-carline:close"  (click)="onCloseClick()">{{getLabel('MXE_BACK_TO_HOME', 'Back to home')}}</button>
   <div class="swiper-button-prev" >
      {{familyNames[currentIndex-1]}}
   </div>
<div class="swiper-button-next"> 
      {{familyNames[currentIndex+1]}}
</div>
   <mxe-pairing-toggle
      [pairingStep]="PairingStepEnum.MODEL_SELECTOR"
      [isMainScreen]="isMainScreen"
      [priceStatus]="priceStatus"
      [labels]="labels"
      [activeSlideIndex]="swiper?.swiperRef?.activeIndex"
      (screenCastActiveEmitter)="onScreenCastActiveEmitter($event)"
      (mirroringStoppedByUserEmitter)="mirroringStoppedByUser = $event">
   </mxe-pairing-toggle>
   <div class="rectangle-blurred rectangle-left"></div>
   <div class="rectangle-blurred rectangle-right"></div>
   <div class="mxe__model--selector-carousel">
      <swiper [config]="config" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()" [pagination]="pagination">
         <ng-template *ngFor="let family of families" swiperSlide>
            <div class="mxe__model--selector-slide text-center">
               <div class="container-360-wrapper model-selector">
                  <!-- todo: workaround who remove console errors due to car images missing -->
                  <div class="cloudimage-360"
                     id="{{family.model.toLowerCase()}}-model"
                     (touchmove) = getActualIndex($event)
                     [attr.data-folder]="getFamilyImage360BasePath(family.model)"
                     [attr.data-filename-x]="'{index}.png'"
                     data-index-zero-base=2
                     data-amount-x="36"
                     [attr.data-image-list-x]="getDataImageList(family.familyCommercialCode)"
                     data-hide-360-logo=true
                     [attr.data-bottom-circle]="showDataBottomCircle(family.familyCommercialCode)"
                     data-bottom-circle-offset=25
                     data-hotspots=false>
                  </div>
               </div>
               <!--getDataImageList(family.familyCommercialCode)-->

               <div class="mxe__bottom-car-selector-wrapper">

                  <div class="mxe__bottom-car-selector-infos">
                     <div class="mxe__selector-family-name-price">
                        <h2>{{family.familyName}}</h2>
                        <span *ngIf="priceStatus">
                           <h4 *ngIf="lowestPriceByFamily[family.familyCommercialCode]>0">{{starting_price_label}} {{this.formatPrice(lowestPriceByFamily[family.familyCommercialCode],
                              family.familyCommercialCode)}}</h4>
                        </span>
                     </div>
                     <div class="selector-bottom-row">
                        <div class="selector-bottom-column">
                           <div class="mxe__model-basic-infos">
                              <div class="mxe__model-basic-info-row" *ngFor="let item of staticPercentages[family.familyCommercialCode]">
                                 <span>{{item.label}}</span>
                                 <div class="mxe__range">
                                    <span class="percentage" [ngStyle]="{width: item.value}"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="selector-bottom-column-double">
                           <div class="mxe__model-dimensions-info-grid">
                              <div class="mxe__model-dimensions-info-item"
                                 *ngFor="let item of infosByFamily[family.familyCommercialCode]">
                                 <span>{{item.label}}</span> <b>{{item.value}}</b>
                              </div>
                           </div>
                        </div>
                        
                        <div class="selector-bottom-column text-right">
                           <button type="button" class="mxe__button-select-family"
                           data-linktracking ="cta:choose-carline:select"
                           (click)="showCarouselTrims(family)">{{selectModel}}</button>
                        </div>
                        
                     </div>
                     <div class="footer-disclaimer">
                        {{disclaimerFooter}}
                        <span class="disclaimer">
                           <button class="disclaimer-content" (click)="openDisclaimerModal()">
                              {{disclaimer_cta}}
                          </button>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </ng-template>
      </swiper>
   </div>
</div>

<mxe-disclaimer-modal *ngIf="isModalDisclaimerOpen"  (valueChange)='disclaimerModalStatusController($event)'>
</mxe-disclaimer-modal>
<app-home-menu (homeMenuStatusChange)="homeMenuStatusController($event)" *ngIf="homeMenuIsOpen">
</app-home-menu>


