import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetPrintSendRequest } from 'src/app/core/models/print-send.model';
import { CarModel } from 'src/app/core/models/get-models-service.model';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { Options } from 'ngx-qrcode-styling';
import { CTA_KEYS_ENUM } from 'src/app/core/models/common.model';
import { ICTA } from 'src/app/core/models/country-prop-service.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit {
  @Output() closeThanksPage = new EventEmitter()
  @Input() configId: string;
  @Input() countryCode: number
  @Input() languageId: string
  @Input() currentCarModel: CarModel
  @Input() sendPdfBasePath: string;
  @Input() ctas: ICTA[];
  @Input() isMainScreen: boolean;
  @Input() dealerCode: string;

  isLoading = false

  public qrCodeConfig: Options = {
    width: 200,
    height: 200,
    data: "",
    image: "../../../assets/icons/logo-maserati-icon-black.svg",
    margin: 1,
    dotsOptions: {
      color: "#000",
      type: "square"
    },
    backgroundOptions: {
      color: "#FFFFFF",
    },
    imageOptions: {
      hideBackgroundDots: true,
      crossOrigin: "anonymous",
      margin: 0
    }
  };

  constructor(
    private maseratiService: MaseratiService,
    private chg: ChangeDetectorRef,
    private uiCommonService: UiCommonService
  ) { }

  ngOnInit(): void {
    if (!this.isMainScreen) {
      this.qrCodeConfig.width = 300
      this.qrCodeConfig.height = 300
    }
    const sendPdfCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.PDF)
    if (sendPdfCTA && sendPdfCTA.link != '') {
      this.sendPdfBasePath = sendPdfCTA.link
      console.debug(sendPdfCTA.link)
      console.debug(this.sendPdfBasePath)
      if (this.sendPdfBasePath) {
        this.qrCodeConfig.data = this.buildSendPdfLink()
      }
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  closeThankYouPage() {
    this.closeThanksPage.emit()
  }

  
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

  buildSendPdfLink() {
    const link = `${this.sendPdfBasePath}?type=pdf&configId=${this.configId}&lang=${this.languageId}&dealercode=${this.dealerCode}`
    console.debug('PDF ', link)
    return link
  }

  printSend() {
    const payload: GetPrintSendRequest = {
      country: `${this.countryCode}`,
      lang: this.languageId,
      configId: this.configId,
      type: 'pdf',
      model: this.currentCarModel.modelCode,
    };
    let pdfWindowReference = window.open('about:blank', "_blank")
    let fileUrlString: string;
    this.maseratiService.printSend(this.sendPdfBasePath, payload).subscribe({
      next: (value: Blob) => {
        const blob = new Blob([value], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        console.debug('Downloading the pdf from: ', fileURL);
        fileUrlString = fileURL
      },
      complete: () => {
        this.isLoading = false
        pdfWindowReference!.location = fileUrlString
        this.chg.detectChanges()
        console.info('[MASERATI SERVICE] printSend call completed');
      },
      error: (e) =>{
        if(!environment.production){
          console.error('An error occurred while creating the pdf file...', e)
        }
        else{
          console.error('An error occurred while creating the pdf file...')
        }
      }
    });
  }
}
