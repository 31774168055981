<div class="mxe__camera-carousel-border">
    <div class="mxe__camera-carousel-container">
        <div class="mxe__camera-carousel-images">
            <button
                type="button"
                class="camera-thumb"
                *ngFor="let environment of environments; let i=index"
                [ngClass]="{'active': currentIndex === i}"
                (click)="selectEnvironment(i)"
                >
                <div class="camera-background"
                [ngStyle]="{'background-image': 'url(' + getCameraImage(i) + ')'}"
                >
                <!-- style="background-image:url('{{environment}}')" -->

                </div>
        </button>

        </div>
    </div>
</div>