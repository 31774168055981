<div class="mxe__afk-modal-wrapper">
  <div class="modal-backdrop"></div>
  <div class="mxe__afk-modal-content">
      <div class="modal-padd">
          <h2>{{config.data.header}} <span *ngIf="config.data.status">{{config.data.status}}</span> </h2>
          <div class="modal-disclaim" *ngIf="config.data.message">
            {{config.data.message}}
          </div>
          <div class="modal-disclaim" *ngIf="config.data.error">
            {{config.data.error}}
          </div>
          <div class="modal-buttons absolute-buttons">
              <button class="btn btn-accent btn-dark"
              (click)="config.data.action == 'redirect' ? onHomepage() : onClose()"
              >
              {{getLabelWithDefaultValue('BTN_CONTINUE','CONTINUE')}}
              </button>
          </div>
      </div>
  </div>
</div>