import { General, CarGridResponse, StandardEquipments } from '../../models/car-grid.model'

export const carGridInitialState : CarGridState = {
    loaded: false,
    carGrid: {
        general: <General>{},
        options: {},
        defaults: [],
        packs: {},
        rules: [],
        colorRules: [],
        taxes: [],
        standardEquipments: <StandardEquipments>{},
        lines: []
    }
}

export interface CarGridState{
    loaded: boolean
    carGrid: CarGridResponse
}