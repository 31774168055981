import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, fromEvent, map, repeat, Subscription, takeUntil } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { CAR_DETAILS_ENUM, PAIRING_STEP_ENUM, STEP_ENUM } from 'src/app/core/models/common.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { ModelActions } from 'src/app/core/store/actions/models/models-exported-actions';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import SwiperCore, { EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import { CarModel, Family, TechData } from '../../core/models/get-models-service.model';
import { FullscreenLoaderComponent } from '../fullscreen-loader/fullscreen-loader.component';
import { GetCountryPropRequest } from 'src/app/core/models/country-prop-service.model';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { CTAS_LOADED, LABELS_LOADED } from 'src/app/core/store/actions/country-prop-actions';
import { APP_SETTINGS_SET_APPLICATION_STEP } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);

@Component({
  selector: 'mxe-car-trim',
  templateUrl: './car-trim-selector.component.html',
  styleUrls: ['./car-trim-selector.component.scss'],
})
export class CarTrimSelectorComponent implements OnInit, AfterViewInit {
  //@ViewChild(SwiperComponent) swiper: SwiperComponent;
  @ViewChild(SwiperComponent, { static: false }) swiper: SwiperComponent;
  @Input() carcode: string;
  @Input() isMainScreen: boolean = true
  @Input() selectedFamily: Family
  @Input() ephemeralDeviceID: string
  @Input() showPrices: boolean
  @Input() currentTrimCode: string;
  @Input() languageId: string;
  @Input() countryCode: number;
  
  @Input() set swiperScrollDelta(data) {
    if(this.swiper  && data) {
      this.swipeTrimTopNavigationBar(data)
    }
  }
  
  
  @Input() set labels(labels: any) {
    this._labels = labels
    if(!this.isMainScreen && labels) {
      this.currentTrimCode = this.selectedFamily.models[0].modelCode
      this.subFamilyNames = this.getSubFamilyNames();
      
      this.currency = this.selectedFamily.models[0].currency
      this.priceMask = this.selectedFamily.models[0].priceMask
      this.uiCommonService.initializeLabels(labels)
      this.selectedFamily.models.forEach(trim => {
        if(this.uiCommonService.isPriceValid(`${trim.price}`)){
          this.startingPrices[trim.modelCode] = `${this.getLabel('LINE_STARTING_PRICE')} ${this.formatPrice(`${trim.price}`)}`
        }
      })
      this.chg.detectChanges()
    }
  }
  get labels(){
    return this._labels
  }

  modelCode: any;
  families$: Subscription;
  
  @Input() set activeSlideIndex(index) {
    if(this.swiper && index >= 0) {
      this.currentIndex = index
      this.swiper.swiperRef.activeIndex = index
      this.specificationsTechData(this.selectedFamily.models[index].techData)
      this.swiper.swiperRef.update()
    }
  }
  @Input() set activeSlideImageIndex (mi: { model:string, index: number }) {
    if(mi) {
      window.CI360.setActiveIndexByID(mi.model, mi.index)
    }
  }

  @Output() closeEmitter = new EventEmitter();
  @ViewChild(FullscreenLoaderComponent) progress: FullscreenLoaderComponent;

  dataIsChanged = false;
  currentIndex: number = 0;
  subFamilyNames: string[] = [];
  activeModelName: string;

  parsedModelName: string = '';
  engineLayout: string;
  engineSize: string;
  acceleration: string;
  maxSpeed: string;
  maxPower: string;
  traction: string;

  parsedTechData: TechData[];
  modalStatus: boolean;

  imagesPathByFamily = [];
  dataFileNamesByFamily = [];

  homeMenuIsOpen = false;

  PairingStepEnum = PAIRING_STEP_ENUM
  Step = STEP_ENUM
  screenCastActive: boolean = false
  startingPrices: string[] = [];


  families: Family[]=[];
  labels$: Subscription;
  starting_price_label: string;
  loadButton : boolean = false;  

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    speed: 1000,
    navigation: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span id="${this.subFamilyNames[index].split(" ").join("_").toUpperCase()}" class="${className} ${this.selectedFamily.model}" data-linktracking="sub-menu:choose-trim:${this.subFamilyNames[index]}"> ${this.subFamilyNames[index]}</span>`;
    },
  };

  private touchEvent$: Subscription;
  private swiperButtonNext: NodeListOf<Element>;
  private swiperButtonPrev: NodeListOf<Element>;
  private routeSubscription$: Subscription;
  private countryCode$: Subscription;
  private languageId$: Subscription;
  private currency: string 
  private priceMask: string
  private showPrices$: Subscription;
  private ephemeralDeviceID$: Subscription
  private _labels: any
  private ctasSub$: Subscription;
  private allCtas: {};
  private ctaLanguageId$: Subscription;
  private ctaLanguageId: string;
  private trimTopNavigationBarEvent$: Subscription;
  private activeImageIndexSubject = new BehaviorSubject<any>(null)
  activeImageIndex: number

  constructor(
    private chg: ChangeDetectorRef,
    private store: Store<MxeReducers>,
    private uiCommonService: UiCommonService,
    private zone: NgZone,
    private autobahnClient: AutobahnClient,
    private route: ActivatedRoute,
    private navigation: NavigationStateService,
    private maseratiService: MaseratiService
  ) { 
    if(this.isMainScreen) {
      this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.TRIM_SELECTOR}))
      this.activeImageIndexSubject.pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      ).subscribe(value => {
        this.activeImageIndex = value
        const trimViewId = `${this.selectedFamily.models[this.currentIndex].modelName.toLowerCase()}-trim`
        if(this.activeImageIndex && this.isMainScreen && this.screenCastActive) {
          this.autobahnClient.setModelSelectorImageUpdate(this.ephemeralDeviceID, trimViewId, this.activeImageIndex)
        }
      })
    }
   }


  ngOnInit(): void {
    if(this.isMainScreen) {
      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        res => this.ephemeralDeviceID = res
      )

      this.showPrices$ = this.store.select(s => s.appState.showPrices).subscribe(
        showPrices => this.showPrices = showPrices
      )

      this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
        countryCode => this.countryCode = +countryCode!
      )
  
      this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
        lang => this.languageId = lang
      )

      this.ctaLanguageId$ = this.store.select(s => s.appState.ctaLanguageId).subscribe(
        (ctaLanguageId) => {
          this.ctaLanguageId = ctaLanguageId
        }
      )

      this.routeSubscription$ = this.route.params.subscribe(params => {
        if (params['mc'] != undefined) {
          this.modelCode = params['mc'];
          this.families$ = this.store.select(s => s.modelsState.families).subscribe(
            fam => {
              this.families = fam
              this.selectedFamily = fam.find(f => f.model === this.modelCode)!
              if(this.selectedFamily){
                if(this.screenCastActive) {
                  //autobahn bind family
                  this.autobahnClient.setCarTrimFamilyCode(this.ephemeralDeviceID, this.selectedFamily)
                  this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0)
                }
    
                this.currentTrimCode = this.selectedFamily.models[0].modelCode               
                this.subFamilyNames = this.getSubFamilyNames();
                
                this.currency = this.selectedFamily.models[0].currency
                this.priceMask = this.selectedFamily.models[0].priceMask
                
                this.ctasSub$ = this.store.select(s => s.countryPropState.ctas).subscribe(
                  (ctas) => {
                    this.allCtas = ctas
                  }
                )

                this.labels$ = this.store.select(s => s.countryPropState.labels)
                .subscribe(
                  (labels) => {
                    this.labels = labels
                    this.fillMissingTrimLabels(this.selectedFamily.models)
                    this.uiCommonService.initializeLabels(labels)
                    if(this.screenCastActive && this.ephemeralDeviceID)
                      this.autobahnClient.setLabels(this.ephemeralDeviceID, this.labels)
                    this.selectedFamily.models.forEach(trim => {
                      if(this.uiCommonService.isPriceValid(`${trim.price}`)){
                        this.startingPrices[trim.modelCode] = `${this.getLabel('LINE_STARTING_PRICE')} ${this.formatPrice(`${trim.price}`)}`
                      }
                    })
                    this.chg.detectChanges()
                  }
                )
                this.chg.detectChanges();
                this.initAdobeTracker()
              }
            }
          )
        }
      });
    }
    //SERVE QUANDO PASSI A TRIM
    setInterval(() => {
      if(this.progress)
        this.progress.progressBarIncrement(20);
    }, 300);

    this.loadButton = true;
    setTimeout(() =>{
      this.loadButton = false;
    },8000)

  }
  getSubFamilyNames(): string[] {
    if(this.selectedFamily && this.selectedFamily.models) {
      const modelsGroupedByEngineGroup = this.uiCommonService.groupBy(this.selectedFamily.models, 'engineGroup');
      return this.selectedFamily.models.map(f => {
        let subFamilyName =  modelsGroupedByEngineGroup[f.engineGroup].length > 1 && modelsGroupedByEngineGroup[f.engineGroup].filter(u => u.commercialName == f.commercialName).length > 1 ?  this.formatSubFamilyName(`${f.commercialName} ${f.yearModel}`) : this.formatSubFamilyName(f.commercialName) 
        return subFamilyName;
      });
    } else return [];
  }

  ngAfterViewInit() {
    this.initDataTracking()
    this.slideSwiperEventHandler()
    this.setTrimTopNavigationBar()
  }

  ngOnDestroy() {
    if (this.touchEvent$) 
      this.touchEvent$.unsubscribe()
    if (this.families$) 
      this.families$.unsubscribe()
    if (this.routeSubscription$) 
      this.routeSubscription$.unsubscribe()
    if (this.countryCode$) 
      this.countryCode$.unsubscribe()
    if (this.languageId$) 
      this.languageId$.unsubscribe()
    if(this.showPrices$)
      this.showPrices$.unsubscribe()
    if(this.ephemeralDeviceID$)
      this.ephemeralDeviceID$?.unsubscribe()
    if(this.labels$)
      this.labels$.unsubscribe()
    if(this.ctasSub$)
      this.ctasSub$.unsubscribe()
    if(this.ctaLanguageId$)
      this.ctaLanguageId$.unsubscribe()
    if(this.trimTopNavigationBarEvent$)
      this.trimTopNavigationBarEvent$.unsubscribe()
    if(this.activeImageIndexSubject)
      this.activeImageIndexSubject.unsubscribe()
  }

  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

  onSwiper(swiper: any) {
    this.currentIndex = this.swiper.swiperRef.activeIndex;
    this.currentTrimCode = this.selectedFamily.models[this.currentIndex].modelCode
    if(this.isMainScreen && this.screenCastActive) {
      this.autobahnClient.setCurrentTrimCode(this.ephemeralDeviceID, this.currentTrimCode)
    }
    this.specificationsTechData(this.selectedFamily.models[this.currentIndex].techData)
    this.selectedFamily.models.forEach(
      (model) => {
        const trimViewId = `${model.modelName.toLowerCase()}-trim`
        window.CI360._viewers = window.CI360._viewers.filter(x => x.id != trimViewId)
        window.CI360.add(trimViewId)
      }
    )
    this.chg.detectChanges();
  }

  onSlideChange() {
    this.dataIsChanged = false
    this.currentIndex = this.swiper.swiperRef.activeIndex;
    this.currentTrimCode = this.selectedFamily.models[this.currentIndex].modelCode
    this.updatePaginationPosition();
    if(this.isMainScreen && this.screenCastActive) {
      this.autobahnClient.setCurrentTrimCode(this.ephemeralDeviceID, this.currentTrimCode)
    }
    this.specificationsTechData(this.selectedFamily.models[this.currentIndex].techData)
    
    this.activeModelName = this.subFamilyNames[this.currentIndex].toLowerCase();
    const trimViewId = `${this.selectedFamily.models[this.currentIndex].modelName.toLowerCase()}-trim`
    if(this.screenCastActive) {
      this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, this.swiper.swiperRef.activeIndex)
      this.activeImageIndexSubject.next(window.CI360.getActiveIndexByID(trimViewId) || 0)
    }
  }

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }

  swipeNext() {
    this.swiper.swiperRef.slideNext();
  }

  updateSelectedCarModel(carModel: CarModel) {
    this.zone.run(() => {
      this.store.dispatch(ModelActions.NEW_MODEL_SELECTED({ currentCarModel: carModel }))
      this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_MODEL_SELECTED({ modelCode: carModel.modelCode }))
      sessionStorage.setItem('trim_selected', carModel.modelCode)
    })
    if(this.screenCastActive && this.ephemeralDeviceID) {
      this.autobahnClient.setLabels(this.ephemeralDeviceID, this.labels).then(() => {
        this.autobahnClient.setConfiguratorNotLoaded(this.ephemeralDeviceID, carModel)
      })
    }
    this.navigation.navigate(['/mxe/detail', carModel.modelName])
  }

  specificationsTechData(data: TechData[]) {
    this.parsedTechData = this.createTechData(data);
  }

  closeTrimModal() {
    if(this.screenCastActive && this.ephemeralDeviceID){
      this.autobahnClient.setLabels(this.ephemeralDeviceID,this.labels)
      this.autobahnClient.setFamilies(this.ephemeralDeviceID, this.families)
      this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.MODEL_SELECTOR)
      this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0)
    }
    this.navigation.navigate(['/mxe/models'])
  }

  onScreenCastActiveEmitter(value: boolean){
    this.screenCastActive = value
  }

  private createTechData(techData: TechData[]): TechData[] {
    let keyFilter: string[] = [
      CAR_DETAILS_ENUM.ENGINE_SIZE,
      CAR_DETAILS_ENUM.MAX_SPEED,
      CAR_DETAILS_ENUM.ENGINE_LAYOUT,
      CAR_DETAILS_ENUM.MAX_POWER,
      CAR_DETAILS_ENUM.ACCELERATION,
      CAR_DETAILS_ENUM.TRACTION
    ];
    const techDataFilterdState = techData.filter((t) => keyFilter.includes(t.key));
    return techDataFilterdState;
  }

  private formatSubFamilyName(value: string): string {
    return value.trim()
  }

  getTrimImage360BasePath(trim: string): string {
    return this.uiCommonService.getTrimImage360BasePath(trim, this.countryCode)
  }

  showHomeMenu() {
    this.homeMenuIsOpen = true;
  }

  homeMenuStatusController(modalStatus) {
    this.homeMenuIsOpen = modalStatus;
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
      "pageInfo":
          { 
            "pageName": "car-trim-selector" 
          }
        }
      })
  }

  formatPrice(price: string) {
    let formattedPrice = '';
    if(this.currency && this.priceMask){
      formattedPrice = this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
    }
    return formattedPrice
  }
  
  
  getActualIndex(event: any){
    const trimViewId = `${this.selectedFamily.models[this.currentIndex].modelName.toLowerCase()}-trim`
    this.activeImageIndexSubject.next(window.CI360.getActiveIndexByID(trimViewId))
  }

  getStartingPrice(trimCode: string): string {
    return this.startingPrices[trimCode]
  }

  /**
   * shows 360 swiper if family is different than Ghibli and Levante
   */
  getDataImageList(familyCommercialCode: string): string {
    switch(familyCommercialCode.toLocaleLowerCase()) {
      case 'lv':
      case 'gh':
      case 'qp':
        return '["33.png"]'
      default:
        return '["33.png","34.png","35.png","36.png","01.png","02.png","03.png","04.png","05.png","06.png","07.png","08.png","09.png","10.png","11.png","12.png","13.png","14.png","15.png","16.png","17.png","18.png","19.png","20.png","21.png","22.png","23.png","24.png","25.png","26.png","27.png","28.png","29.png","30.png","31.png","32.png"]'
    }
  }

  /**
   * shows bottom circle if family is different than Ghibli and Levante
   */
  showDataBottomCircle(familyCommercialCode: string): boolean {
    if(familyCommercialCode.toLocaleLowerCase() === 'gh' || familyCommercialCode.toLocaleLowerCase() === 'lv' || familyCommercialCode.toLocaleLowerCase() === 'qp')
      return false
    return true
  }
  
  private slideSwiperEventHandler() {
    const element: NodeListOf<Element> = document.querySelectorAll('.container-360-wrapper.trim-selector')
    if (!element) {
      console.error('no element');
      return
    }
    const touchEvent$ = fromEvent(element, 'touchstart').pipe(
      takeUntil(fromEvent(element, 'touchend').pipe(
        map((e) => {
          console.debug('Scrolling')
          console.debug(e)
          if (typeof window.adobeDataLayer !== 'undefined') {
            this.activeModelName = this.subFamilyNames[this.currentIndex].toLowerCase()
            window.adobeDataLayer.push({
              "event": "view360Interaction",
              "data": {
                "pageInfo": {
                  "vehicleName": `${this.selectedFamily.familyName}-${this.activeModelName}`,
                  "vehicleBrand": "mxe"
                },
              },
            });
          }
        }),
      )),
      repeat()
    )

    this.touchEvent$ = touchEvent$.subscribe()
  }

  private setTrimTopNavigationBar() {
    const swiperPagination: Element = document.querySelectorAll('.swiper-pagination-horizontal')[0]
    let startX: number
    let endX: number
    let scrollDelta: number

    const swiperPaginationElement = (swiperPagination as HTMLElement)

    if(this.isMainScreen) {
      this.trimTopNavigationBarEvent$ = fromEvent(swiperPagination, 'touchstart').pipe(
        takeUntil(fromEvent(swiperPagination, 'touchstart').pipe(
          map((e) => {
            //startX = e['changedTouches'][0].clientX
            //startX = (e.target as HTMLElement).offsetLeft
            startX = (e.target as HTMLElement).getBoundingClientRect().left
            console.debug(`Start: ${startX}`)
          }),
        )),
        takeUntil(fromEvent(swiperPagination, 'touchend').pipe(
          map((e) => {
            //endX = e['changedTouches'][0].clientX
            endX = (e.target as HTMLElement).getBoundingClientRect().left
            console.debug(`End: ${endX}`)

            scrollDelta = endX - startX
            console.warn(`Scrolled pagination. Delta: ${scrollDelta}`)
            if(this.screenCastActive && scrollDelta !== 0) {
              this.autobahnClient.setSelectorSwiperScroll(this.ephemeralDeviceID, {scrollDelta: scrollDelta, swiperPaginationWidth: (swiperPaginationElement.childNodes[0] as HTMLElement).getBoundingClientRect().width, component: 'trim'})
            }
          }),
        )),
        repeat()
      ).subscribe()


      const activeTrim: Element = document.querySelectorAll(`.swiper-pagination-bullet-active.${this.selectedFamily.model}`)[0]
      if (activeTrim) {
        if (this.selectedFamily.models.length >= 4) {
          swiperPaginationElement.style.justifyContent = 'start';
        }
        else {
          swiperPaginationElement.style.justifyContent = 'center';
        }
      }
    }
  }

  private initDataTracking() {
    this.swiperButtonNext = document.querySelectorAll('.swiper-button-next')
    this.swiperButtonNext.forEach(element => {
      element.setAttribute('data-linktracking', 'engagement:choose-trim:arrow-right')
    })
    this.swiperButtonPrev = document.querySelectorAll('.swiper-button-prev')
    this.swiperButtonPrev.forEach(element => {
      element.setAttribute('data-linktracking', 'engagement:choose-trim:arrow-left')
    })
  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "car-trim-selector", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }
  
  private fillMissingTrimLabels(models: CarModel[]) {
    models.forEach(model => {
      if(!Object.keys(this.labels).includes(model.modelCode)) {
        const payload: GetCountryPropRequest = {
          country: +this.countryCode,
          language: this.languageId,
          modelCode: model.modelCode,
          modelName: model.modelName
        }
        this.maseratiService.getCountryProp(payload).subscribe(
          (result) => {
            this.labels[model.modelCode] = this.uiCommonService.updateLanguageIdsTranslation(result.labels, result.languages)
            this.store.dispatch(LABELS_LOADED({labels: this.labels}))
            this.uiCommonService.initializeLabels(this.labels)
  
            if(this.ctaLanguageId == payload.language){
              this.allCtas[model.modelCode] = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
              this.store.dispatch(CTAS_LOADED({ ctas: this.allCtas }))
            }
          }
        )

        if(this.ctaLanguageId != this.languageId) {
          const payload: GetCountryPropRequest = {
            country: +this.countryCode,
            language: this.ctaLanguageId,
            modelCode: model.modelCode,
            modelName: model.modelName
          }
          this.maseratiService.getCountryProp(payload).subscribe(
            (result) => {
              this.allCtas[model.modelCode] = Object.keys(result.actions).map(a => this.uiCommonService.formatAction(a, result.actions[a])).filter(a => a.enabled === true)
              this.store.dispatch(CTAS_LOADED({ ctas: this.allCtas }))
            }
          )
        }
      }
    })
  }

  private swipeTrimTopNavigationBar(data: any) {
    const firstItem: HTMLElement = document.querySelectorAll('.swiper-pagination-horizontal')[0].childNodes[0] as HTMLElement
    if(firstItem) {
      const actualMargin = parseInt(firstItem.style.marginLeft.replace('px', ''))
      const scrollDelta = firstItem.getBoundingClientRect().width / data['swiperPaginationWidth'] * data['scrollDelta']
      if(scrollDelta < 60){
        if(scrollDelta < 0 && scrollDelta < actualMargin) {
          firstItem.style.marginLeft = `${scrollDelta}px`
        } else if(scrollDelta > 0 && scrollDelta > actualMargin){
          firstItem.style.marginLeft = `${scrollDelta}px`
        }
      } else {
        firstItem.style.marginLeft = `60px`
      }
    }
  }

  updatePaginationPosition(): void {
    if (!this.swiper) return;
    const swiperInstance = this.swiper.swiperRef;
    const activeIndex = swiperInstance.activeIndex;
    const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
    const paginationContainer = document.querySelector('.swiper-pagination');

    if (paginationBullets && paginationContainer) {
      const activeBullet = paginationBullets[activeIndex] as HTMLElement;
      if (activeBullet) {
        const bulletRect = activeBullet.getBoundingClientRect();
        const containerRect = paginationContainer.getBoundingClientRect();
        
        const offset = bulletRect.left - containerRect.left + bulletRect.width / 2 - containerRect.width / 2;
        paginationContainer.scrollTo({
          left: offset,
          behavior: 'smooth'
        });
      }
    }
  }
}
