<div class="mxe__language-selector-modal" *ngIf="isModalCountryOpen">
    <div class="modal-backdrop"></div>

    <div class="modal-content">
        <div class="padd">
            <h1>{{getLabel('MXE_SELECT_COUNTRY_TITLE')}}</h1>
            <div class="mxe__lang-list">
                <div *ngFor="let country of countries; let i = index">
                    <button type="button"
                    [attr.data-linktracking]="'side-nav:preferences:countries:'+ normalizeForAnalytics(country.description)"
                    [ngClass]="{'lang-active' : activeCountryIndex === i}" 
                    class="mxe__lang-item {{country.isoCode2.toLowerCase()}}"
                    (click)="onSelectCountry(i)">
                    {{country.description}}
                </button>
                </div>
            </div>
            <div class="mxe__selection-buttons">
                <button type="button" [attr.data-linktracking]="'side-nav:preferences:countries:'+ normalizeForAnalytics(countries[activeCountryIndex].description) + ':cancel'" class="btn btn-transparent btn-dark btn-bordered" (click)=" onCancel()">
                    {{getLabel('LINE_CANCEL_BTN')}}
                </button>
                <button type="button" [attr.data-linktracking]="'side-nav:countries:preferences:'+ normalizeForAnalytics(countries[activeCountryIndex].description) + ':confirm'" class="btn btn-accent" (click)="areYouSureModal()" >
                    {{getLabel('LINE_CONFIRM_BTN')}}
                </button>
            </div>
        </div>
    </div>
    <div class="modal-content-confirmation" *ngIf="modalAreYouSure">
        <div class="padd">
            <h1>Are you sure?</h1>
            <div class="text-diclaim">
                If you change the country your configuration will be restarted
            </div>
            <div class="mxe__selection-buttons">
                <button type="button" class="btn btn-transparent btn-dark btn-bordered" [attr.data-linktracking]="'side-nav:preferences:countries:'+ normalizeForAnalytics(countries[activeCountryIndex].description) + ':confirm:cancel'" (click)="onCancel(); modalAreYouSure = false">
                    {{getLabel('LINE_CANCEL_BTN')}}
                </button>
                <button type="button" class="btn btn-accent" [attr.data-linktracking]="'side-nav:countries:preferences:'+ normalizeForAnalytics(countries[activeCountryIndex].description) + ':confirm:confirm'" (click)="onConfirm();  modalAreYouSure = false" >
                    {{getLabel('LINE_CONFIRM_BTN')}}
                </button>
            </div>
        </div>

    </div>
</div>