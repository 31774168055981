import { Component, EventEmitter, Input, NgZone, Output, SimpleChanges } from '@angular/core';
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AutobahnClient } from "src/app/core/clients/autobahn.client";
import { Pack, UIOptItem } from "src/app/core/models/car-grid.model";
import { CarModel, Family } from "src/app/core/models/get-models-service.model";
import { Menu } from "src/app/core/models/interface-service.model";
import { APP_SETTINGS_SET_SCREENCAST_ACTIVE } from "src/app/core/store/actions/app-settings/app-settings-actions";
import { MxeReducers } from "src/app/core/store/reducers";
import { PAIRING_STEP_ENUM } from '../../core/models/common.model';
import { HeartbeatService } from '../../core/services/heartbeat.service';
import { ConfigItem } from 'src/app/core/store/initials/temporary-configs-initial-state';
import { UPDATE_VIEW_UI_STATE } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-actions';

@Component({
    selector: 'mxe-pairing-toggle',
    templateUrl: './pairing-toggle-component.html',
    styleUrls: ['./pairing-toggle-component.scss']
})
export class PairingToggleComponent {
    @Input() isMainScreen: boolean
    @Input() priceStatus: boolean
    @Input() pairingStep: string
    @Input() packages: Pack[] = [];
    @Input() carModel: CarModel
    @Input() menuItems: Menu[] = [];
    @Input() modelOptions: UIOptItem[] = [];
    @Input() labels: any = []
    @Input() closedAccordions: string[] = [];
    @Input() scrollPosition: string = ''
    @Input() selectedFamily: Family
    @Input() activeSlideIndex: number | undefined
    @Input() currentTrimCode: string
    @Input() accordionExp: number
    @Input() temporaryConfigItems: ConfigItem[] = []
    @Input() isRestoreConfig: boolean;
    @Input() isLoadConfig: boolean;
    @Input() dealerId: string;
    @Input() viewUi: boolean
    @Output() screenCastActiveEmitter = new EventEmitter()
    @Output() mirroringStoppedByUserEmitter = new EventEmitter();
     

    screenCastActive: boolean;
    showScreenCastModal = false
    showScreenCastAlert = false
    ephemeralDeviceID: string;

    private mirroringStoppedByUser: boolean
    // private heartbeatFn: any;
    // private heartBeatTimer: number;
    private screenCastActive$: Subscription;
    private ephemeralDeviceID$: Subscription
    private sessionJoined$: Subscription

    constructor(
        private autobahnClient: AutobahnClient,
        private store: Store<MxeReducers>,
        private heartbeatService: HeartbeatService,
        private zone: NgZone){}

    ngOnInit() {
        this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe((res) => {
            this.screenCastActive = res
            this.screenCastActiveEmitter.emit(res)
        })
        this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe((res) => {
            this.ephemeralDeviceID = res
        })
        this.sessionJoined$ = this.autobahnClient.sessionJoined.asObservable().subscribe({
            next: (res: boolean) => {
                this.zone.run(() => {
                    this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: res}))
                })
            }
          })
    }

    ngOnDestroy() {
        this.screenCastActive$?.unsubscribe()
        this.ephemeralDeviceID$?.unsubscribe()
        this.sessionJoined$?.unsubscribe()
        if (this.screenCastActive) {
        //   this.onStopMirroring()
        }
    }

    onPairingToggle() {
        if(!this.screenCastActive){
            this.showScreenCastModal = true
            this.initAdobeTracking();
        } else {
            //TODO - Stop pairing
            this.showScreenCastAlert = true
        }
    }
    
    screenCastModalToggle(value: boolean | null = null) {
        if (!value) {
          this.showScreenCastModal = !this.showScreenCastModal
        } else {
          this.showScreenCastModal = value
        }
    }

    public onStopMirroring() {
        this.mirroringStoppedByUser = true
        this.heartbeatService.mirroringStoppedByUser = true
        this.mirroringStoppedByUserEmitter.emit(this.mirroringStoppedByUser);
        this.store.dispatch(APP_SETTINGS_SET_SCREENCAST_ACTIVE({isScreenCastActive: false}))
        //HIDE UI ON TV WHEN PAIRING IS CANCELED
        this.store.dispatch(UPDATE_VIEW_UI_STATE({viewUi: false}))
        if(this.pairingStep == PAIRING_STEP_ENUM.MODEL_SELECTOR || this.pairingStep == PAIRING_STEP_ENUM.TRIM_SELECTOR){
            this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0)
        }
        if(this.pairingStep != PAIRING_STEP_ENUM.CAR_CONFIGURATOR) {
            this.autobahnClient.requestClosePairingFromSimplePage(this.ephemeralDeviceID)
        }
        else {
            this.autobahnClient.onLeaveScreenCast(this.ephemeralDeviceID)
        }

    }

    private initAdobeTracking(){
        window.adobeDataLayer.push({
            "event": "ctaInteraction",
            "data": {
                "interaction": {
                    "element":{
                        "type":"cta",
                        "name":"pair:activate"
                    }
                }
            }
        });
    }
}