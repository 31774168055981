
<div class="show-loader" *ngIf="!dealerInfo || showTridentLoader || !onprem_enabled; else onPremUser">
    <img class="showLoader" src="../../../assets/icons/mxe-Loader Tridente.svg">
    <div class="cover right"></div>
    <div class="cover left"></div>
    <div class="colored-box-brown"></div>
</div> 

<ng-template #onPremUser>
  <div class="app-wrapper">
    <div class="upper-bar-container" >
      <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
      <div class="upper-text">
        <span class="header">{{getLabelWithDefaultValue('MXE_WELCOME_TO_MXE','Welcome to MXE')}}</span>
        <span class="sub-header" 
        [ngClass]="{'fade-out-right': goOnpremise,
                    'fade-in-left': backToModesSlide}" 
                    >{{getLabelWithDefaultValue('MXE_CHOOSE','Choose your configuration experience')}}</span>
        <span class="sub-header second" 
        [ngClass]="{'fade-in': goOnpremise,
                    'fade-out': backToModesSlide}" 
        >{{getLabelWithDefaultValue('MXE_CHOOSE_MACHINE','Choose your machine')}}</span>
      </div>
    </div>
    <button class="back-action" *ngIf="goOnpremise && !backToModesSlide" (click)="backToModes()">
      <img src="../../../assets/icons/mxe_ico-arrow-left-white.svg">
      <span class="arrow-text">{{getLabelWithDefaultValue('MXE_BACK_TO_MODES','Back to modes')}}</span>
    </button>
    <ng-container *ngIf="appExecutionInfos.host == 'localhost:8080' || !appExecutionInfos.hostName.includes('local')">
      <div class="connect-mode-container">
        <div class="first-elements-container" 
                [ngClass]="{'fade-out-left': goOnpremise,
                            'fade-in-right': backToModesSlide}" >
              <div class="border-container" [ngClass]="{'fade-in': firstAnimationOnCloudOnPremiseStep}">
                <div class="bordered"></div>
              </div>
              <div class="connect-mode-type" [ngClass]="{'fade-in': secondAnimationOnCloudOnPremiseStep}">
                <div class="info-container" (click)="goToOnPremiseMachines()" *ngIf="onprem_enabled">
                  <img class="connection-ico" src="../../../assets/icons/mxe-ico_on-premise.svg">
                  <div class="text-container">
                    <span class="header">{{getLabelWithDefaultValue('MXE_ON_PREMISE','On premise')}}</span>
                    <span class="sub-header">{{getLabelWithDefaultValue('MXE_CONNECT_ON_PREMISE','Connect to your pc for an high resolution experience')}}</span>
                  </div>
                  <img class="arrow-ico" src="../../../assets/icons/mxe_ico-arrow-right-white.svg">
                </div>
              </div>
              <div class="border-container" [ngClass]="{'fade-in': thirdAnimationOnCloudOnPremiseStep}">
                <div class="bordered"></div>
              </div>
              <div class="connect-mode-type" [ngClass]="{'fade-in': fourthAnimationOnCloudOnPremiseStep}">
                <div class="info-container" (click)="goOnCloud()" *ngIf="oncloud_enabled">
                  <img class="connection-ico" src="../../../assets/icons/mxe-ico_on-cloud.svg">
                  <div class="text-container">
                    <span class="header">{{getLabelWithDefaultValue('MXE_ON_CLOUD','On cloud')}}</span>
                    <span class="sub-header">{{getLabelWithDefaultValue('MXE_CONNECT_ON_CLOUD','Launch mxe in streaming mode')}}</span>
                  </div>
                  <img class="arrow-ico" src="../../../assets/icons/mxe_ico-arrow-right-white.svg">
                </div>
              </div>
              <div class="border-container" *ngIf="oncloud_enabled" [ngClass]="{'fade-in': fifthAnimationOnCloudOnPremiseStep}">
                <div class="bordered"></div>
              </div>
        </div>
        <div class="second-elements-container"
            [ngClass]="{'fade-out-left': goOnpremise,
                        'fade-in-right': backToModesSlide}" >
              <div class="machine-infos" *ngFor="let machine of dealerMachinesInfo" (click)="goToOnPremiseHome(machine)"
              [ngStyle]="{'pointer-events':machine.isAvailable ? 'auto' : 'none'}" >
                <div class="border-container">
                  <div class="bordered"></div>
                </div>
                <div class="machine" [ngStyle]="{'color': machine.isAvailable ? ' #ffffff' : 'rgba(255, 255, 255, 0.5)'}" >
                  <div class="info-container">
                    <img class="connection-ico" src="../../../assets/icons/mxe-ico_on-premise.svg">
                    <div class="text-container">
                      <span class="header">{{machine.serialNumber}}</span>
                    </div>
                    <div class="status-ico-container">
                      <div class="status">
                        <span class="text">{{machine.isAvailable ? getLabelWithDefaultValue('','') : getLabelWithDefaultValue('MXE_BUSY','busy')}}</span>
                      </div>
                      <img class="arrow-ico" [ngStyle]="{'opacity': machine.isAvailable ? ' 1' : '0.5'}" src="../../../assets/icons/mxe_ico-arrow-right-white.svg">
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="border-container">
                <div class="bordered"></div>
              </div>
        </div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="appExecutionInfos.protocol == 'http:' && appExecutionInfos.host != 'localhost:8080'">
    </ng-container> -->
  </div>
</ng-template>

  