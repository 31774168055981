import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-environments-carousel',
  templateUrl: './environments-carousel.component.html',
  styleUrls: ['./environments-carousel.component.scss']
})
export class EnvironmentsCarouselComponent implements OnInit {

  @Input() environments: string[];
  @Input()  activeEnvironment: string;
  @Output() switchEnvironment: EventEmitter<string> = new EventEmitter();
  currentIndex: number;
  activeCamera: string;


  ngOnInit(){
    this.currentIndex = this.environments.indexOf(this.activeEnvironment)
  }

  selectEnvironment(index: number) {
    if(index != this.currentIndex){
      this.currentIndex = index
      this.activeEnvironment = this.environments[index]
      this.switchEnvironment.emit(this.activeEnvironment);
    }
  }

  getCameraImage(i:number){
    const basePath : string = '../../../assets/env-cameras/env-'

    const bg : string = basePath+i+'.png';
    return bg; 
  }
}
