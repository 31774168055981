<!-- Unico Tab Di highlight -->
<div id="{{menuLabel}}" class="mxe__highlights--section-container accordion-{{menuLabel}} active" >
    <!-- HEADER -->
    <div id="{{menuLabel}}_header" class="mxe__highlights--section-header">
      <div class="header-title" (click)="toggleAccordion($event,0)"
      [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + [accordionOpened ? ':close' : ':open']">
        <span class="header-text">
         {{getLabelWithDefaultValue('MXE_HIGHLIGHT','Highlight')}}
        </span>
        <div class="header-icons">
          <span class="toggle-arrow"></span>
        </div>
      </div>
      <span class="selected-options-infos" *ngIf="totalOptSelected > 0" [innerHtml]="formatSelectionDetails()"></span>
    </div>
    <!-- /HEADER -->
    <!-- CONTENT -->
    <div id="{{menuLabel}}_content" class="mxe__highlights--section-content" [@openCloseAcc]="currentState[0]">
      <div id="highlights_wrapper" class="mxe__highlights-wrapper">
        <ng-container *ngIf="exteriors && exteriors.length > 0">
          <ng-container *ngFor="let ext of exteriors">
            <div class="details-container" id="H_{{ext.section+'_'+ext.id}}">
              <span class="img-wrapper" [ngClass]="ext.status.selected ? 'active' : ''">
              <img *ngIf="ext.colorList.length == 0" class="squareImg" [src]="getImagePathExtInt(ext.group, ext.id)"/>
              <img *ngIf="ext.colorList.length > 0" class="squareImg" [src]="getImagePathExtInt(ext.group, ext.id, ext.status.colorSelected)"/>
              </span>
              <div class="info-button-container">
                <span *ngIf="ext.colorList.length > 0" class="highlight-title">{{ getLabel(ext.group)+':'+' '+getLabel(ext.id, uiCommonService.LABEL_TYPE_ENUM.NAME)+' '+getLabel(ext.id, uiCommonService.LABEL_TYPE_ENUM.COLOR, ext.status.colorSelected) }}</span>
                <span *ngIf="ext.colorList.length == 0" class="highlight-title">{{ getLabel(ext.group)+':'+' '+getLabel(ext.id, uiCommonService.LABEL_TYPE_ENUM.NAME) }}</span>
                <span  class="highlight-price" *ngIf="showPrices">{{ formatPrice(ext.price) }}</span>
                <button class="btn-action int-ext-card" (click)="changeConfiguration(ext.id, ext.status.selected)"
                 [ngClass]="ext.status.selected ? 'opt-selected' : ''"
                 [disabled]="!ext.status.selectable">
                  <span>{{ ext.status.selected ? getLabel('REMOVE_OPT') : getLabel('ADD_OPT')}}</span>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="interiors && interiors.length > 0">
          <ng-container *ngFor="let int of interiors">
            <div class="details-container" id="H_{{int.section+'_'+int.id}}">
              <span class="img-wrapper" [ngClass]="int.status.selected ? 'active' : ''">
              <img *ngIf="int.colorList.length == 0" class="squareImg" [src]="getImagePathExtInt(int.group, int.id)"/>
              <img *ngIf="int.colorList.length > 0" class="squareImg" [src]="getImagePathExtInt(int.group, int.id, int.status.colorSelected)"/>
              </span>
              <div class="info-button-container">
                <span *ngIf="int.colorList.length > 0" class="highlight-title">{{ getLabel(int.id, uiCommonService.LABEL_TYPE_ENUM.NAME)+' '+getLabel(int.id, uiCommonService.LABEL_TYPE_ENUM.COLOR, int.status.colorSelected) }}</span>
                <span *ngIf="int.colorList.length == 0" class="highlight-title">{{ getLabel(int.id, uiCommonService.LABEL_TYPE_ENUM.NAME) }}</span>
                <span  class="highlight-price" *ngIf="showPrices">{{ formatPrice(int.price) }}</span>
                <button class="btn-action int-ext-card" (click)="changeConfiguration(int.id, int.status.selected)"
                 [ngClass]="int.status.selected ? 'opt-selected' : ''"
                 [disabled]="!int.status.selectable">
                  <span>{{ int.status.selected ? getLabel('REMOVE_OPT') : getLabel('ADD_OPT')}}</span>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="packs && packs.length > 0 && packages && packages.length > 0">
          <ng-container *ngFor="let pack of packs; let i = index">
            <div class="mxe__packages-config-panel card-container" id="H_{{pack.section+'_'+pack.id}}" >
              <div class="pkg-accordion" [ngClass]="currentStateHighPacksDetail[i] == 'open' ? 'expanded' : ''"  [class.selected]="pack.status.selected">
                <!-- HEADER -->
                <div class="pkg-accordion-heading">
                  <div class="accordion-toggle"></div>
                  <swiper id="SWIPER_{{i}}" class="pkg-accordion-carousel" [ngClass]="pack.id"
                    [config]="config" [slidesPerView]="1" [spaceBetween]="0" [loop]="true"
                    [pagination]="{ clickable: true }" [navigation]="false" (swiper)="onSwiper($event)"
                    (transitionEnd)="onSlideChange($event, i)" [ngStyle]="{'height.px': isMainScreen ? '128' : '256'}">
                    <ng-template swiperSlide *ngFor="let imagePath of getOptionsImageInPackage(pack.id)">
                      <div *ngIf="pack.status.selectable" class="pkg-acc-sld-bg" style="background-image: url('{{imagePath}}');"
                        (click)="toggleAccordionDetailsStatus(i, $event)">
                      </div>
                      <div *ngIf="!pack.status.selectable" class="pkg-acc-sld-bg" style="background-image: url('{{imagePath}}');"></div>
                    </ng-template>
                  </swiper>
                  <div class="absolute-pkg-infos">
                    <span class="pkg-title">
                      {{getLabel(pack.id, uiCommonService.LABEL_TYPE_ENUM.NAME)}}
                    </span>
                    <span class="pkg-price" *ngIf="showPrices"> {{ formatPrice(getPackById(pack.id).price) }}</span>
                  </div>
                  <button *ngIf="pack.status.selectable" class="btn-action" (click)="changeConfiguration(pack.id, pack.status.selected,'pack')"
                  [ngClass]="pack.status.selected ? 'opt-selected' : ''"
                  [disabled]="!pack.status.selectable">
                  <span >{{ pack.status.selected ? getLabel('REMOVE_OPT') : getLabel('ADD_OPT')}}</span>
                  </button>
                  <span *ngIf="!pack.status.selectable" class="package-not-available">{{
                    getLabel('OPT_NOT_AVAILABLE') }}</span>
                </div>
                <!-- FINE HEADER -->
                <!-- CONTENT -->
                <div class="pkg-accordion-content">
                  <div class="pkg-content-list">
                    <div class="pkg-content-row"*ngFor="let description of getPackageImplicitsDescriptions(pack.id)">
                      <div class="outer-circle">
                        <div class="inner-circle"></div>
                      </div>
                      {{description}}
                    </div>
                    <div *ngFor="let set of getPackById(pack.id).standAlone.sets" class="subset-packages-accordion"
                    [ngClass]="{'active': accordionOpen}">
                    <div class="pkg-content-row" *ngIf="getSelectedItemInList(getPackById(pack.id).packageId,
                    set.id) !== undefined && !accordionOpen">
                      <div class="outer-circle">
                        <div class="inner-circle"></div>
                      </div>
                      <span class="edit-text"
                        [ngClass]="{'edit-active-text':accordionOpen}">
                        {{getSelectedItemTranslation(pack.id, set.id)}}
                        <span *ngIf="showPrices">- {{formatPrice(getSelectedItemInList(getPackById(pack.id).packageId,
                          set.id).price)}}</span></span>
                      <span class="action-edit-icon"
                        [ngClass]="{'edit-active':accordionOpen}"
                        (click)="showSetList(getPackById(pack.id).packageId, set.id)"></span>

                    </div>
                    <ng-container *ngFor="let item of set.list; let i = index">
                      <div class="pkg-content-row"
                          *ngIf="accordionOpen">
                          <input type="radio" name="set_{{pack.id}}" value="{{item.id}}"
                            [class.active]="item.status.selected"
                            [ngClass]="{'disabled': !item.status.selectable}"
                            [checked]="getSelectedItemInList(pack.id, set.id).id === item.id"
                            (change)="onSetListItemChange(item.id, pack.id, set.id)"
                            [disabled]="!item.status.selectable">
                          <label class="item-name" [ngClass]="{'disabled': !item.status.selectable}"
                            [class.active]="item.status.selected">
                            {{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME)}} <span
                              *ngIf="showPrices">- {{formatPrice(item.price)}}</span>
                          </label>
                          <span class="rx-container">
                            <label *ngIf="!item.status.selectable" class="not-available">{{
                              getLabel('OPT_NOT_AVAILABLE') }}</label>
                            <span *ngIf="i === 0" class="hide-set-list"
                              (click)="showSetList(pack.id, set.id)"></span>
                          </span>
                        </div>
                    </ng-container>
                   </div>
                  </div>
                </div>
                <!-- FINE CONTENT -->
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="optionals && optionals.length > 0">
          <ng-container *ngFor="let opt of optionals">
            <div class="card-container" id="H_{{opt.section+'_'+opt.id}}" >
              <div class="card-frame" [ngClass]="opt.status.selected ? 'selected' : ''">
                <img class="image" [src]="getImagePath(opt.group, opt.id)" [ngStyle]="{'opacity' : opt.status.labelKey === 'OPT_INCLUDED_AS_MUST' ? '0.5' : '1'}"/>
                <div class="card-titles-container">
                  <span class="highlight-title">{{ getLabel(opt.id, uiCommonService.LABEL_TYPE_ENUM.NAME) }}</span>
                  <span  class="highlight-price" *ngIf="showPrices">{{ formatPrice(opt.price) }}</span>
                </div>
                <button class="btn-action" (click)="changeConfiguration(opt.id, opt.status.selected)"
                 [ngClass]="opt.status.selected ? 'opt-selected' : ''"
                 [disabled]="!opt.status.selectable"
                 *ngIf="opt.status.labelKey != 'OPT_INCLUDED_AS_MUST'">
                 <span>{{ opt.status.selected ? getLabel('REMOVE_OPT') : getLabel('ADD_OPT')}}</span>
                </button>
                <div class="label-container" *ngIf="opt.status.labelKey === 'OPT_INCLUDED_AS_MUST'">
                  <span class="label-included" >{{getLabel('OPT_INCLUDED_AS_MUST')}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="accessory && accessory.length > 0">
          <ng-container *ngFor="let acc of accessory">
            <div class="card-container" id="H_{{acc.section+'_'+acc.id}}" >
              <div class="card-frame" [ngClass]="acc.status.selected ? 'selected' : ''">
                <img class="image" [src]="getImagePath(acc.group, acc.id)" [ngStyle]="{'opacity' : acc.status.labelKey === 'ACC_INCLUDED_AS_MUST' ? '0.5' : '1'}"/>
                <div class="card-titles-container">
                  <span class="highlight-title">{{ getLabel(acc.id, uiCommonService.LABEL_TYPE_ENUM.NAME) }}</span>
                  <span  class="highlight-price" *ngIf="showPrices">{{ formatPrice(acc.price) }}</span>
                </div>
                <button class="btn-action" (click)="changeConfiguration(acc.id, acc.status.selected)"
                 [ngClass]="acc.status.selected ? 'opt-selected' : ''"
                 [disabled]="!acc.status.selectable"
                 *ngIf="acc.status.labelKey != 'ACC_INCLUDED_AS_MUST'">
                 <span>{{ acc.status.selected ? getLabel('REMOVE_OPT') : getLabel('ADD_OPT')}}</span>
                </button>
                <div class="label-container" *ngIf="acc.status.labelKey === 'ACC_INCLUDED_AS_MUST'">
                  <span class="label-included" >{{getLabel('ACC_INCLUDED_AS_MUST')}}</span>
                </div>
                
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
</div>