<ng-container *ngFor="let tab of menuItems?.tabs, index as i">
  <div class="mxe__exterior--section-container accordion-{{tab.id}} active" id="{{menuLabel}}" *ngIf="isTabVisible(tab.id)">
    <!--HEADER-->
    <div id="{{tab.id}}_header" class="mxe__exterior--section-header">
      <div id="_header_title" class="header-title"  (click)="toggleAccordion(tab.id,i, $event)"
      [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + tab.id.toLocaleLowerCase()+ [accordionOpened ? ':close' : ':open']">
        <span *ngIf="tab.id == 'EXT'"
         class="header-text">
          {{getLabel('MXE_PAINT')}}
        </span>
        <span *ngIf="tab.id != 'EXT'" class="header-text">
          {{getLabel(tab.id)}}
        </span>
        <div class="header-icons">
          <span class="change-camera"
            [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':'+ tab.id.toLocaleLowerCase() + ':camera'"
            (click)="changeCamera($event, tab.id)"></span>
          <span class="toggle-arrow"></span>
        </div>
      </div>
      <ng-container *ngFor="let selectedOptionDetail of getSelectedOptionsDetails(tab.id); let first = first; let i = index">
        <div *ngIf="getSelectedOptionsDetails(tab.id).length > 1 && first">
            <span class="header-options" [innerHtml]="selectedOptionDetail"></span>&nbsp;
            <span class="header-options" *ngIf="!showMoreLivree && selectedOptionDetail != '<span></span>'">+
              {{getSelectedOptionsDetails(tab.id).length - 1}}</span>&nbsp;
            <span class="header-options" style="text-decoration: underline;" (click)="showMore()"
              *ngIf="!showMoreLivree">{{getLabelDefValue('SHOW_ALL','Show all')}}</span>
            <span class="header-options" style="text-decoration: underline;" (click)="showMore()"
              *ngIf="showMoreLivree">{{getLabelDefValue('SHOW_LESS','Show less')}}</span>
            <ng-container *ngIf="showMoreLivree">
              <div *ngFor="let optionDetail of getSelectedOptionsDetails(tab.id); let sec = first">
                <span class="header-options" [innerHtml]="optionDetail" *ngIf="!sec"></span>
              </div>
            </ng-container>
          </div>
          <div *ngIf="getSelectedOptionsDetails(tab.id).length <= 1">
            <span class="header-options" [innerHtml]="selectedOptionDetail">
            </span>
          </div>
     </ng-container>
    </div>
    <!--/HEADER-->
    <!--CONTENT-->
    <div id="{{tab.id}}_content" class="mxe__exterior--section-content" [@openCloseAcc]="currentState[i]">
      <div id="{{tab.id}}_wrapper" class="mxe__ext-wrapper">
        <ng-container *ngFor="let section of tab.sections">
        <div class="section-container" *ngIf="sectionIsVisible(section.id)">
            <div class="subtitles" id="{{section.id}}" *ngIf="getLabel(section.id).length > 0">
              <span>{{ getLabel(section.id) }}</span>
            </div>
            <div class="btn-item-container" id="{{section.id+'_'+item.id}}"
              [ngClass]="tab.id != 'EXT' ? 'squareImg' : 'rectangleImg'"
              *ngFor="let item of getOptionsBySection(section.id, section.filter_fuoriserie)">
              <ng-container *ngIf="item.colorList.length > 0">
                <button *ngFor="let colorCode of getColors(item.id, item.colorList, section.filter_fuoriserie)"
                  class="btn-item-selection"
                  [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, item.id, colorCode.colIdent)"
                  [class.active]="colorCode.selected"
                  (click)="!colorCode.selected ? changeConfiguration(item.id, colorCode.colIdent, colorCode.price, section.id) : ''"
                  [disabled]="!item.status.selectable && !item.status.selected">
                  <span class="img-wrapper">
                    <img [src]="getImagePath(item.group, item.id, colorCode.colIdent)">
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="item.colorList.length === 0">
                <button class="btn-item-selection"
                  [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, item.id)"
                  [class.active]="item.status.selected" (click)="changeConfiguration(item.id, '', item.price, section.id)"
                  [disabled]="!item.status.selectable && !item.status.selected">
                  <span class="img-wrapper">
                    <img [src]="getImagePath(item.group, item.id)">
                  </span>
                </button>
              </ng-container>
            </div>
        </div>
        </ng-container>
      </div>
    </div>
    <!--/CONTENT-->
  </div>

</ng-container>
