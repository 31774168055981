import { } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, fromEvent, Subscription, tap, map } from 'rxjs';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IDomElementData, MENU_ENUM } from 'src/app/core/models/common.model';
import { CarModel } from 'src/app/core/models/get-models-service.model';
import { CarImage, Menu, Tab } from 'src/app/core/models/interface-service.model';
import { Camera } from 'src/app/core/models/unreal-available-models.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';

@Component({
  selector: 'app-sidebar-configurator',
  templateUrl: './sidebar-configurator.component.html',
  styleUrls: ['./sidebar-configurator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarConfiguratorComponent {
  @ViewChild('sidebar') sidebarEl: ElementRef<HTMLElement>;

  @Output() changeConfigurationEvent: EventEmitter<IConflictsData> = new EventEmitter()
  @Output() showSaveModal: EventEmitter<any> = new EventEmitter();
  @Output() showSummaryModal: EventEmitter<any> = new EventEmitter();
  @Output() showPopUpSni: EventEmitter<any> = new EventEmitter();
  @Output() scrollPositionEvent: EventEmitter<string> = new EventEmitter();
  @Output() activeIndexEvent: EventEmitter<number> = new EventEmitter();
  @Output() optionChangeEvent: EventEmitter<UIOptItem[]> = new EventEmitter();
  @Output() accordionToggleEvent: EventEmitter<string> = new EventEmitter();
  @Output() propagateSearchBoxEvent: EventEmitter<string> = new EventEmitter()
  @Output() propagateSearchBoxToggleEvent: EventEmitter<any> = new EventEmitter()
  @Output() changeCameraEvt: EventEmitter<Camera> = new EventEmitter()
  @Output() changeImageEvt: EventEmitter<CarImage> = new EventEmitter()
  @Output() packageDetailsEmitter: EventEmitter<number> = new EventEmitter<number>()
  @Output() closedAccordionsEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() openedAccordionsEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() standAloneSetEditingEmitter: EventEmitter<[]> = new EventEmitter<[]>()
  @Output() standAloneSetEditingIntEmitter: EventEmitter<[]> = new EventEmitter<[]>()
  @Output() accordionExpEvent: EventEmitter<number> = new EventEmitter<number>()
  @Output() packageSwiperSlideChanged:  EventEmitter<{imageIndex: number, packageIndex: number}> = new EventEmitter<{imageIndex:number, packageIndex: number}> ()
  @Output() showCinematicEvent : EventEmitter<boolean> = new EventEmitter;
  
  @Input() priceStatus: boolean
  @Input() carModel: CarModel
  @Input() menuItems: Menu[]
  @Input() cameras: Camera[]
  @Input() options: UIOptItem[]
  @Input() packages: Pack[]
  @Input() isMainScreen: boolean
  @Input() toggleAccordion: EventEmitter<string> = new EventEmitter()
  @Input() searchFilter: string;
  @Input() translations: any
  @Input() searchToggleValue: boolean
  @Input() optionsForAnalytics: string;
  @Input() configId: string;
  @Input() dealerId: string;
  @Input() set scrollPosition(scrollPosition: string) {
    this.setScrollPosition(scrollPosition)
  }
  @Input() updateAnalyticsPageName: EventEmitter<any> = new EventEmitter()
  @Input() saveCtaEnabled: boolean
  @Input() set packageDetailsToggleValue(packageDetails: any) {
    this.packageDetails = packageDetails
  }
  @Input() closedAccordions: string[] = []
  @Input() standAloneSet: {}
  @Input() standAloneSetInt: {}
  @Input() standAloneSetHighlightPacks: {}
  @Input() accordionOpen: boolean
  @Input() accordionExp: number
  @Input() streamingAvailable: boolean
  @Input() swiperViews: CarImage[]
  @Input() languageId: string
  @Input() packagesSwiperSlideChange:{imageIndex: number, packageIndex: number}
  @Input() packagesFormatSelectionDetail : string
  @Input() optFormatSelectionDetail : string
  @Input() accFormatSelectionDetail : string
  @Input() accordionOpenClose : boolean
  @Input() currentStateHigh : string[]
  @Input() currentStateHighPacksDetail : string[]
  @Input() currentStateInt : string[]
  @Input() currentStateExt : string[]
  @Input() currentStateOpt : string[]
  @Input() currentStateAccessories : string[]
  @Input() showMoreLivree : boolean
  @Input() isSearchNewInventoryCtaEnabled : boolean
  @Input() isCinematicActiveForSelectedTrim : boolean
  @Input() saveClicked:boolean = false;
  

  MENU_ENUM = MENU_ENUM;

  public packageDetails: any
  public modelCode: string;
  public currency: string;
  public priceMask: string;
  public activeItem = 0
  public modelName: string;
  public commercialName: string;

  private elementsData: IDomElementData[] = []
  private innerElementData: InnerElementData[] = []
  private rimsSections: IDomElementData[] = []
  private lastSelectedCamera: Camera | null = null
  private rimsEl: HTMLDivElement | null;
  private calipersEl: HTMLDivElement | null;

  private scrollSub$: Subscription;
  private updatePageName$: Subscription;

  private toggleAccordionSub$: Subscription;

  private packageSections: Element[] = []
  private closedAccordionCheck: string[] = []

  screenCastActiveB : boolean;
  @Input() set screenCastActive(value) {
    this.screenCastActiveB = value;
  }

  constructor(
    private chg: ChangeDetectorRef,
    private carConfigurationService: CarConfigurationService,
    private mxeAnalyticsService: MxeAnalyticsService,
    private uiCommonService: UiCommonService,
    private store: Store<MxeReducers>
  ) { }

  ngOnInit() {
    this.modelCode = this.carModel.modelCode
    this.currency = this.carModel.currency
    this.priceMask = this.carModel.priceMask
    this.modelName = this.carModel.modelName
    this.commercialName = this.carModel.commercialName
    if (this.isMainScreen) {
      this.triggerAdobeTrackingAction(1)
      this.onUpdateAnalyticsPageName()
      if(this.isMainScreen && this.screenCastActiveB){
        const sidebarElement = document.getElementById('main_sidebar');
        if (sidebarElement) {
          sidebarElement.style.borderLeft = '1px solid #ffffff';
        }
      }
    }
  }

  ngAfterViewInit() {
    this.rimsSectionRules()
    this.toggleAccordionElements()
    if(!this.isMainScreen){
      this.togglePackageDetails()
    } 
    this.setSectionsData()

    this.scrollSub$ = this.isMainScreen
      ? fromEvent(this.sidebarEl.nativeElement, 'scroll').pipe(tap(() => this.stickyHeader()), debounceTime(150)).subscribe(() => this.pageYOffset())
      : fromEvent(this.sidebarEl.nativeElement, 'scroll').subscribe(() => this.stickyHeader())
  }

  ngOnDestroy() {
    this.scrollSub$.unsubscribe()
    if (!this.isMainScreen) this.toggleAccordionSub$.unsubscribe()
    else this.updatePageName$.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setSectionsData()
    if(changes['screenCastActive'] && changes['screenCastActive'].firstChange == false  && this.isMainScreen){
      if(changes['screenCastActive'].currentValue == true){
        const sidebarElement = document.getElementById('main_sidebar');
        if (sidebarElement) {
          sidebarElement.style.borderLeft = '1px solid #ffffff';
        }
      }
      else{
        const sidebarElement = document.getElementById('main_sidebar');
        if (sidebarElement) {
          sidebarElement.style.borderLeft = 'none';
        }
      }
      
    }
    if(!this.isMainScreen){
      if(changes && (changes['options'] || changes['menuItems'])){
        this.chg.detectChanges();
      }
    }
  }

  public onShowChangeConfigurationAlertBar(eventData: IConflictsData) {
    this.changeConfigurationEvent.emit(eventData)
  }

  public goToSection(sectionLabel: string) {
    const el = this.sidebarEl.nativeElement as HTMLElement;
    const section = this.elementsData.find(s => s.id?.toLowerCase() == sectionLabel)
    if(section){
      el.scrollTo({ top: section.offTop, behavior: 'smooth' });
    }
  }

  getLabel(optId: string) {
    let label = this.carModel? this.uiCommonService.getLabel(optId, '', '', this.carModel.modelCode): ''
    return label
  }

  /**
  * Receives a filter event from search box in upper menu
  * @param event - the string to be used as filter
  */
  public onSearchBoxEvent(event: string) {
    this.propagateSearchBoxEvent.emit(event)
  }

  public onSearchBoxToggleEvent(event: boolean) {
    this.propagateSearchBoxToggleEvent.emit(event)
  }

  private setSectionsData() {
    this.elementsData = []
    if(this.options){
      this.options.forEach(opt => {
        const domOpt = this.uiCommonService.toElementData(opt.section, opt.id)
        if(domOpt)
          this.elementsData.push(domOpt)
      })
      this.options.filter(opt => opt.highlight)?.forEach(hopt => {
        const domHOpt = this.uiCommonService.toElementData(hopt.section,hopt.id, -1,  true)
        if(domHOpt) {
          this.elementsData.push(domHOpt)
        }
      })
      const sections = [...new Set(this.options.map(o => o.section))]
      sections.forEach(
        section => {
          const domOpt = this.uiCommonService.toElementData(section)
          if(domOpt)
            this.elementsData.push(domOpt)
        } 
      )
      const groups = [...new Set(this.options.map(o => o.group))]
      groups.forEach(
        group => {
          const domOpt = this.uiCommonService.toElementData(group)
          if(domOpt)
            this.elementsData.push(domOpt)
        } 
      )
      const menuLabels = this.menuItems?.map(m => m.label)
      if(this.isSectionVisible('MENU_HIGHLIGHT')){
        menuLabels?.forEach(
          (label) => {
            let index
            switch(label) {
              case MENU_ENUM.HIGH:
                index = 0
                break;
              case MENU_ENUM.EXT:
                index = 1
                break;
              case MENU_ENUM.INT:
                index = 2
                break;
              case MENU_ENUM.PACK:
                index = 3
                break;
              case MENU_ENUM.OPT:
                index = 4
                break;
              case MENU_ENUM.ACC:
                index = 5
                break;
            }
            const domOpt = this.uiCommonService.toElementData(label, '', index)
            if(domOpt)
              this.elementsData.push(domOpt)
          } 
        )
      }
      else{
        menuLabels?.forEach(
          (label) => {
            let index
            switch(label) {
             case MENU_ENUM.EXT:
                index = 0
                break;
              case MENU_ENUM.INT:
                index = 1
                break;
              case MENU_ENUM.PACK:
                index = 2
                break;
              case MENU_ENUM.OPT:
                index = 3
                break;
              case MENU_ENUM.ACC:
                index = 4
                break;
            }
            const domOpt = this.uiCommonService.toElementData(label, '', index)
            if(domOpt)
              this.elementsData.push(domOpt)
          } 
        )

      }
    
    }
  }

  private pageYOffset() {
    this.setSectionsData()

    const scrollPosition = this.sidebarEl.nativeElement.scrollTop
    const selectedElement: IDomElementData | undefined = this.getSelectedElement(scrollPosition)
    if (selectedElement){
      this.triggerAdobeTrackingAction(selectedElement.elementIndex + 1)
      this.chg.detectChanges()
      if(this.streamingAvailable) {
        this.setCameraOnScroll(selectedElement)
      } else {
        this.set2DCameraOnScroll(selectedElement)
      }
    }
    this.scrollPositionEvent.emit(selectedElement?.id)
  }

  private stickyHeader(scrollPosition = this.sidebarEl.nativeElement.scrollTop) {

    const passedElement = this.innerElementData.filter(el => scrollPosition + el.elementData.headerHeight! > (el.elementData.offTop + el.elementData.offHeight) && el.elementData.offHeight > el.elementData.headerHeight!)
    passedElement.forEach(el => {
      el.htmlRef.classList.add('scroll-position-element-over')
      el.htmlRef.firstElementChild?.classList.add('scroll-position-child-over')
      el.htmlRef.firstElementChild?.classList.remove('heading-sticky')
    });

    const overElement = this.innerElementData.find(el => scrollPosition + el.elementData.headerHeight! < (el.elementData.offTop + el.elementData.offHeight) && el.elementData.offHeight > el.elementData.headerHeight!)
    overElement?.htmlRef.classList.remove('scroll-position-element-over')
    overElement?.htmlRef.firstElementChild?.classList.remove('scroll-position-child-over')
    if (overElement) overElement.htmlRef.style.paddingTop = overElement.elementData.headerHeight! + 'px'
    overElement?.htmlRef.firstElementChild?.classList.add('heading-sticky')

    const notYetViewedElement = this.innerElementData.filter(el => scrollPosition < el.elementData.offTop)
    notYetViewedElement.forEach(el => {
      el.htmlRef.style.paddingTop = '0'
      el.htmlRef.firstElementChild?.classList.remove('heading-sticky')
      el.htmlRef.classList.remove('scroll-position-element-over')
      el.htmlRef.firstElementChild?.classList.remove('scroll-position-child-over')
    })

  }

  private toggleAccordionElements() {
    if (this.isMainScreen) {
      const el = this.sidebarEl.nativeElement.querySelectorAll('[class*="accordion"].active .header-title')
      el.forEach(el =>{
        el.addEventListener('click',(e) =>{
          //qua sotto trovo l'elemento header
          var accordionHeaderElement = el.parentElement
          //qua sotto trovo l'elemento che contiene tutto
          var globalAccordionContainer = accordionHeaderElement?.parentElement as Element
          let classString = ''
          globalAccordionContainer.classList.forEach(elementClass => {
            if (elementClass != 'active') {
              classString = `${classString}.${elementClass}`
            }
          })
          this.accordionToggleEvent.emit(classString)
          //This section forwards classList to indicate presenter screen which accordion is closed
          if(!this.closedAccordionCheck.includes(classString)) {
            this.closedAccordionCheck.push(classString)
            this.closedAccordionsEmit.emit(classString)
          } else {
            this.closedAccordionCheck = this.closedAccordionCheck.filter(a => a !== classString)
            this.openedAccordionsEmit.emit(classString)
          }
          setTimeout(() => {
            this.setSectionsData()    
          }, 1000)
          e.stopImmediatePropagation && e.stopImmediatePropagation()
       })
      })
    } else {
      if(this.closedAccordions) {
        this.closedAccordions.forEach(classList => {
          if(this.sidebarEl.nativeElement.querySelector(classList)) {
            this.sidebarEl.nativeElement.querySelector(classList)!.classList.remove('active')  
          }
        })
      }
      this.toggleAccordionSub$ = this.toggleAccordion.asObservable().subscribe((classList: string) => {
        if(this.sidebarEl.nativeElement.querySelector(classList)) {
          this.sidebarEl.nativeElement.querySelector(classList)!.classList.toggle('active')
        }
        setTimeout(() => {
          this.setSectionsData()    
        }, 1000)
      });
    }
  }

  onShowPackageDetailEmitter(event) {
    this.packageDetailsEmitter.emit(event) 
  }

  onStandAloneSetEditingEmitter(setList) {
    this.standAloneSetEditingEmitter.emit(setList)
  }

  onStandAloneSetEditingIntEmitter(setListInt) {
    this.standAloneSetEditingIntEmitter.emit(setListInt)
  }
  
  private togglePackageDetails() {
    const packageElements = document.querySelectorAll('.pkg-accordion')
    packageElements.forEach((pack, i)  => {
      this.packageSections.push(pack)
    })
  }

  private getSelectedElement(scrollPosition: number) {
    //se trovo l'elemento esattamente a quell'offset top mi posiziono su quello, altrimenti mi calcolo il più vicino
    if(this.elementsData.some(e => e.offTop == scrollPosition)) {
      return this.elementsData.find(e => e.offTop == scrollPosition)
    }
    const possibleElements: IDomElementData[] = this.elementsData.filter(e => e.elementIndex < 0).filter(el => !el.id?.startsWith('sec'))
    if(possibleElements && possibleElements.length> 0){
      const closestElement = possibleElements?.reduce((closest, current) => {
        const currentDistance = Math.abs(current.offTop - scrollPosition);
        const closestDistance = Math.abs(closest.offTop - scrollPosition);
        return currentDistance < closestDistance ? current : closest;
      })
      return closestElement
    } else {
      
      return undefined;
    }

  }

  private setCameraOnScroll(selectedElement: IDomElementData) {
    let setCamera: Camera | undefined = undefined
    const selectedElementId = selectedElement.id || ''
    const menuLabels = this.menuItems?.map(m => m.label)
    if(selectedElementId.toLocaleLowerCase().startsWith('rim') || selectedElementId.toLocaleLowerCase().indexOf('cal') >= 0){
      this.isSectionVisible('MENU_HIGHLIGHT') ? this.activeItem = 1 : this.activeItem = 0;
      setCamera = this.cameras.find(camera => camera.name === 'MVI_Rim_FrontRight_Cam')
    } else {
      setCamera = this.getActiveSection(selectedElement.offTop)
    }
    if (setCamera && setCamera != this.lastSelectedCamera && this.isMainScreen) {
      this.changeCameraEvt.emit(setCamera)
      this.lastSelectedCamera = setCamera
    }
  }

  private set2DCameraOnScroll(selectedElement: IDomElementData) {
    let setImage: CarImage | undefined = undefined
    setImage = this.getActiveImage(selectedElement.offTop)
    if(setImage && this.isMainScreen) {
      this.changeImageEvt.emit(setImage) 
    }
  }

  private getActiveSection(offTop: number): Camera | undefined{
    const sections = this.elementsData.filter(e => e.elementIndex != -1)
    for (let i = 0; i < sections.length - 1; i++) {
      if (sections[i].offTop <= offTop && sections[i + 1].offTop > offTop) {
        let sectionId = sections[i].id || ""
        this.activeItem = sections[i].elementIndex
        if (sectionId.toLowerCase().indexOf('int') > 0) {
          return this.cameras.find(camera => camera.location == 'int')
        } else {
          return this.cameras.find(camera => camera.location == 'ext')
        }
      }
      else{
        this.activeItem = sections[i+1].elementIndex
      }
    }
    return undefined
  }

  private getActiveImage(offTop: number): CarImage | undefined {
    const sections = this.elementsData.filter(e => e.elementIndex != -1)
    for (let i = 0; i < sections.length - 1; i++) {
      if (sections[i].offTop <= offTop && sections[i + 1].offTop > offTop) {
        let sectionId = sections[i].id || ""
        this.activeItem = sections[i].elementIndex
        if (sectionId.toLowerCase().indexOf('int') > 0) {
          return this.swiperViews?.find(view => view.viewType == 'INT')
        } else {
          return this.swiperViews?.find(view => view.viewType == 'EXT')
        }
      }
      else{
        this.activeItem = sections[i+1].elementIndex
      }
    }
    return undefined
  }

  private setScrollPosition(scrollPosition: string) {
    if(this.sidebarEl) {
      const el = this.sidebarEl.nativeElement as HTMLElement;
      const scrollTo = this.elementsData.find(e => e.id == scrollPosition)
      if(scrollTo){
        el.scrollTo({ top: scrollTo.offTop, behavior: 'smooth' });
        setTimeout(() => {
          const sections = this.elementsData.filter(e => e.elementIndex != -1)
          for (let i = 0; i < sections.length - 1; i++) {
            if (sections[i].offTop <= scrollTo.offTop && sections[i + 1].offTop > scrollTo.offTop) {
              this.activeItem = sections[i].elementIndex
              break;
            } else {
              this.activeItem = sections[i+1].elementIndex
            }
          }
          this.chg.detectChanges()
        }, 500);
      }
    }
  }

  private rimsSectionRules() {
    this.rimsEl = this.sidebarEl.nativeElement.querySelector('.accordion-RIMS') as HTMLDivElement;
    this.calipersEl = this.sidebarEl.nativeElement.querySelector('.accordion-CAL') as HTMLDivElement;
    if(this.rimsEl && this.calipersEl) {
      this.rimsSections.push(
        {
          elementIndex: 10,
          offHeight: this.rimsEl.offsetHeight,
          offTop: this.rimsEl.offsetTop
        },
        {
          elementIndex: 11,
          offHeight: this.calipersEl.offsetHeight,
          offTop: this.calipersEl.offsetTop
        }
      )
    }
  }

  private triggerAdobeTrackingAction(index: number) {
    if (typeof window.adobeDataLayer !== 'undefined') {
      window.adobeDataLayer.push({
        "event": "CC_changeView",
        "data": {
          "pageInfo": {
            "vehicleName": this.mxeAnalyticsService.normalizeStringForAnalytics(this.commercialName),
            "vehicleBrand": "mxe",
            "vehicleModelName": this.mxeAnalyticsService.normalizeStringForAnalytics(this.modelName),
            "vehicleID": this.modelCode,
            "pageName": `car-configurator:${this.menuItems[index].label.toLocaleLowerCase()}`,
            "SysEnv": "mobile"
          },
          "component": {
            "configurator": {
              "stepName": `${this.menuItems[index].label.toLocaleLowerCase()}`,
              "stepNumber": `${index}`,
              "configurationID": this.configId,
              "options": this.optionsForAnalytics,
              "completePrice": parseFloat(this.carConfigurationService.getTotalPrice()).toFixed(0),
              "vehicleID": this.modelCode,
            },
            "dealerSearch": {
              "dealerID": this.dealerId,
            }
          }
        },
      });
    }
  }

  private onUpdateAnalyticsPageName() {
    if (typeof window.adobeDataLayer !== 'undefined') {
      this.updatePageName$ = this.updateAnalyticsPageName.asObservable().subscribe(() => {
        window.adobeDataLayer.push({
          "event": 'updatePageName',
          "data": {
            "pageInfo": {
              "pageName": `${this.menuItems[this.activeItem + 1].label.toLocaleLowerCase()}`
            }
          }
        });
      })
    }
  }

  public isSectionVisible(sectionName: string): boolean {
    let ret: boolean = false
    if (this.menuItems) {
      if (sectionName == MENU_ENUM.HIGH) {
        const mockedHighlights = localStorage.getItem('mocked_highlights') ? JSON.parse(localStorage.getItem('mocked_highlights')!) as boolean : false;
        if (mockedHighlights) ret= true; //Bypass also the filter 'filter_highlights' from interface service
        const highlightTabs: Tab[] | undefined = this.menuItems.find(x => x.id == sectionName)?.tabs

        highlightTabs?.forEach(tab => {
          tab.sections?.forEach(sec => {
            if (sec.filter_highlight === "1") {
              //the highlight section will be visible only if there are options with the attribute highlight set to true
              ret = this.options.some(opt => opt.highlight);
            }
          })
        })
      } else {
        const menuTabs: Tab[] | undefined = this.menuItems.find(x => x.id === sectionName)?.tabs
        menuTabs?.map(x => x.id).forEach(tabId => {
          if (this.options.filter(o => o.group === tabId).length > 0) {
            ret = true
          }
        })
      }
    }
    return ret;
  }

  public onaccordionExpEvent(event: any) {
    this.accordionExpEvent.emit(event)
  }

  public onPackageSwiperSlideChanged(event: any) {
    this.packageSwiperSlideChanged.emit(event);
  }

  public openCinematicOrSummary(){
    this.isCinematicActiveForSelectedTrim ? this.showCinematicEvent.emit(true) : this.showSummaryModal.emit(true)
  }
}

interface InnerElementData {
  elementData: IDomElementData,
  htmlRef: HTMLElement
}