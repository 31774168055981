import { Component, OnInit } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {


  hideModal: number = 1;
  constructor(
    public uiCommonService: UiCommonService,
  ) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.hideModal = 2;
  }

  getLabel(id: string) {
    return this.uiCommonService.getLabel(id);         
  }

}
