<div class="app-wrapper">
    <div class="upper-bar-container" >
        <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
        <div class="upper-text">
          <span class="header">{{getLabelWithDefaultValue('MXE_MANUAL_PAGE','MXE manuals user page')}}</span>
          <span class="sub-header">{{getLabelWithDefaultValue('MXE_MANUAL','Download your manual')}}</span>
        </div>
    </div>
    <div class="manuals-container">
        <div class="column-title">
            <div class="date">{{getLabelWithDefaultValue('MXE_LAST_UPDATE','Last update')}}</div>
        </div>
        <div class="manuals-inner-container" *ngFor="let manual of manuals" (click)="openManualPdf(manual)">
            <div class="border-container">
                <div class="bordered"></div>
            </div>
            <div class="manuals">
                <div class="info-container">
                    <div class="text-container" >
                      <span class="header">{{manual.title}}</span>
                    </div>
                    <div class="status-ico-container">
                        <div class="last-update">
                            <!-- DA CORREGGERE -->
                            <span class="text">{{manual.lastUpdate}}</span>
                        </div>
                        <img class="download-ico" src="../../../assets/icons/mxe__ico-openExternal.svg">
                    </div>
                 </div>
            </div>
        </div>
        <div class="border-container">
            <div class="bordered"></div>
        </div>
    </div>
</div>