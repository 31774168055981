import { LoadConfigurationResult } from '../../models/load-conf-service.model'

export const configurationInitialState : ConfigurationState = {
    loaded: false,
    loadedConfig: {
        modelCode: 0,
        country: 0,
        language: '',
        config: '',
        frozen: false,
        videoRequested: false,
        modelName: '',
        modelDescription: ''
    }
}

export interface ConfigurationState{
    loaded: boolean
    loadedConfig: LoadConfigurationResult
}