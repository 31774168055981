import { UnrealState } from '../../models/get-scene-state'

export const unrealDesiredSceneStateInitialState: UnrealState = {
    config: [],
    modelCode: '',
    daytime: 'day',
    environment: '',
    camera: '',
    country: '',
    currentState: '',
    language: '',
    animation_states: {},
    fullscreen: false,
    presenterScreen: false,
    hotspots: false,
    viewUi: false,
    xrayEnabled: true
}
