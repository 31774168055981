<div class="mxe__summary-configuration--wrapper" id="summary-wrapper" [@fade]="summaryVisible ? 'visible' : 'hidden'">
    <div class="mxe__spinner-loader" *ngIf="isLoading">
        <div class="lds-ring">
            <div></div><div></div><div></div> <div></div></div>
    </div>
    <div class="mxe__summary-configuration--car-info" id="left-summary-bar">
        <div class="left-column-padding">
            <!--<div class="mxe__summary--brand-name">
                Maserati
            </div>-->
            <div class="mxe__summary--configuration--car-image">
                <img [src]="getCarImage()" alt="{{commercialName}}" />
                <div class="cinematic-container" *ngIf="streamingAvailable && isCinematicActiveForSelectedTrim">
                    <button class="cinematic-button" (click)="openCinematic()">
                        <img class="cinematic-ico" src="../../../assets/icons/mxe-ico_cinematics_summary.svg">
                    </button>
                </div>
            </div>
            <div class="summary-details-container">
                <div class="summary-details-container-content">
                    <div class="mxe__summary--name-and-price">
                        <div class="car-name">
                            <div class="family-name" *ngIf="splittedCommercialName">{{splittedCommercialName[0]}}</div>
                            <div class="trim-name" *ngIf="splittedCommercialName.length > 1">
                                {{splittedCommercialName[1]}}
                            </div>
                        </div>
                        <div class="mxe__summary--configuration--car-price" *ngIf="priceStatus">
                            <!--<span class="mxe__summary--configuration--car-price-label">{{getLabel('SIDEBAR_TOTAL_PRICE')}}</span>-->
                            <span class="mxe__summary--configuration--car-price-value">{{totalPrice}}</span>
                        </div>
                    </div>
    
                    <div class="client-name" *ngIf="showCustomerName">
                        personalized by
                        <span class="customer">{{customerName}} {{customerSurname}} </span>
                    </div> 
                </div>
            </div>
            <div class="mxe__summary--configuration--more-info ctas-container" id="cta-container">
                    <button *ngIf="showGenericInfoCTA && isMainScreen"
                        (click)="showGenericInfoModal = !showGenericInfoModal" type="button"
                        class="btn btn-bordered btn-transparent btn-more-info"
                        data-linktracking="cta:summary:more-info">
                        <span> {{getLabel('MXE_MORE_INFO_BUTTON')}} </span>
                    </button>
            </div>
            <div class="discover-similar-cars" *ngIf="showSearchNewInvetoryCTA && isMainScreen" id="sni-container">
                <div class="matches"> Matches to your configuration </div>
                <h5>{{carsCounter}} {{commercialName}} similar to your
                    configuration</h5>
                <div class="btn-area">
                    <button *ngIf="isOnCloud" (click)="popupShow = !popupShow" type="button"
                        class="btn btn-bordered btn-transparent btn-similar-models"
                        data-linktracking="cta:summary:discover-cars">
                        <div>
                            <span>discover available cars</span>
                            <span *ngIf="priceStatus && isPriceValid(unformattedBasePrice)">&nbsp; from {{this.formatPrice(unformattedBasePrice)}}</span>
                        </div>
                    </button>
                    <button *ngIf="isOnPremise" (click)="openSniTab()" type="button"
                        class="btn btn-bordered btn-transparent btn-similar-models"
                        data-linktracking="cta:summary:discover-cars">
                        <div>
                            <span>discover available cars</span>
                            <span *ngIf="priceStatus && isPriceValid(unformattedBasePrice)">&nbsp; from {{this.formatPrice(unformattedBasePrice)}}</span>
                        </div>
                    </button>
                </div>
                <!-- <button type="button" class="btn btn-bordered btn-transparent btn-similar-models btn-arrow">
                            video exp
                        </button>  -->
            </div> 
        </div>
    </div>   
    <div class="close-action" id="CLOSE_ACTION">
        <div class="padding">
            <img data-linktracking="top-nav:logo" class="logo-maserati" src="../../../assets/logo-maserati-icon.svg"
                alt="Maserati" (click)="openTridenteModal()"/>
            <span class="summary-main-title"> {{titleCaseWord(getLabel('MENU_SUM'))}} </span>
            <button *ngIf="isMainScreen" type="button" class="btn btn-transparent btn-close"
                data-linktracking="cta:summary:close" (click)="closeSummary()"></button>
        </div>
    </div>
    <div class="mxe__summary-configuration--summary-sidebar" id="right-summary-sidebar">
        <div *ngIf="isMainScreen" class="mxe__summary-configuration--summary-sidebar--absolute-actions">
            <button type="button" class="btn btn-transparent btn-bordered btn-restart"
                data-linktracking="cta:summary:restart-configuration" (click)="showRestartConfigurationConfirmPopup()">
                Restart
                <!--{{getLabel('RESTART_CONFIG_LABEL')}}-->
            </button>
            <button type="button" *ngIf="saveCtaEnabled" class="btn btn-transparent btn-bordered btn-save"
                (click)="openSaveModal()" data-linktracking="cta:summary:save-configuration">
                {{getLabel('SO_SAVE_SUBMIT')}}
            </button>
            <!-- <button *ngIf="sendPdfCtaEnabled" type="button" class="btn btn-accent btn-arrow-black btn-send-pdf" (click)="openSendModal()">
                send pdf
            </button> -->
            <button *ngIf="sendPdfCtaEnabled" type="button" class="btn btn-accent btn-arrow-black btn-send-pdf"
                (click)="printPdf()" data-linktracking="cta:summary:send-pdf">
                {{getLabel('MXE_SEND_PDF_BUTTON')}}
            </button>
        </div>
        <div class="padd-sidebar" id="summary-sidebar" #summarysidebar>
            <h3 id="SUMMARY_CONFIGURATION_RECAP"
                class="mxe__summary-configuration--summary--title mxe__mega-summary-title">
                {{getLabel('SUMMARY_CONFIGURATION_RECAP')}}</h3>
            <ng-container *ngFor="let menu of menuItems;">
                <div *ngIf="isAccordionVisible(menu.tabs)" class="mxe__custom--accordion">
                    <div id="{{menu.id}}" class="mxe__custom--accordion-heading sticky"
                        (click)="toggleAccordion('accordion-summary-'+ menu.id, $event)"
                        [ngClass]="{'is-closed' : !isAccordionExpanded('accordion-summary-'+ menu.id)}"
                        [attr.data-linktracking]="'cta:summary:accordion:' + normalizeStringForAnalytics(menu.id) + [accordionOpened['accordion-summary-'+ menu.id] ? ':close' : ':open']">
                        <div class="mxe__home-elements">
                            <span class="mxe__custom--accordion-heading--title">
                                {{titleCaseWord(getLabel(menu.id))}}
                            </span>
                            <span class="mxe__custom--accordion-arrow"></span>
                        </div>
                    </div>
                    <div class="mxe__custom--accordion-content" data-accordion="accordion-summary" 
                        [ngClass]="{'active' : accordionOpened['accordion-summary-'+ menu.id]}"
                        [ngStyle]="accordionOpened['accordion-summary-'+ menu.id] && {'height': getAccordionHeight(menu.tabs) }">
                        <ng-container *ngFor="let tab of menu.tabs" >
                            <div *ngIf="getOptionsInGroup(tab.id).length > 0" class="exterior-data-config">
                            <!-- cicliamo le sezioni -->
                                <div *ngFor="let item of getOptionsInGroup(tab.id)" class="exterior-data-config-item">
                                    <!-- in assenza di colori -->
                                    <div id="summary_{{item.id}}" *ngIf="item.colorList.length <= 0">
                                        <div class="item-choice">
                                            <div class="flexible-items">
                                                <img [ngClass]="{'rectangular' : menu.id === 'MENU_PACK' || menu.id === 'MENU_OPT' || menu.id === 'MENU_ACC'}"
                                                    [src]="getImagePath(item.group, item.id, '', menu.id)" alt="">
                                                <div class="infos">
                                                    <div>
                                                        <span>{{getLabel(item.group)}}</span>
                                                        <span class="item-section">{{getLabel(item.id, 'opt_name')}}</span>
                                                    </div>
                                                    <span class="choice-price"
                                                        *ngIf="priceStatus && isPriceValid(item.price)">{{this.formatPrice(item.price)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- in presenza di una lista colori -->
                                    <div class="item-choice" *ngIf="item.colorList.length > 0">
                                        <div *ngFor="let color of item.colorList" class="flexible-items">
                                            <img [src]="getImagePath(item.group, item.id, color.colIdent)" alt=""
                                                class="square">
                                            <div class="infos">
                                                <div>
                                                    <span>{{getLabel(item.group)}}</span>
                                                    <span class="item-section">{{getLabel(item.id,
                                                        'opt_color',color.colIdent)}}</span>
                                                </div>
                                                <span class="choice-price"
                                                    *ngIf="priceStatus && isPriceValid(color.price)">{{this.formatPrice(color.price)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <h3 class="mxe__summary-configuration--summary--title">{{getLabel('TECH_SPECS')}}</h3>
            <div id="TECH_SPECS" class="mxe__summary-configuration--summary--specifications">
                <div class="mxe__summary-configuration--summary--spec-item">
                    <span class="label">
                        {{getLabel('TECHDATA_ENGINELAYOUT')}}
                    </span>
                    <span class="data">
                        <span *ngIf="engineLayout && engineLayout !== ''" class="data-label">{{engineLayout}}</span> <span *ngIf="engineSize && engineSize!== ''" class="data-label">{{engineSize}}</span>
                        <span *ngIf="maxSpeed && maxSpeed!== ''" class="data-label">{{maxSpeed}}</span>
                    </span>
                </div>
                <div *ngIf="maxPower && maxPower!== ''" class="mxe__summary-configuration--summary--spec-item">
                    <span class="label">
                        {{getLabel('TECHDATA_MAXPOWER')}}
                    </span>
                    <span class="data">
                        {{maxPower}}
                    </span>
                </div>
            </div>

            <div *ngIf="parsedDimensionsData && parsedDimensionsData.length > 0" class="mxe__custom--accordion">
                <div id="DIMENSIONS_AND_WEIGHT" class="mxe__custom--accordion-heading sticky"
                    (click)="toggleAccordion('accordion-summary', $event)"
                    [ngClass]="{'is-closed' : !isAccordionExpanded('accordion-summary')}"
                    [attr.data-linktracking]="'cta:summary:accordion:dimensions-weights' + accordionOpened['accordion-summary'] ? ':close' : ':open'">
                    <div class="mxe__home-elements">
                        <span class="mxe__custom--accordion-heading--title">
                            {{getLabel('DIMENSIONS_AND_WEIGHT')}}
                        </span>
                        <span class="mxe__custom--accordion-arrow"></span>
                    </div>
                </div>
                <div class="mxe__custom--accordion-content" data-accordion="accordion-summary"
                    [ngClass]="{'active' : accordionOpened['accordion-summary']}"
                    [ngStyle]="accordionOpened['accordion-summary'] && {'height': parsedDimensionsData.length*62 + 'px'}">
                    <div class="flexible-items">
                        <div class="exterior-data-config-specs">
                            <div class="infos" *ngFor="let dato of parsedDimensionsData">
                                <span> {{dato.label}}</span>
                                <span class="choice-price"> {{dato.value}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showWLtpRangeConsumption && ((parsedEmissionsData && parsedEmissionsData.length > 0) || (parsedConsumptionsData && parsedConsumptionsData.length > 0)) " class="mxe__custom--accordion">
                <div id="WLTP_CTA_LABEL" class="mxe__custom--accordion-heading sticky"
                    (click)="toggleAccordion('accordion-summary-em', $event)"
                    [ngClass]="{'is-closed' : !isAccordionExpanded('accordion-summary-em')}"
                    [attr.data-linktracking]="'cta:summary:accordion:emissions' + accordionOpened['accordion-summary-em'] ? ':close' : ':open'">
                    <div class="mxe__home-elements">
                        <span class="mxe__custom--accordion-heading--title">
                            {{getLabel('WLTP_CTA_LABEL')}}
                        </span>
                        <span class="mxe__custom--accordion-arrow"></span>
                    </div>
                </div>
                <div class="mxe__custom--accordion-content" data-accordion="accordion-summary-em"
                    [ngClass]="{'active' : accordionOpened['accordion-summary-em']}"
                    [ngStyle]="accordionOpened['accordion-summary-em'] && {'height': (parsedEmissionsData.length + parsedConsumptionsData.length)*70 + 'px'}">
                    <div class="exterior-data-config-specs">
                        <h3 class="mxe-cta-title">{{getLabel('MXE_EMISSIONS')}}</h3>
                        <div class="infos" *ngFor="let dato of parsedEmissionsData">
                            <span> {{dato.label}}</span>
                            <span class="choice-price"> {{dato.value}} </span>
                        </div>
                        <h3 class="mxe-cta-title">{{getLabel('MXE_CONSUMPTIONS')}}</h3>
                        <div class="infos" *ngFor="let dato of parsedConsumptionsData">
                            <span> {{dato.label}}</span>
                            <span class="choice-price"> {{dato.value}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mxe__custom--accordion" *ngIf="showWheelNormCta && isMainScreen">
                <div class="mxe__custom--accordion-heading sticky">
                    <div class="mxe__home-elements space-between">
                        <span class="mxe__custom--accordion-heading--title">
                            {{getLabel('WHEELNORM_CTA_LABEL')}}
                        </span>
                        <button class="download-btn" type="button" (click)="downloadTyreRegulationPdf()" target="_blank">
                            <img class="downloadImg" src="../../../assets/icons/mxe__ico-download.svg" >
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-disclaimer">{{getLabel('WLTP_DISCLAIMER', '')}}</div>

            <!-- <h3 class="mxe__summary-configuration--summary--title" *ngIf="priceStatus"> {{getLabel('SUMMARY_PRICE_RECAP_TITLE')}}</h3>
            <div class="mxe__summary-configuration--summary--spec-item mxe__mega-summary-price" *ngIf="priceStatus">
                <span class="label">
                    {{getLabel('BASE_PRICE')}}: {{basePrice}}
                </span>
                <span class="label">
                    {{getLabel('STD_EQUIPMENTS_EDIT')}}: {{equipments}}
                </span>
                <span class="label" *ngIf="showTaxes">
                    {{getLabel('TAXES_PRICE')}}: {{taxes}}
                </span>
                <span class="total">
                    {{getLabel('TOTAL_PRICE')}}: {{totalPrice}}
                </span>
            </div>-->
            <!-- <div class="padding-separator"></div> -->
        </div>
    </div>
</div>
<div *ngIf="popupShow" class="mxe__popup-iframe frame-search-inventory">
    <div class="search-inventory-heading">
        <span class="inventory-search-title"> {{getLabel('MXE_INVENTORY_SEARCH_TITLE')}} </span>
        <span class="trim-selector-close" (click)="popupShow = false"></span>
    </div>
    <iframe #iframe id="mySearchInventoryFrame" [src]="searchNewInventoryEndpoint"></iframe>
</div>

<div *ngIf="showGenericInfoModal" class="mxe__popup-iframe frame-more-info">
    <div class="search-inventory-heading">
        <span class="inventory-search-title"> {{getLabel('MXE_MORE_INFO_BUTTON')}} </span>
        <span class="trim-selector-close" (click)="showGenericInfoModal = false"></span>
    </div>
    <iframe #iframe width="1020px" height="760px" id="myIframe" [src]="genericInfoEndpoint"></iframe>
</div>

<!-- <div *ngIf="showSendPdfModal" class="mxe__popup-iframe">
    <span class="trim-selector-close" (click)="showSendPdfModal = false"></span>
    <iframe #iframe width="100%" height="100%" id="myIframe" [src]="getIFrametUrl('pdf')"></iframe>
</div> -->

<mxe-send-pdf-modal 
    [modelCode]="carModelCode"
    [countryCode]="countryCode"
    [languageId]="languageId"
    [pdfEndpoint]="sendPdfBasePath"
    *ngIf="isModalSendPdfOpen" 
    (valueChange)='sendModalStatusController($event)'>
</mxe-send-pdf-modal>


<mxe-restart-configuration-modal
    [showRestartConfirmationModal] = "showRestartConfirmationModal"
    (restartConfigurationConfirmed) = "restartConfiguration($event)">
</mxe-restart-configuration-modal>