import { CarViewsDict, GetInterfaceResponse, Menu, SpecialGraphicOptionals } from '../../models/interface-service.model'

export const interfaceInitialState: InterfaceState = {
    interface: {
        carImages: [],
        carViewsDict: <CarViewsDict>{},
        menu: <Menu[]>[],
        specialGraphicOptionals: <SpecialGraphicOptionals>{},
        version: ''
    }
}

export interface InterfaceState {
    interface: GetInterfaceResponse
}

