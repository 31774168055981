import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { UnrealDesiredSceneStateActions } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { EnvironmentsActions } from '../actions/unreal-environments/environments-exported-actions';
import { MxeReducers } from '../reducers';

@Injectable()
export class EnvironmentsEffects {

    constructor(
        private actions$: Actions,
        private store: Store<MxeReducers>
        ) { }

    EnvironmentsState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(EnvironmentsActions.UPDATE_SELECTED_ENVIRONMENT),
                tap(actions => {
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_ENVIRONMENT({environment: actions.activeEnvironment}))
                })
            ),
        { dispatch: false }
    );
}