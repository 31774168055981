import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ConfigItem, ConfigState } from '../initials/temporary-configs-initial-state';
import { MxeReducers } from '../reducers';
import { Store } from '@ngrx/store';
import { TemporaryConfigActions } from '../actions/temporary-configs/temporary-configs-exported-actions';


@Injectable()
export class ConfigEffects {

  constructor(private actions$: Actions, private store: Store<MxeReducers>) { }

  addConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemporaryConfigActions.TEMP_CONFIG_ADD),
        tap((action) => {
          const configState = localStorage.getItem('temporaryConfigState');   
          const newConfig = action.configItem;
          let newList: ConfigItem[] = [];

          if(configState) {
            const actualList = (JSON.parse(configState) as ConfigState).configItems
            newList = [...actualList, newConfig];
          } else {
            newList = [newConfig];
          }
          const newState: ConfigState = {
            configItems: newList
          }
          localStorage.setItem('temporaryConfigState', JSON.stringify(newState))
        })
      ),
    { dispatch: false }
  );

  deleteConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TemporaryConfigActions.TEMP_CONFIG_DELETE),
        tap((action) => {
          const today = new Date();
          if (localStorage.getItem('temporaryConfigState')) {
            const configList = (JSON.parse(localStorage.getItem('temporaryConfigState')!) as ConfigState).configItems
            const updatedList = configList.filter((configs) => {
              const todayTimestamp = today.getTime();
              const targetDateTimestamp = configs.savedOn;
              const differenceInMilliseconds = targetDateTimestamp - todayTimestamp;
              const millisecondsPerDay = 24 * 60 * 60 * 1000;
              const daysThreshold = 14 * millisecondsPerDay;
              if (differenceInMilliseconds < -daysThreshold) {
                return false;
              }
              return true;
            })
            const newState: ConfigState = { configItems: updatedList}
            localStorage.setItem('temporaryConfigState', JSON.stringify(newState))
          }
        })
      ),
    { dispatch: false }
  );

  initConfig$ = createEffect(
    () => 
    this.actions$.pipe(
      ofType(TemporaryConfigActions.TEMP_CONFIG_INIT),
      tap((action) => {
        localStorage.setItem('temporaryConfigState', JSON.stringify(action))
      })
    ),
    {dispatch: false}
  )

}
