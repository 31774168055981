import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import smoothscroll from 'smoothscroll-polyfill';
import { AppSettingsActions } from 'src/app/core/store/actions/app-settings/app-settings-exported-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { PAIRING_STEP_ENUM, STEP_ENUM } from '../../core/models/common.model';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { Family } from 'src/app/core/models/get-models-service.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { APP_SETTINGS_SET_APPLICATION_STEP } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { NavigationStateService } from 'src/app/core/services/navigation-state.service';
@Component({
  selector: 'app-client-name',
  templateUrl: './client-name.component.html',
  styleUrls: ['./client-name.component.scss'],
})
export class ClientNameComponent implements OnInit {

  @Input() isMainScreen: boolean = true
  @Input() name: string
  @Input() surname: string
  @Input() ephemeralDeviceID: string
  clientNameForm: FormGroup;
  valNameEmpty: boolean = true;
  valSurnameEmpty: boolean = true;
  homeMenuIsOpen = false;
  priceStatus:boolean
  PairingStep = PAIRING_STEP_ENUM
  Step = STEP_ENUM
  screenCastActive: boolean;
  mirroringStoppedByUser: boolean = false;
  families: Family[] = []
  labels: any

  private countryCode$: Subscription;
  private languageId$: Subscription;
  private ephemeralDeviceID$: Subscription;
  private screenCastActive$: Subscription
  private showPrices$: Subscription
  private languageId: string;
  private countryCode: number | undefined;
  private families$: Subscription;
  private labels$: Subscription;
  

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<MxeReducers>,
    private navigation: NavigationStateService,
    private autobahnClient: AutobahnClient,
    private errorDialogService: ErrorDialogService,
    private uiCommonService: UiCommonService,
    private chg: ChangeDetectorRef,
  ) {
    this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.USER}))
  }

  ngOnInit(): void {
    this.clientNameForm = this.formBuilder.group({
      clientName: ['', [Validators.required]],
      clientSurname: ['', [Validators.required]]
    });

    if(this.isMainScreen) {

      this.showPrices$ = this.store.select(s => s.appState).subscribe(appState => {
        this.priceStatus = appState.showPrices;
      })

      this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
        countryCode => this.countryCode = countryCode
      )
  
      this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
        lang => this.languageId = lang
      )
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive)

      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )

      this.families$ = this.store.select(s => s.modelsState.families).subscribe(
        (fam) => {
          this.families = fam
        }
      )

      this.labels$ = this.store.select(s => s.countryPropState.labels).subscribe(
        labels => {
          this.labels = labels
          if(this.screenCastActive && this.ephemeralDeviceID) {
            this.autobahnClient?.setLabels(this.ephemeralDeviceID,this.labels)
            console.log('CLIENT_NAME: labels set')
          }
        }
      )
       
      this.initAdobeTracker()
    }

    smoothscroll.polyfill();
  }

  ngOnChanges(changes: SimpleChanges) : void {
    if(this.clientNameForm) {
      this.clientNameForm.controls['clientName'].setValue(this.name)
      this.clientNameForm.controls['clientSurname'].setValue(this.surname)
      typeof(this.name) != undefined && this.name? this.valNameEmpty = false : this.valNameEmpty = true
      typeof(this.surname) != undefined && this.surname? this.valSurnameEmpty = false : this.valSurnameEmpty = true;
    }
  }

  ngOnDestroy(){
    if(this.isMainScreen) {
      this.countryCode$?.unsubscribe()
      this.languageId$?.unsubscribe()
      this.ephemeralDeviceID$?.unsubscribe()
      this.screenCastActive$?.unsubscribe()
      this.showPrices$?.unsubscribe()
      this.families$?.unsubscribe()
      this.labels$?.unsubscribe()
  }
}

  sendConfig() {
    this.store.dispatch(
      AppSettingsActions.APP_SETTINGS_SAVE_CLIENT_NAME({ 
        clientName: this.clientNameForm.value['clientName'],
      clientSurname: this.clientNameForm.value['clientSurname'] })
    );

    if(this.screenCastActive) {
      this.goToModelSelection(false);
    }

    this.navigation.navigate(['/mxe/models']);
  }

  getValName(event){
    if(event.target.value != ''){
      this.valNameEmpty = false;
    }
    else{
      this.valNameEmpty = true;
    }
  }

  getValSurname(event){
    if(event.target.value != ''){
      this.valSurnameEmpty = false;
    }
    else{
      this.valSurnameEmpty = true;
    }
  }

  skipDataInsert(){
    this.clearCustomerData()
    if(this.screenCastActive) {
      this.goToModelSelection(true);
    }
    this.navigation.navigate(['/mxe/models']);
  }
  
  showHomeMenu(){
    this.homeMenuIsOpen = true;
  }
  
  homeMenuStatusController(modalStatus) {
    this.homeMenuIsOpen = modalStatus;
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
      "pageInfo":
          { 
            "pageName": "client-name" 
          }
        }
      })
  }

  returnToHome(){
    if(this.screenCastActive) {
      Promise.all(this.clearCustomerData()).then(() => this.autobahnClient?.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME) )
    }
    this.navigation.navigate(['/mxe'])
  }

  setCustomerName(event: any){
    if(this.screenCastActive)
      this.autobahnClient?.setCustomerName(this.ephemeralDeviceID, event.target.value);
  }

  setCustomerSurname(event:any){
    if(this.screenCastActive)
      this.autobahnClient?.setCustomerSurname(this.ephemeralDeviceID, event.target.value);
  }

  clearCustomerData(): Promise<void>[]{
    if(this.clientNameForm) {
      this.clientNameForm.reset()
    }
    this.store.dispatch(
      AppSettingsActions.APP_SETTINGS_SAVE_CLIENT_NAME({ 
        clientName: this.clientNameForm.value[''],
        clientSurname: this.clientNameForm.value[''] })
    );
    if(this.screenCastActive) {
      return [
        this.autobahnClient?.setCustomerName(this.ephemeralDeviceID, ''),
        this.autobahnClient?.setCustomerSurname(this.ephemeralDeviceID,'')
      ]
    } else return [];
  }

  onScreenCastActiveEmitter(value) {
    this.screenCastActive = value
  }

  getLabel(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  private goToModelSelection(skipDataInsert: boolean) {
    this.autobahnClient?.setFamilies(this.ephemeralDeviceID, this.families).then(() => {
      let modelSelectionInit = [
        this.autobahnClient?.setPriceStatus(this.ephemeralDeviceID, this.priceStatus),
        this.autobahnClient?.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0)
      ]
      if(skipDataInsert) {
        modelSelectionInit.push(...this.clearCustomerData())
      }
      Promise.all([
        modelSelectionInit
      ]).then(() => this.autobahnClient?.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.MODEL_SELECTOR))
    })

  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "client-name", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }
}
