<div class="mxe__modal--save-configuration full-modal">
    <span class="modal-backdrop" (click)="closeModal()"></span>
    <div class="mxe__modal--save-configuration--content">
        <button type="button" class="trim-selector-close"  (click)="closeModal()"> </button>
        <div class="mxe__padd">
            <h2 class="mxe__modal--save-configuration--title">Send PDF / Video Experience</h2>
            <form class="mxe__modal--save-configuration--form" formControlName="sendPDFForm" >
                <div class="mxe__modal--save-configuration--input-grp">
                    <label class="mxe__modal--save-configuration--input-label" for="clientname">
                        client name
                    </label>
                    <input class="mxe__modal--save-configuration--input-field" type="text" id="clientname"
                        placeholder="Alexander*">
                </div>
                <div class="mxe__modal--save-configuration--input-grp">
                    <label class="mxe__modal--save-configuration--input-label" for="clientsurname">
                        client surname
                    </label>
                    <input class="mxe__modal--save-configuration--input-field" type="text" id="clientsurname"
                        placeholder="Parkinson*">
                </div>

                <div class="mxe__modal--save-configuration--input-grp">
                    <label class="mxe__modal--save-configuration--input-label" for="email">
                        e-mail
                    </label>
                    <input class="mxe__modal--save-configuration--input-field" type="text" id="email"
                        placeholder="alexanderparkinson@gmail.com*">
                </div>

               <!--<div class="mxe__modal--save-configuration--input-grp">
                    <label class="mxe__modal--save-configuration--sendtype">
                        <div type="checkbox-custom">
                            <input type="checkbox" value="1" formControlName="acceptationPolicy" />
                            <span class="checkmark"></span>
                        </div>
                        <span class="type-select">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </span>
                    </label>
                </div>--> 

                <div class="mxe__modal--save-configuration--checkbox-grp">
                    <label class="mxe__modal--save-configuration--sendtype">
                        <div class="checkbox-custom">
                            <input type="checkbox" value="1" formControlName="sendPdfCheckbox" />
                            <span class="checkmark"></span>
                        </div>
                        <span class="type-select">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </span>
                    </label>
                    <label class="mxe__modal--save-configuration--sendtype">
                        <div class="checkbox-custom">
                            <input type="checkbox" value="1" formControlName="sendPdfCheckbox" />
                            <span class="checkmark"></span>
                        </div>
                        <span class="type-select">
                            PDF
                        </span>
                    </label>
                    <label class="mxe__modal--save-configuration--sendtype">
                        <div class="checkbox-custom">
                            <input type="checkbox" value="1" formControlName="drivingExpCheckbox" />
                            <span class="checkmark"></span>
                        </div>
                        <span class="type-select">
                            Driving experience
                        </span>
                    </label>
                </div>
                <div class="button-div">
                <button type="button" class="sendButton" (click)="printSendPdf()">
                    send
                </button>
            </div>
            </form>
        </div>
    </div>

</div>