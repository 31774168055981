<div class="car-model-data-wrapper" 
*ngIf="!showCinematic || idleTimeIsExpired">
    <img (click)="openTrimModal()" data-linktracking="top-nav:logo" class="logo-maserati" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />

    <div id="model-data" class="maserati__car-configurator-upper-panel" [ngClass]="{'align-header': _splittedCommercialName.length < 2}">
        <div class="basic-infos">
            <div class="family-name" *ngIf="_splittedCommercialName">{{_splittedCommercialName[0]}}</div>
            <div class="car-name" *ngIf="_splittedCommercialName.length > 1">
                {{_splittedCommercialName[1]}} <span
                    *ngIf="_splittedCommercialName.length > 2">{{_splittedCommercialName[2]}}</span>
                <span class="configuration-code" *ngIf="priceStatus">{{price}}&nbsp;
                </span>
            </div>
        </div>

        <!--  <div class="customization-infos" *ngIf="priceStatus">
            <div class="car-base-price">
                {{price}}
            </div>
            <span class="price-customizations">
                Monthly Fee: xxxx
            </span>
        </div>-->
    </div>
</div>