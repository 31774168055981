<ng-container *ngFor="let tab of menuItems?.tabs, index as i">
  <div id="{{menuLabel}}" class="mxe__interior--section-container accordion-{{tab.id}} active" *ngIf="isTabVisible(tab.id)">
    <!--HEADER-->
    <div id="{{tab.id}}_header" class="mxe__interior--section-header">
      <div class="header-title" (click)="toggleAccordion(tab.id,i,$event)"
      [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':' + tab.id.toLocaleLowerCase() + [accordionOpened ? ':close' : ':open']">
        <span class="header-text">
          {{getLabel(tab.id)}}
        </span>
        <div class="header-icons">
          <span class="change-camera"
            [attr.data-linktracking]="'cta:configurator:accordion:' + menuLabel.toLocaleLowerCase() + ':'+ tab.id.toLocaleLowerCase() + ':camera'"
            (click)="changeCamera($event, tab.id)"></span>
          <span class="toggle-arrow"></span>
        </div>
      </div>
      <div *ngFor="let selectedOptionDetail of getSelectedOptionsDetails(tab.id)">
        <span class="header-options" [innerHtml]="selectedOptionDetail">
        </span>
      </div>
    </div>
    <!--/HEADER-->
  <!--CONTENT-->
  <div id="{{tab.id}}_content" class="mxe__interior--section-content" 
    [@openCloseAcc]="currentState[i]">
      <div id="{{tab.id}}_wrapper" class="mxe__int-wrapper">
      <ng-container *ngFor="let section of tab.sections">
        <div class="section-container" *ngIf="sectionIsVisible(section.id)" >
          <div id="{{section.id}}" class="subtitles" *ngIf="getLabel(tab.id) != section.label">
            {{ getLabel(section.id) }}
          </div>
          <div *ngFor="let item of getOptionsInSection(section.id, section.filter_fuoriserie)" class="btn-item-container rectangleImg"
            id="{{item.section+'_'+item.id}}">
              <ng-container *ngIf="item.colorList.length > 0">
                <button *ngFor="let c of getColors(item.id, item.colorList, section.filter_fuoriserie)"
                  class="btn-item-selection"
                  [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, item.id, c.colIdent)"
                  (click)="changeConfiguration(item.id, section.id, c.colIdent, c.price)" [class.active]="c.selected"
                  [disabled]="!item.status.selectable && !item.status.selected">
                  <span class="img-wrapper">
                    <img [src]="getImagePath(item.group, item.id, c.colIdent)" />
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="item.colorList.length === 0">
                <button (click)="changeConfiguration(item.id, section.id, '', item.price)" class="btn-item-selection"
                  [class.active]="item.status.selected"
                  [attr.data-linktracking]="getOptionDataLinkTracking(menuLabel, tab.id, section.id, item.id)"
                  [disabled]="!item.status.selectable && !item.status.selected">
                  <span class="img-wrapper">
                    <img [src]="getImagePath(item.group, item.id)" />
                  </span>
                </button>
              </ng-container>
          </div>
          <div id="{{section.id}}_radio" class="radio-container accordion-{{section.id}} active" *ngIf="sectionHasVariants(section.id, section.filter_fuoriserie)">
            <span class="selectedVariant"> 
              <input type="radio" name="selectedVariant" id="selected_variant" [checked]="true">
              <label class="labelVariant">{{getSelectedVariant(section.id, section.filter_fuoriserie)}}</label> 
              <span class="action-edit" [ngClass]="{'opened': standAloneSetEditingInt[section.id] === true}" (click)="changeSelectedVariant(section.id)"></span>   
            </span>
            <ng-container *ngIf="standAloneSetEditingInt[section.id] === true">
              <div class="radio-options" *ngFor="let item of getRadiosInSection(section.id, section.filter_fuoriserie, true)" id="{{item.id}}">
                <span class="label">
                  <input type="radio" name="variant" id="{{item.id}}" value="{{item.id}}" 
                    [checked]="item.status.selected"
                    (click)="changeConfiguration(item.id, section.id, '', item.price)">
                  <label for="{{item.id}}">
                    {{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME, item.price)}}
                    <span class="radio-price available" *ngIf="showPrices">
                      - {{this.formatPrice(item.price)}}
                    </span>
                  </label>
                </span>
              </div>
              <div class="radio-options not-available" *ngFor="let item of getRadiosInSection(section.id, section.filter_fuoriserie, false); let first = first" [ngClass]="{'not-available-first-child': first}" id="{{item.id}}">
                <span class="label not-available">
                  <input type="radio" name="notAvailableVariant" id="{{item.id}}" value="{{item.id}}"
                    [checked]="false"
                    [disabled]="true">
                    <label for="{{item.id}}" class="label-name">
                    {{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME, item.price)}} 
                    <span class="radio-price" *ngIf="showPrices">
                      - {{this.formatPrice(item.price)}}
                    </span>
                    </label>
                    <span class="opt_not_available">
                      {{ getLabel('OPT_NOT_AVAILABLE') }}
                    </span>
                </span>
              </div>
            </ng-container>
          
          </div>
          <div class="checkbox-container" *ngIf="getCheckboxInSection(section.id, section.filter_fuoriserie).length > 0">
            <div class="checkbox-options" 
              *ngFor="let item of getCheckboxInSection(section.id, section.filter_fuoriserie)" id="{{item.id}}">
              <span class="label" *ngIf="item.status.selectable" [class.active]="item.status.selected">
                <input type="checkbox" id="{{item.id}}" value="{{item.id}}"
                  (click)="changeConfiguration(item.id, section.id, '', item.price, item.status.selected)"
                  [checked]="item.status.selected">
                <label for="{{item.id}}">
                  {{getLabel(item.id, uiCommonService.LABEL_TYPE_ENUM.NAME, item.price)}}
                  <span class="checkbox-price" *ngIf="showPrices">
                    - {{this.formatPrice(item.price)}}
                  </span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      </div>
  </div>
  <!--CONTENT-->
  </div>
</ng-container>
