export const CAR_DETAILS_ENUM = {
    ACCELERATION: 'acceleration',
    MAX_SPEED: 'maxSpeed',
    MAX_TORQUE: 'maxTorque',
    MAX_POWER: 'maxPower',
    LENGTH: 'length',
    HEIGHT: 'height',
    WIDTH: 'width_no_mirrors',
    WIDTH_WITH_MIRRORS: 'width_with_mirrors',
    TRUNK: 'boot_capacity',
    ENGINE_SIZE: 'engineSize',
    ENGINE_LAYOUT: 'engineLayout',
    TRACTION: 'traction',
    WHEELBASE: 'wheelbase',
    FRONT_TRACK: 'front_track',
    REAR_TRACK: 'rear_track',
    FRONT_OVERHANG: 'front_overhang',
    REAL_OVERHANG: 'rear_overhang',
    TURNING_CIRCLE: 'turning_circle',
    FUEL_TANK_CAPACITY: 'fuel_tank_capacity',
    CONSUMPTION_LOW: 'wltp_consumption_low',
    CONSUMPTION_MEDIUM: 'wltp_consumption_medium',
    CONSUMPTION_HIGH: 'wltp_consumption_high',
    CONSUMPTION_EXTRA_HIGH: 'wltp_consumption_extra_high',
    CONSUMPTION_COMBINED: 'wltp_consumption_combined',
    EMISSIONS_LOW: 'wltp_emissions_low',
    EMISSIONS_MEDIUM: 'wltp_emissions_medium',
    EMISSIONS_HIGH: 'wltp_emissions_high',
    EMISSIONS_EXTRA_HIGH: 'wltp_emissions_extra_high',
    EMISSIONS_COMBINED: 'wltp_emissions_combined'
}

export const IMAGE_TYPE_ENUM = {
    MENU_ICON: 'menu_icon',
    INFO_1400_875: 'info1400x875'

}

export const LABEL_TYPE_ENUM = {
    DESCRIPTION: 'opt_descr',
    NAME: 'opt_name',
    COLOR: 'opt_color',
    VIDEO: 'opt_video'
}

export const MENU_ENUM = {
    HIGH: 'MENU_HIGHLIGHT',
    EXT: 'MENU_EXT',
    INT: 'MENU_INT',
    ACC: 'MENU_ACC',
    OPT: 'MENU_OPT',
    PACK: 'MENU_PACK'
}

export const OPTION_TYPE_ENUM = {
    OPTION: 'option',
    RADIO: 'radio',
    CHECKBOX: 'checkbox'
}

export interface IConflictsData {
    toAdd: IOptionConflictItem[]
    toRemove: IOptionConflictItem[]
}

export interface IOptionConflictItem {
    id: string
    price: string
}

export interface IOptionInPackage {
    group: string
    id: string,
    isSelectable: boolean
}

export const CTA_KEYS_ENUM = {
    PDF: 'PDF_CTA',
    SAVE_CTA_WTE: 'SAVE_CTA_WTE',
    GET_VIDEO: 'GET_VIDEO_CTA',
    SEARCH_NEW_INVENTORY: 'MXE_SEARCH_NEW_INVENTORY_CTA',
    SEARCH_NEW_INVENTORY_COUNT: 'MXE_SEARCH_NEW_INVENTORY_COUNT',
    GENERIC_INFO_CTA: 'GENERIC_INFO_CTA',
    MXE_SAVE_CTA_WTL: 'MXE_SAVE_CTA_WTL',
    WHEELNORM_CTA : 'WHEELNORM_CTA',
    WLTP_RANGE_CTA: 'WLTP_RANGE_CTA'

}

export interface IDomElementData {
    elementIndex: number;
    offTop: number;
    offHeight: number;
    headerHeight?: number;
    id?: string;
}

export const CAR_MODEL_NAME = {
    GRECALE: 'grecale',
    GHIBLI: 'ghibli',
    LEVANTE: 'levante',
    QUATTROPORTE: 'quattroporte',
    GRANTURISMO: 'granturismo',
    MC20: 'mc20',
    MC20SR: 'mc20sr',
    GRANCABRIO: 'grancabrio',
    G2:'g2'
}

export const PAIRING_STEP_ENUM = {
    HOME: 'home',
    USER: 'user',
    CONFIGURATION_LOADER: 'configuration_loader',
    MODEL_SELECTOR: 'model_selector',
    TRIM_SELECTOR: 'trim_selector',
    CAR_CONFIGURATOR: 'car_configurator'
}

export const STEP_ENUM = {
    HOME: 'home',
    USER: 'user',
    CONFIGURATION_LOADER: 'configuration_loader',
    MODEL_SELECTOR: 'model_selector',
    TRIM_SELECTOR: 'trim_selector',
    CAR_CONFIGURATOR: 'car_configurator'
}

export interface ModelOfInterest {
    childlist : any[];
    code: string;
    descr: string;
}