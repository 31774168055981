<div class="mxe__presenter-screen-modal"
    *ngIf="appExecutionInfos && (!appExecutionInfos.hostName.includes('local') || appExecutionInfos.host == 'localhost:8080'); else localPairing">
    <div *ngIf="!actionIsStart" class="loading-screen-view">
        <h2>Loading</h2>
        <div class="custom-loader"></div>
    </div>
    <div class="mxe__presenter-screen-heading">
        <h1>{{getLabel('MXE_MIRROR_SCREEN_SETUP_TITLE')}}</h1>
        <button class="mxe__close-action" data-linktracking="top-nav:close" (click)="closeModal()"></button>

    </div>
    <div class="mxe__presenter-screen-camera">

        <!-- camera is always showing a background video -->
        <!-- <ngx-scanner-qrcode #action="scanner" (data)="output = $event" (error)="onError($event)"></ngx-scanner-qrcode> -->
        <!-- if is qrcode -->
        <!-- <div *ngIf="!manuallyInsertTriggered">
            <div class="blur-bg">
            </div>

            <div class="disclaimer-camera" *ngIf="!syncUnsuccessfulQRCode">
                <img src="../../../assets/icons/mxe__ico-eye-screencast.svg" />
                <span *ngIf="!syncUnsuccessfulQRCode">{{getLabel('MXE_QR_CODE')}}</span>
            </div>
            <div class="disclaimer-camera" *ngIf="syncUnsuccessfulQRCode">
                <img src="../../../assets/icons/mxe__ko-icon.svg" alt="ok" />
                <span class="message-label">{{getLabel('MXE_UNSUCCESSFUL_QR_SCAN')}}</span>
            </div> -->
            <!-- if the qrcode is valid -->
            <!-- <div class="scan-successful-message" *ngIf="qrCodeIsOk">
                <div class="scan-successful-message-details">
                    <img class="mxe__qr-code-placeholder" src="../../../assets/icons/mxe__qr-code-icon.svg"
                        alt="qr code" />
                    <div class="mxe__confirmation-message">
                        <img src="../../../assets/icons/mxe__ok-icon.svg" alt="ok" />
                        <span class="message-label">{{getLabel('MXE_SUCCESSFUL_QR_SCAN')}}</span>
                    </div>
                </div>
            </div>

        </div> -->


        <!-- if is not qrcode -->
        <div class="insert-manually-code-form">
            <div class="form-centered-elements">
                <form [formGroup]="manualInputForm" (ngSubmit)="manualSubmission()">
                    <div class="input-group" [ngClass]="{'qr-code-sync':qrCodeIsSync}">
                        <input #pairingCode type="text" formControlName="clientID" required />
                        <button type="submit" class="btn btn-transparent btn-bordered"
                            [disabled]="manualInputForm.invalid"> {{getLabel('BTN_CONTINUE')}} </button>
                    </div>
                </form>
                <!-- initail state -->
                <div *ngIf="!manualCodeCheck">
                    <div class="text-disclaimer" *ngIf="!manualCodeIsOk && !syncUnsuccessfulManualCode">
                        <img src="../../../assets/icons/mxe__ico-edit-small-white.svg" />
                        <span>
                            Insert the manual key shown on your screen
                        </span>
                    </div>
                    <!-- once the user insert the correct deviceId -->
                    <div class="text-disclaimer" *ngIf="manualCodeIsOk">
                        <img src="../../../assets/icons/mxe__ok-icon.svg" alt="ok" />
                        <span>
                            {{getLabel('MXE_SUCCESSFUL_QR_SCAN')}}
                        </span>
                    </div>
                    <!-- once the user insert the wrong deviceId -->
                    <div class="text-disclaimer" *ngIf="syncUnsuccessfulManualCode">
                        <img src="../../../assets/icons/mxe__ko-icon.svg" alt="ok" />
                        <span>
                            {{getLabel('MXE_UNSUCCESSFUL_QR_SCAN')}}
                        </span>
                    </div>
                </div>
                <div *ngIf="manualCodeCheck" class="manual-check">
                    <span>
                        <div class="custom-loader"></div>
                        we are checking the code validity
                    </span>
                </div>

            </div>
        </div>
    </div>
    <div class="mxe__presenter-screen-bottom-bar">

        <!-- <button *ngIf="!qrCodeIsOk" class="btn btn-transparent btn-bordered" (click)="insertCodeManually()">{{
            !manuallyInsertTriggered ? getLabel('MXE_ENTER_KEY_BUTTON') : 'scan the QRcode'}}</button> -->
        <!-- <button (click)="toggleCamera()" [disabled]="action.isLoading"> activate camera </button> -->
       
    </div>
</div>


<ng-template #localPairing>
    <div class="mxe__presenter-screen-modal">
        <div *ngIf="!actionIsStart" class="loading-screen-view">
            <h2>Loading</h2>
            <div class="custom-loader"></div>
        </div>
        <div class="mxe__presenter-screen-heading">
            <h1>{{getLabel('MXE_MIRROR_SCREEN_SETUP_TITLE')}}</h1>
            <button class="mxe__close-action" data-linktracking="top-nav:close" (click)="closeModal()"></button>

        </div>
        <div class="mxe__presenter-screen-camera" style="background: #000;">
            <div class="insert-manually-code-form">
                <div class="form-centered-elements">
                    <form [formGroup]="manualInputForm" (ngSubmit)="manualSubmission()">
                        <div class="input-group" [ngClass]="{'qr-code-sync':qrCodeIsSync}">
                            <input type="text" formControlName="clientID" required />
                            <button type="submit" class="btn btn-transparent btn-bordered"
                                [disabled]="manualInputForm.invalid"> {{getLabel('BTN_CONTINUE')}} </button>
                        </div>
                    </form>
                    <!-- initail state -->
                    <div *ngIf="!manualCodeCheck">
                        <div class="text-disclaimer" *ngIf="!manualCodeIsOk && !syncUnsuccessfulManualCode">
                            <img src="../../../assets/icons/mxe__ico-edit-small-white.svg" />
                            <span>
                                Insert the manual key shown on your screen
                            </span>
                        </div>
                        <!-- once the user insert the correct deviceId -->
                        <div class="text-disclaimer" *ngIf="manualCodeIsOk">
                            <img src="../../../assets/icons/mxe__ok-icon.svg" alt="ok" />
                            <span>
                                {{getLabel('MXE_SUCCESSFUL_QR_SCAN')}}
                            </span>
                        </div>
                        <!-- once the user insert the wrong deviceId -->
                        <div class="text-disclaimer" *ngIf="syncUnsuccessfulManualCode">
                            <img src="../../../assets/icons/mxe__ko-icon.svg" alt="ok" />
                            <span>
                                {{getLabel('MXE_UNSUCCESSFUL_QR_SCAN')}}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="manualCodeCheck" class="manual-check">
                        <span>
                            <div class="custom-loader"></div>
                            we are checking the code validity
                        </span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>