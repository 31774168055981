import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'mxe-video-modal-with-descriptions',
  templateUrl: './video-modal-with-descriptions.component.html',
  styleUrls: ['./video-modal-with-descriptions.component.scss']
})
export class VideoModalWithDescriptionsComponent  implements OnInit {
  @Input() videoSource = '';
  @Input() videoModalOpen: string;
  @Input() videoTitle: string;
  @Input() videoDescription: string;
  @Output() valueChange = new EventEmitter();
  modalStatus: boolean;
  modalOpen: boolean;


  hideModal: boolean = false;
  currentPathName: string = '';

  
  constructor(
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    this.currentPathName = window.location.pathname;
  }
  getVideoUrl(){
    let basePath = 'https://www.youtube.com/embed/';
    const idVideo = this.videoSource.split('=').pop();
    basePath = basePath+idVideo;
    let videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(basePath);
    return videoUrl;
  }
  closeModal() {
    this.modalStatus = false;
    this.valueChange.emit(this.modalStatus);
  }
}