import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy'
import { MxeReducers } from '../store/reducers';
import { Store } from '@ngrx/store';
import { UserState } from '../store/initials/user-initial-state';
import { distinctUntilChanged, map, Subscription, tap } from 'rxjs';
import * as uuid from 'uuid';
import { AppState } from '../store/initials/app-settings-initial-state';

@Injectable({
    providedIn: 'root'
  })
  export class SentryMessaging {
    private userState: UserState;
    private userState$: Subscription
    private appState: AppState;
    private appState$: Subscription
    private SENTRY_SESSION_ID: string;

    public getSentrySessionId(): string {
        return this.SENTRY_SESSION_ID;
    }
    constructor(private store: Store<MxeReducers>) {
        this.userState$ = this.store.select(s => s.userState).pipe(
            tap(userState => this.userState = userState),
            map(userState => (userState as UserState)?.dealerId),
            distinctUntilChanged()
        ).subscribe((dealerId: string) => {
            if (dealerId) {
                this.SENTRY_SESSION_ID = `session-${uuid.v4()}`
                Sentry.setTags({
                    userSessionId: this.SENTRY_SESSION_ID,
                })
                Sentry.setUser({
                    id: this.userState.dealerId
                })
            }
        })
        this.appState$ = this.store.select(s => s.appState).subscribe(
            (appState: AppState) => this.appState = appState
        )
    }


    public logEvent(message: string, severity: Sentry.SeverityLevel, extra: any): string {
        return Sentry.captureEvent({
            message: message,
            level: severity,
            user: {
                dealerCode: this.userState.dealerId,
                dealersInfos: this.userState.dealersInfo,
                applicationStep: this.appState.applicationStep,
                countryCode: this.appState.countryCode,
                languageId: this.appState.languageId
            },
            extra: extra
        })
    }
  }