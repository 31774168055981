import { Component, EventEmitter, Output } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'app-stop-screen-cast-modal',
  templateUrl: './stop-screen-cast-modal.component.html',
  styleUrls: ['../afk-modal/afk-modal.component.scss']
})
export class StopScreenCastModalComponent {

  @Output() modalClosingEvent = new EventEmitter()
  @Output() modalConfirmedEvent = new EventEmitter()
  constructor(
    private uiCommonService: UiCommonService
    ) { }
  onConfirm(){
    this.modalConfirmedEvent.emit()
    this.modalClosingEvent.emit()
  }

  onCancel(){
    this.modalClosingEvent.emit()
  }
  
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

}
