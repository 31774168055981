
<div class="mxe__modal--save-configuration" *ngIf="hideModal != 2">
    
    <div class="mxe__modal--save-configuration--content">
        <button type="button" (click)="closeModal()" class="mxe_close-x"></button>
        <div class="mxe__padd">
            <h2 class="mxe__modal--save-configuration--title">Dettaglio modifiche</h2>
            
            <div class="mxe__package-configuration-details">
                <h5 class="grey-label">Hai aggiunto</h5>
                <div class="package-info package-added">
                    harman kardon premium sound system
                    <span class="price-pkg">
                        &euro; 1.830
                    </span>
                </div>
                <h5 class="grey-label">{{getLabel("ALERT_MODULE_REMOVES")}}</h5>
                <div class="package-info package-removed">
                    harman kardon premium sound system
                    <span class="price-pkg">
                        &euro; 2.830
                    </span>
                </div>

                <div class="package-info package-totals">
                    variazione del prezzo delle modifiche
                    <span class="price-pkg">
                        - &euro; 976
                    </span>
                </div>
            </div>
            <div class="mxe__action-buttons">

                <button type="button"class="btn btn-bordered btn-transparent btn-dark">
                    cancel choice
                </button>
                <button
            class="btn btn-accent"
            >confirm</button>
            </div>
        </div>
    </div>
    
</div>