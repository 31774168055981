import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-restart-configuration-modal',
  templateUrl: './restart-configuration-modal.component.html',
  styleUrls: ['./restart-configuration-modal.component.scss']
})
export class RestartConfigurationModalComponent implements OnInit {
  
  @Input() showRestartConfirmationModal: boolean;
  @Output() restartConfigurationConfirmed = new EventEmitter()

  constructor(private uiCommonService: UiCommonService) { }

  ngOnInit(): void {
  }

  getLabel(key: string, defaultValue: string) {
    return this.uiCommonService.getLabel(key, '', '' , '' ,  defaultValue);
  }

  closeModal(reason: string) {
    this.restartConfigurationConfirmed.emit(reason);
  }
}
