import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { RESET_AUTHORIZATION_TOKEN } from '../store/actions/users/user-actions';
import { MxeReducers } from '../store/reducers';

@Injectable()
export class AuthService {

  public wrongDealerConfigPopUpShown : boolean = false;

  constructor(
    private store: Store<MxeReducers>
  ) {
  }
  
  async checkTokenExpiration(jwt_token_expiration_time: number, access_toke_expiration_time: number): Promise<boolean> {
      if(!(Date.now() / 1000 < +jwt_token_expiration_time!) || !(Date.now() / 1000 < +access_toke_expiration_time!)){
        await this.clearToken()
        this.userAuthentication()
      }
      return true
  }
  
  async userAuthentication() {
    const authUrl = `${environment.authentication.maserati_authentication_endpoint}/authorize?response_type=code+id_token&resource=${environment.authentication.resource}&client_id=${environment.authentication.client_id}&redirect_uri=${environment.authentication.redirect_uri}`;
    window.open(authUrl, '_self')!.focus();
  }

  
  private clearToken(): Promise<boolean> {
    return new Promise((resolve) => {
      this.store.dispatch(RESET_AUTHORIZATION_TOKEN())
      resolve(true)
    })
  }
}
