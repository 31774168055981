import { Action, createReducer, on } from '@ngrx/store';
import { CamerasActions } from '../actions/unreal-cameras/cameras-exported-ections';
import { camerasInitialState } from '../initials/cameras.initial-state';

const _cameraReducer = createReducer(camerasInitialState,
    on(CamerasActions.UPDATE_SELECTED_CAMERA, (state, payload) => ({
        ...state,
        activeCamera: payload.activeCamera
    })),
    on(CamerasActions.UPLOAD_CAMERAS, (state, payload) => ({
        ...state,
        cameras: payload.cameras
    }))
)

export function cameraReducer( state = camerasInitialState, action: Action){
    return _cameraReducer(state, action)
}