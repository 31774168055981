import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IDomElementData, IMAGE_TYPE_ENUM, MENU_ENUM } from 'src/app/core/models/common.model';
import { Menu, Tab } from 'src/app/core/models/interface-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';
@Component({
  selector: 'app-optional-configuration',
  templateUrl: './optional-configuration.component.html',
  styleUrls: ['./optional-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ 
    trigger('openCloseAcc', [
      state('open', style({ 
          'height': '*'
        })),
      state('closed', style({ 
          'height': '0px'
        })),
      transition('open <=> closed', [
      animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
      ])
 ],
})
export class OptionalConfigurationComponent {
  @Input() options: UIOptItem[];
  @Input() menu: Menu[]
  @Input() modelCode: string
  @Input() currency: string
  @Input() priceMask: string
  @Input() showPrices: boolean;
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != '') this.inputText = inputText.toLowerCase()
    else this.inputText = text
  } 
  @Input() configId: string;
  @Input() optionsForAnalytics: string;
  @Input() dealerId: string;
  @Input() modelName: string;
  @Input() commercialName: string;
  @Input() formatSelection : string;
  @Input() isMainScreen: boolean;
 
  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()
  inputText: string
  selectedOption: string = '';
  optionActive: any = '';
  accCounter: number = 0;
  menuItems: Menu | undefined;
  videoModalOpen: boolean = false;
  videoSelected: string = '';
  videoTitleSelected: string = '';
  videoDescriptionSelected: string = '';
  triggerModalOpen: boolean = false;
  isModalVideoOpen: boolean = false;
  menuLabel: string = '';
  visibleSections: string[] = []
  accordionOpened = true;
  totalOptSelected : number = 0;
  globalHeight : number = 0;
  elementHeaderHeight : number = 0;
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;
  optsFilteredByTabsId : any[] = []
  heightArray : any[] = [];
  setHeightDiv : number;
  visibleItems : Tab[] | undefined;
  formatDetailsForSection : string[] = []
  
  i = 0;
  //NUMBER OF ACCORDION IN OPTIONAL
  @Input() currentState : string[]
  
 
  private domOptionElements: IDomElementData[] = [];
  constructor(
    public uiCommonService: UiCommonService,
    private carConfigurationService: CarConfigurationService,
    private el: ElementRef,
    private mxeAnalyticsService: MxeAnalyticsService,
    private chg: ChangeDetectorRef,
    private store: Store<MxeReducers>,
    private autobahn : AutobahnClient
  ) { }
  ngOnInit() : void {
    this.menuItems = this.menu.find(x => x.id === MENU_ENUM.OPT)
    if (this.menuItems?.id) {
      this.menuLabel = this.menuItems?.id;
    }
    else {
      this.menuLabel = 'Options';
    }
    this.visibleItems = this.menuItems?.tabs.filter(t => this.isTabVisible(t.id));
    this.currentState = ["open","open","open","open","open","open"];
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
   
  }

  ngAfterViewInit(){
    if (this.visibleItems) {
      this.visibleItems.forEach(tab => {
        tab.sections.forEach(sec => {
          var format = this.getSelectedOptionDetails(sec.id)
          if (format.length > 0) {
            this.formatDetailsForSection.push(format);
            this.totalOptSelected = 0
          }
        })
      });
    }
    this.formatDetailsForSection.forEach(element => {
      var splitted = element.split(" ", 1)
      this.totalOptSelected += parseInt(splitted[0])
    });
  }
 
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["options"]) {
      this.formatDetailsForSection = []
      if (this.visibleItems) {
        this.visibleItems.forEach(tab => {
          tab.sections.forEach(sec => {
            var format = this.getSelectedOptionDetails(sec.id)
            if (format.length > 0) {
              this.formatDetailsForSection.push(format);
              this.totalOptSelected = 0
            }
          })
        });
      }
      if (this.formatDetailsForSection.length > 0) {
        this.formatDetailsForSection.forEach(element => {
          var splitted = element.split(" ", 1)
          this.totalOptSelected += parseInt(splitted[0])
        });
      }
      else{
        this.totalOptSelected = 0;
      }
    }
    var totPreSelected = 0;
    if(changes && changes["options"] && changes["options"].firstChange == true  && changes["formatSelection"].currentValue == undefined){
        var menu = changes["menu"].currentValue.find(x => x.id === MENU_ENUM.OPT)
        for(let i = 0; i < changes["options"].currentValue.length; i++){
          for(let j = 0; j < menu.tabs.length; j++){
            if(changes["options"].currentValue[i].group == menu.tabs[j].id ){
               this.optsFilteredByTabsId.push(changes["options"].currentValue[i])
               
            }
          }
        }
        var optsReduced = this.optsFilteredByTabsId.reduce((uniqueValue, o) => {
          if(!uniqueValue.some(obj => obj.id === o.id)) {
            uniqueValue.push(o);
          }
          return uniqueValue;
        },[]);
        for(let i = 0; i < optsReduced.length; i++){
          if(optsReduced[i].quantity){
            totPreSelected += optsReduced[i].quantity;
            changes["formatSelection"].currentValue = totPreSelected.toString();
          }
        }
        var formatSelection = changes["formatSelection"].currentValue
        if(this.screenCastActive){
          this.autobahn.setOptFormatSelectionDetail(this.ephemeralDeviceID,formatSelection);
        }
       
        
      }
      this.formatSelectionDetails();
  
      if(changes && changes["formatSelection"] && changes["formatSelection"].currentValue != changes["formatSelection"].previousValue){
          this.totalOptSelected = parseInt(changes["formatSelection"].currentValue);
          this.formatSelectionDetails();
      }
    
  }
  
  ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$?.unsubscribe();
    }
  }
  
  getImagePath(tab: string, optionId: string): string {
    return this.carConfigurationService.getImagePath(this.modelCode, tab, optionId, IMAGE_TYPE_ENUM.INFO_1400_875)
  }
  getLabel(id: string, type = '') {
    return this.uiCommonService.getLabel(id, type, '', this.modelCode);
  }
  changeConfiguration(id: string, isSelected: boolean) {
    const res = this.carConfigurationService.checkConflicts(id, isSelected ? '0' : '1')
    if(
      (!isSelected && res.changes.toRemove.length > 0) 
      || 
      (!isSelected && res.changes.toAdd.length > 1) 
      ||
      (isSelected && res.changes.toAdd.length > 0)
      ||
      (isSelected && res.changes.toRemove.length > 1))
      {
      this.changeConfigurationEmitter.emit(res.changes)
    }
  }
  toggleAccordion(dataaccordion: string,i,j, e): void {
    e.stopPropagation && e.stopPropagation()
    this.accordionOpened = !this.accordionOpened
    if(i != null){
      this.currentState[i] = this.currentState[i]  === 'open' ? 'closed' : 'open';
    }
    if(j != null){
      this.currentState[j] = this.currentState[j]  === 'open' ? 'closed' : 'open';
    }
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionState(this.ephemeralDeviceID,this.currentState,'opt')
    }
    const boxes = [`#${dataaccordion}_header`, `#${dataaccordion}_content` ]
    boxes.forEach(box => {
      let myTag = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(myTag) {
        
        if(!myTag.classList.contains('closed')) {
          myTag.classList.add('closed');
         
         } else {
          myTag.classList.remove('closed');
        
        }
       
      }
    })
  }
  isValidUrl(url: string): boolean {
    return url?.startsWith("https://www.youtube.com/")
  }
  showModalVideo(link: string, title: string, description: string) {
    this.isModalVideoOpen = true;
    this.videoSelected = link;
    this.videoTitleSelected = title;
    this.videoDescriptionSelected = description;
  }
  videoModalStatusController(modalStatus: boolean) {
    this.isModalVideoOpen = modalStatus;
  }
  formatPrice(price: string): string {
    if(this.uiCommonService.isPriceValid(price))
      return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
    return ''
  }
  /**
   * Get all options for a given section
   * @param sectionId 
   */
  getOptionsBySection(sectionId: string, filter_fuoriserie: string): UIOptItem[] {
    if (filter_fuoriserie != null) {
      if (filter_fuoriserie === '1') {
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        const options_with_fuoriserie_colors = this.options.filter(o =>
          o.section === sectionId
          && o.onlyImplicit === false
          && o.fuoriserie === false
          && o.colorList.length > 0
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        return fuoriserie_options.concat(options_with_fuoriserie_colors)
      }
      return this.options.filter(o => o.section === sectionId && o.fuoriserie === false && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
    }
    return this.options.filter(o => o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  }
  /**
   * Returns if the section is visible or not
   * @param sectionId 
   * @returns 
   */
  sectionIsVisible(sectionId: string, filter_fuoriserie): boolean {
    if (filter_fuoriserie != null) {
      if (filter_fuoriserie === '1') {
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        const options_with_fuoriserie_colors = this.options.filter(o =>
          o.section === sectionId
          && o.fuoriserie === false
          && o.onlyImplicit === false
          && o.colorList.length > 0
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        if (fuoriserie_options.concat(options_with_fuoriserie_colors).length > 0) {
          if (this.visibleSections.includes(sectionId) === false)
            this.visibleSections.push(sectionId)
          return true
        }
      } else {
        if (this.options.filter(o => o.section === sectionId && o.fuoriserie === false && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
          if (this.visibleSections.includes(sectionId) === false)
            this.visibleSections.push(sectionId)
          return true
        }
      }
    } else {
      if (this.options.filter(o => o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
        if (this.visibleSections.includes(sectionId) === false)
          this.visibleSections.push(sectionId)
        return true
      }
    }
    return false
  }
  /**
   * Returns if a given tab is visible
   * @param tabId 
   * @returns 
   */
  isTabVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }
  /**
   * Returns the number of selected option per section and the total price of selection (if prices are shown)
   * @param sectionId 
   * @returns 
   */
  getSelectedOptionDetails(sectionId: string): string {
    let result: string = '';
    let quantity = 0
    let totalPrice = 0
    this.options.filter(o => o.status.selected === true).forEach(option => {
      if (this.visibleSections.includes(option.section) && option.section === sectionId) {
        quantity++
        totalPrice += +option.price
      }
    })
    
    let formattedPrice = ''
    if (totalPrice > 0) {
      formattedPrice = ' - ' + this.formatPrice(totalPrice.toString())
    }
    if (quantity > 1) {
      var selected = this.getLabel("MXE_SELECTED_PLUR_M");
      result = `${quantity} ${selected} ${this.showPrices ? `<span class='selected-price-details'>${formattedPrice}</span>` : ''}`
    } else if (quantity > 0) {
      var selected = this.getLabel("MXE_SELECTED_SING_M");
      result = `${quantity} ${selected} ${this.showPrices ? `<span class='selected-price-details'>${formattedPrice}</span>` : ''}`
    }
    
    return result
  }
  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string = '', action: string = ''): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }
  
  getInitialOptSelectedQuantity(){
   var totalInitialOptSelected : any[] = [];
   var optionSelected = this.options.filter(o => o.status.selected === true);
   optionSelected.forEach(opt =>{
    this.visibleSections.forEach(element => {
      if(element == opt.section){
        totalInitialOptSelected.push(opt);
      }
      
      });
    })
    this.totalOptSelected = totalInitialOptSelected.length;
    this.chg.detectChanges();
  }
  
  formatSelectionDetails(): string {
    var formatSelection : string = "";
    if(this.totalOptSelected < 2){
      var selected = this.getLabel("MXE_SELECTED_SING_M");
      formatSelection = `${this.totalOptSelected} ${selected}`;
    }
    if(this.totalOptSelected > 1){
      var selected = this.getLabel("MXE_SELECTED_PLUR_M");
      formatSelection = `${this.totalOptSelected} ${selected}`;
    }
    if(this.screenCastActive && this.isMainScreen){
      this.autobahn.setOptFormatSelectionDetail(this.ephemeralDeviceID,formatSelection);
    }
    return formatSelection;
  }
 
}