import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanDeactivate } from '@angular/router';
import { AuthorizationComponent } from './components/authorization-component/authorization.component';
import { CarConfiguratorWrapperComponent } from './components/car-configurator-wrapper/car-configurator-wrapper.component';
import { CarModelSelectorComponent } from './components/car-models-selector/car-models-selector.component';
import { CarTrimSelectorComponent } from './components/car-trim-selector/car-trim-selector.component';
import { ClientNameComponent } from './components/client-name/client-name.component';
import { ConfigurationLoaderComponent } from './components/configuration-loader/configuration-loader.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { HomeComponent } from './components/home/home.component';
import { SummaryConfigurationComponent } from './components/summary-configuration/summary-configuration.component';
import { TvScreenViewComponent } from './components/tv-screen-view/tv-screen-view.component';
import { AuthGuard } from './core/services/auth.guard';
import { ExperienceChoiceComponent } from './components/experience-choice/experience-choice.component';
import { UserManualPageComponent } from './components/user-manual-page/user-manual-page.component';
import { PreventBackNavigationGuard } from './core/services/navigation.guard';

const routes: Routes = [
  { path: '', component: ExperienceChoiceComponent, canDeactivate: [PreventBackNavigationGuard]  },
  { path: 'mxe', component: HomeComponent, canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/oauth/redirecturi', component: AuthorizationComponent, canDeactivate: [PreventBackNavigationGuard]},
  
 
  { path: 'mxe/models', component: CarModelSelectorComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/summary', component: SummaryConfigurationComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/configuration', component: ConfigurationLoaderComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard]},
  { path: 'mxe/clientname', component: ClientNameComponent, canActivate: [AuthGuard] , canDeactivate: [PreventBackNavigationGuard]},
  { path: 'mxe/menu', component: HomeMenuComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/detail/:modelname', component: CarConfiguratorWrapperComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/presenter-screen', component: TvScreenViewComponent, canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/models/:mc', component: CarTrimSelectorComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },
  { path: 'mxe/utilities/user-guidelines', component: UserManualPageComponent, canActivate: [AuthGuard], canDeactivate: [PreventBackNavigationGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
