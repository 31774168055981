import { Action, createReducer, on } from '@ngrx/store';
import { INTERFACE_LOADED } from '../actions/interface-actions';
import { interfaceInitialState } from '../initials/interface-initial-state';

const _interfaceReducer = createReducer(interfaceInitialState,
    on(INTERFACE_LOADED, (state, payload) => ({
        ...state,
        interface: payload.interface
    }))
);

export function interfaceReducer( state = interfaceInitialState, action: Action){
    return _interfaceReducer(state, action)
}