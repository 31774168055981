import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { CamerasActions } from '../actions/unreal-cameras/cameras-exported-ections';
import { UnrealDesiredSceneStateActions } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from '../reducers';

@Injectable()
export class CameraEffects {

    constructor(
        private actions$: Actions,
        private store: Store<MxeReducers>
        ) { }

    CamerasState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(CamerasActions.UPDATE_SELECTED_CAMERA),
                tap(actions => {
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_CAMERA({camera: actions.activeCamera.name}))
                })
            ),
        { dispatch: false }
    );
}