<div class="mxe__config-loader--wrapper">
  <div class="mxe__spinner-loader" *ngIf="isLoading">
    <div class="lds-ring">
        <div></div><div></div><div></div> <div></div></div>
  </div>
  <button type="button" class="btn burger-menu" data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
    <span class="bar1"></span>
    <span class="bar2"></span>
    <span class="bar3"></span>
  </button>
  <div>
    <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
    <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
      <span id="label-1" class="swiper-pagination-bullet swiper-pagination-bullet-active" (click)="loadConfiguration('label-1','label-2','label-3',$event)">{{getLabel('MXE_LOAD_CONFIGURATION', 'load configuration')}}</span>
      <span id="label-2" class="swiper-pagination-bullet" (click)="showRestoreConfiguration('label-2','label-1','label-3',$event)">{{getLabel('MXE_RESTORE_CONFIGURATION', 'restore configuration')}}</span>
      <!-- <span *ngIf="false" id="label-3" class="swiper-pagination-bullet" (click)="showSuggestedConfiguration('label-3','label-1','label-2',$event)">SUGGESTED CONFIGURATION</span>  -->
    </div>
    <mxe-pairing-toggle
    [isMainScreen]="isMainScreen"
    [priceStatus]="priceStatus"
    [pairingStep]="PairingStep.CONFIGURATION_LOADER"
    [temporaryConfigItems]="temporaryConfigItemsFromStore"
    [isRestoreConfig]="isRestoreConfiguration"
    [isLoadConfig]="isLoadConfiguration"
    (screenCastActiveEmitter)="onScreenCastActiveEmitter($event)">
    </mxe-pairing-toggle>
  </div>
 <button *ngIf="isMainScreen" class="mxe__close-action" data-linktracking="top-nav:close"  (click)="returnToHome()">{{getLabel('MXE_BACK_TO_HOME', 'Back to home')}}</button>
  
  <div class="mxe__config-loader--container" *ngIf="isLoadConfiguration">
    <div class="fcontent">
      <div class="title-group">
        <div class="title">{{getLabel('MXE_LOAD_CONFIGURATION', 'load configuration')}}</div>
        <div class="subtitle">{{getLabel('MXE_IMPORT_WINDOW_DESCRIPTION')}}</div>
      </div>
      <div class="open-descr">{{getLabel('SO_OPEN_DESCRIPTION')}}</div>
      
      <form class="mxe__config-loader--form" [formGroup]="clientNameForm">
        <div class="input-code">
          <div class="igroup">
            <input
            [ngClass]="{'empty-field': valCodeEmpty}"
            (keyup)="setConfigurationId($event)"
            (paste)="setConfigurationId($event)"
            (blur)="getValCode($event)" class="mxe__config-loader--input-field" type="text" id="pwd" maxlength="8" name="pwd"
            required="required" autocomplete="off" #code formControlName="clientName" />
            <label class="mxe__config-loader--input-label" for="pwd"></label>
          </div>
          
          <div class="bar" [ngClass]="{'hasError':errorLabel!=''}">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="error-label" *ngIf="errorLabel!=''"> {{errorLabel}}</div>
      </form>

      <button type="submit" (click)="loadCarConfig(code.value)" class="btn btn-accent btn-fullwidth btn-go-code load-btn"
        [ngClass]="
      clientNameForm.invalid
        ? 'btn btn-accent btn-fullwidth btn-disabled'
        : 'btn btn-accent btn-fullwidth'
      "
      data-linktracking="cta:load-configuration:import">
      {{getLabel('MXE_LOAD_CONFIGURATION', 'load configuration')}}
      </button>
    </div>
  </div>

    
    <div class="mxe__config-loader--container load-config" *ngIf="isRestoreConfiguration">
        <p class="load-title">{{getLabel('MXE_REST_CONFIG_DESC','All restorable configurations are available for 14 days after they were last opened.')}}</p>
        <div #cardContainer class="card-container" [@listAnimation]="temporaryConfigItemsFromStore.length">
          <!-- [@flyInOut]="'in'" -->

            <div id="last_card" class="card" *ngIf="carModel && carModel.commercialName">
              <div class="card-car-title" style="margin-top: 27px;">{{carModel?.commercialName}}</div>
              <img [src]="getCarImage()" class="imgcont">
              <div class="btn-area">
                <button type="button" class="restart-btn" (click)="loadCarConfig()">
                  <span class="btn-text">{{getLabel('MXE_RESTART_CONFIG','RESTART CONFIGURATION')}}</span>
                  <img src="../../../assets/icons/Chevron_Right.svg">
                </button>
              </div>
            </div>
            <div id="{{savedcars.configId}}_card" class="card" *ngFor="let savedcars of temporaryConfigItemsFromStore">
              <div class="ico-text-container">
                <div class="card-time-exp">{{getLabel('MXE_EXPIRES','Expires in:')+' '+ExpirationTime(savedcars.savedOn)+' '+getLabel('MXE_DAYS','days')}}</div>
                <button class="download-btn" type="button" (click)="printPdf(savedcars.configId)" target="_blank">
                  <img class="downloadImg" src="../../../assets/icons/mxe__ico-download.svg" >
                </button>
              </div>
              <div class="card-car-title">{{savedcars.carName}}</div>
              <div class="saved-date">{{(getLabel('MXE_SAVED_ON','SAVED ON:')+' '+(savedcars.savedOn | date: 'MM/dd/yyyy - h:mm:ss a'))}}</div>
             <img [src]="getCarImage(savedcars.configId)" class="imgcont">
              <div class="btn-area">
                <button type="button" class="restart-btn" (click)="loadCarConfig(savedcars.configId)">
                  <span class="btn-text">{{getLabel('MXE_RESTART_CONFIG','RESTART CONFIGURATION')}}</span>
                  <img src="../../../assets/icons/Chevron_Right.svg">
                </button>
              </div>
            </div>

            
        </div>
    </div>


  <!-- <div *ngIf="isSuggestConfiguration"></div> -->


</div>
<app-home-menu
(homeMenuStatusChange)="homeMenuStatusController($event)"
*ngIf="homeMenuIsOpen"></app-home-menu>